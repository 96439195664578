import React from "react";
import "../components/analytics/analytics.css";
import "../components/exchange/exchange.css";
import { TTheme } from "../types/types";

interface Props {
	path: string;
	group: string;
	className: string;
	theme?: TTheme;
}
const BaseIcon = (props: Props) => {
	const uploadGroup = (): string => {
		switch (props.group.toLowerCase()) {
			case "fiat":
				return "fiat";
			case "tokinezed forex":
				return "fiat";
			case "forex":
				return "fiat";
			default:
				return "crypto";
		}
	};

	return (
		<img
			key={props.path}
			className={props.className}
			src={`https://files.free2ex.com/download/icons/assets/${
				props.theme != undefined ? props.theme : "light"
			}/${uploadGroup()}/${props.path?.toUpperCase()}.svg`}
			alt={""}
		/>
	);
};

export default BaseIcon;
