import React from 'react';
import {TTheme} from "../types/types";

interface Props {
    theme: TTheme
}
const Logo = ({theme}: Props) => {
    return (
        theme === 'light' ? <svg xmlns="http://www.w3.org/2000/svg" width="205" height="36" viewBox="0 0 205 36" fill="none">
            <path d="M144.988 31.6019H138.27L147.906 17.6786L138.846 4.75269H145.564L151.514 13.5746L157.426 4.75269H164.183L155.123 17.6786L164.758 31.6019H158.002L151.514 21.8213L144.988 31.6019Z" fill="#06205E"/>
            <path d="M27.8776 31.6019H22.123V4.75269H33.1714C34.5782 4.75269 35.8697 4.89349 37.0462 5.17476C38.248 5.45603 39.3734 5.91604 40.4219 6.55553C41.496 7.19466 42.3273 8.10229 42.9157 9.27876C43.5296 10.4549 43.8364 11.8612 43.8364 13.498C43.8364 17.5638 41.8799 20.2231 37.9669 21.4758L45.9847 31.6019H39.3477L32.0974 22.2047H27.8776V31.6019ZM27.8776 9.8158V17.4101H33.44C34.7445 17.4101 35.8186 17.1033 36.6626 16.4897C37.5319 15.8503 37.9669 14.8916 37.9669 13.613C37.9669 12.3343 37.5451 11.35 36.701 10.7362C35.857 10.1226 34.77 9.8158 33.44 9.8158H27.8776Z" fill="#06205E"/>
            <path d="M49.1426 4.75269H68.0867V9.8158H54.9063V15.5307H66.9338V20.5555H54.9063V26.5389H68.3172V31.6019H49.1426V4.75269Z" fill="#06205E"/>
            <path d="M71.4785 4.75269H90.4227V9.8158H77.2423V15.5307H89.2696V20.5555H77.2423V26.5389H90.653V31.6019H71.4785V4.75269Z" fill="#06205E"/>
            <path d="M115.936 4.75269H134.88V9.8158H121.699V15.5307H133.727V20.5555H121.699V26.5389H135.11V31.6019H115.936V4.75269Z" fill="#06205E"/>
            <path d="M0 4.75269H18.9615V9.93065H5.75768V16.1829H17.8483V21.361H5.75768V31.6019H0V4.75269Z" fill="#06205E"/>
            <path d="M112.776 26.9892V31.6018H94.521V28.7481L102.647 18.8582L103.943 17.2943L105.16 15.848C105.395 15.5614 105.67 15.1833 105.984 14.7145C106.534 13.9324 106.809 13.1769 106.809 12.4469C106.809 11.561 106.508 10.8182 105.906 10.2188C105.304 9.61945 104.427 9.31977 103.276 9.31977C102.124 9.31977 101.247 9.65848 100.567 10.3362C99.8864 11.0137 99.4806 11.9649 99.3498 13.1897H93.8145C93.8667 10.5054 94.7174 8.38164 96.366 6.81786C98.0151 5.22819 100.266 4.43335 103.118 4.43335C105.971 4.43335 108.275 5.21506 109.949 6.77885C111.651 8.31638 112.501 10.2058 112.501 12.4469C112.501 13.698 112.135 14.8837 111.402 16.0044C110.852 16.8646 109.491 18.6234 107.319 21.2817L102.608 26.9892H112.776Z" fill="url(#paint0_linear_2281_7767)"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M186.167 0C196.108 0 204.166 8.05796 204.166 18.0018C204.166 27.9456 196.108 36 186.167 36C176.227 36 168.166 27.9421 168.166 18.0018C168.166 8.06151 176.224 0 186.167 0ZM178.273 25.2925V21.4499L181.611 18.1117H178.273V14.9512H184.772L188.966 10.757H174.079V25.289H178.273V25.2925ZM192.916 18.5807L192.362 18.0265L199.627 10.7607H193.696L179.162 25.2925H185.092L189.392 20.9921L193.693 25.2925H199.623L193.193 18.8577L192.916 18.5807Z" fill="url(#paint1_linear_2281_7767)"/>
            <defs>
                <linearGradient id="paint0_linear_2281_7767" x1="117.943" y1="4.77865" x2="91.0663" y2="31.6014" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#00BEB4"/>
                    <stop offset="1" stopColor="#0166A5"/>
                </linearGradient>
                <linearGradient id="paint1_linear_2281_7767" x1="204.12" y1="1.55594e-06" x2="168.155" y2="35.9275" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#00BEB4"/>
                    <stop offset="1" stopColor="#005BA3"/>
                </linearGradient>
            </defs>
        </svg> : <svg xmlns="http://www.w3.org/2000/svg" width="204" height="36" viewBox="0 0 204 36" fill="none">
            <path d="M144.988 31.5705H138.27L147.905 17.6616L138.845 4.74902H145.564L151.514 13.5619L157.426 4.74902H164.182L155.123 17.6616L164.758 31.5705H158.002L151.514 21.8L144.988 31.5705Z" fill="white"/>
            <path d="M27.8755 31.5705H22.1211V4.74902H33.1693C34.5761 4.74902 35.8676 4.88969 37.0439 5.17066C38.2459 5.45163 39.3711 5.91117 40.4196 6.55C41.4938 7.18847 42.325 8.09516 42.9134 9.27042C43.5273 10.4453 43.8341 11.8502 43.8341 13.4853C43.8341 17.5468 41.8776 20.2035 37.9646 21.4549L45.9823 31.5705H39.3455L32.0952 22.183H27.8755V31.5705ZM27.8755 9.80685V17.3934H33.4378C34.7423 17.3934 35.8164 17.0869 36.6604 16.474C37.5297 15.8351 37.9646 14.8774 37.9646 13.6001C37.9646 12.3228 37.5428 11.3396 36.6988 10.7263C35.8548 10.1133 34.7679 9.80685 33.4378 9.80685H27.8755Z" fill="white"/>
            <path d="M49.1406 4.74902H68.0844V9.80685H54.9042V15.5159H66.9314V20.5355H54.9042V26.5127H68.3148V31.5705H49.1406V4.74902Z" fill="white"/>
            <path d="M71.4766 4.74902H90.4203V9.80685H77.2402V15.5159H89.2674V20.5355H77.2402V26.5127H90.6507V31.5705H71.4766V4.74902Z" fill="white"/>
            <path d="M115.934 4.74902H134.877V9.80685H121.697V15.5159H133.724V20.5355H121.697V26.5127H135.108V31.5705H115.934V4.74902Z" fill="white"/>
            <path d="M0 4.74902H18.9611V9.92164H5.75758V16.1675H17.848V21.3401H5.75758V31.5705H0V4.74902Z" fill="white"/>
            <path d="M112.77 26.9603V31.5681H94.5152V28.7173L102.641 18.8377L103.937 17.2755L105.154 15.8306C105.389 15.5443 105.664 15.1666 105.978 14.6982C106.528 13.917 106.803 13.1623 106.803 12.4331C106.803 11.548 106.502 10.8061 105.9 10.2073C105.298 9.6085 104.421 9.30911 103.27 9.30911C102.118 9.30911 101.241 9.64748 100.561 10.3246C99.8804 11.0013 99.4746 11.9516 99.3439 13.175H93.8086C93.8608 10.4936 94.7116 8.37195 96.3602 6.80978C98.0092 5.22175 100.26 4.42773 103.112 4.42773C105.965 4.42773 108.268 5.20864 109.943 6.77081C111.644 8.30676 112.495 10.1942 112.495 12.4331C112.495 13.6828 112.128 14.8672 111.396 15.9869C110.846 16.8461 109.485 18.6031 107.313 21.2587L102.602 26.9603H112.77Z" fill="white"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M186.084 0C195.979 0 204 8.05797 204 18.0018C204 27.9456 195.979 36 186.084 36C176.189 36 168.164 27.942 168.164 18.0018C168.164 8.06152 176.185 0 186.084 0ZM178.225 25.2926V21.45L181.548 18.1117H178.225V14.9512H184.694L188.869 10.7571H174.05V25.289H178.225V25.2926ZM192.8 18.5806L192.249 18.0266L199.482 10.7606H193.578L179.109 25.2926H185.012L189.293 20.9921L193.575 25.2926H199.478L193.076 18.8576L192.8 18.5806Z" fill="white"/>
        </svg>
    );
};

export default Logo;