import React, { useContext, useEffect, useState } from "react";
import "./card-deposit.css";
import CustomButton from "../common/button/custom-button";
import { useTranslation } from "react-i18next";
import CustomInput from "../common/input/custom-input";
import { getCommission, initCardDepositPage, sendCardDeposit } from "../../api/api";
import CircleProgress from "../circle-progress/circle-progress";
import ExchangeModalSelect from "../exchange/exchange-modal-select/exchange-modal-select";
import { useTelegram } from "../../hooks/useTelegram";
import { Divider } from "@mui/material";
import { ThemeContext } from "../../data/theme-provider";
import { TTheme } from "../../types/types";
import { Helmet } from "react-helmet";
import { Titles } from "../../types/titles";
import { FirebaseContext } from "../../data/firebase-provider";
import { AnalyticsEventsName } from "../../types/analytics/analytics-events";

interface Props {
	isSuccess?: boolean;
}
const CardDeposit = ({ isSuccess }: Props) => {
	const { initializeFirebase, sendAnalytics } = useContext(FirebaseContext);
	const { theme, updateTheme } = useContext(ThemeContext);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [amount, setAmount] = useState<string>("");
	const [isAmountRequired, setIsAmountRequired] = useState<boolean>(false);
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const [assets, setAssets] = useState<any[]>([]);
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const [currentAsset, setCurrentAsset] = useState<any>();
	const [eWallet, setEWallet] = useState<string>("");
	const [commission, setCommission] = useState<string>("");
	const [isButtonLoading, setIsButtonLoading] = useState<boolean>(false);
	const [isError, setIsError] = useState<boolean>(false);
	const [isErrorText, setErrorText] = useState<string>("");

	const { tg } = useTelegram();
	const { t } = useTranslation();

	const initData = async () => {
		await initializeFirebase();
		const wrapperElement = document.getElementById("wrapper");
		if (wrapperElement) wrapperElement.style.removeProperty("text-align");
		updateTheme();
		sessionStorage.setItem(
			"userToken",
			new URLSearchParams(window.location.search).get("token") as string
		);
		setIsLoading(true);
		await initCardDepositPage(
			sessionStorage.getItem("userToken") as string,
			setAssets,
			setIsLoading,
			setCurrentAsset,
			setEWallet
		);
		setIsLoading(false);
	};

	useEffect(() => {
		console.log(isSuccess);
		initData();
	}, []);

	if (isLoading || theme === "" || currentAsset === undefined || assets.length == 0)
		return <CircleProgress thickness={6} size={40} />;

	const handleModalOpen = () => {
		setIsModalOpen(!isModalOpen);
	};

	const handleInputChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
		const regex = new RegExp(`^[0-9]*\\.?[0-9]{0,2}$`);
		if (e.target.value === "" || regex.test(e.target.value)) {
			setAmount(e.target.value);
			e.target.value.length > 0
				? setIsAmountRequired(true)
				: setIsAmountRequired(false);
			if (e.target.value != "" || Number(e.target.value) != 0) {
				const response = await getCommission(
					currentAsset.code,
					e.target.value,
					sessionStorage.getItem("userToken") as string
				);
				setCommission(response.feeAmount);
			}
		}
	};

	const handleCommissionChange = async () => {
		if (amount != "" || Number(amount) != 0) {
			const response = await getCommission(
				currentAsset.code,
				amount,
				sessionStorage.getItem("userToken") as string
			);
			setCommission(response.feeAmount);
		}
	};

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const handleItemClick = (e: any) => {
		setCurrentAsset(e);
	};

	const handleSubmit = async () => {
		await sendAnalytics({
			name: AnalyticsEventsName.depositStart,
			params: {
				deposit_source: "Bot",
			},
		});
		await sendCardDeposit(
			theme,
			currentAsset.code,
			amount,
			eWallet,
			sessionStorage.getItem("userToken") as string,
			setIsButtonLoading,
			setIsError,
			setErrorText
		);
	};

	const handleBackClickButton = () => {
		tg.close();
	};

	return (
		<>
			<Helmet>
				<title>{Titles.CARD_DEPOSIT.INDEX}</title>
			</Helmet>
			<div className={"component-clean" + (theme === "dark" ? "-dark" : "")}>
				<div className={"innerComponent"}>
					<ExchangeModalSelect
						isFrom={false}
						label={t("cardDepositModalHeader") as string}
						data={assets}
						isModalOpen={isModalOpen}
						handleIsModalOpen={handleModalOpen}
						handleItemClick={handleItemClick}
					/>
					<h1
						className={
							"exchangeWelcomeText" + (theme === "dark" ? "-dark" : "")
						}
					>
						{t("cardDepositHeaderText")}
					</h1>
					<CustomInput
						theme={theme as TTheme}
						isInternal={true}
						isRequired={isAmountRequired}
						type={"number"}
						id={"internalAmountInput"}
						label={t("internalAmountText") as string}
						value={amount}
						onChange={handleInputChange}
						onKeyUp={handleCommissionChange}
						className={"internalInputContainer"}
						asset={currentAsset}
						handleModalOpen={handleModalOpen}
						themeNumber={true}
					/>
					{Number(amount) > 0 ? (
						<div className={"creditCardCommissionContainer"}>
							<span
								className={
									"creditCardCommissionText" +
									(theme === "dark" ? "-dark" : "")
								}
							>
								{t("cardDepositCommissionFirst")
									.replace(
										"$code$",
										currentAsset?.code.toUpperCase() as string
									)
									.replace(
										"$feeAmount$",
										Number(commission).toFixed(2)
									)}
							</span>
							<span
								className={
									"creditCardCommissionText" +
									(theme === "dark" ? "-dark" : "")
								}
							>
								{t("cardDepositCommissionSecond")}{" "}
								<span
									className={
										"creditCardCommissionTextBold" +
										(theme === "dark" ? "-dark" : "")
									}
								>
									{String(
										Number(
											Number(amount) - Number(commission)
										).toFixed(2)
									) +
										" " +
										currentAsset?.code.toUpperCase()}
								</span>
							</span>
						</div>
					) : (
						""
					)}
					{isError ? (
						<h1 className={"registrationEmailError"}>{t(isErrorText)}</h1>
					) : (
						<div className={"registrationEmailError"} />
					)}
					<CustomButton
						theme={theme as TTheme}
						isLoading={isButtonLoading}
						onClick={handleSubmit}
						className={"exchangeConfirmButton"}
						isDisabled={Number(amount) <= 0}
						text={t("cardDepositButtonText") as string}
					/>
					<div className={"divider" + (theme === "dark" ? "-dark" : "")}>
						<Divider
							className={"divider" + (theme === "dark" ? "-dark" : "")}
						/>
					</div>
					<CustomButton
						theme={theme as TTheme}
						isBack={true}
						onClick={handleBackClickButton}
						className={"backButton"}
					/>
				</div>
			</div>
		</>
	);
};

export default CardDeposit;
