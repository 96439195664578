import { TTg } from "../types/types";

export const useTelegram = () => {
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	const tg: TTg = window.Telegram.WebApp;

	return {
		tg,
		user: tg?.initDataUnsafe?.user,
		openLink: tg?.openLink,
		biometricManager: tg?.BiometricManager,
	};
};
