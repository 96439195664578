import React, { useContext, useState } from "react";
import { ThemeContext } from "../../../data/theme-provider";
import { useTranslation } from "react-i18next";
import CustomInput from "../../common/input/custom-input";
import { TAddress, TTheme } from "../../../types/types";
import AddressModal from "./modal/address-modal";

interface Props {
	value: string;
	isAddressRequired: boolean;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	handleItemClick: (e: any) => void;
	addresses: TAddress[] | undefined;
}
const AddressesInput = ({ value, isAddressRequired, addresses, handleItemClick }: Props) => {
	const { theme } = useContext(ThemeContext);
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
	const { t } = useTranslation();

	const handleModalOpen = () => {
		setIsModalOpen(!isModalOpen);
	};

	return (
		<>
			<AddressModal
				label={"cryptoWithdrawAddress"}
				assets={addresses}
				isModalOpen={isModalOpen}
				handleIsModalOpen={handleModalOpen}
				handleItemClick={handleItemClick}
			/>
			<CustomInput
				isDisabled={addresses === undefined || addresses.length === 0}
				theme={theme as TTheme}
				onChange={() => ""}
				isBankTransfer={true}
				className={"internalInputContainer"}
				value={value}
				label={t("cryptoWithdrawAddress") as string}
				isRequired={isAddressRequired}
				type={"text"}
				id={"internalInput"}
				handleModalOpen={handleModalOpen}
			/>
		</>
	);
};

export default AddressesInput;
