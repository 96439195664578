import React, {MutableRefObject, useContext} from 'react';
import "../registration.css";
import PinInput from "../../common/input/pin-input";
import CustomButton from "../../common/button/custom-button";
import {PinInputFieldProps} from "react-pin-input-hook";
import {Divider} from "@mui/material";
import {useTranslation} from "react-i18next";
import {ThemeContext} from "../../../data/theme-provider";
import {TTheme} from "../../../types/types";

interface Props {
    email: string;
    isResendCodeActive: boolean;
    handleResendCode: () => void;
    counter: number;
    handleConfirmEmail: () => void;
    handleBackClickFromConfirmEmail: () => void;
    fields: PinInputFieldProps[];
    isError: boolean;
    errorText: string;
    isLoading: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    inputPropsRef?: MutableRefObject<any>;
}
const ConfirmEmail = (
    {
        email,
        handleConfirmEmail,
        handleBackClickFromConfirmEmail,
        handleResendCode,
        isResendCodeActive,
        counter,
        fields,
        isError,
        errorText,
        isLoading,
        inputPropsRef
    }: Props) => {
    const {t} = useTranslation();

    const {theme} = useContext(ThemeContext);

    return (
        <div className={"component-clean" + (theme === 'dark' ? '-dark' : '')}>
            <div className={"innerComponent"}>
                <h1 className={"confirmEmailText" + (theme === 'dark' ? '-dark' : '')}>{t("registrationConfirm")}</h1>
                <h1 className={"confirmCodeText" + (theme === 'dark' ? '-dark' : '')}>{t("registrationCode")} {email}</h1>
                <div className={"codeInputBlock"}>
                    <PinInput fields={fields}/>
                    {isResendCodeActive ?
                        <h1 className={"resendCodeText" + (theme === 'dark' ? '-dark' : '')} onClick={handleResendCode}>{t("registrationResend")}</h1> :
                        <h1 className={"resendCodeText-inactive" + (theme === 'dark' ? '-dark' : '')}>{t("registrationResendIn")} {counter}</h1>
                    }
                </div>
                {isError ? <h1 className={"registrationEmailError"}>{t(errorText)}</h1> : <div className={"registrationEmailError"}/>}
                <CustomButton
                    theme={theme as TTheme}
                    buttonRef={inputPropsRef}
                    isLoading={isLoading}
                    text={t("registrationContinue") as string}
                    type={"submit"}
                    onClick={handleConfirmEmail}
                    className={"confirmEmailButton"}
                    isDisabled={fields.map((field: PinInputFieldProps) => field.value).join('').length < 6}
                />
                <div className={"divider" + (theme === 'dark' ? '-dark' : '')}>
                    <Divider className={"divider" + (theme === 'dark' ? '-dark' : '')}/>
                </div>
                <CustomButton
                    theme={theme as TTheme}
                    isBack={true}
                    onClick={handleBackClickFromConfirmEmail}
                    className={"backButton"}
                />
            </div>
        </div>
    )
};

export default ConfirmEmail;