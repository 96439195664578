const UpdatedLogo = () => {
    return <svg xmlns="http://www.w3.org/2000/svg" width="162" height="48" viewBox="0 0 162 48" fill="none">
        <path d="M2.92073 4.4102C3.78714 4.4102 4.41599 3.90698 4.61163 3.13816H5.18458C4.96099 4.20752 4.08759 4.92741 2.92073 4.92741C1.55124 4.92741 0.447266 3.86504 0.447266 2.46021C0.447266 1.05537 1.55124 0 2.92073 0C4.08759 0 4.96099 0.712901 5.18458 1.78225H4.61163C4.41599 1.00645 3.78714 0.517203 2.92073 0.517203C1.8447 0.517203 1.00624 1.34892 1.00624 2.46021C1.00624 3.5715 1.8447 4.4102 2.92073 4.4102Z" fill="white"/>
        <path d="M9.91255 4.83655L8.52909 3.13816H7.32031V4.83655H6.76832V0.0838709H8.56403C9.63307 0.0838709 10.3807 0.670966 10.3807 1.60752C10.3807 2.36236 9.89858 2.89354 9.15794 3.06827L10.5973 4.83655H9.91255ZM7.32031 0.594084V2.62795H8.55005C9.2907 2.62795 9.8357 2.24354 9.8357 1.60752C9.8357 0.971503 9.2907 0.594084 8.55005 0.594084H7.32031Z" fill="white"/>
        <path d="M14.9362 0.0838709H15.5511L13.6016 3.15214V4.83655H13.0426V3.15214L11.0862 0.0838709H11.7011L13.3221 2.62795L14.9362 0.0838709Z" fill="white"/>
        <path d="M18.4713 0.0838709C19.5683 0.0838709 20.302 0.712902 20.302 1.66344C20.302 2.64892 19.5404 3.29892 18.4014 3.29892H17.3464V4.83655H16.7944V0.0838709H18.4713ZM18.4503 2.7887C19.2329 2.7887 19.757 2.34139 19.757 1.66344C19.757 1.02043 19.2329 0.587095 18.4503 0.587095H17.3464V2.7887H18.4503Z" fill="white"/>
        <path d="M25.2286 0.0838709V0.594084H23.4748V4.83655H22.9228V0.594084H21.176V0.0838709H25.2286Z" fill="white"/>
        <path d="M28.4158 0C29.7853 0 30.8892 1.09032 30.8892 2.46021C30.8892 3.8301 29.7853 4.92042 28.4158 4.92042C27.0463 4.92042 25.9423 3.8301 25.9423 2.46021C25.9423 1.09032 27.0463 0 28.4158 0ZM28.4158 4.40321C29.4918 4.40321 30.3303 3.53655 30.3303 2.46021C30.3303 1.38387 29.4918 0.517203 28.4158 0.517203C27.3397 0.517203 26.5013 1.38387 26.5013 2.46021C26.5013 3.53655 27.3397 4.40321 28.4158 4.40321Z" fill="white"/>
        <path d="M36.5242 0.0838709C37.6212 0.0838709 38.3548 0.712902 38.3548 1.66344C38.3548 2.64892 37.5932 3.29892 36.4543 3.29892H35.3992V4.83655H34.8473V0.0838709H36.5242ZM36.5032 2.7887C37.2858 2.7887 37.8098 2.34139 37.8098 1.66344C37.8098 1.02043 37.2858 0.587095 36.5032 0.587095H35.3992V2.7887H36.5032Z" fill="white"/>
        <path d="M40.2622 4.32633H42.8824V4.83655H39.7102V0.0838709H40.2622V4.32633Z" fill="white"/>
        <path d="M48.0118 4.83655L47.4249 3.50859H44.9724L44.3855 4.83655H43.7916L45.9366 0.0838709H46.4607L48.6057 4.83655H48.0118ZM45.189 3.01236H47.2013L46.1952 0.733869L45.189 3.01236Z" fill="white"/>
        <path d="M52.9254 0.0838709V0.594084H51.1716V4.83655H50.6196V0.594084H48.8728V0.0838709H52.9254Z" fill="white"/>
        <path d="M57.4528 0.594084H54.7417V2.34139H57.1593V2.85161H54.7417V4.83655H54.1897V0.0838709H57.4528V0.594084Z" fill="white"/>
        <path d="M60.9755 0C62.345 0 63.449 1.09032 63.449 2.46021C63.449 3.8301 62.345 4.92042 60.9755 4.92042C59.606 4.92042 58.502 3.8301 58.502 2.46021C58.502 1.09032 59.606 0 60.9755 0ZM60.9755 4.40321C62.0515 4.40321 62.89 3.53655 62.89 2.46021C62.89 1.38387 62.0515 0.517203 60.9755 0.517203C59.8995 0.517203 59.061 1.38387 59.061 2.46021C59.061 3.53655 59.8995 4.40321 60.9755 4.40321Z" fill="white"/>
        <path d="M68.1789 4.83655L66.7954 3.13816H65.5866V4.83655H65.0346V0.0838709H66.8303C67.8994 0.0838709 68.647 0.670966 68.647 1.60752C68.647 2.36236 68.1649 2.89354 67.4242 3.06827L68.8636 4.83655H68.1789ZM65.5866 0.594084V2.62795H66.8164C67.557 2.62795 68.102 2.24354 68.102 1.60752C68.102 0.971503 67.557 0.594084 66.8164 0.594084H65.5866Z" fill="white"/>
        <path d="M74.8168 0.0838709V4.83655H74.2648V1.29301L72.6787 3.92795H72.3154L70.7153 1.27903L70.7293 4.83655H70.1773V0.0838709H70.5965L72.497 3.25698L74.3976 0.0838709H74.8168Z" fill="white"/>
        <path d="M114.415 32.9514H109.113L116.717 21.961L109.568 11.7578H114.869L119.565 18.7215L124.23 11.7578H129.562L122.413 21.961L130.017 32.9514H124.685L119.565 25.231L114.415 32.9514Z" fill="white"/>
        <path d="M21.998 32.9514H17.457V11.7578H26.1756C27.2858 11.7578 28.3049 11.869 29.2332 12.091C30.1817 12.313 31.0697 12.6761 31.8971 13.1809C32.7447 13.6854 33.4007 14.4018 33.865 15.3305C34.3495 16.2589 34.5916 17.3689 34.5916 18.661C34.5916 21.8703 33.0476 23.9695 29.9598 24.9583L36.2868 32.9514H31.0495L25.328 25.5336H21.998V32.9514ZM21.998 15.7544V21.749H26.3874C27.4169 21.749 28.2645 21.5069 28.9306 21.0225C29.6165 20.5177 29.9598 19.761 29.9598 18.7517C29.9598 17.7424 29.6269 16.9655 28.9609 16.4809C28.2948 15.9965 27.4371 15.7544 26.3874 15.7544H21.998Z" fill="white"/>
        <path d="M38.7793 11.7578H53.7285V15.7544H43.3276V20.2655H52.8187V24.2318H43.3276V28.9548H53.9104V32.9514H38.7793V11.7578Z" fill="white"/>
        <path d="M56.4043 11.7578H71.3535V15.7544H60.9526V20.2655H70.4437V24.2318H60.9526V28.9548H71.5354V32.9514H56.4043V11.7578Z" fill="white"/>
        <path d="M91.4883 11.7578H106.438V15.7544H96.0366V20.2655H105.528V24.2318H96.0366V28.9548H106.619V32.9514H91.4883V11.7578Z" fill="white"/>
        <path d="M0 11.7578H14.963V15.8451H4.54353V20.7803H14.0845V24.8676H4.54353V32.9514H0V11.7578Z" fill="white"/>
        <path d="M88.9923 29.3124V32.9534H74.5869V30.7007L80.9997 22.8941L82.0219 21.6597L82.9824 20.518C83.1682 20.2918 83.3851 19.9934 83.6328 19.6232C84.0665 19.0059 84.2834 18.4096 84.2834 17.8334C84.2834 17.134 84.0458 16.5478 83.5708 16.0746C83.0959 15.6015 82.4041 15.3649 81.4954 15.3649C80.5867 15.3649 79.8945 15.6323 79.3577 16.1673C78.8208 16.7021 78.5005 17.4529 78.3974 18.4197H74.0293C74.0705 16.3009 74.7419 14.6244 76.0429 13.39C77.3441 12.1352 79.1203 11.5078 81.3712 11.5078C83.6221 11.5078 85.4401 12.1249 86.7618 13.3592C88.1043 14.5729 88.7754 16.0643 88.7754 17.8334C88.7754 18.8209 88.4862 19.7568 87.9079 20.6415C87.4744 21.3204 86.4004 22.7088 84.6861 24.8071L80.9686 29.3124H88.9923Z" fill="white"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M146.925 8.10938C154.777 8.10938 161.142 14.4765 161.142 22.3337C161.142 30.1909 154.777 36.5552 146.925 36.5552C139.073 36.5552 132.705 30.1881 132.705 22.3337C132.705 14.4793 139.07 8.10938 146.925 8.10938ZM140.689 28.0946V25.0583L143.326 22.4206H140.689V19.9232H145.823L149.136 16.6092H137.376V28.0918H140.689V28.0946ZM152.255 22.791L151.818 22.3533L157.557 16.612H152.872L141.39 28.0946H146.075L149.472 24.6965L152.87 28.0946H157.555L152.474 23.0099L152.255 22.791Z" fill="white"/>
        <path d="M5.14782 43.8694C5.80636 43.6309 6.22646 42.938 6.22646 42.1771C6.22646 40.9164 5.27271 40.1441 3.62636 40.1441H0.140625V47.8672H3.79667C5.4998 47.8672 6.56709 47.0154 6.56709 45.7547C6.56709 44.8688 6.12428 44.0738 5.14782 43.8694ZM3.44469 41.507C4.33032 41.507 4.77313 41.825 4.77313 42.3815C4.77313 42.9608 4.30761 43.4037 3.44469 43.4037H1.68479V41.507H3.44469ZM3.72855 46.5043H1.68479V44.6757H3.78532C4.54605 44.6757 5.04563 45.0051 5.04563 45.5616C5.04563 46.0614 4.63688 46.5043 3.72855 46.5043Z" fill="white"/>
        <path d="M13.5461 41.5751V40.1441H7.86901V47.8672H13.5461V46.4362H9.41317V44.778H13.0919V43.3469H9.41317V41.5751H13.5461Z" fill="white"/>
        <path d="M23.3683 41.5751V40.1441H17.7706V47.8672H19.3148V44.9938H22.8573V43.5627H19.3148V41.5751H23.3683Z" fill="white"/>
        <path d="M29.3063 47.8672H31.1798L29.1701 45.1073C30.0898 44.6871 30.6802 43.858 30.6802 42.779C30.6802 41.189 29.3971 40.1441 27.66 40.1441H24.4013V47.8672H25.9455V45.414H27.5351L29.3063 47.8672ZM25.9455 41.5751H27.6032C28.4548 41.5751 29.1587 42.0408 29.1587 42.779C29.1587 43.5173 28.4548 43.9829 27.6032 43.9829H25.9455V41.5751Z" fill="white"/>
        <path d="M37.7624 41.5751V40.1441H32.0853V47.8672H37.7624V46.4362H33.6295V44.778H37.3083V43.3469H33.6295V41.5751H37.7624Z" fill="white"/>
        <path d="M44.7146 41.5751V40.1441H39.0376V47.8672H44.7146V46.4362H40.5817V44.778H44.2605V43.3469H40.5817V41.5751H44.7146Z" fill="white"/>
        <path d="M58.1073 40.1441L56.4269 45.4821L54.5989 40.1441H53.2932L51.4651 45.4821L49.7847 40.1441H48.1497L50.6931 47.9126H52.2145L53.9517 42.9267L55.6775 47.9126H57.199L59.7423 40.1441H58.1073Z" fill="white"/>
        <path d="M60.7591 40.1441V47.8672H62.3032V40.1441H60.7591Z" fill="white"/>
        <path d="M70.0877 40.1441H63.2979V41.5751H65.9207V47.8672H67.4649V41.5751H70.0877V40.1441Z" fill="white"/>
        <path d="M76.1914 40.1441V43.381H72.6262V40.1441H71.0821V47.8672H72.6262V44.812H76.1914V47.8672H77.7356V40.1441H76.1914Z" fill="white"/>
        <path d="M85.7525 46.5611C84.3446 46.5611 83.3 45.5276 83.3 44.0057C83.3 42.4837 84.3446 41.4502 85.7525 41.4502C86.888 41.4502 87.66 42.0976 87.9439 43.2333H89.5335C89.1815 41.2685 87.7168 40.0078 85.7525 40.0078C83.5271 40.0078 81.7332 41.666 81.7332 44.0057C81.7332 46.3453 83.5271 48.0035 85.7525 48.0035C87.7168 48.0035 89.1815 46.7428 89.5335 44.778H87.9439C87.66 45.9137 86.888 46.5611 85.7525 46.5611Z" fill="white"/>
        <path d="M95.8347 47.8672H97.7081L95.6984 45.1073C96.6181 44.6871 97.2085 43.858 97.2085 42.779C97.2085 41.189 95.9255 40.1441 94.1883 40.1441H90.9297V47.8672H92.4739V45.414H94.0634L95.8347 47.8672ZM92.4739 41.5751H94.1316C94.9831 41.5751 95.6871 42.0408 95.6871 42.779C95.6871 43.5173 94.9831 43.9829 94.1316 43.9829H92.4739V41.5751Z" fill="white"/>
        <path d="M103.351 40.1441L101.205 43.7899L99.0701 40.1441H97.3556L100.433 45.3231V47.8672H101.999V45.3231L105.065 40.1441H103.351Z" fill="white"/>
        <path d="M108.94 40.1441H105.965V47.8672H107.509V45.7093H108.713C110.7 45.7093 112.017 44.5735 112.017 42.8472C112.017 41.2231 110.791 40.1441 108.94 40.1441ZM108.883 44.2896H107.509V41.5638H108.883C109.848 41.5638 110.495 42.0862 110.495 42.8472C110.495 43.7104 109.848 44.2896 108.883 44.2896Z" fill="white"/>
        <path d="M119.374 40.1441H112.584V41.5751H115.207V47.8672H116.751V41.5751H119.374V40.1441Z" fill="white"/>
        <path d="M123.629 40.0078C121.404 40.0078 119.61 41.7796 119.61 44.0057C119.61 46.2317 121.404 48.0035 123.629 48.0035C125.855 48.0035 127.649 46.2317 127.649 44.0057C127.649 41.7796 125.855 40.0078 123.629 40.0078ZM123.629 46.5611C122.221 46.5611 121.177 45.414 121.177 44.0057C121.177 42.5973 122.221 41.4502 123.629 41.4502C125.037 41.4502 126.082 42.5973 126.082 44.0057C126.082 45.414 125.037 46.5611 123.629 46.5611Z" fill="white"/>
        <path d="M130.651 40.1441H129.085L129.209 45.3913H130.527L130.651 40.1441ZM129.868 47.9353C130.368 47.9353 130.776 47.5605 130.776 47.0608C130.776 46.5724 130.368 46.1976 129.868 46.1976C129.38 46.1976 128.971 46.5724 128.971 47.0608C128.971 47.5605 129.38 47.9353 129.868 47.9353Z" fill="white"/>
    </svg>
}

export default UpdatedLogo;