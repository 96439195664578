import React, { ChangeEvent, useContext, useEffect, useState } from "react";
import { ThemeContext } from "../../data/theme-provider";
import { useTranslation } from "react-i18next";
import "./statement.css";
import { TFileInfo, TFormat, TLocale, TTheme } from "../../types/types";
import CustomButton from "../common/button/custom-button";
import { getStatementPdf } from "../../api/api";
import CircleProgress from "../circle-progress/circle-progress";
import CustomInput from "../common/input/custom-input";
import Summary from "./summary/summary";
import { FormControlLabel, RadioGroup } from "@mui/material";
import CustomRadio from "../common/radio/custom-radio";
import { Helmet } from "react-helmet";
import { Titles } from "../../types/titles";
import { FirebaseContext } from "../../data/firebase-provider";
import { AnalyticsEventsName } from "../../types/analytics/analytics-events";

const locale = window.location.pathname.split("/")[1] != "en" ? "ru" : ("en" as TLocale);

const Statement = () => {
	const { initializeFirebase, sendAnalytics } = useContext(FirebaseContext);
	const { theme, updateTheme } = useContext(ThemeContext);
	const { t } = useTranslation();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isError, setIsError] = useState<boolean>(false);
	const [isButtonLoading, setIsButtonLoading] = useState<boolean>(false);

	const [dateFrom, setDateFrom] = useState<string>("");
	const [isDateFromRequired, setIsDateFromRequired] = useState<boolean>(false);

	const [dateTo, setDateTo] = useState<string>("");
	const [isDateToRequired, setIsDateToRequired] = useState<boolean>(false);

	const [value, setValue] = useState<TFormat | string>("");

	const [isSummary, setIsSummary] = useState<boolean>(false);

	const [fileInfo, setFileInfo] = useState<TFileInfo | undefined>(undefined);

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setValue((event.target as HTMLInputElement).value);
	};

	const initPage = async () => {
		await initializeFirebase();
	};

	useEffect(() => {
		setIsLoading(true);
		sessionStorage.setItem(
			"userToken",
			new URLSearchParams(window.location.search).get("token") as string
		);
		updateTheme();
		initPage();
		setIsLoading(false);
	}, []);

	if (isLoading) {
		return <CircleProgress thickness={6} size={40} />;
	}

	const handleDateFromChange = (e: string | ChangeEvent<HTMLInputElement>) => {
		if (typeof e === "string") {
			const regex = new RegExp(
				/^\s*(3[01]|[12][0-9]|0?[1-9])\.(1[012]|0?[1-9])\.((?:19|20)\d{2})\s*$/
			);
			if (e.length <= dateFrom.length) {
				setDateFrom(e);
				e.length >= 1
					? setIsDateFromRequired(true)
					: setIsDateFromRequired(false);
			}
			if (regex.test(e)) {
				const parsedDate = e.split(".");
				if (parsedDate[0] > "29" && parsedDate[1] === "02") {
					setDateFrom("29.02." + parsedDate[2]);
					e.length >= 1
						? setIsDateFromRequired(true)
						: setIsDateFromRequired(false);
					return;
				}
				setDateFrom(e);
				e.length >= 1
					? setIsDateFromRequired(true)
					: setIsDateFromRequired(false);
			}
		}
	};

	const handleDateFromClear = () => {
		setDateFrom("");
		setIsDateFromRequired(false);
	};

	const handleDateToChange = (e: string | ChangeEvent<HTMLInputElement>) => {
		if (typeof e === "string") {
			const regex = new RegExp(
				/^\s*(3[01]|[12][0-9]|0?[1-9])\.(1[012]|0?[1-9])\.((?:19|20)\d{2})\s*$/
			);
			if (e.length <= dateFrom.length) {
				setDateTo(e);
				e.length >= 1 ? setIsDateToRequired(true) : setIsDateToRequired(false);
			}
			if (regex.test(e)) {
				const parsedDate = e.split(".");
				if (parsedDate[0] > "29" && parsedDate[1] === "02") {
					setDateTo("29.02." + parsedDate[2]);
					e.length >= 1
						? setIsDateToRequired(true)
						: setIsDateToRequired(false);
					return;
				}
				setDateTo(e);
				e.length >= 1 ? setIsDateToRequired(true) : setIsDateToRequired(false);
			}
		}
	};

	const handleDateToClear = () => {
		setDateTo("");
		setIsDateToRequired(false);
	};

	const handleSubmit = async () => {
		await sendAnalytics({
			name: AnalyticsEventsName.transactionsStatementSent,
			params: {
				statement_format: value,
				statement_period: dateFrom + " - " + dateTo,
			},
		});
		await getStatementPdf(
			sessionStorage.getItem("userToken") as string,
			dateFrom.replaceAll(".", ""),
			dateTo.replaceAll(".", ""),
			locale,
			value as TFormat,
			setIsError,
			setIsButtonLoading,
			setIsSummary,
			setFileInfo
		);
	};

	if (isSummary) {
		return (
			<>
				<Helmet>
					<title>{Titles.STATEMENT.SUMMARY}</title>
				</Helmet>
				<Summary
					fileName={fileInfo?.fileName as string}
					link={fileInfo?.link as string}
				/>
			</>
		);
	}

	return (
		<>
			<Helmet>
				<title>{Titles.STATEMENT.INDEX}</title>
			</Helmet>
			<div className={"component-clean" + (theme === "dark" ? "-dark" : "")}>
				<div className={"innerComponent"}>
					<h1
						className={
							"exchangeWelcomeText" + (theme === "dark" ? "-dark" : "")
						}
					>
						{t("statementHeader")}
					</h1>
					<div
						className={
							"statementConfirmContainer" +
							(theme === "dark" ? "-dark" : "")
						}
					>
						<RadioGroup value={value} onChange={handleChange}>
							<FormControlLabel
								className={"statementConfirmFirstBlock"}
								value="PDF"
								control={<CustomRadio />}
								label={
									<span
										className={
											"statementRadioText" +
											(theme === "dark" ? "-dark" : "")
										}
									>
										{t("statementPDF")}
									</span>
								}
							/>
							<FormControlLabel
								className={"statementConfirmSecondBlock"}
								value="CSV"
								control={<CustomRadio />}
								label={
									<span
										className={
											"statementRadioText" +
											(theme === "dark" ? "-dark" : "")
										}
									>
										{t("statementCSV")}
									</span>
								}
							/>
						</RadioGroup>
					</div>
					<CustomInput
						theme={theme as TTheme}
						isRequired={isDateFromRequired}
						type={"text"}
						id={"dateOfBirth"}
						label={t("statementDateFrom")}
						value={dateFrom}
						onChange={handleDateFromChange}
						className={"verificationLastName"}
						onClearClick={handleDateFromClear}
						placeHolder={locale === "en" ? "dd.mm.yyyy" : "дд.мм.гггг"}
						isDateOfBirth={true}
					/>
					<CustomInput
						theme={theme as TTheme}
						isRequired={isDateToRequired}
						type={"text"}
						id={"dateOfBirth"}
						label={t("statementDateTo")}
						value={dateTo}
						onChange={handleDateToChange}
						className={"verificationLastName"}
						onClearClick={handleDateToClear}
						placeHolder={locale === "en" ? "dd.mm.yyyy" : "дд.мм.гггг"}
						isDateOfBirth={true}
					/>
					{isError ? (
						<h1 className={"registrationEmailError"}>
							{t("exchangeErrorOperationHeader")}
						</h1>
					) : (
						<div className={"registrationEmailError"} />
					)}
					<CustomButton
						theme={theme as TTheme}
						isLoading={isButtonLoading}
						onClick={handleSubmit}
						className={"exchangeConfirmButton"}
						isDisabled={
							value === "" || dateFrom.length < 10 || dateTo.length < 10
						}
						text={t("statementConfirmButton") as string}
					/>
				</div>
			</div>
		</>
	);
};

export default Statement;
