import { useEffect } from "react";
import "./styles/index.css";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Login from "./components/login/login";
import ErrorPage from "./components/error-page/errorPage";
import Analytics from "./components/analytics/analytics";
import AnalyticsProvider from "./data/analytics-provider";
import Exchange from "./components/exchange/exchange";
import ExchangeProvider from "./data/exchange-provider";
import InternalTransfer from "./components/internal-transfer/internal-transfer";
import InternalProvider from "./data/internal-provider";
import i18n from "./locale/i18n";
import Registration from "./components/registration/registration";
import Verification from "./components/verification/verification";
import CardDeposit from "./components/card-deposit/card-deposit";
import BankDeposit from "./components/bank-deposit/bank-deposit";
import ThemeProvider from "./data/theme-provider";
import { useTelegram } from "./hooks/useTelegram";
import CryptoWithdraw from "./components/crypto-withdraw/crypto-withdraw";
import Statement from "./components/statement/statement";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import BankWithdraw from "./components/bank-withdraw/bank-withdraw";
import FirebaseProvider from "./data/firebase-provider";
import { Helmet } from "react-helmet";
import { Titles } from "./types/titles";
import { ROUTES } from "./types/routes";
import OTC from "./components/otc/otc";

const locale = window.location.pathname.split("/")[1] != "en" ? "ru" : "en";

const App = () => {
	const { tg } = useTelegram();

	useEffect(() => {
		const rootElement = document.getElementById("root");
		if (rootElement) {
			rootElement.style.background =
				tg.colorScheme === "light" ? "#FFFFFF" : "#141414";
		}
		i18n.changeLanguage(locale);
	}, []);

	return (
		<div id={"wrapper"} className={"wrapper"}>
			<LocalizationProvider dateAdapter={AdapterDayjs}>
				<ThemeProvider>
					<FirebaseProvider>
						<BrowserRouter>
							<Routes>
								<Route
									path={ROUTES.OTC}
									element={
										<ExchangeProvider>
											<>
												<Helmet>
													<title>{Titles.OTC.INDEX}</title>
												</Helmet>
												<OTC/>
											</>
										</ExchangeProvider>
									}
								/>
								<Route
									path={ROUTES.STATEMENT}
									element={
										<>
											<Helmet>
												<title>{Titles.STATEMENT.INDEX}</title>
											</Helmet>
											<Statement />
										</>
									}
								/>
								<Route
									path={ROUTES.BANK_WITHDRAW}
									element={
										<ExchangeProvider>
											<>
												<Helmet>
													<title>
														{Titles.BANK_WITHDRAW.INDEX}
													</title>
												</Helmet>
												<BankWithdraw />
											</>
										</ExchangeProvider>
									}
								/>
								<Route
									path={ROUTES.CRYPTO_WITHDRAW}
									element={
										<ExchangeProvider>
											<>
												<Helmet>
													<title>
														{Titles.CRYPTO_WITHDRAW.INDEX}
													</title>
												</Helmet>
												<CryptoWithdraw />
											</>
										</ExchangeProvider>
									}
								/>
								<Route
									path={ROUTES.LOGIN}
									element={
										<>
											<Helmet>
												<title>{Titles.LOGIN.INDEX}</title>
											</Helmet>
											<Login />
										</>
									}
								/>
								<Route
									path={ROUTES.CARD_DEPOSIT}
									element={
										<>
											<Helmet>
												<title>{Titles.CARD_DEPOSIT.INDEX}</title>
											</Helmet>
											<CardDeposit />
										</>
									}
								/>
								<Route
									path={ROUTES.BANK_DEPOSIT}
									element={
										<>
											<Helmet>
												<title>{Titles.BANK_DEPOSIT.INDEX}</title>
											</Helmet>
											<BankDeposit />
										</>
									}
								/>
								<Route
									path={ROUTES.ANALYTICS}
									element={
										<AnalyticsProvider>
											<>
												<Helmet>
													<title>
														{Titles.ANALYTICS.INDEX}
													</title>
												</Helmet>
												<Analytics />
											</>
										</AnalyticsProvider>
									}
								/>
								<Route
									path={ROUTES.EXCHANGE}
									element={
										<ExchangeProvider>
											<>
												<Helmet>
													<title>{Titles.EXCHANGE.INDEX}</title>
												</Helmet>
												<Exchange />
											</>
										</ExchangeProvider>
									}
								/>
								<Route
									path={ROUTES.INTERNAL_TRANSFER}
									element={
										<InternalProvider>
											<>
												<Helmet>
													<title>
														{Titles.INTERNAL_TRANSFER.INDEX}
													</title>
												</Helmet>
												<InternalTransfer />
											</>
										</InternalProvider>
									}
								/>
								<Route
									path={ROUTES.REGISTRATION}
									element={
										<>
											<Helmet>
												<title>{Titles.REGISTRATION.INDEX}</title>
											</Helmet>
											<Registration />
										</>
									}
								/>
								<Route
									path={ROUTES.VERIFICATION}
									element={
										<>
											<Helmet>
												<title>{Titles.VERIFICATION.INDEX}</title>
											</Helmet>
											<Verification />
										</>
									}
								/>
								<Route
									path={ROUTES.ERROR}
									element={
										<>
											<Helmet>
												<title>{Titles.ERROR.INDEX}</title>
											</Helmet>
											<ErrorPage />
										</>
									}
								/>
								<Route path={ROUTES.INDEX} element={<Navigate to={ROUTES.ERROR} />} />
							</Routes>
						</BrowserRouter>
					</FirebaseProvider>
				</ThemeProvider>
			</LocalizationProvider>
		</div>
	);
};

export default App;
