import React, { useContext, useRef, useState } from "react";
import PinInput from "../../common/input/pin-input";
import CustomButton from "../../common/button/custom-button";
import { PinInputFieldProps, usePinInput } from "react-pin-input-hook";
import { TMappedAsset, TTheme } from "../../../types/types";
import { Divider } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ThemeContext } from "../../../data/theme-provider";
import { confirmTwoFactorCryptoWithdraw } from "../../../api/api";
import { FirebaseContext } from "../../../data/firebase-provider";

interface Props {
	handleBackClick: () => void;
	handleSubmit: () => void;
	selectedAsset: TMappedAsset;
	address: string;
	amount: string;
	eWallet: string;
	state: string;
	setChainId: React.Dispatch<React.SetStateAction<string>>;
}
const CryptoTwoFactor = ({
	handleBackClick,
	handleSubmit,
	selectedAsset,
	amount,
	address,
	eWallet,
	state,
	setChainId,
}: Props) => {
	const { sendAnalytics } = useContext(FirebaseContext);
	const { t } = useTranslation();
	const { theme } = useContext(ThemeContext);
	const [values, setValues] = useState<string[]>(["", "", "", "", "", ""]);
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const inputPropsRef = useRef<any>();
	const { fields } = usePinInput({
		values,
		onChange: (values: string[]) => {
			setValues(values);
			if (values.join("").length == 6) {
				inputPropsRef.current.focus();
			}
		},
		onComplete: () => {
			if (values.join("").toString().length == 6) {
				inputPropsRef.current.focus();
			}
		},
		placeholder: "",
	});
	const [isError, setIsError] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const handleSubmitClick = async () => {
		setIsError(false);
		setIsLoading(true);
		await confirmTwoFactorCryptoWithdraw(
			sessionStorage.getItem("userToken") as string,
			eWallet,
			selectedAsset.code,
			amount,
			selectedAsset.networkCode,
			selectedAsset.networkName,
			address,
			state,
			values.join("").toString(),
			setIsError,
			setValues,
			setChainId,
			handleSubmit,
			sendAnalytics,
			"GoogleAuthenticator"
		);
		setIsLoading(false);
	};

	return (
		<div className={"component-clean" + (theme === "dark" ? "-dark" : "")}>
			<div className={"innerComponent"}>
				<h1 className={"confirmEmailText" + (theme === "dark" ? "-dark" : "")}>
					{t("authenticationText")}
				</h1>
				<h1 className={"confirmCodeText" + (theme === "dark" ? "-dark" : "")}>
					{t("mfCodeDescription")}
				</h1>
				<div className={"codeInputBlock"}>
					<PinInput fields={fields} />
				</div>
				{isError ? (
					<h1 className={"loginErrorText"}>{t("mfCodeErrorText")}</h1>
				) : (
					<div className={"registrationEmailError"} />
				)}
				<CustomButton
					buttonRef={inputPropsRef}
					isLoading={isLoading}
					text={t("exchangeSubmitButton") as string}
					type={"submit"}
					onClick={handleSubmitClick}
					className={isError ? "loginButtonError" : "loginButton"}
					isDisabled={
						fields.map((field: PinInputFieldProps) => field.value).join("")
							.length < 6
					}
					theme={theme as TTheme}
				/>
				<div className={"divider" + (theme === "dark" ? "-dark" : "")}>
					<Divider className={"divider" + (theme === "dark" ? "-dark" : "")} />
				</div>
				<CustomButton
					theme={theme as TTheme}
					isBack={true}
					onClick={handleBackClick}
					className={"backButton"}
				/>
			</div>
		</div>
	);
};

export default CryptoTwoFactor;
