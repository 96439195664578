import React, {MutableRefObject, useContext, useState} from 'react';
import "./internal-input.css";
import InternalModalSelect from "../../../internal-transfer/internal-model-select/internal-modal-select";
import {TTheme, TWallet} from "../../../../types/types";
import CustomInput from "../custom-input";
import {ThemeContext} from "../../../../data/theme-provider";

interface Props {
    isFrom: boolean;
    className: string;
    value: string;
    label: string;
    isRequired: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    inputPropsRef?: MutableRefObject<any>;
    data: string[];
    wallets: TWallet;
    handleItemClick: (i: string) => void;
    selectedItem: string;
}

const InternalInput = ({className, value, isRequired, label, inputPropsRef, isFrom, handleItemClick, selectedItem, data, wallets}: Props) => {
    const {theme} = useContext(ThemeContext);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

    const handleButtonClick = () => {
        setIsModalOpen(!isModalOpen)
    }

    return (
        <div className={className}>
            <InternalModalSelect
                label={label}
                data={data}
                wallets={wallets}
                isModalOpen={isModalOpen}
                handleIsModalOpen={handleButtonClick}
                handleItemClick={handleItemClick}
                selectedItem={selectedItem}
                isFrom={isFrom}
            />
            <CustomInput
                theme={theme as TTheme}
                onClearClick={handleButtonClick}
                isRequired={isRequired}
                type={"text"}
                id={"internalInput"}
                label={label}
                value={value}
                isInternalSelect={true}
                className={''}
                inputPropsRef={inputPropsRef}
            />
        </div>
    );
};

export default InternalInput;