import React, { useContext, useEffect, useState } from "react";
import { TTheme } from "../../../types/types";
import BankPreview from "../../../icons/bank-preview";
import ShareIcon from "@mui/icons-material/Share";
import CustomButton from "../../common/button/custom-button";
import { Divider } from "@mui/material";
import { useTelegram } from "../../../hooks/useTelegram";
import { ThemeContext } from "../../../data/theme-provider";
import { useTranslation } from "react-i18next";

interface Props {
	fileName: string;
	link: string;
}
const Summary = ({ fileName, link }: Props) => {
	const [isMobile, setIsMobile] = useState<boolean>(false);
	const { t } = useTranslation();
	const { theme } = useContext(ThemeContext);
	const { tg, openLink } = useTelegram();

	useEffect(() => {
		if (
			navigator.userAgent.match(/Android/i) ||
			navigator.userAgent.match(/iPhone/i) ||
			navigator.userAgent.match(/Mac/i)
		) {
			setIsMobile(true);
		}
	}, []);

	const editHtmlPdf = async () => {
		if (isMobile) {
			openLink(link);
			return;
		}
		window.location.replace(link);
	};

	const handleBackClickButton = () => {
		tg.close();
	};

	return (
		<div className={"component-clean" + (theme === "dark" ? "-dark" : "")}>
			<div className={"innerComponent"}>
				<h1 className={"exchangeWelcomeText" + (theme === "dark" ? "-dark" : "")}>
					{t("statementHeader")}
				</h1>
				<div
					id={"bankDepositPDFPreviewContainer"}
					className={
						"bankDepositPDFUpperContainer" + (theme === "dark" ? "-dark" : "")
					}
				>
					<BankPreview />
				</div>
				<div
					className={
						"bankDepositPDFBottomContainer" +
						(theme === "dark" ? "-dark" : "")
					}
				>
					<h1
						className={
							"bankDepositPDFBottomText" + (theme === "dark" ? "-dark" : "")
						}
					>
						{fileName.split(".pdf")[0].length > 13
							? fileName.substring(0, 13) + "...pdf"
							: fileName}
					</h1>
					<ShareIcon
						sx={{
							fill: theme === "dark" ? "white" : "black",
						}}
						className={"bankDepositShareButton"}
						onClick={editHtmlPdf}
					/>
				</div>
				<div className={"divider" + (theme === "dark" ? "-dark" : "")}>
					<Divider className={"divider" + (theme === "dark" ? "-dark" : "")} />
				</div>
				<CustomButton
					theme={theme as TTheme}
					isBack={true}
					onClick={handleBackClickButton}
					className={"backButton"}
				/>
			</div>
		</div>
	);
};

export default Summary;
