export const ROUTES = {
    OTC: "/:locale/createOTC",
    STATEMENT: "/:locale/statement",
    BANK_WITHDRAW: "/:locale/bankWithdraw",
    CRYPTO_WITHDRAW: "/:locale/cryptoWithdraw",
    LOGIN: "/:locale/login",
    CARD_DEPOSIT: "/:locale/card",
    BANK_DEPOSIT: "/:locale/bank",
    ANALYTICS: "/:locale/analytics",
    EXCHANGE: "/:locale/exchange",
    INTERNAL_TRANSFER: "/:locale/transfer",
    REGISTRATION: "/:locale/registration",
    VERIFICATION: "/:locale/verification",
    ERROR: "/error",
    INDEX: "*"
}