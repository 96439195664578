import React, {useContext} from 'react';
import {PinInputFieldProps} from "react-pin-input-hook";
import {ThemeContext} from "../../../data/theme-provider";

interface Props {
    fields: PinInputFieldProps[];
}
const PinInput = ({fields}: Props) => {
    const {theme} = useContext(ThemeContext);

    return (
        <div className={'pin_input'}>
            {fields.map((propsField, idx) => (
                <input
                    key={idx}
                    className={'pin_input__field' + (theme === 'dark' ? '-dark' : '')}
                    {...propsField}
                />
            ))}
        </div>
    );
};

export default PinInput;