import React, { useContext, useEffect, useRef, useState } from "react";
import "./exchange-input.css";
import Chev from "../../../../icons/chev";
import CustomButton from "../../../common/button/custom-button";
import { useTranslation } from "react-i18next";
import BaseIcon from "../../../../icons/base-icon";
import { TExchangeItem, TTheme } from "../../../../types/types";
import ExchangeModalSelect from "../../../exchange/exchange-modal-select/exchange-modal-select";
import { ThemeContext } from "../../../../data/theme-provider";

interface Props {
	label: string;
	amount: string;
	setAmount: (amount: string) => Promise<void>;
	available: number;
	asset: TExchangeItem;
	assets: TExchangeItem[];
	handleItemClick: (i: TExchangeItem) => void;
	handleAvailableClick?: (amount: string) => void;
	isFrom: boolean;
	isInputError: boolean;
	setIsInputError: React.Dispatch<React.SetStateAction<boolean>>;
	fontSize: string;
	isOTC?: boolean;
}
const ExchangeInput = ({
	label,
	amount,
	setAmount,
	assets,
	asset,
	available,
	handleItemClick,
	handleAvailableClick,
	isFrom,
	setIsInputError,
	isInputError,
	fontSize,
	isOTC
}: Props) => {
	const { theme } = useContext(ThemeContext);
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const inputRef = useRef<any>(null);
	const [isFocused, setIsFocused] = useState<boolean>(false);
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
	const { t } = useTranslation();

	const handleFocus = () => {
		setIsFocused(!isFocused);
	};

	useEffect(() => {
		if (isFrom) {
			Number(amount) > available ? setIsInputError(true) : setIsInputError(false);
		}
	}, [amount]);

	const handleModalOpen = () => {
		setIsModalOpen(!isModalOpen);
	};

	const handleAvailableContainerClick = () => {
		if(handleAvailableClick)
		handleAvailableClick(String(available));
	};

	const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setAmount(e.target.value);
	};

	return (
		<div
			className={
				(isFocused ? "exchangeInputContainerFocused" : "exchangeInputContainer") +
				(theme === "dark" ? "-dark" : "")
			}
		>
			<ExchangeModalSelect
				isFrom={isFrom}
				label={label}
				data={assets}
				isModalOpen={isModalOpen}
				handleIsModalOpen={handleModalOpen}
				handleItemClick={handleItemClick}
			/>
			<div className={"inputHeader"}>
				<h1
					className={
						"inputHeaderFirstElement" + (theme === "dark" ? "-dark" : "")
					}
				>
					{label}
				</h1>
				{!isOTC ? <div
					className={"inputHeaderAmountContainer"}
					onClick={handleAvailableContainerClick}
				>
					<h1
						className={
							"inputHeaderSecondElement" + (theme === "dark" ? "-dark" : "")
						}
					>
						{t("exchangeAvailableText")}:
					</h1>
					<h1
						className={
							"inputHeaderThirdElement" + (theme === "dark" ? "-dark" : "")
						}
					>
						{available}
					</h1>
				</div> : ""}
			</div>
			<div className={"inputMain"}>
				<input
					onKeyUp={(keyboardEvent: React.KeyboardEvent<HTMLElement>) => {
						if (keyboardEvent.key === "Enter") {
							keyboardEvent.preventDefault();
							if (inputRef.current) {
								inputRef.current.blur();
							}
						}
					}}
					inputMode="decimal"
					pattern={`^[0-9]*\\.?[0-9]{0,${asset.precision}}$`}
					placeholder={"0"}
					onFocus={handleFocus}
					onBlur={handleFocus}
					className={
						(isInputError && isFrom
							? "exchangeInputError"
							: "exchangeInput") + (theme === "dark" ? "-dark" : "")
					}
					style={{
						fontSize: fontSize,
						padding: 0,
					}}
					type={"number"}
					onChange={onChange}
					value={amount}
				/>
				<div className={"inputMainAssetContainer"}>
					<CustomButton
						theme={theme as TTheme}
						onClick={handleModalOpen}
						isSelectAsset={true}
						className={"inputMainAssetContainer"}
					>
						<BaseIcon
							theme={theme as TTheme}
							path={asset.code.toLowerCase()}
							group={asset.group.toLowerCase()}
							className={"inputAssetIcon"}
						/>
						<h1
							className={
								"inputAssetText" + (theme === "dark" ? "-dark" : "")
							}
						>
							{asset.code}
						</h1>
						<Chev />
					</CustomButton>
				</div>
			</div>
			<div className={"inputBottom"}>
				{isInputError && isFrom ? (
					<h1 className={"inputBottomErrorText"}>
						{t("exchangeInsufficientFunds")}
					</h1>
				) : (
					""
				)}
			</div>
		</div>
	);
};

export default ExchangeInput;
