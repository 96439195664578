import React, { createContext, useState } from "react";
import { TAllAccountsResponse, TExchangeItem } from "../types/types";
import {
	exchangeCurrencyPair,
	getAvailableForExchangePairCurrency,
	getAvailableForOtcPairCurrency,
	getRateForCurrentPair,
	getTransactionInfoById,
	getUserRestByAccountWithoutAnalytics,
	getUserRests,
} from "../api/api";
import { useTranslation } from "react-i18next";

//eslint-disable-next-line @typescript-eslint/no-unused-vars
const updateUserToken = (userToken: string, userLocale: string) => {
	return;
};

//eslint-disable-next-line @typescript-eslint/no-unused-vars
const updateUserTokenOtc = (userToken: string, userLocale: string) => {
	return;
};


//eslint-disable-next-line @typescript-eslint/no-unused-vars
const getPairForSymbol = (asset: TExchangeItem) => {
	return;
};

const getRateForPair = (
	//eslint-disable-next-line @typescript-eslint/no-unused-vars
	asset: string,
	//eslint-disable-next-line @typescript-eslint/no-unused-vars
	assetFrom: TExchangeItem,
	//eslint-disable-next-line @typescript-eslint/no-unused-vars
	assetTo: TExchangeItem
) => {
	return;
};

const exchangePair = (
	//eslint-disable-next-line @typescript-eslint/no-unused-vars
	assetFrom: TExchangeItem,
	//eslint-disable-next-line @typescript-eslint/no-unused-vars
	assetTo: TExchangeItem,
	//eslint-disable-next-line @typescript-eslint/no-unused-vars
	amountFrom: string
) => {
	return;
};

//eslint-disable-next-line @typescript-eslint/no-unused-vars
const getOtcPairForSymbol = async (asset: TExchangeItem) => {
	return;
};

//eslint-disable-next-line @typescript-eslint/no-unused-vars
const getRateForUsdt = (amountFrom: string, assetFrom: string): Promise<string> => {
	return new Promise<string>((resolve) => {
		resolve("");
	});
};

export const ExchangeContext = createContext({
	rate: "",
	rateNum: "",
	data: [],
	pairs: [],
	otcPairs: [],
	isLoading: false,
	eWallet: "",
	date: "",
	isSuccess: false,
	isExchanged: false,
	updateUserToken: updateUserToken,
	updateUserTokenOtc: updateUserTokenOtc,
	getPairForSymbol: getPairForSymbol,
	getRateForPair: getRateForPair,
	exchangePair: exchangePair,
	getRateForUsdt: getRateForUsdt,
	getOtcPairForSymbol: getOtcPairForSymbol,
	isOperationLoading: false,
	isWalletsEmpty: false,
} as {
	rate: string;
	rateNum: string;
	data: TExchangeItem[];
	pairs: TExchangeItem[];
	otcPairs: TExchangeItem[];
	isLoading: boolean;
	updateUserToken: typeof updateUserToken;
	updateUserTokenOtc: typeof updateUserTokenOtc;
	getPairForSymbol: typeof getPairForSymbol;
	getRateForPair: typeof getRateForPair;
	exchangePair: typeof exchangePair;
	getRateForUsdt: typeof getRateForUsdt;
	getOtcPairForSymbol: typeof getOtcPairForSymbol;
	eWallet: string;
	date: string;
	isSuccess: boolean;
	isExchanged: boolean;
	isOperationLoading: boolean;
	isWalletsEmpty: boolean;
});

const ExchangeProvider = ({ children }: { children: React.ReactNode }) => {
	const { t } = useTranslation();
	const [data, setData] = useState<TExchangeItem[]>([]);
	const [pairs, setPairs] = useState<TExchangeItem[]>([]);
	const [rate, setRate] = useState<string>(t("defaultRateValue") as string);
	const [rateNum, setRateNum] = useState<string>("");
	const [userLocale, setUserLocale] = useState<string>("");
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [eWallet, setEWallet] = useState<string>("");
	const [date, setDate] = useState<string>("");
	const [isOperationLoading, setIsOperationLoading] = useState<boolean>(false);
	const [isSuccess, setIsSuccess] = useState<boolean>(false);
	const [isExchanged, setIsExchanged] = useState<boolean>(false);
	const [isWalletsEmpty, setIsWalletsEmpty] = useState<boolean>(false);
	const [otcPairs, setOtcPairs] = useState<TExchangeItem[]>([]);

	const getOtcPairForSymbol = async (asset: TExchangeItem) => {
		await setPairs([]);
		await setOtcPairs([]);
		const response = await getAvailableForOtcPairCurrency(
			sessionStorage.getItem("userToken") as string
		);

		const array = [];
		for (let i = 0; i < response.otcBuyAssets.length; i++) {
			const obj = {
				code: response.otcBuyAssets[i].code,
				group: response.otcBuyAssets[i].group,
				precision: response.otcBuyAssets[i].precision,
				available: 0,
				name: response.otcBuyAssets[i].name,
				symbolName: response.otcBuyAssets[i].symbolName + asset.code,
			};
			for (let j = 0; j < data.length; j++) {
				if (data[j].code === response.otcBuyAssets[i].code) {
					obj.available = data[j].available;
				}
			}
			array.push(obj);
		}
		await setPairs(array);
	};

	const getPairForSymbol = async (asset: TExchangeItem) => {
		await setPairs([]);
		const response = await getAvailableForExchangePairCurrency(
			asset.code,
			sessionStorage.getItem("userToken") as string
		);
		const array = [];
		const selectedItem = asset.group;
		for (let i = 0; i < response.length; i++) {
			if (
				selectedItem === "Fiat" ||
				selectedItem === "Tokinezed Forex" ||
				selectedItem === "Forex"
			) {
				if (
					response[i].group != "Fiat" ||
					response[i].group != "Tokenized Forex"
				) {
					const obj = {
						code: response[i].assetTo.code,
						group: response[i].assetTo.group,
						precision: response[i].assetTo.precision,
						available: 0,
						name: response[i].assetTo.name,
						symbolName: response[i].symbolName,
					};
					for (let j = 0; j < data.length; j++) {
						if (data[j].code === response[i].assetTo.code) {
							obj.available = data[j].available;
						}
					}
					array.push(obj);
				}
			}
			if (selectedItem === "Crypto" || selectedItem === "Crypto Token") {
				const obj = {
					code: response[i].assetTo.code,
					group: response[i].assetTo.group,
					precision: response[i].assetTo.precision,
					available: 0,
					name: response[i].assetTo.name,
					symbolName: response[i].symbolName,
				};
				for (let j = 0; j < data.length; j++) {
					if (data[j].code === response[i].assetTo.code) {
						obj.available = data[j].available;
					}
				}
				array.push(obj);
			}
		}
		await setPairs(array);
	};

	const getRateForUsdt = async (amountFrom: string, assetFrom: string) => {
		const response = await getRateForCurrentPair(
			assetFrom,
			"USDT",
			sessionStorage.getItem("userToken") as string
		);
		if (response.code) {
			return "";
		}
		if (response?.rateBid === undefined || response?.rateAsk === undefined) {
			return "";
		}
		if (assetFrom + "USDT" === response.symbol) {
			return Number(Number(response.rateBid) * Number(amountFrom)).toFixed(4);
		} else {
			return Number(Number(amountFrom) / Number(response.rateAsk)).toFixed(4);
		}
	};

	const getRateForPair = async (
		asset: string,
		assetFrom: TExchangeItem,
		assetTo: TExchangeItem
	) => {
		await setRate("");
		setRateNum("");
		const response = await getRateForCurrentPair(
			assetFrom.code,
			assetTo.code,
			sessionStorage.getItem("userToken") as string
		);
		if (response.code) {
			setRate(t("defaultRateValue") as string);
			return;
		}
		if (response?.rateBid === undefined || response?.rateAsk === undefined) {
			setRate(t("defaultRateValue") as string);
			return;
		}
		if (assetFrom.code.concat(assetTo.code) === response.symbol) {
			await setRateNum(response.rateBid);
			await setRate(
				`1 ${assetFrom?.code} ~ ${response?.rateBid?.toFixed(
					assetTo.precision
				)} ${assetTo?.code}*`
			);
		} else {
			await setRateNum(response.rateAsk);
			await setRate(
				`1 ${assetTo?.code} ~ ${response?.rateAsk?.toFixed(
					assetFrom.precision
				)} ${assetFrom?.code}*`
			);
		}
	};

	const exchangePair = async (
		assetFrom: {
			code: string;
		},
		assetTo: {
			code: string;
		},
		amountFrom: string
	) => {
		setIsOperationLoading(true);
		const codeFrom = assetFrom.code;
		const codeTo = assetTo.code;
		const response = await exchangeCurrencyPair(
			codeFrom,
			codeTo,
			amountFrom,
			sessionStorage.getItem("userToken") as string
		);
		let success = true;
		if (response.code) {
			success = false;
			setIsSuccess(success);
			setIsOperationLoading(false);
			setIsExchanged(true);
			return;
		}
		const infoResponse = await getTransactionInfoById(
			response.chainId,
			userLocale,
			sessionStorage.getItem("userToken") as string
		);
		if (infoResponse.transactions[0].exchange.payInfo.status === "error") {
			success = false;
			setIsSuccess(success);
			setIsOperationLoading(false);
			setIsExchanged(true);
			return;
		}
		if (infoResponse.transactions[0].exchange.createDate) {
			setDate(infoResponse.transactions[0].exchange.createDate);
			setIsExchanged(true);
			setIsOperationLoading(false);
			setIsSuccess(success);
			return;
		}
	};

	const updateUserToken = async (userToken: string, userLocale: string) => {
		setIsLoading(true);
		await setUserLocale(userLocale);
		await initData();
	};

	const updateUserTokenOtc = async (userToken: string, userLocale: string) => {
		setIsLoading(true);
		await setUserLocale(userLocale);

		await setOtcPairs([]);
			const response = await getAvailableForOtcPairCurrency(
				sessionStorage.getItem("userToken") as string
			);
	
			const localOtcSaleAssets = [];
			for (let i = 0; i < response?.otcBuyAssets?.length; i++) {
				const obj = {
					code: response.otcBuyAssets[i].code,
					group: response.otcBuyAssets[i].group,
					precision: response.otcBuyAssets[i].precision,
					available: 0,
					name: response.otcBuyAssets[i].name,
					symbolName: response.otcBuyAssets[i].symbolName,
				};
				localOtcSaleAssets.push(obj);
			}
			setOtcPairs(localOtcSaleAssets);

		const accountResponse: TAllAccountsResponse = await getUserRests(
			sessionStorage.getItem("userToken") as string
		);
		const array: TExchangeItem[] = [];
		for (let i = 0; i < accountResponse.length; i++) {
			if (accountResponse[i].accTypeName === "Main") {
				setEWallet(accountResponse[i].accNum);
				const rests = await getUserRestByAccountWithoutAnalytics(
					sessionStorage.getItem("userToken") as string,
					accountResponse[i].accNum
				);
				for (let j = 0; j < rests.accounts[0].assets.length; j++) {
					array.push({
						code: rests.accounts[0].assets[j].code,
						group: rests.accounts[0].assets[j].group,
						precision: rests.accounts[0].assets[j].precision,
						available: rests.accounts[0].assets[j].available,
						name: rests.accounts[0].assets[j].name,
					});
				}
				await setData(array);
			}
		}
		if (array.length == 0) {
			await setIsWalletsEmpty(true);
		}

		setIsLoading(false);
	};

	const initData = async () => {
		const accountResponse: TAllAccountsResponse = await getUserRests(
			sessionStorage.getItem("userToken") as string
		);
		const array: TExchangeItem[] = [];
		for (let i = 0; i < accountResponse.length; i++) {
			if (accountResponse[i].accTypeName === "Main") {
				setEWallet(accountResponse[i].accNum);
				const rests = await getUserRestByAccountWithoutAnalytics(
					sessionStorage.getItem("userToken") as string,
					accountResponse[i].accNum
				);
				for (let j = 0; j < rests.accounts[0].assets.length; j++) {
					array.push({
						code: rests.accounts[0].assets[j].code,
						group: rests.accounts[0].assets[j].group,
						precision: rests.accounts[0].assets[j].precision,
						available: rests.accounts[0].assets[j].available,
						name: rests.accounts[0].assets[j].name,
					});
				}
				await setData(array);
			}
		}
		if (array.length == 0) {
			await setIsWalletsEmpty(true);
		}
		setIsLoading(false);
	};

	return (
		<ExchangeContext.Provider
			value={{
				getOtcPairForSymbol,
				getRateForUsdt,
				isWalletsEmpty,
				isExchanged,
				isOperationLoading,
				rate,
				rateNum,
				data,
				isLoading,
				pairs,
				otcPairs,
				updateUserToken,
				updateUserTokenOtc,
				getPairForSymbol,
				getRateForPair,
				exchangePair,
				eWallet,
				date,
				isSuccess,
			}}
		>
			{children}
		</ExchangeContext.Provider>
	);
};

export default ExchangeProvider;
