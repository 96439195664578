import React, { useContext } from "react";
import { ThemeContext } from "../../../data/theme-provider";
import { useTranslation } from "react-i18next";
import { TLocale, TTheme } from "../../../types/types";
import { Checkbox, Link } from "@mui/material";
import { replaceJSX } from "../../../hooks/replaceJSX";
import { useTelegram } from "../../../hooks/useTelegram";
import * as links from "../../common/links/links.json";
import CustomButton from "../../common/button/custom-button";
import "../otc.css";

interface Props {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	checkBoxes: any;
	checked: boolean;
	setIsChecked: React.Dispatch<React.SetStateAction<boolean>>;
	handleSubmit: () => void;
	isButtonLoading: boolean;
	isError: boolean;
	locale: TLocale;
}
const OTCEula = ({
	checkBoxes,
	handleSubmit,
	isError,
	isButtonLoading,
	locale,
	checked,
	setIsChecked,
}: Props) => {
	const { theme } = useContext(ThemeContext);
	const { t } = useTranslation();
	const { openLink } = useTelegram();

	const handleCheckBoxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setIsChecked(e.target.checked);
	};

	const handleFirstLinkClick = () => {
		openLink(links[locale as "ru" | "en"][8]);
	};

	return (
		<div className={"component-clean" + (theme === "dark" ? "-dark" : "")}>
			<div className={"innerComponent"}>
				<h1 className={"exchangeWelcomeText" + (theme === "dark" ? "-dark" : "")}>
					{t("OTCHeader")}
				</h1>
				<h1 className={"otcEulaText" + (theme === "dark" ? "-dark" : "")}>{t("OTCEulaTextFirst")}</h1>
				<h1 className={"otcEulaText" + (theme === "dark" ? "-dark" : "")}>{t("OTCEulaTextSecond")}</h1>
				<div className={"checkboxes"}>
					<div className={"firstCheckBox"}>
						<Checkbox
							sx={{
								"&.Mui-checked": {
									color: "#00BEB4 !important",
								},
								color:
									theme === "dark"
										? "#303030 !important"
										: "#EBECEE !important",
								borderRadius: "50% !important",
							}}
							key={"firstCheckBox"}
							checked={checked}
							onChange={handleCheckBoxChange}
						/>
						<h1 className={"checkBox-h1" + (theme === "dark" ? "-dark" : "")}>
							{locale === "en"
								? replaceJSX(t("OTCEulaButton") as string, {
										"terms and conditions of OTC transactions": (
											<Link onClick={handleFirstLinkClick}>
												terms and conditions of OTC transactions
											</Link>
										),
								  })
								: replaceJSX(t("OTCEulaButton") as string, {
										"условиями совершения ОТС сделок": (
											<Link onClick={handleFirstLinkClick}>
												Пользования сервисом
											</Link>
										),
								  })}
						</h1>
					</div>
				</div>
				{isError ? (
					<h1 className={"loginErrorText"}>
						{t("exchangeErrorOperationHeader")}
					</h1>
				) : (
					<div className={"registrationEmailError"} />
				)}
				<CustomButton
					theme={theme as TTheme}
					text={t("sendButtonText") as string}
					type={"submit"}
					onClick={handleSubmit}
					className={"startButton"}
					isDisabled={checked != checkBoxes[0]?.isRequired}
					isLoading={isButtonLoading}
				/>
			</div>
		</div>
	);
};

export default OTCEula;
