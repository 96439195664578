import React, { useContext, useEffect, useState } from "react";
import "./crypto-withdraw.css";
import { ThemeContext } from "../../data/theme-provider";
import CustomButton from "../common/button/custom-button";
import { TAddress, TExchangeItem, TMappedAsset, TTheme } from "../../types/types";
import { Divider } from "@mui/material";
import { useTranslation } from "react-i18next";
import { fetchLoginOTP, getAddresses, getCryptoCommission, initCryptoWithdrawPage } from "../../api/api";
import AssetInput from "./asset-input/asset-input";
import CircleProgress from "../circle-progress/circle-progress";
import AddressInput from "./address-input/address-input";
import { useTelegram } from "../../hooks/useTelegram";
import CustomInput from "../common/input/custom-input";
import { ExchangeContext } from "../../data/exchange-provider";
import CryptoConfirmation from "./confirmation/crypto-confirmation";
import CryptoTwoFactor from "./two-factor/crypto-two-factor";
import CryptoSummary from "./summary/crypto-summary";
import { Helmet } from "react-helmet";
import { Titles } from "../../types/titles";
import { FirebaseContext } from "../../data/firebase-provider";
import { AnalyticsEventsName } from "../../types/analytics/analytics-events";
import { Whale } from "../../icons/whale";
import SelectCryptoTwoFa from "./select-two-fa/selectTwoFa";
import CryptoOtp from "./otp/crypto-otp";

const locale = window.location.pathname.split("/")[1] != "en" ? "ru" : "en";

const CryptoWithdraw = () => {
	const {initializeFirebase, sendAnalytics} = useContext(FirebaseContext);
	const { data, eWallet, isWalletsEmpty, updateUserToken } =
		useContext(ExchangeContext);
	const { theme, updateTheme } = useContext(ThemeContext);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [assets, setAssets] = useState<TMappedAsset[] | undefined>();
	const [selectedAsset, setSelectedAsset] = useState<TMappedAsset | undefined>();
	const [isSelectedAssetRequired, setIsSelectedAssetRequired] =
		useState<boolean>(false);
	const [addresses, setAddresses] = useState<TAddress[] | undefined>([]);
	const [selectedAddress, setSelectedAddress] = useState<TAddress | undefined>();
	const [isAddressEmpty, setIsAddressEmpty] = useState<boolean>(false);
	const [isAddressRequired, setIsAddressRequired] = useState<boolean>(false);

	const [network, setNetwork] = useState<string>("");
	const [isNetworkRequired, setIsNetworkRequired] = useState<boolean>(false);

	const { t } = useTranslation();
	const { tg } = useTelegram();

	const [amount, setAmount] = useState<string>("");
	const [isInputError, setIsInputError] = useState<boolean>(false);
	const [isAmountRequired, setIsAmountRequired] = useState<boolean>(false);
	
	const [fakeContact, setFakeContact] = useState<string>("");

	const [isConfirmation, setIsConfirmation] = useState<boolean>(false);
	const [isSelectOtp, setIsSelectOtp] = useState<boolean>(false);
	const [isOtp, setIsOtp] = useState<boolean>(false);
	const [isTwoFactor, setIsTwoFactor] = useState<boolean>(false);

	const [isError, setIsError] = useState<boolean>(false);

	const [fee, setFee] = useState<string>();

	const [state, setState] = useState<string>("");
	const [chainId, setChainId] = useState<string>("");

	const [isSummary, setIsSummary] = useState<boolean>(false);

	const initCryptoWithdraw = async () => {
		await initializeFirebase();
		console.log(chainId);
		updateUserToken(sessionStorage.getItem("userToken") as string, locale);
		await initCryptoWithdrawPage(
			sessionStorage.getItem("userToken") as string,
			setIsLoading,
			setAssets
		);
	};

	useEffect(() => {
		updateTheme();
		sessionStorage.setItem(
			"userToken",
			new URLSearchParams(window.location.search).get("token") as string
		);
		setIsLoading(true);
		initCryptoWithdraw();
	}, []);

	const handleSelectedAssetChange = async () => {
		if (selectedAsset) {
			setNetwork(selectedAsset.networkCode);
			setIsNetworkRequired(true);
			setAmount("");
			setIsAmountRequired(false);
			await getAddresses(
				sessionStorage.getItem("userToken") as string,
				selectedAsset?.code as string,
				setAddresses,
				setIsAddressEmpty
			);
		}
	};

	useEffect(() => {
		handleSelectedAssetChange();
	}, [selectedAsset]);

	if (isWalletsEmpty) {
		return (
			<>
				<Helmet>
					<title>{Titles.EXCHANGE.EMPTY}</title>
				</Helmet>
				<div className={"component-clean" + (theme === "dark" ? "-dark" : "")}>
					<div className={"innerComponent"}>
						<div className={"exchangePaymentLogoContainer"}>
							<Whale className={""} />
						</div>
						<h1
							className={
								"exchangePaymentHeaderText" +
								(theme === "dark" ? "-dark" : "")
							}
						>
							{t("exchangeWelcomeText")}
						</h1>
						<h1
							className={
								"exchangePaymentBottomText" +
								(theme === "dark" ? "-dark" : "")
							}
						>
							{t("exchangeWalletsEmptyText")}
						</h1>
						<CustomButton
							theme={theme as TTheme}
							onClick={() => tg.close()}
							className={"exchangeConfirmCloseButton"}
							text={t("exchangeCloseButton") as string}
						/>
					</div>
				</div>
			</>
		);
	}

	if (isLoading || assets === undefined || data.length === 0) {
		return <CircleProgress thickness={6} size={40} />;
	}

	const handleSubmit = async () => {
		await sendAnalytics({
			name: AnalyticsEventsName.withdrawStart
		})
		setIsConfirmation(true);
	};

	const isButtonLoading = false;

	const handleBackClickButton = () => {
		tg.close();
	};

	const handleAssetItemClick = (e: unknown) => {
		setSelectedAsset(e as TMappedAsset);
		setAmount("");
		setIsInputError(false);
		setIsAmountRequired(false);
		setSelectedAddress(undefined);
		setIsSelectedAssetRequired(true);
	};

	const handleNetworkItemClick = (e: unknown) => {
		setSelectedAddress(e as TAddress);
		setIsAddressRequired(true);
	};

	const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const regex = new RegExp(
			`^[0-9]*\\.?[0-9]{0,${
				data.find((item: TExchangeItem) => item.code === selectedAsset?.code)
					?.precision
			}}$`
		);
		if (e.target.value === "" || regex.test(e.target.value)) {
			setAmount(e.target.value);
			e.target.value.length > 0
				? setIsAmountRequired(true)
				: setIsAmountRequired(false);
			Number(e.target.value) >
			Number(
				data.find((item: TExchangeItem) => item.code === selectedAsset?.code)
					?.available
			)
				? setIsInputError(true)
				: setIsInputError(false);
		}
	};

	const handleCommissionChange = async () => {
		if (
			Number(amount) > 0 &&
			Number(
				data.find((item: TExchangeItem) => item.code === selectedAsset?.code)
					?.available
			) >= Number(amount)
		) {
			await getCryptoCommission(
				sessionStorage.getItem("userToken") as string,
				selectedAsset?.code as string,
				amount,
				selectedAsset?.networkName as string,
				setFee
			);
		}
	};

	const handleAvailableClick = async (e: string) => {
		setAmount(e);
		setIsInputError(false);
		e.length > 0 ? setIsAmountRequired(true) : setIsAmountRequired(false);
		await getCryptoCommission(
			sessionStorage.getItem("userToken") as string,
			selectedAsset?.code as string,
			e,
			selectedAsset?.networkName as string,
			setFee
		);
	};

	const handleConfirmationSubmit = () => {
		setIsTwoFactor(true);
		setIsConfirmation(false);
	};
	
	const handleSelectOtpSubmit = () => {
		setIsSelectOtp(true);
		setIsConfirmation(false);
	};

	const handleTwoFactorSubmit = () => {
		setIsTwoFactor(false);
		setIsSummary(true);
	};

	const handleOtpSubmit = () => {
		setIsOtp(false);
		setIsSummary(true);
	};

	const handleIsOtp = async (otpKey: string) => {
		await fetchLoginOTP(
			state,
			otpKey,
			setIsLoading,
			setIsError,
			setIsOtp,
			setIsSelectOtp,
			setFakeContact
		);
		setIsOtp(true);
		setIsConfirmation(false);
	}

	const handleOtpSelect = async (otpKey: string) => {
		await fetchLoginOTP(
			state,
			otpKey,
			setIsLoading,
			setIsError,
			setIsOtp,
			setIsSelectOtp,
			setFakeContact
		);
	};

	if(isSelectOtp) {
		return (
			<>
				<Helmet>
					<title>{Titles.CRYPTO_WITHDRAW.SELECT_TWOFA}</title>
				</Helmet>
				<SelectCryptoTwoFa 
					handleBackClick={() => {
						setIsSelectOtp(false);
						setIsConfirmation(true);
					}}
					handleGASelect={() => {
						setIsTwoFactor(true);
						setIsSelectOtp(false);
					}}
					handleSelect={handleOtpSelect}
					isError={isError}
				/>
			</>
		)
	}

	if(isOtp) {
		return (
			<>
				<Helmet>
					<title>{Titles.CRYPTO_WITHDRAW.OTP}</title>
				</Helmet>
				<CryptoOtp 
					fakeContact={fakeContact}
					handleBackClick={() => {
						setIsOtp(false);
						setIsSelectOtp(true);
					}}
					handleSubmit={handleOtpSubmit}
					address={selectedAddress?.address as string}
					selectedAsset={selectedAsset as TMappedAsset}
					amount={amount}
					state={state}
					eWallet={eWallet}
					setChainId={setChainId}
				/>
			</>
		)
	}

	if (isConfirmation) {
		return (
			<>
				<Helmet>
					<title>{Titles.CRYPTO_WITHDRAW.CONFIRMATION}</title>
				</Helmet>
				<CryptoConfirmation
					handleBackClickButton={() => setIsConfirmation(!isConfirmation)}
					setIsTwoFactor={handleConfirmationSubmit}
					setIsSelectOtp={handleSelectOtpSubmit}
					setIsOtp={handleIsOtp}
					address={selectedAddress?.address as string}
					maskedAddress={selectedAddress?.maskedAddress as string}
					selectedAsset={selectedAsset as TMappedAsset}
					fee={fee as string}
					amount={amount}
					setState={setState}
					eWallet={eWallet}
				/>
			</>
		);
	}

	if (isTwoFactor) {
		return (
			<>
				<Helmet>
					<title>{Titles.CRYPTO_WITHDRAW.TWOFA}</title>
				</Helmet>
				<CryptoTwoFactor
					handleBackClick={() => setIsTwoFactor(!isTwoFactor)}
					handleSubmit={handleTwoFactorSubmit}
					address={selectedAddress?.address as string}
					selectedAsset={selectedAsset as TMappedAsset}
					amount={amount}
					state={state}
					eWallet={eWallet}
					setChainId={setChainId}
				/>
			</>
		);
	}

	if (isSummary) {
		return (
			<>
				<Helmet>
					<title>{Titles.CRYPTO_WITHDRAW.SUMMARY}</title>
				</Helmet>
				<CryptoSummary
					address={selectedAddress?.maskedAddress as string}
					selectedAsset={selectedAsset as TMappedAsset}
					amount={amount}
					fee={fee as string}
				/>
			</>
		);
	}

	return (
		<>
			<Helmet>
				<title>{Titles.CRYPTO_WITHDRAW.INDEX}</title>
			</Helmet>
			<div className={"component-clean" + (theme === "dark" ? "-dark" : "")}>
				<div className={"innerComponent"}>
					<h1
						className={
							"exchangeWelcomeText" + (theme === "dark" ? "-dark" : "")
						}
					>
						{t("cryptoWithdrawHeader")}
					</h1>
					<AssetInput
						value={selectedAsset ? selectedAsset.code : ""}
						isAssetRequired={isSelectedAssetRequired}
						handleItemClick={handleAssetItemClick}
						assets={assets as TMappedAsset[]}
					/>
					<AddressInput
						value={selectedAddress ? selectedAddress.maskedAddress : ""}
						isAddressRequired={isAddressRequired}
						handleItemClick={handleNetworkItemClick}
						addresses={addresses}
					/>
					<CustomInput
						theme={theme as TTheme}
						isDisabled={true}
						onChange={() => ""}
						isAddress={true}
						className={"internalInputContainer"}
						value={network}
						label={t("cryptoWithdrawNetwork") as string}
						isRequired={isNetworkRequired}
						type={"text"}
						id={"internalInput"}
					/>
					<CustomInput
						isDisabled={selectedAsset === undefined}
						theme={theme as TTheme}
						isWithdraw={true}
						isError={isInputError}
						isRequired={isAmountRequired}
						type={"number"}
						id={"internalAmountInput"}
						label={
							selectedAsset && Number(amount) > 0
								? (t("cryptoWithdrawAmountLabel")
										.replace(
											"$minWithdraw$",
											String(selectedAsset.minWithdraw)
										)
										.replace("$code$", selectedAsset.code) as string)
								: (t("internalAmountText") as string)
						}
						value={amount}
						onChange={handleAmountChange}
						onKeyUp={handleCommissionChange}
						className={"internalInputContainer"}
						withdrawAsset={selectedAsset}
					/>
					<div className={"internalAvailableContainer"}>
						{isInputError ? (
							<h1 className={"inputBottomErrorText"}>
								{t("exchangeInsufficientFunds")}
							</h1>
						) : (
							""
						)}
					</div>
					<div className={"internalAvailableContainer"}>
						<h1
							className={
								"internalAvailableText" +
								(theme === "dark" ? "-dark" : "")
							}
						>
							{t("exchangeAvailableText")}:
						</h1>
						<h1
							className={
								"internalAvailableAmount" +
								(theme === "dark" ? "-dark" : "")
							}
							onClick={() =>
								handleAvailableClick(
									(data
										.find(
											(asset: TExchangeItem) =>
												asset.code === selectedAsset?.code
										)
										?.available.toString() as string)
										? (data
												.find(
													(asset: TExchangeItem) =>
														asset.code === selectedAsset?.code
												)
												?.available.toString() as string)
										: "0"
								)
							}
						>
							{(data
								.find(
									(asset: TExchangeItem) =>
										asset.code === selectedAsset?.code
								)
								?.available.toString() as string)
								? (data
										.find(
											(asset: TExchangeItem) =>
												asset.code === selectedAsset?.code
										)
										?.available.toString() as string)
								: 0}
						</h1>
					</div>
					{isAddressEmpty ? (
						<h1 className={"registrationEmailError"}>
							{t("cryptoWithdrawEmptyIssue")}
						</h1>
					) : (
						""
					)}
					<CustomButton
						theme={theme as TTheme}
						isLoading={isButtonLoading}
						onClick={handleSubmit}
						className={"exchangeConfirmButton"}
						isDisabled={
							selectedAsset === undefined ||
							isInputError ||
							Number(amount) <= 0 ||
							Number(fee) >= Number(amount)
						}
						text={t("cryptoWithdrawConfirm") as string}
					/>
					<div className={"divider" + (theme === "dark" ? "-dark" : "")}>
						<Divider
							className={"divider" + (theme === "dark" ? "-dark" : "")}
						/>
					</div>
					<CustomButton
						theme={theme as TTheme}
						isBack={true}
						onClick={handleBackClickButton}
						className={"backButton"}
					/>
				</div>
			</div>
		</>
	);
};

export default CryptoWithdraw;
