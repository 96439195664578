import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import "./analytics.css";
import AnalyticsTotalCard from "./analytics-total-card/analytics-total-card";
import { AnalyticsContext } from "../../data/analytics-provider";
import AnalyticsList from "./analytics-list/analytics-list";
import AnalyticsTransactions from "./analytics-transactions/analytics-transactions";
import CircleProgress from "../circle-progress/circle-progress";
import toast, { Toaster } from "react-hot-toast";
import AnalyticsMarginList from "./analytics-margin-list/analytics-margin-list";
import { ThemeContext } from "../../data/theme-provider";
import { Helmet } from "react-helmet";
import { Titles } from "../../types/titles";

const locale = window.location.pathname.split("/")[1] != "en" ? "ru" : "en";

const Analytics = () => {
	const { theme, updateTheme } = useContext(ThemeContext);
	const {
		data,
		isTransactionsLoading,
		transactions,
		mainAccountID,
		isLoading,
		marginData,
		updateUserToken,
	} = useContext(AnalyticsContext);

	const notify = () =>
		toast.success(t("copiedText"), {
			position: "top-center",
			duration: 1500,
			style: {
				background: theme === "dark" ? "#141414" : "#FFFFFF",
				fontFamily: "Noto Sans",
				fontStyle: "normal",
				fontWeight: "400",
				lineHeight: "normal",
				color: theme === "dark" ? "#FEFEFE" : "#181A21",
			},
			icon: (
				<img
					className={"analytics-copied-icon"}
					src={"/success-icon.png"}
					alt={"SUCCESS-ICON"}
				/>
			),
		});
	const { t } = useTranslation();

	useEffect(() => {
		updateTheme();
		sessionStorage.setItem(
			"userToken",
			new URLSearchParams(window.location.search).get("token") as string
		);
		updateUserToken(sessionStorage.getItem("userToken") as string, locale);
	}, []);

	if (isLoading || theme === "") return <CircleProgress thickness={6} size={40} />;

	return (
		<>
			<Helmet>
				<title>{Titles.ANALYTICS.INDEX}</title>
			</Helmet>
			<div className={"component-clean" + (theme === "dark" ? "-dark" : "")}>
				<div className={"innerComponent"}>
					<Toaster
						toastOptions={{
							className: "",
							duration: 1500,
							style: {
								background: theme === "dark" ? "#303030" : "#FFFFFF",
								color: theme === "dark" ? "#FFFFFF" : "#000",
							},
						}}
					/>
					<AnalyticsTotalCard data={data} />
					{theme != undefined && theme === "light" ? (
						<div className={"analyticsWaveContainer"}>
							<img
								className={"analyticsWaveImage"}
								src={"/wave.png"}
								alt={"wave image"}
							/>
						</div>
					) : (
						""
					)}
					<AnalyticsList data={data} />
					<AnalyticsMarginList data={marginData} />
					<AnalyticsTransactions
						accountId={mainAccountID}
						locale={t}
						data={transactions}
						isLoading={isTransactionsLoading}
						userLocale={locale}
						handleCopyClick={notify}
					/>
				</div>
			</div>
		</>
	);
};

export default Analytics;
