import { useContext } from "react";
import { Radio, RadioProps, styled } from "@mui/material";
import { ThemeContext } from "../../../data/theme-provider";
import { TStyledRadioProps } from "../../../types/styled/types";
import { TTheme } from "../../../types/types";

const StyledRadio = styled(Radio, {
	shouldForwardProp: (prop) => prop !== "isDarkMode",
})<TStyledRadioProps>(({ isDarkMode }) => ({
	padding: "0px !important",
	color: isDarkMode === "dark" ? "#303030" : "#EBECEE",
	backgroundColor: isDarkMode === "dark" ? "#303030" : "#EBECEE",
}));

const CustomRadio = (props: RadioProps) => {
	const { theme } = useContext(ThemeContext);

	return (
		<StyledRadio
			disableRipple
			color="default"
			isDarkMode={theme as TTheme}
			checkedIcon={
				<img
					className={"statementIcon"}
					src={"/success-icon.png"}
					alt={"success"}
				/>
			}
			{...props}
		/>
	);
};

export default CustomRadio;
