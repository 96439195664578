// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const replaceJSX = (str: string, replacement: any) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const result: any[] = [];
    const keys = Object.keys(replacement);
    const getRegExp = () => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const regexp: any[] = [];
        keys.forEach((key) => regexp.push(`${key}`));
        return new RegExp(regexp.join('|'));
    };
    str.split(getRegExp()).forEach((item, i) => {
        result.push(item, replacement[keys[i]]);
    });
    return result;
}