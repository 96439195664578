import React, { useContext, useState } from "react";
import { TExchangeItem, TTheme } from "../../../types/types";
import BankCountryInput from "../bank-country/bank-country-input";
import CustomInput from "../../common/input/custom-input";
import { ThemeContext } from "../../../data/theme-provider";
import { useTranslation } from "react-i18next";
import CustomButton from "../../common/button/custom-button";
import { Divider } from "@mui/material";
import { confirmBankWithdraw, fetchLoginOTP } from "../../../api/api";
import BankTwoFactor from "../bank-two-factor/bank-two-factor";
import SelectBankTwoFa from "../select-two-fa/selectTwoFa";
import BankOtp from "../otp/bank-otp";

interface Props {
	currencyCode: string;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	handleBankCountryClick: (e: any) => void;
	currencies: TExchangeItem[];
	iban: string;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	onChange: (e: any) => void;
	handleBackClick: () => void;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	currentAsset: any;
	assets: TExchangeItem[];
	eWallet: string;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	bankInfo: any;
}
const BankFound = ({
	currencyCode,
	handleBankCountryClick,
	currencies,
	handleBackClick,
	assets,
	currentAsset,
	iban,
	onChange,
	eWallet,
	bankInfo,
}: Props) => {
	const { theme } = useContext(ThemeContext);
	const { t } = useTranslation();
	const [isButtonLoading, setIsButtonLoading] = useState<boolean>(false);
	const [isError, setIsError] = useState<boolean>(false);

	const [amount, setAmount] = useState<string>("");
	const [isAmountRequired, setIsAmountRequired] = useState<boolean>(false);
	const [isInputError, setIsInputError] = useState<boolean>(false);

	const [isTwoFactor, setIsTwoFactor] = useState<boolean>(false);

	const [state, setState] = useState<string>("");

	const [isSelectTwoFa, setIsSelectTwoFa] = useState<boolean>(false);
	const [isOtp, setIsOtp] = useState<boolean>(false);
	const [fakeContact, setFakeContact] = useState<string>("");

	const handleInputChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
		const regex = new RegExp(`^[0-9]*\\.?[0-9]{0,2}$`);
		if (Number(e.target.value) === 0) {
			setIsInputError(true);
		}
		Number(e.target.value) >
		Number(
			assets.find((item: TExchangeItem) => item.code === currencyCode)?.available
		)
			? setIsInputError(true)
			: setIsInputError(false);
		if (e.target.value === "" || regex.test(e.target.value)) {
			setAmount(e.target.value);
			e.target.value.length > 0
				? setIsAmountRequired(true)
				: setIsAmountRequired(false);
			/*if(e.target.value != "" || Number(e.target.value) != 0) {
				const response = await getCommission(currentAsset.code, e.target.value, sessionStorage.getItem("userToken") as string);
				setCommission(response.feeAmount);
			}*/
		}
	};

	const handleCommissionChange = async () => {
		/*if(amount != "" || Number(amount) != 0) {
			const response = await getCommission(currentAsset.code, amount, sessionStorage.getItem("userToken") as string);
			setCommission(response.feeAmount);
		}*/
	};

	const handleAvailableClick = (e: string) => {
		setAmount(e);
		setIsInputError(false);
		e.length > 0 ? setIsAmountRequired(true) : setIsAmountRequired(false);
	};

	const handleIsOtp = async (otpKey: string) => {
		await fetchLoginOTP(
			state,
			otpKey,
			setIsButtonLoading,
			setIsError,
			setIsOtp,
			setIsSelectTwoFa,
			setFakeContact
		);
	};

	const handleIsSelectTwoFa = () => {
		setIsSelectTwoFa(true);
	};

	const handleSubmit = async () => {
		await confirmBankWithdraw(
			sessionStorage.getItem("userToken") as string,
			currencyCode,
			iban,
			amount,
			bankInfo.bankName,
			bankInfo.bankCode,
			bankInfo.bankCountry,
			eWallet,
			"",
			"",
			"",
			"",
			bankInfo.paymentSystem,
			setIsError,
			setIsButtonLoading,
			setState,
			setIsTwoFactor,
			handleIsOtp,
			handleIsSelectTwoFa
		);
	};

	if (isTwoFactor) {
		return (
			<BankTwoFactor
				currencyCode={currencyCode}
				iban={iban}
				handleBackClick={() => setIsTwoFactor(false)}
				currentAsset={currentAsset}
				eWallet={eWallet}
				amount={amount}
				bankName={bankInfo.bankName}
				bankCode={bankInfo.bankCode}
				bankCountry={bankInfo.bankCountry}
				paymentSystem={bankInfo.paymentSystem}
				state={state}
				taxIdentificationNumber={""}
				intermediaryAccount={""}
				intermediaryBankName={""}
				intermediaryBankCode={""}
			/>
		);
	}

	if (isSelectTwoFa) {
		return (
			<>
				<SelectBankTwoFa
					handleBackClick={() => {
						setIsSelectTwoFa(false);
					}}
					handleGASelect={() => {
						setIsTwoFactor(true);
						setIsSelectTwoFa(false);
					}}
					handleSelect={handleIsOtp}
					isError={isError}
				/>
			</>
		);
	}

	if (isOtp) {
		return (
			<>
				<BankOtp
					currencyCode={currencyCode}
					iban={iban}
					handleBackClick={() => {
						setIsSelectTwoFa(false);
						setIsOtp(false);
					}}
					currentAsset={currentAsset}
					eWallet={eWallet}
					amount={amount}
					bankName={bankInfo.bankName}
					bankCode={bankInfo.bankCode}
					bankCountry={bankInfo.bankCountry}
					paymentSystem={bankInfo.paymentSystem}
					state={state}
					taxIdentificationNumber={""}
					intermediaryAccount={""}
					intermediaryBankName={""}
					intermediaryBankCode={""}
					fakeContact={fakeContact}
				/>
			</>
		);
	}

	return (
		<div className={"component-clean" + (theme === "dark" ? "-dark" : "")}>
			<div className={"innerComponent"}>
				<h1 className={"exchangeWelcomeText" + (theme === "dark" ? "-dark" : "")}>
					{t("bankWithdrawHeader")}
				</h1>
				<BankCountryInput
					value={currencyCode}
					isBankCountryRequired={true}
					handleItemClick={handleBankCountryClick}
					currencies={currencies}
					disabled={true}
				/>
				<CustomInput
					isNonCloseIcon
					isDisabled={true}
					isRequired={true}
					type={"email"}
					id={"email"}
					label={t("bankWithdrawAccountNumber") as string}
					value={iban}
					onChange={onChange}
					className={"internalInputContainer"}
					theme={theme as TTheme}
				/>
				<CustomInput
					isNonCloseIcon
					isRequired={true}
					type={"email"}
					id={"email"}
					isDisabled={true}
					label={t("bankWithdrawCode") as string}
					value={bankInfo.bankCode}
					className={"internalInputContainer"}
					theme={theme as TTheme}
				/>
				<CustomInput
					isNonCloseIcon
					isRequired={true}
					type={"email"}
					id={"email"}
					isDisabled={true}
					label={t("bankWithdrawName") as string}
					value={bankInfo.bankName}
					className={"internalInputContainer"}
					theme={theme as TTheme}
				/>
				<CustomInput
					isNonCloseIcon
					theme={theme as TTheme}
					isRequired={true}
					isDisabled={true}
					type={"text"}
					id={"countryNameSelect"}
					label={t("bankWithdrawPaymentSystem") as string}
					value={bankInfo.paymentSystem}
					className={"verificationLastName"}
				/>
				<CustomInput
					theme={theme as TTheme}
					isInternal={true}
					isInputError={isInputError}
					isInternalWithoutChev={true}
					isRequired={isAmountRequired}
					type={"number"}
					id={"internalAmountInput"}
					label={
						t("bankWithdrawAmount")
							.replace("$amount$", currentAsset.min)
							.replace("$code$", currencyCode) as string
					}
					value={amount}
					onChange={handleInputChange}
					onKeyUp={handleCommissionChange}
					className={"internalInputContainer"}
					asset={currentAsset}
					themeNumber={true}
				/>
				<div className={"internalAvailableContainer"}>
					{isInputError ? (
						<h1 className={"inputBottomErrorText"}>
							{t("exchangeInsufficientFunds")}
						</h1>
					) : (
						""
					)}
				</div>
				<div className={"internalAvailableContainer"}>
					<h1
						className={
							"internalAvailableText" + (theme === "dark" ? "-dark" : "")
						}
					>
						{t("exchangeAvailableText")}:
					</h1>
					<h1
						className={
							"internalAvailableAmount" + (theme === "dark" ? "-dark" : "")
						}
						onClick={() =>
							handleAvailableClick(
								(assets
									.find(
										(asset: TExchangeItem) =>
											asset.code === currencyCode
									)
									?.available.toString() as string)
									? (assets
											.find(
												(asset: TExchangeItem) =>
													asset.code === currencyCode
											)
											?.available.toString() as string)
									: "0"
							)
						}
					>
						{(assets
							.find((asset: TExchangeItem) => asset.code === currencyCode)
							?.available.toString() as string)
							? (assets
									.find(
										(asset: TExchangeItem) =>
											asset.code === currencyCode
									)
									?.available.toString() as string)
							: 0}
					</h1>
				</div>
				{isError ? (
					<h1 className={"registrationEmailError"}>
						{t("exchangeErrorOperationHeader")}
					</h1>
				) : (
					""
				)}
				<CustomButton
					theme={theme as TTheme}
					isLoading={isButtonLoading}
					onClick={handleSubmit}
					className={"exchangeConfirmButton"}
					isDisabled={
						Number(amount) >
							Number(
								assets.find(
									(item: TExchangeItem) => item.code === currencyCode
								)?.available
							) ||
						isInputError ||
						Number(amount) < Number(currentAsset.min)
					}
					text={t("bankWithdrawButton") as string}
				/>
				<div className={"divider" + (theme === "dark" ? "-dark" : "")}>
					<Divider className={"divider" + (theme === "dark" ? "-dark" : "")} />
				</div>
				<CustomButton
					theme={theme as TTheme}
					isBack={true}
					onClick={handleBackClick}
					className={"backButton"}
				/>
			</div>
		</div>
	);
};

export default BankFound;
