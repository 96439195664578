import React, { useContext, useEffect } from "react";
import BaseIcon from "../../../icons/base-icon";
import { TMappedAsset, TTheme } from "../../../types/types";
import CustomButton from "../../common/button/custom-button";
import { ThemeContext } from "../../../data/theme-provider";
import { useTranslation } from "react-i18next";
import { Divider } from "@mui/material";
import { useTelegram } from "../../../hooks/useTelegram";
import { FirebaseContext } from "../../../data/firebase-provider";
import { AnalyticsEventsName } from "../../../types/analytics/analytics-events";

interface Props {
	selectedAsset: TMappedAsset;
	amount: string;
	fee: string;
	address: string;
}
const CryptoSummary = ({ selectedAsset, amount, fee, address }: Props) => {
	const { sendAnalytics } = useContext(FirebaseContext);
	const { theme } = useContext(ThemeContext);
	const { t } = useTranslation();
	const { tg } = useTelegram();

	const handleSendAnalytics = async () => {
		await sendAnalytics({
			name: AnalyticsEventsName.withdrawSuccess,
			params: {
				withdraw_amount: amount,
				withdraw_method: "crypto",
				withdraw_network: selectedAsset.networkName,
			},
		});
	};

	useEffect(() => {
		handleSendAnalytics();
	}, []);

	const handleCloseClick = () => {
		tg.close();
	};

	return (
		<div className={"component-clean" + (theme === "dark" ? "-dark" : "")}>
			<div className={"innerComponent"}>
				<div className={"exchangePaymentLogoContainer"}>
					<img
						className={"exchangePaymentLogo"}
						src={"/success-icon.png"}
						alt={"success"}
					/>
				</div>
				<h1
					className={
						"exchangePaymentHeaderText" + (theme === "dark" ? "-dark" : "")
					}
				>
					{t("exchangeOperationStatusPayment")}
				</h1>
				<h1
					className={
						"exchangePaymentBottomText" + (theme === "dark" ? "-dark" : "")
					}
				>
					{t("exchangeOperationStatusQueue")}
				</h1>
				<div
					className={
						"exchangeConfirmContainer" + (theme === "dark" ? "-dark" : "")
					}
				>
					<div className={"exchangeConfirmFirstBlock"}>
						<h1
							className={
								"exchangeSellText" + (theme === "dark" ? "-dark" : "")
							}
						>
							{t("cryptoWithdrawTotalWithdrawAmount")}
						</h1>
						<div className={"exchangeAssetBlock"}>
							<span
								className={
									"spanAsymp" + (theme === "dark" ? "-dark" : "")
								}
							>
								&asymp;
							</span>
							<h1
								className={
									"exchangeConfirmAmount" +
									(theme === "dark" ? "-dark" : "")
								}
							>
								{" "}
								{amount}
							</h1>
							<h1
								className={
									"exchangeConfirmText" +
									(theme === "dark" ? "-dark" : "")
								}
							>
								{selectedAsset.code.toUpperCase()}
							</h1>
							<BaseIcon
								path={selectedAsset.code.toLowerCase()}
								group={"crypto"}
								className={"exchangeConfirmAsset"}
							/>
						</div>
					</div>

					<div className={"exchangeConfirmSecondBlock"}>
						<h1
							className={
								"exchangeSellText" + (theme === "dark" ? "-dark" : "")
							}
						>
							{t("cryptoWithdrawWithdrawFee")}
						</h1>
						<div className={"exchangeAssetBlock"}>
							<span
								className={
									"spanAsymp" + (theme === "dark" ? "-dark" : "")
								}
							>
								&asymp;
							</span>
							<h1
								className={
									"exchangeConfirmAmount" +
									(theme === "dark" ? "-dark" : "")
								}
							>
								{" "}
								{fee}
							</h1>
							<h1
								className={
									"exchangeConfirmText" +
									(theme === "dark" ? "-dark" : "")
								}
							>
								{selectedAsset.code.toUpperCase()}
							</h1>
							<BaseIcon
								path={selectedAsset.code.toLowerCase()}
								group={"crypto"}
								className={"exchangeConfirmAsset"}
							/>
						</div>
					</div>

					<div className={"exchangeConfirmSecondBlock"}>
						<h1
							className={
								"exchangeSellText" + (theme === "dark" ? "-dark" : "")
							}
						>
							{t("cryptoWithdrawAddress")}
						</h1>
						<div className={"exchangeAssetBlock"}>
							<h1
								className={
									"exchangeConfirmAmount" +
									(theme === "dark" ? "-dark" : "")
								}
							>
								{address}
							</h1>
						</div>
					</div>

					<div className={"divider" + (theme === "dark" ? "-dark" : "")}>
						<Divider
							className={"divider" + (theme === "dark" ? "-dark" : "")}
						/>
					</div>

					<div className={"exchangeConfirmSecondBlock"}>
						<h1
							className={
								"exchangeSellText" + (theme === "dark" ? "-dark" : "")
							}
						>
							{t("cryptoWithdrawCreated")}
						</h1>
						<div className={"exchangeAssetBlock"}>
							{/*<h1
                                className={
                                    "exchangeConfirmAmount" +
                                    (theme === "dark" ? "-dark" : "")
                                }
                            >
                                {amount}
                            </h1>*/}
							<h1
								className={
									"exchangeConfirmText" +
									(theme === "dark" ? "-dark" : "")
								}
							>
								{new Date(Date.now()).toUTCString()}
							</h1>
						</div>
					</div>
				</div>
				<CustomButton
					theme={theme as TTheme}
					onClick={handleCloseClick}
					className={"exchangeConfirmButton"}
					text={t("exchangeCloseButton") as string}
				/>
			</div>
		</div>
	);
};

export default CryptoSummary;
