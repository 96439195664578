import React, { ChangeEvent, MutableRefObject, useState } from 'react';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import PhoneInput from '../../common/input/phone-input';
import SearchIcon from '@mui/icons-material/Search';
import { VisibilityOffOutlined, VisibilityOutlined } from '@mui/icons-material';
import DateOfBirthInput from './date-of-birth-input';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import BaseIcon from '../../../icons/base-icon';
import Chev from '../../../icons/chev';
import { TExchangeItem, TMappedAsset, TTheme } from '../../../types/types';
import ContactIcon from '../../../icons/contact-icon';

interface Props {
	isRequired: boolean;
	type: string;
	id: string;
	label: string;
	value: string;
	onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
	onClearClick?: () => void;
	className: string;
	placeHolder?: string;
	isMobile?: boolean;
	searchInput?: boolean;
	isSelectCode?: boolean;
	isSelectCountry?: boolean;
	imgUrl?: string;
	isPassword?: boolean;
	isDateOfBirth?: boolean;
	isInternalSelect?: boolean;
	isDisabled?: boolean;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	inputPropsRef?: MutableRefObject<any>;
	isInternal?: boolean;
	asset?: TExchangeItem;
	handleModalOpen?: () => void;
	themeNumber?: boolean;
	isBankTransfer?: boolean;
	onKeyUp?: () => void;
	theme?: TTheme;
	isWithdraw?: boolean;
	withdrawAsset?: TMappedAsset;
	isAddress?: boolean;
	isError?: boolean;
	isInputError?: boolean;
	isInternalWithoutChev?: boolean;
	isNonCloseIcon?: boolean;
	isPattern?: boolean;
	isSelectOtpCode?: boolean;
}
const CustomInput = ({
	isRequired,
	type,
	id,
	label,
	value,
	onChange,
	className,
	onClearClick,
	placeHolder,
	isMobile,
	isInternalSelect,
	searchInput,
	isSelectCode,
	imgUrl,
	isPassword,
	isDateOfBirth,
	isSelectCountry,
	isDisabled,
	inputPropsRef,
	isInternal,
	asset,
	isBankTransfer,
	handleModalOpen,
	onKeyUp,
	theme,
	isWithdraw,
	withdrawAsset,
	isAddress,
	isError,
	isInputError,
	isInternalWithoutChev,
	isNonCloseIcon,
	isPattern,
	isSelectOtpCode
}: Props) => {
	if (isSelectOtpCode) {
		return (
			<div className={className} onClick={onClearClick}>
				<TextField
					sx={{
						'& label.Mui-focused': {
							transform: 'translate(14px, 5px) scale(0.75)'
						},
						'& label.Mui-required': {
							transform: 'translate(14px, 5px) scale(0.75)'
						},
						'& .MuiOutlinedInput-root': {
							'&.Mui-focused fieldset': {
								borderColor: '#00BEB4 !important',
								borderWidth: '1px'
							},
							'&.Mui-disabled fieldset': {
								borderColor: '#F6F6F8 !important',
								borderWidth: '1px'
							},
							'&.Mui-disabled': {
								color: '#181A21'
							},
							'& input.Mui-disabled': {
								color: '#181A21'
							},
							'.MuiInputBase-input.Mui-disabled': {
								WebkitTextFillColor: '#848790',
								color: '#848790'
							},
							'& fieldset': {
								borderColor:
									theme === 'dark' ? '#1F1F1F' : '#F6F6F8'
							},
							'&:hover fieldset': {
								borderColor:
									theme === 'dark' ? '#1F1F1F' : '#F6F6F8'
							}
						},
						'.MuiInputBase-input.Mui-disabled': {
							WebkitTextFillColor: '#848790',
							color: '#848790'
						},
						'& input.Mui-disabled': {
							WebkitTextFillColor: '#181A21 !important'
						},
						fieldset: {
							legend: {
								maxWidth: '0.01px'
							}
						},
						borderRadius: '12px !important',
						background: theme === 'dark' ? '#1F1F1F' : '#F6F6F8',
						borderColor: '#0159A2'
					}}
					disabled={isDisabled}
					inputMode={'decimal'}
					fullWidth
					placeholder={placeHolder}
					InputLabelProps={{
						required: isRequired,
						style: {
							color: theme === 'dark' ? '#9C9C9D' : '#848790',
							fontFamily: 'Noto Sans',
							fontStyle: 'normal',
							fontSize: '16px !important',
							lineHeight: '24px'
						}
					}}
					inputProps={{
						style: {
							color: theme === 'dark' ? '#FEFEFE' : '#181A21',
							fontFamily: 'Noto Sans',
							fontStyle: 'normal',
							fontSize: '16px !important',
							lineHeight: '24px',
							paddingBottom: value != '' ? '2px' : '16.5px',
							marginLeft: '0'
						},
						inputMode: 'decimal',
						returnkeytype: 'go'
					}}
					InputProps={{
						readOnly: true,
						style: {
							borderColor:
								theme === 'dark' ? '#1F1F1F' : '#F6F6F8',
							borderRadius: '12px',
							height: '60px',
							paddingLeft: '0'
						},
						endAdornment:
							value === '' ? (
								<InputAdornment position='end'>
									<IconButton
										className={'iconButtonTransfer'}
										edge='end'
									>
										<Chev />
									</IconButton>
								</InputAdornment>
							) : (
								''
							),
						startAdornment:
							imgUrl != '' ? (
								<InputAdornment position='end'>
									<IconButton
										sx={{
											paddingBottom: '0px !important',
											paddingTop: '13px !important'
										}}
										onClick={onClearClick}
										aria-label=''
										edge='end'
									>
										<img
											className={'selectImg'}
											src={imgUrl}
											alt={value}
										/>
									</IconButton>
								</InputAdornment>
							) : (
								''
							)
					}}
					onClick={onClearClick}
					type={type}
					size='medium'
					id={id}
					label={label}
					value={value}
					onChange={onChange}
				/>
			</div>
		);
	}

	if (isInternalSelect) {
		return (
			<TextField
				onClick={onClearClick}
				fullWidth
				sx={{
					'& label.Mui-focused': {
						transform: 'translate(14px, 5px) scale(0.75)'
					},
					'& label.Mui-required': {
						transform: 'translate(14px, 5px) scale(0.75)'
					},
					'& .MuiOutlinedInput-root': {
						'&.Mui-focused fieldset': {
							borderColor: '#00BEB4 !important',
							borderWidth: '1px'
						},
						'&.Mui-disabled fieldset': {
							borderColor: '#F6F6F8 !important',
							borderWidth: '1px'
						},
						'& fieldset': {
							borderColor:
								theme === 'dark' ? '#1F1F1F' : '#F6F6F8'
						},
						'&:hover fieldset': {
							borderColor:
								theme === 'dark' ? '#1F1F1F' : '#F6F6F8'
						}
					},
					fieldset: {
						zIndex: '1',
						legend: {
							maxWidth: '0.01px'
						}
					},
					borderRadius: '12px !important',
					background: theme === 'dark' ? '#1F1F1F' : '#F6F6F8'
				}}
				InputLabelProps={{
					required: isRequired,
					style: {
						color: theme === 'dark' ? '#9C9C9D' : '#848790',
						fontFamily: 'Noto Sans',
						fontStyle: 'normal',
						fontSize: '16px !important',
						lineHeight: '24px'
					}
				}}
				inputProps={{
					style: {
						color: theme === 'dark' ? '#FEFEFE' : '#181A21',
						fontFamily: 'Noto Sans',
						fontStyle: 'normal',
						fontSize: '16px !important',
						lineHeight: '24px',
						paddingBottom: '2px'
					},
					onKeyPress: (event) => {
						const { key } = event;
						if (key === 'Enter') {
							inputPropsRef?.current.focus();
						}
					},
					returnkeytype: 'go'
				}}
				InputProps={{
					style: {
						borderColor: theme === 'dark' ? '#1F1F1F' : '#F6F6F8',
						borderRadius: '12px',
						height: '60px'
					},
					readOnly: true,
					endAdornment: (
						<InputAdornment position='end'>
							<IconButton
								className={'iconButtonTransfer'}
								edge='end'
							>
								<ContactIcon
									className={'contactIconContainer'}
								/>
							</IconButton>
						</InputAdornment>
					)
				}}
				type={type}
				size='medium'
				id={id}
				label={label}
				value={value}
			/>
		);
	}

	if (isAddress) {
		return (
			<div className={className}>
				<TextField
					onClick={handleModalOpen}
					fullWidth
					sx={{
						'& label.Mui-focused': {
							transform: 'translate(14px, 5px) scale(0.75)'
						},
						'& label.Mui-required': {
							transform: 'translate(14px, 5px) scale(0.75)'
						},
						'& .MuiInputBase-input.Mui-disabled': {
							WebkitTextFillColor:
								theme === 'dark' ? '#848790' : '#848790'
						},
						'& .MuiOutlinedInput-root': {
							'&.Mui-focused fieldset': {
								borderColor: '#00BEB4 !important',
								borderWidth: '1px'
							},
							'&.Mui-disabled fieldset': {
								borderColor:
									theme === 'dark'
										? '#1F1F1F !important'
										: '#F6F6F8 !important',
								borderWidth: '1px'
							},
							'&.Mui-disabled': {
								color: theme === 'dark' ? '#FEFEFE' : '#181A21'
							},
							'& input.Mui-disabled': {
								color: theme === 'dark' ? '#FEFEFE' : '#181A21'
							},
							'& fieldset': {
								borderColor:
									theme === 'dark' ? '#1F1F1F' : '#F6F6F8'
							},
							'&:hover fieldset': {
								borderColor:
									theme === 'dark' ? '#1F1F1F' : '#F6F6F8'
							}
						},
						fieldset: {
							zIndex: '1',
							legend: {
								maxWidth: '0.01px'
							}
						},
						borderRadius: '12px !important',
						background: theme === 'dark' ? '#1F1F1F' : '#F6F6F8'
					}}
					InputLabelProps={{
						required: isRequired,
						style: {
							color: theme === 'dark' ? '#9C9C9D' : '#848790',
							fontFamily: 'Noto Sans',
							fontStyle: 'normal',
							fontSize: '16px !important',
							lineHeight: '24px'
						}
					}}
					inputProps={{
						style: {
							color: theme === 'dark' ? '#FEFEFE' : '#181A21',
							fontFamily: 'Noto Sans',
							fontStyle: 'normal',
							fontSize: '16px !important',
							lineHeight: '24px',
							paddingBottom: '2px'
						},
						onKeyPress: (event) => {
							const { key } = event;
							if (key === 'Enter') {
								inputPropsRef?.current.focus();
							}
						},
						returnkeytype: 'go'
					}}
					InputProps={{
						style: {
							borderColor:
								theme === 'dark' ? '#1F1F1F' : '#F6F6F8',
							borderRadius: '12px',
							height: '60px'
						},
						readOnly: true
					}}
					type={'text'}
					size='medium'
					id={'internalInput'}
					label={label}
					value={value}
					disabled={isDisabled}
				/>
			</div>
		);
	}

	if (isBankTransfer) {
		return (
			<div className={className}>
				<TextField
					onClick={handleModalOpen}
					fullWidth
					sx={{
						'& label.Mui-focused': {
							transform: 'translate(14px, 5px) scale(0.75)'
						},
						'& label.Mui-required': {
							transform: 'translate(14px, 5px) scale(0.75)'
						},
						'& .MuiOutlinedInput-root': {
							'&.Mui-focused fieldset': {
								borderColor: '#00BEB4 !important',
								borderWidth: '1px'
							},
							'&.Mui-disabled fieldset': {
								borderColor:
									theme === 'dark'
										? '#1F1F1F !important'
										: '#F6F6F8 !important',
								borderWidth: '1px'
							},
							'&.Mui-disabled': {
								color: theme === 'dark' ? '#FEFEFE' : '#181A21'
							},
							'& input.Mui-disabled': {
								color: theme === 'dark' ? '#FEFEFE' : '#181A21'
							},
							'.MuiInputBase-input.Mui-disabled': {
								WebkitTextFillColor:
									theme === 'dark' ? '#848790' : '#848790',
								color: theme === 'dark' ? '#848790' : '#848790'
							},
							'& fieldset': {
								borderColor:
									theme === 'dark' ? '#1F1F1F' : '#F6F6F8'
							},
							'&:hover fieldset': {
								borderColor:
									theme === 'dark' ? '#1F1F1F' : '#F6F6F8'
							}
						},
						fieldset: {
							zIndex: '1',
							legend: {
								maxWidth: '0.01px'
							}
						},
						borderRadius: '12px !important',
						background: theme === 'dark' ? '#1F1F1F' : '#F6F6F8'
					}}
					InputLabelProps={{
						required: isRequired,
						style: {
							color: theme === 'dark' ? '#9C9C9D' : '#848790',
							fontFamily: 'Noto Sans',
							fontStyle: 'normal',
							fontSize: '16px !important',
							lineHeight: '24px'
						}
					}}
					inputProps={{
						style: {
							color: theme === 'dark' ? '#FEFEFE' : '#181A21',
							fontFamily: 'Noto Sans',
							fontStyle: 'normal',
							fontSize: '16px !important',
							lineHeight: '24px',
							paddingBottom: '2px'
						},
						onKeyPress: (event) => {
							const { key } = event;
							if (key === 'Enter') {
								inputPropsRef?.current.focus();
							}
						},
						returnkeytype: 'go'
					}}
					InputProps={{
						style: {
							borderColor:
								theme === 'dark' ? '#1F1F1F' : '#F6F6F8',
							borderRadius: '12px',
							height: '60px'
						},
						readOnly: true,
						endAdornment: (
							<InputAdornment position='end'>
								<IconButton
									className={'iconButtonTransfer'}
									edge='end'
								>
									<Chev />
								</IconButton>
							</InputAdornment>
						)
					}}
					type={'text'}
					size='medium'
					id={'internalInput'}
					label={label}
					value={value}
					disabled={isDisabled}
				/>
			</div>
		);
	}

	if (isWithdraw) {
		return (
			<div className={className}>
				<TextField
					disabled={isDisabled}
					fullWidth
					sx={{
						'& label.Mui-focused': {
							transform: 'translate(14px, 5px) scale(0.75)'
						},
						'& label.Mui-required': {
							transform: 'translate(14px, 5px) scale(0.75)'
						},
						'& .MuiOutlinedInput-root': {
							'&.Mui-focused fieldset': {
								borderColor: isError
									? '#E74C3C !important'
									: '#00BEB4 !important',
								borderWidth: '1px'
							},
							'&.Mui-disabled fieldset': {
								borderColor:
									theme === 'dark'
										? '#1F1F1F !important'
										: '#F6F6F8 !important',
								borderWidth: '1px'
							},
							'& fieldset': {
								borderColor:
									theme === 'dark' ? '#1F1F1F' : '#F6F6F8'
							},
							'&:hover fieldset': {
								borderColor:
									theme === 'dark' ? '#1F1F1F' : '#F6F6F8'
							}
						},
						fieldset: {
							legend: {
								maxWidth: '0.01px'
							}
						},
						borderRadius: '12px !important',
						background: theme === 'dark' ? '#1F1F1F' : '#F6F6F8'
					}}
					placeholder={placeHolder}
					InputLabelProps={{
						required: isRequired,
						style: {
							color: theme === 'dark' ? '#9C9C9D' : '#848790',
							fontFamily: 'Noto Sans',
							fontStyle: 'normal',
							fontSize: '16px !important',
							lineHeight: '24px'
						}
					}}
					inputProps={{
						style: {
							color: isError
								? '#E74C3C'
								: theme === 'dark'
								? '#FEFEFE'
								: '#181A21',
							fontFamily: 'Noto Sans',
							fontStyle: 'normal',
							fontSize: '16px !important',
							lineHeight: '24px',
							paddingBottom: '2px'
						},
						onKeyPress: (event) => {
							const { key } = event;
							if (key === 'Enter') {
								inputPropsRef?.current.focus();
							}
						},
						returnkeytype: 'go'
					}}
					InputProps={{
						style: {
							borderColor:
								theme === 'dark' ? '#1F1F1F' : '#F6F6F8',
							borderRadius: '12px',
							height: '60px'
						},
						endAdornment: (
							<InputAdornment position='end'>
								<div className={'inputMainAssetContainer'}>
									{withdrawAsset?.networkIcon != undefined &&
									withdrawAsset ? (
										<BaseIcon
											theme={theme as TTheme}
											path={
												withdrawAsset?.code.toLowerCase() as string
											}
											group={'crypto'}
											className={'withdrawInputAssetIcon'}
										/>
									) : (
										''
									)}
									{withdrawAsset ? (
										<h1
											className={
												'withdrawInputAssetText' +
												(theme === 'dark'
													? '-dark'
													: '')
											}
										>
											{withdrawAsset?.code}
										</h1>
									) : (
										''
									)}
								</div>
							</InputAdornment>
						)
					}}
					onKeyUp={onKeyUp}
					type={type}
					size='medium'
					id={id}
					label={label}
					value={value}
					onChange={onChange}
				/>
			</div>
		);
	}

	if (isInternal) {
		return (
			<div className={className}>
				<TextField
					disabled={isDisabled}
					fullWidth
					sx={{
						'& label.Mui-focused': {
							transform: 'translate(14px, 5px) scale(0.75)'
						},
						'& label.Mui-required': {
							transform: 'translate(14px, 5px) scale(0.75)'
						},
						'& .MuiOutlinedInput-root': {
							'&.Mui-focused fieldset': {
								borderColor: '#00BEB4 !important',
								borderWidth: '1px'
							},
							'&.Mui-disabled fieldset': {
								borderColor:
									theme === 'dark'
										? '#1F1F1F !important'
										: '#F6F6F8 !important',
								borderWidth: '1px'
							},
							'& fieldset': {
								borderColor:
									theme === 'dark' ? '#1F1F1F' : '#F6F6F8'
							},
							'&:hover fieldset': {
								borderColor:
									theme === 'dark' ? '#1F1F1F' : '#F6F6F8'
							}
						},
						fieldset: {
							legend: {
								maxWidth: '0.01px'
							}
						},
						borderRadius: '12px !important',
						background: theme === 'dark' ? '#1F1F1F' : '#F6F6F8'
					}}
					placeholder={placeHolder}
					InputLabelProps={{
						required: isRequired,
						style: {
							color: theme === 'dark' ? '#9C9C9D' : '#848790',
							fontFamily: 'Noto Sans',
							fontStyle: 'normal',
							fontSize: '16px !important',
							lineHeight: '24px'
						}
					}}
					inputProps={{
						pattern: `^[0-9]*\\.?[0-9]{0,${asset?.precision}}$`,
						inputMode: 'decimal',
						style: {
							color: isInputError
								? '#E74C3C'
								: theme === 'dark'
								? '#FEFEFE'
								: '#181A21',
							fontFamily: 'Noto Sans',
							fontStyle: 'normal',
							fontSize: '16px !important',
							lineHeight: '24px',
							paddingBottom: '2px'
						},
						onKeyPress: (event) => {
							const { key } = event;
							if (key === 'Enter') {
								inputPropsRef?.current.focus();
							}
						},
						returnkeytype: 'go'
					}}
					InputProps={{
						inputMode: 'decimal',
						style: {
							borderColor:
								theme === 'dark' ? '#1F1F1F' : '#F6F6F8',
							borderRadius: '12px',
							height: '60px'
						},
						endAdornment: (
							<InputAdornment position='end'>
								<IconButton
									onClick={handleModalOpen as () => void}
									edge='end'
								>
									<div className={'inputMainAssetContainer'}>
										{asset?.group != undefined && asset ? (
											<BaseIcon
												theme={theme as TTheme}
												path={
													asset?.code.toLowerCase() as string
												}
												group={
													asset?.group.toLowerCase() as string
												}
												className={'inputAssetIcon'}
											/>
										) : (
											''
										)}
										{asset ? (
											<h1
												className={
													'inputAssetText' +
													(theme === 'dark'
														? '-dark'
														: '')
												}
											>
												{asset?.code}
											</h1>
										) : (
											''
										)}
										{isInternalWithoutChev ? '' : <Chev />}
									</div>
								</IconButton>
							</InputAdornment>
						)
					}}
					inputMode='decimal'
					onKeyUp={onKeyUp}
					type={type}
					size='medium'
					id={id}
					label={label}
					value={value}
					onChange={onChange}
				/>
			</div>
		);
	}

	if (isDateOfBirth) {
		return (
			<div className={className}>
				<TextField
					fullWidth
					sx={{
						'& label.Mui-focused': {
							transform: 'translate(14px, 5px) scale(0.75)'
						},
						'& label.Mui-required': {
							transform: 'translate(14px, 5px) scale(0.75)'
						},
						'& .MuiOutlinedInput-root': {
							'&.Mui-focused fieldset': {
								borderColor: '#00BEB4 !important',
								borderWidth: '1px'
							},
							'& fieldset': {
								borderColor:
									theme === 'dark' ? '#1F1F1F' : '#F6F6F8'
							},
							'&:hover fieldset': {
								borderColor:
									theme === 'dark' ? '#1F1F1F' : '#F6F6F8'
							}
						},
						fieldset: {
							legend: {
								maxWidth: '0.01px'
							}
						},
						borderRadius: '12px !important',
						background: theme === 'dark' ? '#1F1F1F' : '#F6F6F8'
					}}
					placeholder={'dd.mm.yyyy'}
					InputLabelProps={{
						required: isRequired,
						style: {
							color: theme === 'dark' ? '#9C9C9D' : '#848790',
							fontFamily: 'Noto Sans',
							fontStyle: 'normal',
							fontSize: '16px !important',
							lineHeight: '24px'
						}
					}}
					inputProps={{
						autoComplete: 'off',
						style: {
							color: theme === 'dark' ? '#FEFEFE' : '#181A21',
							fontFamily: 'Noto Sans',
							fontStyle: 'normal',
							fontSize: '16px !important',
							lineHeight: '24px',
							paddingBottom: '2px'
						},
						returnkeytype: 'go'
					}}
					InputProps={{
						style: {
							borderColor:
								theme === 'dark' ? '#1F1F1F' : '#F6F6F8',
							borderRadius: '12px',
							height: '60px'
						},
						endAdornment: (
							<InputAdornment position='end'>
								<IconButton onClick={onClearClick} edge='end'>
									<ClearIcon
										sx={{
											fill:
												theme === 'dark'
													? 'white'
													: 'black'
										}}
									/>
								</IconButton>
							</InputAdornment>
						),
						inputComponent: isDateOfBirth
							? DateOfBirthInput
							: undefined
					}}
					autoComplete={'off'}
					type={type}
					size='medium'
					id={id}
					label={label}
					value={value}
					onChange={onChange}
				/>
			</div>
		);
	}

	if (isPassword) {
		const [showPassword, setShowPassword] = useState<boolean>(false);

		const handleClickShowPassword = () => setShowPassword((show) => !show);

		const handleMouseDownPassword = (
			event: React.MouseEvent<HTMLButtonElement>
		) => {
			event.preventDefault();
		};

		return (
			<div className={className}>
				<TextField
					fullWidth
					sx={{
						'& label.Mui-focused': {
							transform: 'translate(14px, 5px) scale(0.75)'
						},
						'& label.Mui-required': {
							transform: 'translate(14px, 5px) scale(0.75)'
						},
						'& .MuiOutlinedInput-root': {
							'&.Mui-focused fieldset': {
								borderColor: '#00BEB4 !important',
								borderWidth: '1px'
							},
							'& fieldset': {
								borderColor:
									theme === 'dark' ? '#1F1F1F' : '#F6F6F8'
							},
							'&:hover fieldset': {
								borderColor:
									theme === 'dark' ? '#1F1F1F' : '#F6F6F8'
							}
						},
						fieldset: {
							legend: {
								maxWidth: '0.01px'
							}
						},
						borderRadius: '12px !important',
						background: theme === 'dark' ? '#1F1F1F' : '#F6F6F8'
					}}
					placeholder={placeHolder}
					InputLabelProps={{
						required: isRequired,
						style: {
							color: theme === 'dark' ? '#9C9C9D' : '#848790',
							fontFamily: 'Noto Sans',
							fontStyle: 'normal',
							fontSize: '16px !important',
							lineHeight: '24px'
						}
					}}
					inputProps={{
						style: {
							color: theme === 'dark' ? '#FEFEFE' : '#181A21',
							fontFamily: 'Noto Sans',
							fontStyle: 'normal',
							fontSize: '16px !important',
							lineHeight: '24px',
							paddingBottom: '2px'
						},
						onKeyPress: (event) => {
							const { key } = event;
							if (key === 'Enter') {
								inputPropsRef?.current.focus();
							}
						},
						returnkeytype: 'go'
					}}
					InputProps={{
						style: {
							borderColor:
								theme === 'dark' ? '#1F1F1F' : '#F6F6F8',
							borderRadius: '12px',
							height: '60px'
						},
						endAdornment: (
							<InputAdornment position='end'>
								<IconButton
									aria-label='toggle password visibility'
									onClick={handleClickShowPassword}
									onMouseDown={handleMouseDownPassword}
									edge='end'
								>
									{showPassword ? (
										<VisibilityOffOutlined
											sx={{
												fill:
													theme === 'dark'
														? 'white'
														: 'black'
											}}
										/>
									) : (
										<VisibilityOutlined
											sx={{
												fill:
													theme === 'dark'
														? 'white'
														: 'black'
											}}
										/>
									)}
								</IconButton>
							</InputAdornment>
						)
					}}
					type={showPassword ? 'text' : 'password'}
					size='medium'
					id={id}
					label={label}
					value={value}
					onChange={onChange}
				/>
			</div>
		);
	}

	if (isSelectCountry) {
		return (
			<div className={className} onClick={onClearClick}>
				<TextField
					inputMode={'decimal'}
					onClick={onClearClick}
					fullWidth
					sx={{
						'& label.Mui-focused': {
							transform: 'translate(14px, 5px) scale(0.75)'
						},
						'& label.Mui-required': {
							transform: 'translate(14px, 5px) scale(0.75)'
						},
						'& input.Mui-disabled': {
							WebkitTextFillColor: '#181A21 !important'
						},
						'& .MuiOutlinedInput-root': {
							'&.Mui-focused fieldset': {
								borderColor: '#00BEB4 !important',
								borderWidth: '1px'
							},
							'& fieldset': {
								borderColor:
									theme === 'dark' ? '#1F1F1F' : '#F6F6F8'
							},
							'&:hover fieldset': {
								borderColor:
									theme === 'dark' ? '#1F1F1F' : '#F6F6F8'
							}
						},
						fieldset: {
							legend: {
								maxWidth: '0.01px'
							}
						},
						borderRadius: '12px !important',
						background: theme === 'dark' ? '#1F1F1F' : '#F6F6F8',
						borderColor: '#0159A2'
					}}
					placeholder={placeHolder}
					InputLabelProps={{
						required: isRequired,
						style: {
							color: theme === 'dark' ? '#9C9C9D' : '#848790',
							fontFamily: 'Noto Sans',
							fontStyle: 'normal',
							fontSize: '16px !important',
							lineHeight: '24px'
						}
					}}
					inputProps={{
						style: {
							color: theme === 'dark' ? '#FEFEFE' : '#181A21',
							fontFamily: 'Noto Sans',
							fontStyle: 'normal',
							fontSize: '16px !important',
							lineHeight: '24px',
							marginLeft: '10px',
							paddingBottom: '2px'
						},
						inputMode: 'decimal',
						returnkeytype: 'go'
					}}
					InputProps={{
						readOnly: true,
						style: {
							borderColor:
								theme === 'dark' ? '#1F1F1F' : '#F6F6F8',
							borderRadius: '12px',
							height: '60px',
							paddingLeft: '0'
						},
						startAdornment: (
							<InputAdornment position='end'>
								<IconButton
									onClick={onClearClick}
									edge='end'
									sx={{
										marginTop: '13px !important'
									}}
								>
									{imgUrl === '' ? (
										''
									) : (
										<img
											className={'selectImg'}
											src={imgUrl}
											alt={value}
										/>
									)}
								</IconButton>
							</InputAdornment>
						),
						endAdornment: (
							<InputAdornment position='end'>
								<IconButton onClick={onClearClick} edge='end'>
									<KeyboardArrowDownIcon
										sx={{
											fill:
												theme === 'dark'
													? 'white'
													: 'black'
										}}
									/>
								</IconButton>
							</InputAdornment>
						)
					}}
					type={type}
					size='medium'
					id={id}
					label={label}
					value={value}
					onChange={onChange}
				/>
			</div>
		);
	}

	if (isSelectCode) {
		return (
			<div className={className} onClick={onClearClick}>
				<TextField
					inputMode={'decimal'}
					fullWidth
					sx={{
						'& label.Mui-focused': {
							transform: 'translate(14px, 5px) scale(0.75)'
						},
						'& label.Mui-required': {
							transform: 'translate(14px, 5px) scale(0.75)'
						},
						'& input.Mui-disabled': {
							WebkitTextFillColor: '#181A21 !important'
						},
						'& .MuiOutlinedInput-root': {
							'&.Mui-focused fieldset': {
								borderColor: '#00BEB4 !important',
								borderWidth: '1px'
							},
							'& fieldset': {
								borderColor:
									theme === 'dark' ? '#1F1F1F' : '#F6F6F8'
							},
							'&:hover fieldset': {
								borderColor:
									theme === 'dark' ? '#1F1F1F' : '#F6F6F8'
							}
						},
						fieldset: {
							legend: {
								maxWidth: '0.01px'
							}
						},
						borderRadius: '12px !important',
						background: theme === 'dark' ? '#1F1F1F' : '#F6F6F8',
						borderColor: '#0159A2'
					}}
					placeholder={placeHolder}
					InputLabelProps={{
						required: isRequired,
						style: {
							color: theme === 'dark' ? '#9C9C9D' : '#848790',
							fontFamily: 'Noto Sans',
							fontStyle: 'normal',
							fontSize: '16px !important',
							lineHeight: '24px'
						}
					}}
					inputProps={{
						style: {
							color: theme === 'dark' ? '#FEFEFE' : '#181A21',
							fontFamily: 'Noto Sans',
							fontStyle: 'normal',
							fontSize: '16px !important',
							lineHeight: '24px',
							marginLeft: '10px'
						},
						inputMode: 'decimal',
						returnkeytype: 'go'
					}}
					InputProps={{
						readOnly: true,
						style: {
							borderColor:
								theme === 'dark' ? '#1F1F1F' : '#F6F6F8',
							borderRadius: '12px',
							height: '60px',
							paddingLeft: '0'
						},
						startAdornment: (
							<InputAdornment position='end'>
								<IconButton
									onClick={onClearClick}
									aria-label='toggle password visibility'
									edge='end'
								>
									{imgUrl === '' ? (
										<div
											className={
												'countryCodeSelect-defaultCountry'
											}
										/>
									) : (
										<img
											className={'selectImg'}
											src={imgUrl}
											alt={value}
										/>
									)}
								</IconButton>
							</InputAdornment>
						)
					}}
					onClick={onClearClick}
					type={type}
					size='medium'
					id={id}
					label={label}
					value={value}
					onChange={onChange}
				/>
			</div>
		);
	}

	if (searchInput) {
		return (
			<div className={className}>
				<TextField
					fullWidth
					sx={{
						'& label.Mui-focused': {
							transform: 'translate(14px, 5px) scale(0.75)'
						},
						'& label.Mui-required': {
							transform: 'translate(14px, 5px) scale(0.75)'
						},
						'& .MuiOutlinedInput-root': {
							'&.Mui-focused fieldset': {
								borderColor: '#00BEB4 !important',
								borderWidth: '1px'
							},
							'& fieldset': {
								borderColor:
									theme === 'dark' ? '#1F1F1F' : '#F6F6F8'
							},
							'&:hover fieldset': {
								borderColor:
									theme === 'dark' ? '#1F1F1F' : '#F6F6F8'
							}
						},
						fieldset: {
							legend: {
								maxWidth: '0.01px'
							}
						},
						borderRadius: '12px !important',
						background: theme === 'dark' ? '#1F1F1F' : '#F6F6F8',
						borderColor: '#0159A2'
					}}
					placeholder={placeHolder}
					InputLabelProps={{
						required: isRequired,
						style: {
							color: theme === 'dark' ? '#9C9C9D' : '#848790',
							fontFamily: 'Noto Sans',
							fontStyle: 'normal',
							fontSize: '16px !important',
							lineHeight: '24px'
						}
					}}
					inputProps={{
						autoComplete: 'off',
						style: {
							color: theme === 'dark' ? '#FEFEFE' : '#181A21',
							fontFamily: 'Noto Sans',
							fontStyle: 'normal',
							fontSize: '16px !important',
							lineHeight: '24px',
							paddingBottom: '2px'
						},
						returnkeytype: 'go'
					}}
					InputProps={{
						style: {
							borderColor:
								theme === 'dark' ? '#1F1F1F' : '#F6F6F8',
							borderRadius: '12px',
							height: '60px'
						},
						endAdornment: (
							<InputAdornment position='end'>
								<IconButton edge='end'>
									<SearchIcon
										sx={{
											fill:
												theme === 'dark'
													? 'white'
													: 'black'
										}}
									/>
								</IconButton>
							</InputAdornment>
						)
					}}
					autoComplete={'off'}
					type={type}
					size='medium'
					id={id}
					label={label}
					value={value}
					onChange={onChange}
				/>
			</div>
		);
	}

	if (isMobile) {
		return (
			<div className={className}>
				<TextField
					disabled={isDisabled}
					fullWidth
					sx={{
						'& label.Mui-focused': {
							transform: 'translate(14px, 5px) scale(0.75)'
						},
						'& label.Mui-required': {
							transform: 'translate(14px, 5px) scale(0.75)'
						},
						'& .MuiOutlinedInput-root': {
							'&.Mui-focused fieldset': {
								borderColor: '#00BEB4 !important',
								borderWidth: '1px'
							},
							'& fieldset': {
								borderColor:
									theme === 'dark' ? '#1F1F1F' : '#F6F6F8'
							},
							'&:hover fieldset': {
								borderColor:
									theme === 'dark' ? '#1F1F1F' : '#F6F6F8'
							}
						},
						fieldset: {
							legend: {
								maxWidth: '0.01px'
							}
						},
						borderRadius: '12px !important',
						background: theme === 'dark' ? '#1F1F1F' : '#F6F6F8',
						borderColor: '#0159A2'
					}}
					placeholder={placeHolder}
					InputLabelProps={{
						required: isRequired,
						style: {
							color: theme === 'dark' ? '#9C9C9D' : '#848790',
							fontFamily: 'Noto Sans',
							fontStyle: 'normal',
							fontSize: '16px !important',
							lineHeight: '24px'
						}
					}}
					inputProps={{
						style: {
							color: theme === 'dark' ? '#FEFEFE' : '#181A21',
							fontFamily: 'Noto Sans',
							fontStyle: 'normal',
							fontSize: '16px !important',
							lineHeight: '24px',
							paddingBottom: '2px'
						},
						returnkeytype: 'go'
					}}
					InputProps={{
						style: {
							borderColor:
								theme === 'dark' ? '#1F1F1F' : '#F6F6F8',
							borderRadius: '12px',
							height: '60px'
						},
						inputComponent: isMobile ? PhoneInput : undefined
					}}
					type={type}
					size='medium'
					id={id}
					label={label}
					value={value}
					onChange={onChange}
				/>
			</div>
		);
	}

	return (
		<div className={className}>
			<TextField
				disabled={isDisabled}
				fullWidth
				sx={{
					'& label.Mui-focused': {
						transform: 'translate(14px, 5px) scale(0.75)'
					},
					'& label.Mui-required': {
						transform: 'translate(14px, 5px) scale(0.75)'
					},
					'& .MuiOutlinedInput-root': {
						'&.Mui-focused fieldset': {
							borderColor: '#00BEB4 !important',
							borderWidth: '1px'
						},
						'&.Mui-disabled fieldset': {
							borderColor:
								theme === 'dark'
									? '#1F1F1F !important'
									: '#F6F6F8 !important',
							borderWidth: '1px'
						},
						'&.Mui-disabled': {
							color: theme === 'dark' ? '#FEFEFE' : '#181A21'
						},
						'& input.Mui-disabled': {
							color: theme === 'dark' ? '#FEFEFE' : '#181A21'
						},
						'.MuiInputBase-input.Mui-disabled': {
							WebkitTextFillColor:
								theme === 'dark' ? '#848790' : '#848790',
							color: theme === 'dark' ? '#848790' : '#848790'
						},
						'& fieldset': {
							borderColor:
								theme === 'dark' ? '#1F1F1F' : '#F6F6F8'
						},
						'&:hover fieldset': {
							borderColor:
								theme === 'dark' ? '#1F1F1F' : '#F6F6F8'
						}
					},
					'.MuiInputBase-input.Mui-disabled': {
						WebkitTextFillColor:
							theme === 'dark' ? '#848790' : '#848790',
						color: theme === 'dark' ? '#848790' : '#848790'
					},
					fieldset: {
						legend: {
							maxWidth: '0.01px'
						}
					},
					borderRadius: '12px !important',
					background: theme === 'dark' ? '#1F1F1F' : '#F6F6F8',
					borderColor: '#0159A2'
				}}
				placeholder={placeHolder}
				InputLabelProps={{
					required: isRequired,
					style: {
						color: theme === 'dark' ? '#9C9C9D' : '#848790',
						fontFamily: 'Noto Sans',
						fontStyle: 'normal',
						fontSize: '16px !important',
						lineHeight: '24px'
					}
				}}
				inputProps={{
					inputMode: isPattern ? 'decimal' : undefined,
					pattern: isPattern
						? `^[0-9]*\\.?[0-9]{0,${asset?.precision}}$`
						: '',
					autoComplete: type === 'email' ? 'none' : 'off',
					style: {
						color: theme === 'dark' ? '#FEFEFE' : '#181A21',
						fontFamily: 'Noto Sans',
						fontStyle: 'normal',
						fontSize: '16px !important',
						lineHeight: '24px',
						paddingBottom: '2px'
					},
					onKeyPress: (event) => {
						const { key } = event;
						if (key === 'Enter') {
							inputPropsRef?.current.focus();
						}
					},
					returnkeytype: 'go'
				}}
				InputProps={{
					style: {
						borderColor: theme === 'dark' ? '#1F1F1F' : '#F6F6F8',
						borderRadius: '12px',
						height: '60px'
					},
					endAdornment: isNonCloseIcon ? (
						''
					) : (
						<InputAdornment position='end'>
							<IconButton onClick={onClearClick} edge='end'>
								<ClearIcon
									sx={{
										fill:
											theme === 'dark' ? 'white' : 'black'
									}}
								/>
							</IconButton>
						</InputAdornment>
					)
				}}
				type={type}
				size='medium'
				id={id}
				label={label}
				value={value}
				onChange={onChange}
			/>
		</div>
	);
};

export default CustomInput;
