import React, { useContext } from "react";
import { TMappedAsset } from "../../../../types/types";
import { ThemeContext } from "../../../../data/theme-provider";

interface Props {
	item: TMappedAsset;
	handleItemClick: (i: unknown) => void;
	handleModalOpen: () => void;
	setSearchValue: React.Dispatch<React.SetStateAction<string>>;
}
const AssetItem = ({ item, handleItemClick, handleModalOpen, setSearchValue }: Props) => {
	const { theme } = useContext(ThemeContext);
	const handleClick = () => {
		handleItemClick(item);
		handleModalOpen();
		setSearchValue("");
	};

	return (
		<div onClick={handleClick} className={"modalItem"}>
			<div key={item.code} className={"modalItemLogo"}>
				<img
					key={item.code}
					className={"modalItemImg"}
					src={item.networkIcon}
					alt={item.code}
				/>
				<h1 className={"modalItemLogoText" + (theme === "dark" ? "-dark" : "")}>
					{item.code}
				</h1>
			</div>
		</div>
	);
};

export default AssetItem;
