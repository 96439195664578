import React, {useContext} from 'react';
import {useTranslation} from "react-i18next";
import "../exchange.css";
import {ThemeContext} from "../../../data/theme-provider";

interface Props {
    text: string;
}
const ExchangeRate = (props: Props) => {
    const {theme} = useContext(ThemeContext);
    const {t} = useTranslation();

    return (
        <div className={"exchangeRate"}>
            <h1 className={"exchangeRatePriceText" + (theme === 'dark' ? '-dark' : '')}>
                {t("exchangePriceText")}
            </h1>
            <h1 className={"exchangeRateText" + (theme === 'dark' ? '-dark' : '')}>
                {props.text}
            </h1>
            <h1 className={"exchangeRateWarningText" + (theme === 'dark' ? '-dark' : '')}>
                {t("exchangeWarningRateText")}
            </h1>
        </div>
    );
};

export default ExchangeRate;