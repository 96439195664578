import React, {useContext} from 'react';
import "../exchange.css";
import BaseIcon from "../../../icons/base-icon";
import {TExchangeItem, TItem, TTheme} from "../../../types/types";
import {ThemeContext} from "../../../data/theme-provider";

interface Props {
    isFrom: boolean;
    item: TExchangeItem;
    handleItemClick: (i: TExchangeItem | TItem) => void;
    handleIsModalOpen: () => void;
    setSearchValue: React.Dispatch<React.SetStateAction<string>>;
}
const ExchangeModalItem = ({item, handleItemClick, handleIsModalOpen, setSearchValue, isFrom}: Props) => {
    const {theme} = useContext(ThemeContext);
    const handleClick = (item: TExchangeItem) => {
        handleItemClick(item);
        handleIsModalOpen();
        setSearchValue("");
    }

    return (
        <div className={"exchangeModalItem"} onClick={() => handleClick(item)}>
            <div className={"exchangeModalItemFirstContainer"}>
                <BaseIcon theme={theme as TTheme} path={item.code.toLowerCase()} group={item.group.toLowerCase()} className={"analyticsImg"}/>
                <div className={"exchangeModalItemNameContainer"}>
                    <h1 className={"analyticsCurrencyRowAssetText" + (theme === 'dark' ? '-dark' : '')}>{item.code}</h1>
                    <h1 className={"analyticsCurrencyRowUsdtText" + (theme === 'dark' ? '-dark' : '')}>{item.name}</h1>
                </div>
            </div>
            {isFrom ? <h1 className={"exchangeCurrencyRowAssetAmount" + (theme === 'dark' ? '-dark' : '')}>{item.available}</h1> : ""}
        </div>
    );
};

export default ExchangeModalItem;