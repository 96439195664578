import React, { ChangeEvent, useContext, useEffect, useState } from "react";
import { useTelegram } from "../../../hooks/useTelegram";
import { Backdrop, Fade, Modal } from "@mui/material";
import ExchangeModalItem from "../../exchange/exchange-modal-item/exchange-modal-item";
import "../../exchange/exchange.css";
import { TItem, TTheme, TWallet } from "../../../types/types";
import CustomButton from "../../common/button/custom-button";
import CloseIcon from "@mui/icons-material/Close";
import CustomInput from "../../common/input/custom-input";
import { Whale } from "../../../icons/whale";
import { useTranslation } from "react-i18next";
import { ThemeContext } from "../../../data/theme-provider";

interface Props {
	currentWallet: string;
	wallets: TWallet;
	isModalOpen: boolean;
	handleIsModalOpen: () => void;
	//eslint-disable-next-line
	handleItemClick: (i: any) => void;
}
const InternalModalCurrencySelect = (props: Props) => {
	const { theme } = useContext(ThemeContext);
	const { tg } = useTelegram();
	const { t } = useTranslation();
	const [searchValue, setSearchValue] = useState<string>("");
	const [isRequired, setIsRequired] = useState<boolean>(false);
	const [mappedData, setMappedData] = useState<TItem[]>([]);

	useEffect(() => {
		setMappedData(props.wallets[props.currentWallet].assets);
		tg.expand();
	}, [props.isModalOpen]);

	const handleCloseModal = () => {
		props.handleIsModalOpen();
		setSearchValue("");
	};

	useEffect(() => {
		setMappedData(
			props.wallets[props.currentWallet].assets.filter(
				(item: TItem) =>
					item.code.toLowerCase().includes(searchValue.toLowerCase()) ||
					item.name.toLowerCase().includes(searchValue.toLowerCase())
			)
		);
	}, [searchValue]);

	const handleSearchValueChange = (e: ChangeEvent<HTMLInputElement>) => {
		setSearchValue(e.target.value);
		e.target.value.length > 0 ? setIsRequired(true) : setIsRequired(false);
	};

	const handleKeyPress = (e: React.KeyboardEvent) => {
		if (e.key === "Enter" || e.key == "Escape") {
			handleCloseModal();
		}
	};

	return (
		<Modal
			open={props.isModalOpen}
			onClose={handleCloseModal}
			closeAfterTransition
			disableAutoFocus={true}
			onKeyDown={handleKeyPress}
			slots={{ backdrop: Backdrop }}
			slotProps={{
				backdrop: {
					timeout: 500,
				},
			}}
		>
			<Fade in={props.isModalOpen} timeout={200}>
				<div
					className={
						"registrationModalComponent" + (theme === "dark" ? "-dark" : "")
					}
				>
					<div className={"exchange-modal-header"}>
						<div className={"registrationModalHeader"}>
							<h1
								className={
									"registrationModalHeaderText" +
									(theme === "dark" ? "-dark" : "")
								}
							>
								{t("analyticsAssetText")}
							</h1>
							<CustomButton
								theme={theme as TTheme}
								isClose={true}
								onClick={handleCloseModal}
								className={"registrationCloseButtonContainer"}
							>
								<CloseIcon
									sx={{
										fill: theme === "dark" ? "white" : "black",
									}}
									className={"registrationCloseButton"}
								/>
							</CustomButton>
						</div>
						<CustomInput
							theme={theme as TTheme}
							className={"registrationModalInput"}
							id={"registrationModalInput"}
							value={searchValue}
							label={t("registrationSearch")}
							isRequired={isRequired}
							type={"text"}
							onChange={handleSearchValueChange}
							searchInput={true}
						/>
					</div>
					{mappedData.length > 0 ? (
						<div className={"exchangeModalContent"}>
							{mappedData?.map((item) => (
								<ExchangeModalItem
									isFrom={true}
									key={item.code}
									handleIsModalOpen={props.handleIsModalOpen}
									item={item}
									handleItemClick={props.handleItemClick}
									setSearchValue={setSearchValue}
								/>
							))}
						</div>
					) : (
						<div className={"exchangeModalContent-empty"}>
							<div className={"exchangeModalEmptyContainer"}>
								<Whale className={"exchangeWhale"} />
								<h1
									className={
										"exchangeModalEmptyHeader" +
										(theme === "dark" ? "-dark" : "")
									}
								>
									{t("exchangeNotFound")}
								</h1>
								<h1
									className={
										"exchangeModalEmptyBottom" +
										(theme === "dark" ? "-dark" : "")
									}
								>
									{t("exchangeChangeSettingsText")}
								</h1>
							</div>
						</div>
					)}
				</div>
			</Fade>
		</Modal>
	);
};

export default InternalModalCurrencySelect;
