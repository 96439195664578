import React from 'react';
import {IMaskInput} from "react-imask";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const DateOfBirthInput = (props: any) => {
    return (
        <IMaskInput
            {...props}
            mask="00.00.0000"
            onAccept={props.onChange}
            definitions={{
                '#': /[1-9]/,
            }}
            overwrite={false}
        />
    );
};

export default DateOfBirthInput;