import React, {useContext, useState} from 'react';
import CustomInput from "../../common/input/custom-input";
import {useTranslation} from "react-i18next";
import BankCountryModal from "./bank-country-modal";
import {TMappedBank, TTheme} from "../../../types/types";
import {ThemeContext} from "../../../data/theme-provider";

interface Props {
    value: string;
    isBankCountryRequired: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    handleItemClick: (e: any) => void;
    countries: TMappedBank[];
}
const BankCountryInput = ({value, isBankCountryRequired, handleItemClick, countries}: Props) => {
    const {theme} = useContext(ThemeContext);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const {t} = useTranslation();

    const handleModalOpen = () => {
        setIsModalOpen(!isModalOpen);
    }

    return (
        <>
            <BankCountryModal
                label={"bankDepositBankCountryLabel"}
                countries={countries}
                isModalOpen={isModalOpen}
                handleIsModalOpen={handleModalOpen}
                handleItemClick={handleItemClick}
            />
            <CustomInput
                theme={theme as TTheme}
                onChange={() => ''}
                isBankTransfer={true}
                className={"internalInputContainer"}
                value={value}
                label={t("bankDepositBankCountryLabel") as string}
                isRequired={isBankCountryRequired}
                type={"text"}
                id={"internalInput"}
                handleModalOpen={handleModalOpen}
            />
        </>
    );
};

export default BankCountryInput;