import React from 'react';
import {IMaskInput} from "react-imask";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const PhoneInput = (props: any) => {
    return (
        <IMaskInput
            {...props}
            mask="00 000 00 00 00 00"
            definitions={{
                '#': /[1-9]/,
            }}
            onAccept={props.onChange}
            overwrite={false}
        />
    );
};

export default PhoneInput;