import React, { useContext, useState } from "react";
import { ThemeContext } from "../../../data/theme-provider";
import { useTranslation } from "react-i18next";
import { Divider } from "@mui/material";
import CustomButton from "../../common/button/custom-button";
import { TMappedAsset, TTheme } from "../../../types/types";
import BaseIcon from "../../../icons/base-icon";
import { withdraw } from "../../../api/api";

interface Props {
	handleBackClickButton: () => void;
	setIsTwoFactor: () => void;
	setIsOtp: (otpKey: string) => void;
	setIsSelectOtp: () => void;
	selectedAsset: TMappedAsset;
	address: string;
	maskedAddress: string;
	amount: string;
	fee: string;
	eWallet: string;
	setState: React.Dispatch<React.SetStateAction<string>>;
}
const CryptoConfirmation = ({
	handleBackClickButton,
	setIsTwoFactor,
	setIsSelectOtp,
	setIsOtp,
	fee,
	selectedAsset,
	maskedAddress,
	amount,
	address,
	eWallet,
	setState,
}: Props) => {
	const { theme } = useContext(ThemeContext);
	const { t } = useTranslation();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isError, setIsError] = useState<boolean>(false);

	const handleSubmit = async () => {
		setIsLoading(true);
		await withdraw(
			sessionStorage.getItem("userToken") as string,
			eWallet,
			selectedAsset.code,
			amount,
			selectedAsset.networkCode,
			selectedAsset.networkName,
			address,
			setState,
			setIsError,
			setIsTwoFactor,
			setIsSelectOtp,
			setIsOtp
		);
		setIsLoading(false);
	};

	const handleBackClick = () => {
		setIsError(false);
		handleBackClickButton();
	};

	return (
		<div className={"component-clean" + (theme === "dark" ? "-dark" : "")}>
			<div className={"innerComponent"}>
				<h1 className={"exchangeWelcomeText" + (theme === "dark" ? "-dark" : "")}>
					{t("exchangeConfirmationText")}
				</h1>
				<div
					className={
						"exchangeConfirmContainer" + (theme === "dark" ? "-dark" : "")
					}
				>
					<div className={"exchangeConfirmFirstBlock"}>
						<h1
							className={
								"exchangeSellText" + (theme === "dark" ? "-dark" : "")
							}
						>
							{t("cryptoWithdrawAsset")}
						</h1>
						<div className={"exchangeAssetBlock"}>
							<h1
								className={
									"exchangeConfirmText" +
									(theme === "dark" ? "-dark" : "")
								}
							>
								{selectedAsset.code.toUpperCase()}
							</h1>
							<BaseIcon
								path={selectedAsset.code.toLowerCase()}
								group={"crypto"}
								className={"exchangeConfirmAsset"}
							/>
						</div>
					</div>

					<div className={"exchangeConfirmSecondBlock"}>
						<h1
							className={
								"exchangeSellText" + (theme === "dark" ? "-dark" : "")
							}
						>
							{t("cryptoWithdrawNetwork")}
						</h1>
						<div className={"exchangeAssetBlock"}>
							<h1
								className={
									"exchangeConfirmAmount" +
									(theme === "dark" ? "-dark" : "")
								}
							>
								{selectedAsset.networkCode}
							</h1>
						</div>
					</div>

					<div className={"exchangeConfirmSecondBlock"}>
						<h1
							className={
								"exchangeSellText" + (theme === "dark" ? "-dark" : "")
							}
						>
							{t("cryptoWithdrawAddress")}
						</h1>
						<div className={"exchangeAssetBlock"}>
							<h1
								className={
									"exchangeConfirmAmount" +
									(theme === "dark" ? "-dark" : "")
								}
							>
								{maskedAddress}
							</h1>
						</div>
					</div>

					<div className={"divider" + (theme === "dark" ? "-dark" : "")}>
						<Divider
							className={"divider" + (theme === "dark" ? "-dark" : "")}
						/>
					</div>

					<div className={"exchangeConfirmSecondBlock"}>
						<h1
							className={
								"exchangeSellText" + (theme === "dark" ? "-dark" : "")
							}
						>
							{t("cryptoWithdrawTotalWithdrawAmount")}
						</h1>
						<div className={"exchangeAssetBlock"}>
							<span
								className={
									"spanAsymp" + (theme === "dark" ? "-dark" : "")
								}
							>
								&asymp;
							</span>
							<h1
								className={
									"exchangeConfirmAmount" +
									(theme === "dark" ? "-dark" : "")
								}
							>
								{" "}
								{amount}
							</h1>
							<h1
								className={
									"exchangeConfirmText" +
									(theme === "dark" ? "-dark" : "")
								}
							>
								{selectedAsset.code.toUpperCase()}
							</h1>
							<BaseIcon
								path={selectedAsset.code.toLowerCase()}
								group={"crypto"}
								className={"exchangeConfirmAsset"}
							/>
						</div>
					</div>

					<div className={"exchangeConfirmSecondBlock"}>
						<h1
							className={
								"exchangeSellText" + (theme === "dark" ? "-dark" : "")
							}
						>
							{t("cryptoWithdrawWithdrawFee")}
						</h1>
						<div className={"exchangeAssetBlock"}>
							<span
								className={
									"spanAsymp" + (theme === "dark" ? "-dark" : "")
								}
							>
								&asymp;
							</span>
							<h1
								className={
									"exchangeConfirmAmount" +
									(theme === "dark" ? "-dark" : "")
								}
							>
								{" "}
								{fee}
							</h1>
							<h1
								className={
									"exchangeConfirmText" +
									(theme === "dark" ? "-dark" : "")
								}
							>
								{selectedAsset.code.toUpperCase()}
							</h1>
							<BaseIcon
								path={selectedAsset.code.toLowerCase()}
								group={"crypto"}
								className={"exchangeConfirmAsset"}
							/>
						</div>
					</div>

					<div className={"exchangeConfirmSecondBlock"}>
						<h1
							className={
								"exchangeSellText" + (theme === "dark" ? "-dark" : "")
							}
						>
							{t("cryptoWithdrawToBeReceiver")}
						</h1>
						<div className={"exchangeAssetBlock"}>
							<span
								className={
									"spanAsymp" + (theme === "dark" ? "-dark" : "")
								}
							>
								&asymp;
							</span>
							<h1
								className={
									"exchangeConfirmAmount" +
									(theme === "dark" ? "-dark" : "")
								}
							>
								{" "}
								{Number(Number(amount) - Number(fee)).toPrecision(3)}
							</h1>
							<h1
								className={
									"exchangeConfirmText" +
									(theme === "dark" ? "-dark" : "")
								}
							>
								{selectedAsset.code.toUpperCase()}
							</h1>
							<BaseIcon
								path={selectedAsset.code.toLowerCase()}
								group={"crypto"}
								className={"exchangeConfirmAsset"}
							/>
						</div>
					</div>
				</div>
				{isError ? (
					<h1 className={"registrationEmailError"}>
						{t("exchangeErrorOperationHeader")}
					</h1>
				) : (
					""
				)}
				<CustomButton
					isLoading={isLoading}
					theme={theme as TTheme}
					onClick={handleSubmit}
					className={"exchangeConfirmButton"}
					text={t("exchangeSubmitButton") as string}
				/>
				<div className={"divider" + (theme === "dark" ? "-dark" : "")}>
					<Divider className={"divider" + (theme === "dark" ? "-dark" : "")} />
				</div>
				<CustomButton
					theme={theme as TTheme}
					isBack={true}
					onClick={handleBackClick}
					className={"backButton"}
				/>
			</div>
		</div>
	);
};

export default CryptoConfirmation;
