import React, {useContext} from 'react';
import BaseIcon from "../../../icons/base-icon";
import {TRest, TTheme} from "../../../types/types";
import "../analytics.css";
import {ThemeContext} from "../../../data/theme-provider";

interface Props {
    item: TRest;
}
const AnalyticsItem = ({item}: Props) => {
    const {theme} = useContext(ThemeContext);

    return (
        <div className={"analyticsCurrencyRow" + (theme === 'dark' ? '-dark' : '')}>
            <div className={"analyticsImgContainer"}>
                <BaseIcon theme={theme as TTheme} className={"analyticsImg"} path={item.code.toLowerCase()} group={item.group}/>
            </div>
            <div className={"analyticsCurrencyRowContainer" + (theme === 'dark' ? '-dark' : '')}>
                <div className={"analyticsCurrencyRowAmount"}>
                    <h1 className={"analyticsCurrencyRowAssetText" + (theme === 'dark' ? '-dark' : '')}>{item.code}</h1>
                    <h1 className={"analyticsCurrencyRowAssetAmount" + (theme === 'dark' ? '-dark' : '')}>
                        {item.available?.toLocaleString('ru-RU', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: item.precision
                        }).replace(",", ".")}</h1>
                </div>
                <div className={"analyticsCurrencyRowUsdt"}>
                    <h1 className={"analyticsCurrencyRowUsdtText" + (theme === 'dark' ? '-dark' : '')}>{item.name}</h1>
                    <h1 className={"analyticsCurrencyRowUsdtAmount" + (theme === 'dark' ? '-dark' : '')}>
                        {item.baseAvailable?.toLocaleString('ru-RU', {
                            minimumFractionDigits: 4,
                        }).replace(",", ".")} USDT</h1>
                </div>
            </div>
        </div>
    );
};

export default AnalyticsItem;