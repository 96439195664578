import React, { useContext, useEffect, useRef, useState } from "react";
import "./setupMfCode.css";
import "../login.css";
import { Divider } from "@mui/material";
import { useTranslation } from "react-i18next";
import QRCode from "qrcode";
import MFCode from "../mf-code/mfCode";
import { getInitMFCode } from "../../../api/api";
import CustomButton from "../../common/button/custom-button";
import { ThemeContext } from "../../../data/theme-provider";
import { TTheme } from "../../../types/types";
import CopyIcon from "../../../icons/copy-icon";
import { Helmet } from "react-helmet";
import { Titles } from "../../../types/titles";
import { FirebaseContext } from "../../../data/firebase-provider";
import { AnalyticsEventsName } from "../../../types/analytics/analytics-events";

interface Props {
	handleIsSetupMfCodeOpen: () => void;
	userInfo: {
		login: string;
		password: string;
		state: string;
	};
	handleBackClick(): void;
	setIsRedirectPageOpen(isOpen: boolean): void;
	isRedirectPageOpen: boolean;
	setError: React.Dispatch<boolean>;
	isError: boolean;
	userToken: string;
	userLocale: string;
	maxExpSec: string;
	locale: "ru" | "en";
}
const SetupMfCode = (props: Props) => {
	const { initializeFirebase, sendAnalytics } = useContext(FirebaseContext);
	const { theme } = useContext(ThemeContext);
	const [isSecretKeyPageOpen, setIsSecretKeyPageOpen] = useState<boolean>(false);
	const [isMfPageOpen, setIsMfPageOpen] = useState<boolean>(false);
	const [isMobile, setIsMobile] = useState<boolean>(false);
	const [qrCodeImage, setQrCodeImage] = useState<string>("");
	const [secretKey, setSecretKey] = useState<string>("");
	const buttonRef = useRef();
	const { t } = useTranslation();

	useEffect(() => {
		let isMobile = false;
		if (
			navigator.userAgent.match(/Android/i) ||
			navigator.userAgent.match(/iPhone/i)
		) {
			isMobile = true;
			setIsMobile(isMobile);
		}
		initData();
	}, []);

	const initData = async () => {
		await initializeFirebase();
		const response: {
			uri: string;
			key: string;
		} = await getInitMFCode(sessionStorage.getItem("accessToken") as string).then(
			(response) => {
				return response.json();
			}
		);
		QRCode.toDataURL(
			response.uri,
			{
				width: 250,
			},
			(err, url) => {
				setQrCodeImage(url);
			}
		);
		await sendAnalytics({
			name: AnalyticsEventsName.twoFAStart,
		});
		setSecretKey(response.key);
	};

	const handleSubmit = async () => {
		setIsSecretKeyPageOpen(true);
	};

	const handleSubmitOnSecretKeyPage = async () => {
		setIsSecretKeyPageOpen(false);
		setIsMfPageOpen(true);
	};

	const handleIsSetupMfCodeOpen = () => {
		setIsSecretKeyPageOpen(false);
	};

	const handleCopyClick = async () => {
		await navigator.clipboard.writeText(secretKey);
	};

	const handleBackClickFromMfCode = () => {
		setIsMfPageOpen(false);
		setIsSecretKeyPageOpen(true);
	};

	if (isMfPageOpen) {
		return (
			<MFCode
				handleBackClick={handleBackClickFromMfCode}
				setIsRedirectPageOpen={props.setIsRedirectPageOpen}
				isRedirectPageOpen={props.isRedirectPageOpen}
				login={props.userInfo.login}
				password={props.userInfo.password}
				state={props.userInfo.state}
				setError={props.setError}
				isError={props.isError}
				userToken={props.userToken}
				userLocale={props.userLocale}
				maxExpSec={props.maxExpSec}
				accessToken={sessionStorage.getItem("accessToken") as string}
				isEnablingMfCode={true}
				secretKey={secretKey}
			/>
		);
	}

	if (isSecretKeyPageOpen) {
		return (
			<>
				<Helmet>
					<title>{Titles.LOGIN.SECRET_KEY}</title>
				</Helmet>
				<div className={"component-clean" + (theme === "dark" ? "-dark" : "")}>
					<div className={"innerComponent"}>
						<h1
							className={
								"confirmEmailText" + (theme === "dark" ? "-dark" : "")
							}
						>
							{t("mfCodeSecretKey")}
						</h1>
						<h1
							className={
								"confirmCodeText" + (theme === "dark" ? "-dark" : "")
							}
						>
							{isMobile
								? t("mfCodeSecretKeyDescription")
								: t("mfCodeSecretKeyQR")}
						</h1>
						{isMobile ? (
							<div className={"setupMfCode-item-secretKey"}>
								<div className={"setupMfCode-item-secretKey-inner"}>
									<h1
										className={
											"key" + (theme === "dark" ? "-dark" : "")
										}
									>
										{t("mfCodeKey")}
									</h1>
									<div className={"key-text-inner"}>
										<h1
											className={
												"key-text" +
												(theme === "dark" ? "-dark" : "")
											}
										>
											{secretKey}
										</h1>
										<CopyIcon
											onClick={handleCopyClick}
											theme={theme as TTheme}
											className={"bankDepositCopyIcon"}
										/>
									</div>
								</div>
							</div>
						) : (
							<div className={"qrCode-image"}>
								<img src={qrCodeImage} alt={qrCodeImage} />
							</div>
						)}
						<CustomButton
							theme={theme as TTheme}
							text={t("mfCodeNext") as string}
							type={"submit"}
							onClick={handleSubmitOnSecretKeyPage}
							className={"loginButtonError"}
							buttonRef={buttonRef}
						/>
						<div className={"divider" + (theme === "dark" ? "-dark" : "")}>
							<Divider
								className={"divider" + (theme === "dark" ? "-dark" : "")}
							/>
						</div>
						<CustomButton
							theme={theme as TTheme}
							isBack={true}
							onClick={handleIsSetupMfCodeOpen}
							className={"backButton"}
						/>
					</div>
				</div>
			</>
		);
	}

	return (
		<div className={"component-clean" + (theme === "dark" ? "-dark" : "")}>
			<div className={"innerComponent"}>
				<h1 className={"confirmEmailText" + (theme === "dark" ? "-dark" : "")}>
					{t("mfCodeDownloadAppText")}
				</h1>
				<div
					className={
						"forgotPasswordContainerText" + (theme === "dark" ? "-dark" : "")
					}
				>
					{props.locale === "ru" ? (
						<h1
							className={
								"setupMfCode-item-description-text" +
								(theme === "dark" ? "-dark" : "")
							}
						>
							Загрузите и установите приложение «Google Authenticator» в
							<a
								href={
									"https://apps.apple.com/ru/app/google-authenticator/id388497605"
								}
							>
								{" "}
								App Store
							</a>
							,{" "}
							<a
								href={
									"https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&pli=1"
								}
							>
								Google Play Store{" "}
							</a>
							или в магазине приложений другой платформы.
						</h1>
					) : (
						<h1
							className={
								"setupMfCode-item-description-text" +
								(theme === "dark" ? "-dark" : "")
							}
						>
							Download and install the Google Authenticator app in
							<a
								href={
									"https://apps.apple.com/ru/app/google-authenticator/id388497605"
								}
							>
								{" "}
								App Store
							</a>
							,{" "}
							<a
								href={
									"https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&pli=1"
								}
							>
								Google Play Store{" "}
							</a>
							or in the app store of another platform.
						</h1>
					)}
				</div>
				<CustomButton
					theme={theme as TTheme}
					text={t("mfCodeNext") as string}
					type={"submit"}
					onClick={handleSubmit}
					className={"loginButtonError"}
					buttonRef={buttonRef}
				/>
				<div className={"divider" + (theme === "dark" ? "-dark" : "")}>
					<Divider className={"divider" + (theme === "dark" ? "-dark" : "")} />
				</div>
				<CustomButton
					theme={theme as TTheme}
					isBack={true}
					onClick={props.handleIsSetupMfCodeOpen}
					className={"backButton"}
				/>
			</div>
		</div>
	);
};

export default SetupMfCode;
