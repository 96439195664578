import { ChangeEvent, useContext, useEffect, useState } from "react";
import { ThemeContext } from "../../data/theme-provider";
import { useTranslation } from "react-i18next";
import { useTelegram } from "../../hooks/useTelegram";
import { findBankByIban, initBankWithdrawPage } from "../../api/api";
import CircleProgress from "../circle-progress/circle-progress";
import { TCountry, TExchangeItem, TFoundedBankInfo, TTheme } from "../../types/types";
import CustomButton from "../common/button/custom-button";
import { Divider } from "@mui/material";
import BankCountryInput from "./bank-country/bank-country-input";
import CustomInput from "../common/input/custom-input";
import BankNotFound from "./bank-not-found/bank-not-found";
import { ExchangeContext } from "../../data/exchange-provider";
import { Whale } from "../../icons/whale";
import BankFound from "./bank-found/bank-found";
import { Helmet } from "react-helmet";
import { Titles } from "../../types/titles";
import { FirebaseContext } from "../../data/firebase-provider";
import { AnalyticsEventsName } from "../../types/analytics/analytics-events";

const locale = window.location.pathname.split("/")[1] != "en" ? "ru" : "en";

const BankWithdraw = () => {
	const { initializeFirebase, sendAnalytics } = useContext(FirebaseContext);
	const { data, eWallet, isWalletsEmpty, updateUserToken } =
		useContext(ExchangeContext);
	const { theme, updateTheme } = useContext(ThemeContext);
	const { t } = useTranslation();
	const { tg } = useTelegram();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isButtonLoading, setIsButtonLoading] = useState<boolean>(false);
	const [isError, setIsError] = useState<boolean>(false);

	const [currentAsset, setCurrentAsset] = useState<TExchangeItem>();
	const [currencies, setCurrencies] = useState<TExchangeItem[]>([]);
	const [isBankCountryRequired, setIsBankCountryRequired] = useState<boolean>(false);

	const [iban, setIban] = useState<string>("");
	const [isIbanRequired, setIsIbanRequired] = useState<boolean>(false);

	const [isBankFound, setIsBankFound] = useState<boolean>(false);
	const [isBankNotFound, setIsBankNotFound] = useState<boolean>(false);
	const [bankInfoForFoundedBank, setBankInfoForFoundedBank] =
		useState<TFoundedBankInfo>();

	const [countries, setCountries] = useState<TCountry[] | undefined>();

	const initPage = async () => {
		await initializeFirebase();
		setIsLoading(true);
		console.log(locale);
		console.log(isError);
		updateUserToken(sessionStorage.getItem("userToken") as string, locale);
		await initBankWithdrawPage(
			sessionStorage.getItem("userToken") as string,
			setCurrencies,
			setIsLoading,
			setCurrentAsset,
			setIsBankCountryRequired,
			setCountries
		);
		setIsLoading(false);
	};

	useEffect(() => {
		updateTheme();
		sessionStorage.setItem(
			"userToken",
			new URLSearchParams(window.location.search).get("token") as string
		);
		initPage();
	}, []);

	if (isWalletsEmpty) {
		return (
			<>
				<Helmet>
					<title>{Titles.BANK_WITHDRAW.EMPTY}</title>
				</Helmet>
				<div className={"component-clean" + (theme === "dark" ? "-dark" : "")}>
					<div className={"innerComponent"}>
						<div className={"exchangePaymentLogoContainer"}>
							<Whale className={""} />
						</div>
						<h1
							className={
								"exchangePaymentHeaderText" +
								(theme === "dark" ? "-dark" : "")
							}
						>
							{t("exchangeWelcomeText")}
						</h1>
						<h1
							className={
								"exchangePaymentBottomText" +
								(theme === "dark" ? "-dark" : "")
							}
						>
							{t("exchangeWalletsEmptyText")}
						</h1>
						<CustomButton
							theme={theme as TTheme}
							onClick={() => tg.close()}
							className={"exchangeConfirmCloseButton"}
							text={t("exchangeCloseButton") as string}
						/>
					</div>
				</div>
			</>
		);
	}

	if (isLoading || data.length === 0 || currencies.length == 0)
		return <CircleProgress thickness={6} size={40} />;

	const handleBackClickButton = () => {
		tg.close();
	};

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const handleBankCountryClick = (e: any) => {
		setCurrentAsset(e);
		setIsBankCountryRequired(true);
	};

	const handleSubmit = async () => {
		await sendAnalytics({
			name: AnalyticsEventsName.withdrawStart,
		});
		await findBankByIban(
			sessionStorage.getItem("userToken") as string,
			locale,
			currentAsset?.code as string,
			iban,
			setBankInfoForFoundedBank,
			setIsError,
			setIsButtonLoading,
			setIsBankFound,
			setIsBankNotFound
		);
		console.log(eWallet);
	};

	const handleIbanChange = (e: ChangeEvent<HTMLInputElement>) => {
		setIban(e.target.value);
		e.target.value.length > 0 ? setIsIbanRequired(true) : setIsIbanRequired(false);
	};

	const handleIbanClearClick = () => {
		setIban("");
		setIsIbanRequired(false);
	};

	const handleBackClickFromSecondStep = () => {
		setIsBankFound(false);
		setIsBankNotFound(false);
	};

	if (isBankFound) {
		return (
			<>
				<Helmet>
					<title>{Titles.BANK_WITHDRAW.DATA}</title>
				</Helmet>
				<BankFound
					currencyCode={currentAsset?.code as string}
					handleBankCountryClick={handleBankCountryClick}
					currencies={currencies}
					iban={iban}
					onChange={handleIbanChange}
					handleBackClick={handleBackClickFromSecondStep}
					currentAsset={currentAsset}
					assets={data}
					eWallet={eWallet}
					bankInfo={bankInfoForFoundedBank}
				/>
			</>
		);
	}

	if (isBankNotFound) {
		return (
			<>
				<Helmet>
					<title>{Titles.BANK_WITHDRAW.DATA}</title>
				</Helmet>
				<BankNotFound
					assets={data}
					handleIbanClearClick={handleIbanClearClick}
					iban={iban}
					onChange={handleIbanChange}
					handleBankCountryClick={handleBankCountryClick}
					currencyCode={currentAsset?.code as string}
					currencies={currencies}
					handleBackClick={handleBackClickFromSecondStep}
					countries={countries as TCountry[]}
					currentAsset={currentAsset as TExchangeItem}
					eWallet={eWallet}
				/>
			</>
		);
	}

	return (
		<>
			<Helmet>
				<title>{Titles.BANK_WITHDRAW.INDEX}</title>
			</Helmet>
			<div className={"component-clean" + (theme === "dark" ? "-dark" : "")}>
				<div className={"innerComponent"}>
					<h1
						className={
							"exchangeWelcomeText" + (theme === "dark" ? "-dark" : "")
						}
					>
						{t("bankWithdrawHeader")}
					</h1>
					<BankCountryInput
						value={currentAsset?.code as string}
						isBankCountryRequired={isBankCountryRequired}
						handleItemClick={handleBankCountryClick}
						currencies={currencies}
					/>
					<CustomInput
						isRequired={isIbanRequired}
						type={"email"}
						id={"email"}
						label={t("bankWithdrawAccountNumber") as string}
						value={iban}
						onChange={handleIbanChange}
						className={"internalInputContainer"}
						onClearClick={handleIbanClearClick}
						theme={theme as TTheme}
					/>
					<CustomButton
						theme={theme as TTheme}
						isLoading={isButtonLoading}
						onClick={handleSubmit}
						className={"exchangeConfirmButton"}
						isDisabled={false}
						text={t("registrationContinue") as string}
					/>
					<div className={"divider" + (theme === "dark" ? "-dark" : "")}>
						<Divider
							className={"divider" + (theme === "dark" ? "-dark" : "")}
						/>
					</div>
					<CustomButton
						theme={theme as TTheme}
						isBack={true}
						onClick={handleBackClickButton}
						className={"backButton"}
					/>
				</div>
			</div>
		</>
	);
};

export default BankWithdraw;
