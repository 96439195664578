import React, { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Divider } from "@mui/material";
import { sendPasswordRecovery } from "../../api/api";
import { useTelegram } from "../../hooks/useTelegram";
import CustomButton from "../common/button/custom-button";
import CustomInput from "../common/input/custom-input";
import { ThemeContext } from "../../data/theme-provider";
import { TTheme } from "../../types/types";
import { Helmet } from "react-helmet";
import { Titles } from "../../types/titles";

interface Props {
	handleBackClick: () => void;
	email: string;
	setEmail: React.Dispatch<React.SetStateAction<string>>;
	locale: string;
}

const ForgotPassword = (props: Props) => {
	const { theme } = useContext(ThemeContext);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isEmailRequired, setIsEmailRequired] = useState(false);
	const [isSent, setIsSent] = useState<boolean>(false);
	const buttonRef = useRef();
	const { tg } = useTelegram();
	const { t } = useTranslation();

	useEffect(() => {
		if (props.email != "") {
			setIsEmailRequired(true);
		}
	}, []);

	const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		props.setEmail(e.target.value);
		e.target.value.length >= 1 ? setIsEmailRequired(true) : setIsEmailRequired(false);
	};

	const handleEmailClearClick = () => {
		props.setEmail("");
		setIsEmailRequired(false);
	};

	const handleBackClick = () => {
		setIsEmailRequired(false);
		props.handleBackClick();
	};

	const handleSubmitButton = async () => {
		const result = await sendPasswordRecovery(
			props.email,
			props.locale,
			setIsLoading
		);
		setIsSent(result);
	};

	const handleCloseButton = () => {
		tg.close();
	};

	if (isSent) {
		return (
			<>
				<Helmet>
					<title>{Titles.LOGIN.SENT}</title>
				</Helmet>
				<div className={"component-clean" + (theme === "dark" ? "-dark" : "")}>
					<div className={"innerComponent"}>
						<h1
							className={
								"confirmEmailText" + (theme === "dark" ? "-dark" : "")
							}
						>
							{t("forgotText")}
						</h1>
						<div
							className={
								"forgotPasswordContainerText" +
								(theme === "dark" ? "-dark" : "")
							}
						>
							<h1
								className={
									"passwordRecoveryText" +
									(theme === "dark" ? "-dark" : "")
								}
							>
								{t("forgotTextOperation")}
							</h1>
						</div>
						<CustomButton
							isLoading={isLoading}
							text={t("exchangeCloseButton") as string}
							type={"submit"}
							onClick={handleCloseButton}
							className={"loginButtonError"}
							buttonRef={buttonRef}
							isDisabled={props.email.length === 0}
							theme={theme as TTheme}
						/>
						<div className={"divider" + (theme === "dark" ? "-dark" : "")}>
							<Divider
								className={"divider" + (theme === "dark" ? "-dark" : "")}
							/>
						</div>
						<CustomButton
							theme={theme as TTheme}
							isBack={true}
							onClick={handleBackClick}
							className={"backButton"}
						/>
					</div>
				</div>
			</>
		);
	}

	return (
		<div className={"component-clean" + (theme === "dark" ? "-dark" : "")}>
			<div className={"innerComponent"}>
				<h1 className={"confirmEmailText" + (theme === "dark" ? "-dark" : "")}>
					{t("forgotText")}
				</h1>
				<h1 className={"confirmCodeText" + (theme === "dark" ? "-dark" : "")}>
					{t("passwordRecoveryDescriptionText")}
				</h1>
				<CustomInput
					isRequired={isEmailRequired}
					type={"email"}
					id={"email"}
					label={"Email"}
					value={props.email}
					onChange={handleEmailChange}
					className={"passwordInput"}
					onClearClick={handleEmailClearClick}
					inputPropsRef={buttonRef}
					theme={theme as TTheme}
				/>
				<CustomButton
					isLoading={isLoading}
					text={t("sendButtonText") as string}
					type={"submit"}
					onClick={handleSubmitButton}
					className={"loginButtonError"}
					buttonRef={buttonRef}
					isDisabled={props.email.length === 0}
					theme={theme as TTheme}
				/>
				<div className={"divider" + (theme === "dark" ? "-dark" : "")}>
					<Divider className={"divider" + (theme === "dark" ? "-dark" : "")} />
				</div>
				<CustomButton
					isBack={true}
					onClick={handleBackClick}
					className={"backButton"}
					theme={theme as TTheme}
				/>
			</div>
		</div>
	);
};

export default ForgotPassword;
