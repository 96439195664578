import React, {ChangeEvent, useContext, useRef, useState} from 'react';
import "../registration.css";
import {Divider} from "@mui/material";
import CustomInput from "../../common/input/custom-input";
import CustomButton from "../../common/button/custom-button";
import {useTranslation} from "react-i18next";
import CountryModal from "../country-modal/country-modal";
import {TCountry, TTheme} from "../../../types/types";
import {ThemeContext} from "../../../data/theme-provider";

interface Props {
    isPhoneRequired: boolean;
    phone: string;
    countryCode: {
        code: string,
        imgUrl: string,
    };
    handleCountryCodeChange: (value: {
        code: string,
        imgUrl: string,
    }) => void;
    handlePhoneChange: (e: ChangeEvent<HTMLInputElement>) => void;
    handlePhoneClearClick: () => void;
    handleConfirmMobile: () => void;
    handleBackClickFromConfirmMobile: () => void;
    isLoading: boolean;
    isError: boolean;
    errorText: string;
    isPhoneVerified: boolean;
    countries: TCountry[];
}
const MobilePhoneSelect = (
    {
        isPhoneRequired,
        handleConfirmMobile,
        phone,
        handlePhoneClearClick,
        handleBackClickFromConfirmMobile,
        handlePhoneChange,
        countryCode,
        handleCountryCodeChange,
        isLoading,
        errorText,
        isError,
        isPhoneVerified,
        countries
    }: Props) => {
    const {t} = useTranslation();
    const {theme} = useContext(ThemeContext);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const buttonRef = useRef(null);

    const handleSelectClick = () => {
        setIsModalOpen(!isModalOpen);
    }

    const handleChangeWIP = (e: ChangeEvent<HTMLInputElement>) => {
        console.log(e)
    }

    return (
        <div className={"component-clean" + (theme === 'dark' ? '-dark' : '')}>
            <div className={"innerComponent"}>
                <h1 className={"confirmEmailText" + (theme === 'dark' ? '-dark' : '')}>{t("registrationMobilePhone")}</h1>
                <h1 className={"confirmCodeText" + (theme === 'dark' ? '-dark' : '')}>{t("registrationProvideMobile")}</h1>
                <CountryModal countries={countries} isModalOpen={isModalOpen} handleIsModalOpen={handleSelectClick} handleItemClick={handleCountryCodeChange}/>
                <div className={"mobileInputBlock"}>
                    <CustomInput
                        theme={theme as TTheme}
                        isDisabled={isPhoneVerified}
                        isRequired={false}
                        type={"text"}
                        id={"countryCodeSelect"}
                        label={""}
                        value={countryCode.code}
                        onChange={handleChangeWIP}
                        onClearClick={handleSelectClick}
                        className={"countryCodeSelect"}
                        isSelectCode={true}
                        imgUrl={countryCode.imgUrl}
                    />
                    <CustomInput
                        theme={theme as TTheme}
                        isDisabled={isPhoneVerified}
                        isRequired={isPhoneRequired}
                        type={"text"}
                        id={"number"}
                        label={t("registrationPhone")}
                        value={phone}
                        onChange={handlePhoneChange}
                        className={"phoneInput"}
                        onClearClick={handlePhoneClearClick}
                        placeHolder={"000000000"}
                        isMobile={true}
                        inputPropsRef={buttonRef}
                    />
                </div>
                {isError ? <h1 className={"registrationEmailError"}>{t(errorText)}</h1> : <div className={"registrationEmailError"}/>}
                <CustomButton theme={theme as TTheme} buttonRef={buttonRef} isLoading={isLoading} text={t("registrationContinue") as string} type={"submit"} onClick={handleConfirmMobile} className={"confirmEmailButton"} isDisabled={phone.replace('(',"").replace(")","").replaceAll("-","").replace(" ","").length == 0}/>
                <div className={"divider" + (theme === 'dark' ? '-dark' : '')}>
                    <Divider className={"divider" + (theme === 'dark' ? '-dark' : '')}/>
                </div>
                <CustomButton theme={theme as TTheme} isBack={true} onClick={handleBackClickFromConfirmMobile} className={"backButton"}/>
            </div>
        </div>
    )
};

export default MobilePhoneSelect;