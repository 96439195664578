import React, { useContext, useEffect, useRef, useState } from "react";
import "./internal-transfer.css";
import { useTranslation } from "react-i18next";
import { useTelegram } from "../../hooks/useTelegram";
import { InternalContext } from "../../data/internal-provider";
import CircleProgress from "../circle-progress/circle-progress";
import { TItem, TTheme } from "../../types/types";
import InternalPayment from "./internal-payment/internal-payment";
import { Whale } from "../../icons/whale";
import CustomButton from "../common/button/custom-button";
import SwapButton from "../../icons/swap-button";
import CustomInput from "../common/input/custom-input";
import InternalInput from "../common/input/internal-input/internal-input";
import InternalModalCurrencySelect from "./internal-modal-currency-select/internal-modal-currency-select";
import { ThemeContext } from "../../data/theme-provider";
import { Helmet } from "react-helmet";
import { Titles } from "../../types/titles";
import { FirebaseContext } from "../../data/firebase-provider";

const locale = window.location.pathname.split("/")[1] != "en" ? "ru" : "en";

const InternalTransfer = () => {
	const { initializeFirebase } = useContext(FirebaseContext);
	const { theme, updateTheme } = useContext(ThemeContext);
	const {
		updateUserToken,
		data,
		fromData,
		wallets,
		isLoading,
		transfer,
		isTransferLoading,
		isWalletsEmpty,
		isSuccess,
		isCompleted,
		transferResponse,
	} = useContext(InternalContext);
	const { t } = useTranslation();
	const { tg } = useTelegram();

	const [selectedFromWallet, setSelectedFromWallet] = useState<string>("");
	const [isFromRequired, setIsFromRequired] = useState<boolean>(false);
	const inputRef = useRef();

	const [selectedToWallet, setSelectedToWallet] = useState<string>("");
	const [isToRequired, setIsToRequired] = useState<boolean>(false);

	const [selectedFromItem, setSelectedItem] = useState<TItem>();
	const [selectedToItem, setSelectedToItem] = useState<TItem>();

	const [isAssetModalOpen, setIsAssetModalOpen] = useState<boolean>(false);
	const [amount, setAmount] = useState<string>("");
	const [isAmountRequired, setIsAmountRequired] = useState<boolean>(false);
	const [isInputError, setIsInputError] = useState<boolean>(false);

	const disabledSwapButton =
		selectedToItem == undefined || wallets[selectedToWallet].assets.length == 0;

	const handleFirebaseInitialization = async () => {
		await initializeFirebase();
	};

	useEffect(() => {
		handleFirebaseInitialization();
		tg.expand();
		sessionStorage.setItem(
			"userToken",
			new URLSearchParams(window.location.search).get("token") as string
		);
		updateTheme();
		updateUserToken(sessionStorage.getItem("userToken") as string, locale);
	}, []);

	useEffect(() => {
		if (data.length > 1 && Object.keys(wallets).length > 0) {
			setSelectedFromWallet(fromData[0]);
			setIsFromRequired(true);
			const toWallet = data.find((wallet) => wallet != fromData[0]) as string;
			setSelectedToWallet(toWallet);
			setIsToRequired(true);
			const selectedFromItem = wallets[fromData[0]].assets[0];
			setSelectedItem(selectedFromItem);
			const selectedToItem = wallets[toWallet].assets.find(
				(item: TItem) => item.code == selectedFromItem.code
			);
			setSelectedToItem(selectedToItem);
		}
	}, [data]);

	const handleCloseClick = () => {
		tg.close();
	};

	if (isWalletsEmpty) {
		return (
			<>
				<Helmet>
					<title>{Titles.INTERNAL_TRANSFER.EMPTY}</title>
				</Helmet>
				<div className={"component-clean" + (theme === "dark" ? "-dark" : "")}>
					<div className={"innerComponent"}>
						<div className={"exchangePaymentLogoContainer"}>
							<Whale className={""} />
						</div>
						<h1
							className={
								"exchangePaymentHeaderText" +
								(theme === "dark" ? "-dark" : "")
							}
						>
							{t("transferLogoText")}
						</h1>
						<h1
							className={
								"exchangePaymentBottomText" +
								(theme === "dark" ? "-dark" : "")
							}
						>
							{t("exchangeWalletsEmptyText")}
						</h1>
						<CustomButton
							theme={theme as TTheme}
							onClick={handleCloseClick}
							className={"exchangeConfirmCloseButton"}
							text={t("exchangeCloseButton") as string}
						/>
					</div>
				</div>
			</>
		);
	}

	if (!isLoading && selectedFromWallet != "" && selectedToWallet == "") {
		return (
			<>
				<Helmet>
					<title>{Titles.INTERNAL_TRANSFER.EMPTY}</title>
				</Helmet>
				<div className={"component-clean" + (theme === "dark" ? "-dark" : "")}>
					<div className={"innerComponent"}>
						<div className={"exchangePaymentLogoContainer"}>
							<Whale className={""} />
						</div>
						<h1
							className={
								"exchangePaymentHeaderText" +
								(theme === "dark" ? "-dark" : "")
							}
						>
							{t("transferLogoText")}
						</h1>
						<h1
							className={
								"exchangePaymentBottomText" +
								(theme === "dark" ? "-dark" : "")
							}
						>
							{t("exchangeWalletsEmptyText")}
						</h1>
						<CustomButton
							theme={theme as TTheme}
							onClick={handleCloseClick}
							className={"exchangeConfirmCloseButton"}
							text={t("exchangeCloseButton") as string}
						/>
					</div>
				</div>
			</>
		);
	}

	if (
		isLoading ||
		theme === "" ||
		data.length == 0 ||
		Object.keys(wallets).length == 0 ||
		selectedFromItem == undefined ||
		selectedFromWallet == "" ||
		selectedToWallet == ""
	)
		return <CircleProgress thickness={6} size={40} />;

	const handleFromWalletClick = (i: string) => {
		setSelectedFromWallet(i);
		i.length > 0 ? setIsFromRequired(true) : setIsFromRequired(false);
		setAmount("");
		setIsInputError(false);
		if (fromData.length > 0) {
			const toWallet = fromData.find((item) => item != i) as string;
			setSelectedToWallet(toWallet);
			i.length > 0 ? setIsToRequired(true) : setIsToRequired(false);
			const fromItem = wallets[i].assets[0];
			setSelectedItem(fromItem);
			setSelectedToItem(
				wallets[toWallet].assets.find(
					(item: TItem) => item.code === fromItem?.code
				)
			);
		}
	};

	const handleToWalletClick = (i: string) => {
		setSelectedToWallet(i);
		i.length > 0 ? setIsToRequired(true) : setIsToRequired(false);
		setAmount("");
		setIsInputError(false);
		if (data.length > 0) {
			if (wallets[i].assets.length > 0) {
				const fromWallet = fromData.find((item) => item != i) as string;
				setSelectedFromWallet(fromWallet);
				i.length > 0 ? setIsFromRequired(true) : setIsFromRequired(false);
				const fromItem = wallets[fromWallet].assets[0];
				setSelectedItem(fromItem);
				setSelectedToItem(
					wallets[i].assets.find((item: TItem) => item.code === fromItem?.code)
				);
				return;
			}
			setSelectedToItem(
				wallets[i].assets.length > 0
					? wallets[i].assets.find(
							(item: TItem) => item.code === selectedFromItem.code
					  )
					: undefined
			);
			setIsToRequired(true);
		}
	};

	const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const regex = new RegExp(`^[0-9]*\\.?[0-9]{0,${selectedFromItem.precision}}$`);
		if (e.target.value === "" || regex.test(e.target.value)) {
			setAmount(e.target.value);
			e.target.value.length > 0
				? setIsAmountRequired(true)
				: setIsAmountRequired(false);
			Number(e.target.value) > Number(selectedFromItem.available)
				? setIsInputError(true)
				: setIsInputError(false);
		}
	};

	const handleSwapClick = () => {
		const fromWallet = selectedFromWallet;
		const toWallet = selectedToWallet;
		const fromItem = selectedFromItem;
		const toItem = selectedToItem;
		setSelectedFromWallet(toWallet);
		setSelectedToWallet(fromWallet);
		setSelectedItem(toItem);
		setSelectedToItem(fromItem);
		setIsInputError(false);
		setAmount("");
		setIsAmountRequired(false);
	};

	const handleModalOpen = () => {
		setIsAssetModalOpen(!isAssetModalOpen);
	};

	const handleAvailableClick = (e: string) => {
		setAmount(e);
		setIsInputError(false);
		e.length > 0 ? setIsAmountRequired(true) : setIsAmountRequired(false);
	};

	const handleItemClick = (i: TItem) => {
		setIsInputError(false);
		setSelectedItem(i);
		setAmount("");
		setIsAmountRequired(false);
		setSelectedToItem(
			wallets[selectedToWallet].assets.find((item: TItem) => item.code === i.code)
		);
	};

	const handleSubmitClick = async () => {
		await transfer(
			wallets[selectedFromWallet].wallet,
			wallets[selectedToWallet].wallet,
			amount,
			selectedFromItem.code
		);
	};

	const handleBackClickButton = () => {
		tg.close();
	};

	if (isCompleted) {
		return (
			<>
				<Helmet>
					<title>{Titles.INTERNAL_TRANSFER.SUMMARY}</title>
				</Helmet>
				<InternalPayment
					isSuccess={isSuccess}
					assetFrom={selectedFromItem}
					date={transferResponse.date}
					eWallet={selectedFromWallet}
					amountFrom={amount}
					toWallet={selectedToWallet}
					locale={locale}
				/>
			</>
		);
	}

	return (
		<>
			<Helmet>
				<title>{Titles.INTERNAL_TRANSFER.INDEX}</title>
			</Helmet>
			<div className={"component-clean" + (theme === "dark" ? "-dark" : "")}>
				<div className={"innerComponent"}>
					<InternalModalCurrencySelect
						currentWallet={selectedFromWallet}
						wallets={wallets}
						isModalOpen={isAssetModalOpen}
						handleIsModalOpen={handleModalOpen}
						handleItemClick={handleItemClick}
					/>
					<h1
						className={
							"exchangeWelcomeText" + (theme === "dark" ? "-dark" : "")
						}
					>
						{t("transferLogoText")}
					</h1>
					<InternalInput
						isFrom
						inputPropsRef={inputRef}
						className={"internalInputContainer"}
						value={selectedFromWallet}
						label={t("internalFromText") as string}
						isRequired={isFromRequired}
						data={data}
						wallets={wallets}
						selectedItem={selectedFromWallet}
						handleItemClick={handleFromWalletClick}
					/>
					<CustomButton
						theme={theme as TTheme}
						themeNumber={false}
						onClick={handleSwapClick}
						isDisabled={disabledSwapButton}
						isSwapButton={true}
						className={"internalSwapContainer"}
					>
						<SwapButton className={"exchangeSwapIcon"} />
					</CustomButton>
					<InternalInput
						isFrom={false}
						inputPropsRef={inputRef}
						className={"internalInputContainer"}
						value={selectedToWallet}
						label={t("internalToText") as string}
						isRequired={isToRequired}
						data={data}
						wallets={wallets}
						selectedItem={selectedToWallet}
						handleItemClick={handleToWalletClick}
					/>
					<CustomInput
						isInputError={isInputError}
						theme={theme as TTheme}
						isInternal={true}
						isRequired={isAmountRequired}
						type={"number"}
						id={"internalAmountInput"}
						label={t("internalAmountText") as string}
						value={amount}
						onChange={handleInputChange}
						className={"internalInputContainer"}
						asset={selectedFromItem}
						handleModalOpen={handleModalOpen}
						themeNumber={false}
					/>
					<div className={"internalAvailableContainer"}>
						{isInputError ? (
							<h1 className={"inputBottomErrorText"}>
								{t("exchangeInsufficientFunds")}
							</h1>
						) : (
							""
						)}
					</div>
					<div className={"internalAvailableContainer"}>
						<h1
							className={
								"internalAvailableText" +
								(theme === "dark" ? "-dark" : "")
							}
						>
							{t("exchangeAvailableText")}:
						</h1>
						<h1
							className={
								"internalAvailableAmount" +
								(theme === "dark" ? "-dark" : "")
							}
							onClick={() =>
								handleAvailableClick(
									selectedFromItem?.available.toString()
								)
							}
						>
							{selectedFromItem.available}
						</h1>
					</div>
					<CustomButton
						theme={theme as TTheme}
						themeNumber={false}
						isLoading={isTransferLoading}
						onClick={handleSubmitClick}
						className={"exchangeConfirmButton"}
						isDisabled={
							isInputError ||
							Number(amount) <= 0 ||
							Number(amount) > Number(selectedFromItem?.available)
						}
						text={t("internalTransferText") as string}
					/>
					<CustomButton
						theme={theme as TTheme}
						isBack={true}
						onClick={handleBackClickButton}
						className={"backButton"}
					/>
				</div>
			</div>
		</>
	);
};

export default InternalTransfer;
