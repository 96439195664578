import React, { ChangeEvent, useContext, useState } from "react";
import BankCountryInput from "../bank-country/bank-country-input";
import CustomInput from "../../common/input/custom-input";
import { TCountry, TExchangeItem, TModifiedCountry, TTheme } from "../../../types/types";
import { ThemeContext } from "../../../data/theme-provider";
import { useTranslation } from "react-i18next";
import CustomButton from "../../common/button/custom-button";
import { Divider } from "@mui/material";
import CountryModal from "../../registration/country-modal/country-modal";
import { confirmBankWithdraw, fetchLoginOTP } from "../../../api/api";
import BankTwoFactor from "../bank-two-factor/bank-two-factor";
import SelectBankTwoFa from "../select-two-fa/selectTwoFa";
import BankOtp from "../otp/bank-otp";

interface Props {
	assets: TExchangeItem[];
	currencyCode: string;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	handleBankCountryClick: (e: any) => void;
	currencies: TExchangeItem[];
	iban: string;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	onChange: (e: any) => void;
	handleIbanClearClick: () => void;
	handleBackClick: () => void;
	countries: TCountry[];
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	currentAsset: any;
	eWallet: string;
}
const BankNotFound = ({
	currencyCode,
	handleBankCountryClick,
	currencies,
	iban,
	onChange,
	handleBackClick,
	countries,
	currentAsset,
	assets,
	eWallet,
}: Props) => {
	const { theme } = useContext(ThemeContext);
	const { t } = useTranslation();
	const [isButtonLoading, setIsButtonLoading] = useState<boolean>(false);

	const [isError, setIsError] = useState<boolean>(false);

	const [bankName, setBankName] = useState<string>("");
	const [isBankNameRequired, setIsBankNameRequired] = useState<boolean>(false);

	const [bankCode, setBankCode] = useState<string>("");
	const [isBankCodeRequired, setIsBankCodeRequired] = useState<boolean>(false);

	const [bankCountry, setBankCountry] = useState<TModifiedCountry | undefined>({
		code: t("verificationChooseCountry"),
		imgUrl: "",
	});
	const [isBankCountryRequired, setIsBankCountryRequired] = useState<boolean>(true);
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

	const [taxIdentificationNumber, setTaxIdentificationNumber] = useState<string>("");
	const [isTaxIdentificationNumberRequired, setIsTaxIdentificationNumberRequired] =
		useState<boolean>(false);

	const [intermediaryAccount, setIntermediaryAccount] = useState<string>("");
	const [isIntermediaryAccountRequired, setIsIntermediaryAccountRequired] =
		useState<boolean>(false);

	const [intermediaryBankName, setIntermediaryBankName] = useState<string>("");
	const [isIntermediaryBankNameRequired, setIsIntermediaryBankNameRequired] =
		useState<boolean>(false);

	const [intermediaryBankCode, setIntermediaryBankCode] = useState<string>("");
	const [isIntermediaryBankCodeRequired, setIsIntermediaryBankCodeRequired] =
		useState<boolean>(false);

	const [amount, setAmount] = useState<string>("");
	const [isAmountRequired, setIsAmountRequired] = useState<boolean>(false);
	const [isInputError, setIsInputError] = useState<boolean>(false);

	const [isTwoFactor, setIsTwoFactor] = useState<boolean>(false);
	const [state, setState] = useState<string>("");

	const [isSelectTwoFa, setIsSelectTwoFa] = useState<boolean>(false);
	const [isOtp, setIsOtp] = useState<boolean>(false);
	const [fakeContact, setFakeContact] = useState<string>("");

	const handleBankCodeChange = (e: ChangeEvent<HTMLInputElement>) => {
		setBankCode(e.target.value);
		e.target.value.length > 0
			? setIsBankCodeRequired(true)
			: setIsBankCodeRequired(false);
	};

	const handleBankCodeClear = () => {
		setBankCode("");
		setIsBankCodeRequired(false);
	};

	const handleBankNameChange = (e: ChangeEvent<HTMLInputElement>) => {
		setBankName(e.target.value);
		e.target.value.length > 0
			? setIsBankNameRequired(true)
			: setIsBankNameRequired(false);
	};

	const handleBankNameClear = () => {
		setBankName("");
		setIsBankNameRequired(false);
	};

	const handleSelectClick = () => {
		setIsModalOpen(!isModalOpen);
	};

	const handleCountryChange = (value: { code: string; imgUrl: string }) => {
		setAmount("");
		setIsAmountRequired(false);

		setTaxIdentificationNumber("");
		setIsTaxIdentificationNumberRequired(false);

		setIntermediaryBankCode("");
		setIsIntermediaryBankCodeRequired(false);

		setIntermediaryBankName("");
		setIsIntermediaryBankNameRequired(false);

		setIntermediaryAccount("");
		setIsIntermediaryAccountRequired(false);

		setIsBankCountryRequired(true);
		setBankCountry({
			code: value.code,
			imgUrl: value.imgUrl,
		});
	};

	const handleTaxIdentificationNumberChange = (e: ChangeEvent<HTMLInputElement>) => {
		setTaxIdentificationNumber(e.target.value);
		e.target.value.length > 0
			? setIsTaxIdentificationNumberRequired(true)
			: setIsTaxIdentificationNumberRequired(false);
	};

	const handleTaxIdentificationNumberClear = () => {
		setTaxIdentificationNumber("");
		setIsTaxIdentificationNumberRequired(false);
	};

	const handleIntermediaryAccountChange = (e: ChangeEvent<HTMLInputElement>) => {
		setIntermediaryAccount(e.target.value);
		e.target.value.length > 0
			? setIsIntermediaryAccountRequired(true)
			: setIsIntermediaryAccountRequired(false);
	};

	const handleIntermediaryAccountClear = () => {
		setIntermediaryAccount("");
		setIsIntermediaryAccountRequired(false);
	};

	const handleIntermediaryBankNameChange = (e: ChangeEvent<HTMLInputElement>) => {
		setIntermediaryBankName(e.target.value);
		e.target.value.length > 0
			? setIsIntermediaryBankNameRequired(true)
			: setIsIntermediaryBankNameRequired(false);
	};

	const handleIntermediaryBankNameClear = () => {
		setIntermediaryBankName("");
		setIsIntermediaryBankNameRequired(false);
	};

	const handleIntermediaryBankCodeChange = (e: ChangeEvent<HTMLInputElement>) => {
		setIntermediaryBankCode(e.target.value);
		e.target.value.length > 0
			? setIsIntermediaryBankCodeRequired(true)
			: setIsIntermediaryBankCodeRequired(false);
	};

	const handleIntermediaryBankCodeClear = () => {
		setIntermediaryBankCode("");
		setIsIntermediaryBankCodeRequired(false);
	};

	const handleInputChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
		const regex = new RegExp(`^[0-9]*\\.?[0-9]{0,2}$`);
		if (Number(e.target.value) === 0) {
			setIsInputError(true);
		}
		Number(e.target.value) >
		Number(
			assets.find((item: TExchangeItem) => item.code === currencyCode)?.available
		)
			? setIsInputError(true)
			: setIsInputError(false);
		if (e.target.value === "" || regex.test(e.target.value)) {
			setAmount(e.target.value);
			e.target.value.length > 0
				? setIsAmountRequired(true)
				: setIsAmountRequired(false);
			/*if(e.target.value != "" || Number(e.target.value) != 0) {
				const response = await getCommission(currentAsset.code, e.target.value, sessionStorage.getItem("userToken") as string);
				setCommission(response.feeAmount);
			}*/
		}
	};

	const handleCommissionChange = async () => {
		/*if(amount != "" || Number(amount) != 0) {
			const response = await getCommission(currentAsset.code, amount, sessionStorage.getItem("userToken") as string);
			setCommission(response.feeAmount);
		}*/
	};

	const handleAvailableClick = (e: string) => {
		setAmount(e);
		setIsInputError(false);
		e.length > 0 ? setIsAmountRequired(true) : setIsAmountRequired(false);
	};

	const handleIsOtp = async (otpKey: string) => {
		await fetchLoginOTP(
			state,
			otpKey,
			setIsButtonLoading,
			setIsError,
			setIsOtp,
			setIsSelectTwoFa,
			setFakeContact
		);
	};

	const handleIsSelectTwoFa = () => {
		setIsSelectTwoFa(true);
	};

	const handleSubmit = async () => {
		await confirmBankWithdraw(
			sessionStorage.getItem("userToken") as string,
			currencyCode,
			iban,
			amount,
			bankName,
			bankCode,
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			countries.find((country: any) => country.name === bankCountry?.code)
				?.code as string,
			eWallet,
			taxIdentificationNumber,
			intermediaryAccount,
			intermediaryBankName,
			intermediaryBankCode,
			"",
			setIsError,
			setIsButtonLoading,
			setState,
			setIsTwoFactor,
			handleIsOtp,
			handleIsSelectTwoFa
		);
	};

	if (isTwoFactor) {
		return (
			<BankTwoFactor
				currencyCode={currencyCode}
				iban={iban}
				handleBackClick={() => setIsTwoFactor(false)}
				currentAsset={currentAsset}
				eWallet={eWallet}
				amount={amount}
				bankName={bankName}
				bankCode={bankCode}
				paymentSystem={""}
				bankCountry={
					// eslint-disable-next-line @typescript-eslint/no-explicit-any
					countries.find((country: any) => country.name === bankCountry?.code)
						?.code as string
				}
				state={state}
				taxIdentificationNumber={taxIdentificationNumber}
				intermediaryAccount={intermediaryAccount}
				intermediaryBankName={intermediaryBankName}
				intermediaryBankCode={intermediaryBankCode}
			/>
		);
	}

	if (isSelectTwoFa) {
		return (
			<>
				<SelectBankTwoFa
					handleBackClick={() => {
						setIsSelectTwoFa(false);
					}}
					handleGASelect={() => {
						setIsTwoFactor(true);
						setIsSelectTwoFa(false);
					}}
					handleSelect={handleIsOtp}
					isError={isError}
				/>
			</>
		);
	}

	if (isOtp) {
		return (
			<>
				<BankOtp
					currencyCode={currencyCode}
					iban={iban}
					handleBackClick={() => setIsTwoFactor(false)}
					currentAsset={currentAsset}
					eWallet={eWallet}
					amount={amount}
					bankName={bankName}
					bankCode={bankCode}
					paymentSystem={""}
					bankCountry={
						// eslint-disable-next-line @typescript-eslint/no-explicit-any
						countries.find((country: any) => country.name === bankCountry?.code)
							?.code as string
					}
					state={state}
					taxIdentificationNumber={taxIdentificationNumber}
					intermediaryAccount={intermediaryAccount}
					intermediaryBankName={intermediaryBankName}
					intermediaryBankCode={intermediaryBankCode}
					fakeContact={fakeContact}
				/>
			</>
		);
	}

	return (
		<div className={"component-clean" + (theme === "dark" ? "-dark" : "")}>
			<div className={"innerComponent"}>
				<h1 className={"exchangeWelcomeText" + (theme === "dark" ? "-dark" : "")}>
					{t("bankWithdrawHeader")}
				</h1>
				<CountryModal
					countries={countries}
					isVerificationSelect={true}
					isModalOpen={isModalOpen}
					handleIsModalOpen={handleSelectClick}
					handleItemClick={handleCountryChange}
				/>
				<BankCountryInput
					value={currencyCode}
					isBankCountryRequired={true}
					handleItemClick={handleBankCountryClick}
					currencies={currencies}
					disabled={true}
				/>
				<CustomInput
					isNonCloseIcon
					isDisabled={true}
					isRequired={true}
					type={"email"}
					id={"email"}
					label={t("bankWithdrawAccountNumber") as string}
					value={iban}
					onChange={onChange}
					className={"internalInputContainer"}
					theme={theme as TTheme}
				/>
				<CustomInput
					isRequired={isBankCodeRequired}
					type={"email"}
					id={"email"}
					label={t("bankWithdrawCode") as string}
					value={bankCode}
					onChange={handleBankCodeChange}
					className={"internalInputContainer"}
					onClearClick={handleBankCodeClear}
					theme={theme as TTheme}
				/>
				<CustomInput
					isRequired={isBankNameRequired}
					type={"email"}
					id={"email"}
					label={t("bankWithdrawName") as string}
					value={bankName}
					onChange={handleBankNameChange}
					className={"internalInputContainer"}
					onClearClick={handleBankNameClear}
					theme={theme as TTheme}
				/>
				<CustomInput
					theme={theme as TTheme}
					isRequired={isBankCountryRequired}
					type={"text"}
					id={"countryNameSelect"}
					label={t("bankWithdrawCountry")}
					value={bankCountry?.code as string}
					onClearClick={handleSelectClick}
					className={"verificationLastName"}
					isSelectCountry={true}
					imgUrl={bankCountry?.imgUrl}
				/>
				{bankCountry?.code != t("verificationChooseCountry") ? (
					bankCountry?.code === "Russia" ? (
						<>
							<CustomInput
								isRequired={isTaxIdentificationNumberRequired}
								type={"email"}
								id={"email"}
								label={t("bankWithdrawTaxIdentification") as string}
								value={taxIdentificationNumber}
								onChange={handleTaxIdentificationNumberChange}
								className={"internalInputContainer"}
								onClearClick={handleTaxIdentificationNumberClear}
								theme={theme as TTheme}
							/>
							<CustomInput
								isRequired={isIntermediaryAccountRequired}
								type={"email"}
								id={"email"}
								label={t("bankWithdrawIntermediary") as string}
								value={intermediaryAccount}
								onChange={handleIntermediaryAccountChange}
								className={"internalInputContainer"}
								onClearClick={handleIntermediaryAccountClear}
								theme={theme as TTheme}
							/>
							<CustomInput
								isRequired={isBankNameRequired}
								type={"email"}
								id={"email"}
								label={t("bankWithdrawName") as string}
								value={bankName}
								onChange={handleBankNameChange}
								className={"internalInputContainer"}
								onClearClick={handleBankNameClear}
								theme={theme as TTheme}
							/>
							<CustomInput
								theme={theme as TTheme}
								isInternal={true}
								isInputError={isInputError}
								isInternalWithoutChev={true}
								isRequired={isAmountRequired}
								type={"number"}
								id={"internalAmountInput"}
								label={
									t("bankWithdrawAmount")
										.replace("$amount$", currentAsset.min)
										.replace("$code$", currencyCode) as string
								}
								value={amount}
								onChange={handleInputChange}
								onKeyUp={handleCommissionChange}
								className={"internalInputContainer"}
								asset={currentAsset}
								themeNumber={true}
							/>
							<div className={"internalAvailableContainer"}>
								{isInputError ? (
									<h1 className={"inputBottomErrorText"}>
										{t("exchangeInsufficientFunds")}
									</h1>
								) : (
									""
								)}
							</div>
							<div className={"internalAvailableContainer"}>
								<h1
									className={
										"internalAvailableText" +
										(theme === "dark" ? "-dark" : "")
									}
								>
									{t("exchangeAvailableText")}:
								</h1>
								<h1
									className={
										"internalAvailableAmount" +
										(theme === "dark" ? "-dark" : "")
									}
									onClick={() =>
										handleAvailableClick(
											(assets
												.find(
													(asset: TExchangeItem) =>
														asset.code === currencyCode
												)
												?.available.toString() as string)
												? (assets
														.find(
															(asset: TExchangeItem) =>
																asset.code ===
																currencyCode
														)
														?.available.toString() as string)
												: "0"
										)
									}
								>
									{(assets
										.find(
											(asset: TExchangeItem) =>
												asset.code === currencyCode
										)
										?.available.toString() as string)
										? (assets
												.find(
													(asset: TExchangeItem) =>
														asset.code === currencyCode
												)
												?.available.toString() as string)
										: 0}
								</h1>
							</div>
						</>
					) : (
						<>
							<CustomInput
								isRequired={isIntermediaryBankNameRequired}
								type={"email"}
								id={"email"}
								label={t("bankWithdrawForeignName") as string}
								value={intermediaryBankName}
								onChange={handleIntermediaryBankNameChange}
								className={"internalInputContainer"}
								onClearClick={handleIntermediaryBankNameClear}
								theme={theme as TTheme}
							/>
							<CustomInput
								isRequired={isIntermediaryBankCodeRequired}
								type={"email"}
								id={"email"}
								label={t("bankWithdrawForeignCode") as string}
								value={intermediaryBankCode}
								onChange={handleIntermediaryBankCodeChange}
								className={"internalInputContainer"}
								onClearClick={handleIntermediaryBankCodeClear}
								theme={theme as TTheme}
							/>
							<CustomInput
								isRequired={isIntermediaryAccountRequired}
								type={"email"}
								id={"email"}
								label={t("bankWithdrawIntermediary") as string}
								value={intermediaryAccount}
								onChange={handleIntermediaryAccountChange}
								className={"internalInputContainer"}
								onClearClick={handleIntermediaryAccountClear}
								theme={theme as TTheme}
							/>
							<CustomInput
								theme={theme as TTheme}
								isInternal={true}
								isInputError={isInputError}
								isInternalWithoutChev={true}
								isRequired={isAmountRequired}
								type={"number"}
								id={"internalAmountInput"}
								label={
									t("bankWithdrawAmount")
										.replace("$amount$", currentAsset.min)
										.replace("$code$", currencyCode) as string
								}
								value={amount}
								onChange={handleInputChange}
								onKeyUp={handleCommissionChange}
								className={"internalInputContainer"}
								asset={currentAsset}
								themeNumber={true}
							/>
							<div className={"internalAvailableContainer"}>
								{isInputError ? (
									<h1 className={"inputBottomErrorText"}>
										{t("exchangeInsufficientFunds")}
									</h1>
								) : (
									""
								)}
							</div>
							<div className={"internalAvailableContainer"}>
								<h1
									className={
										"internalAvailableText" +
										(theme === "dark" ? "-dark" : "")
									}
								>
									{t("exchangeAvailableText")}:
								</h1>
								<h1
									className={
										"internalAvailableAmount" +
										(theme === "dark" ? "-dark" : "")
									}
									onClick={() =>
										handleAvailableClick(
											(assets
												.find(
													(asset: TExchangeItem) =>
														asset.code === currencyCode
												)
												?.available.toString() as string)
												? (assets
														.find(
															(asset: TExchangeItem) =>
																asset.code ===
																currencyCode
														)
														?.available.toString() as string)
												: "0"
										)
									}
								>
									{(assets
										.find(
											(asset: TExchangeItem) =>
												asset.code === currencyCode
										)
										?.available.toString() as string)
										? (assets
												.find(
													(asset: TExchangeItem) =>
														asset.code === currencyCode
												)
												?.available.toString() as string)
										: 0}
								</h1>
							</div>
						</>
					)
				) : (
					""
				)}
				{isError ? (
					<h1 className={"registrationEmailError"}>
						{t("exchangeErrorOperationHeader")}
					</h1>
				) : (
					""
				)}
				{bankCountry?.code != t("verificationChooseCountry") ? (
					bankCountry?.code === "Russia" ? (
						<CustomButton
							theme={theme as TTheme}
							isLoading={isButtonLoading}
							onClick={handleSubmit}
							className={"exchangeConfirmButton"}
							isDisabled={
								Number(amount) >
									Number(
										assets.find(
											(item: TExchangeItem) =>
												item.code === currencyCode
										)?.available
									) ||
								isInputError ||
								Number(amount) < Number(currentAsset.min) ||
								bankCode.length === 0 ||
								bankName.length === 0 ||
								intermediaryAccount.length === 0 ||
								intermediaryBankCode.length === 0 ||
								intermediaryBankName.length === 0
							}
							text={t("bankWithdrawButton") as string}
						/>
					) : (
						<CustomButton
							theme={theme as TTheme}
							isLoading={isButtonLoading}
							onClick={handleSubmit}
							className={"exchangeConfirmButton"}
							isDisabled={
								Number(amount) >
									Number(
										assets.find(
											(item: TExchangeItem) =>
												item.code === currencyCode
										)?.available
									) ||
								isInputError ||
								Number(amount) < Number(currentAsset.min) ||
								bankCode.length === 0 ||
								bankName.length === 0 ||
								intermediaryAccount.length === 0 ||
								intermediaryBankCode.length === 0 ||
								intermediaryBankName.length === 0
							}
							text={t("bankWithdrawButton") as string}
						/>
					)
				) : (
					<CustomButton
						theme={theme as TTheme}
						isLoading={isButtonLoading}
						onClick={() => setIsInputError(false)}
						className={"exchangeConfirmButton"}
						isDisabled={true}
						text={t("registrationContinue") as string}
					/>
				)}
				<div className={"divider" + (theme === "dark" ? "-dark" : "")}>
					<Divider className={"divider" + (theme === "dark" ? "-dark" : "")} />
				</div>
				<CustomButton
					theme={theme as TTheme}
					isBack={true}
					onClick={handleBackClick}
					className={"backButton"}
				/>
			</div>
		</div>
	);
};

export default BankNotFound;
