import { useContext, useEffect, useState } from "react";
import { ThemeContext } from "../../../data/theme-provider";
import { usePinInput } from "react-pin-input-hook";
import { useTranslation } from "react-i18next";
import { TTheme } from "../../../types/types";
import CustomButton from "../../common/button/custom-button";
import PinInput from "../../common/input/pin-input";
import { Divider } from "@mui/material";
import { confirmBankWithdrawTwoFactor, refetchLoginOTP } from "../../../api/api";
import BankSummary from "../bank-summary/bank-summary";

interface Props {
	currencyCode: string;
	iban: string;
	handleBackClick: () => void;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	currentAsset: any;
	eWallet: string;
	amount: string;
	bankName: string;
	bankCode: string;
	bankCountry: string;
	taxIdentificationNumber: string;
	paymentSystem: string;
	intermediaryAccount: string;
	intermediaryBankName: string;
	intermediaryBankCode: string;
	state: string;
	fakeContact: string;
}
const BankOtp = ({
	currencyCode,
	bankCountry,
	bankName,
	bankCode,
	intermediaryBankCode,
	intermediaryBankName,
	intermediaryAccount,
	taxIdentificationNumber,
	paymentSystem,
	state,
	currentAsset,
	iban,
	amount,
	eWallet,
	handleBackClick,
	fakeContact,
}: Props) => {
	const { theme, updateTheme } = useContext(ThemeContext);

	const [sessionIdentityType, setSessionIdentityType] = useState<string>("");

	const [isError, setIsError] = useState<boolean>(false);

	const [isSummary, setIsSummary] = useState<boolean>(false);

	const [errorText, setErrorText] = useState<string>("");
	const [isButtonLoading, setIsButtonLoading] = useState<boolean>(false);

	const [isResendCodeActive, setIsResendCodeActive] = useState<boolean>(false);
	const [counter, setCounter] = useState<number>(
		Number(sessionStorage.getItem("resendIn"))
	);

	const [values, setValues] = useState<string[]>(["", "", "", "", "", ""]);
	const { fields } = usePinInput({
		values,
		onChange: (values: string[]) => {
			setValues(values);
		},
		onComplete: async (values: string) => {
			if (values.length == 6) {
				await confirmBankWithdrawTwoFactor(
					sessionStorage.getItem("userToken") as string,
					currencyCode,
					iban,
					amount,
					bankName,
					bankCode,
					bankCountry,
					eWallet,
					taxIdentificationNumber,
					intermediaryAccount,
					intermediaryBankName,
					intermediaryBankCode,
					values,
					state,
					paymentSystem,
					setIsError,
					setIsButtonLoading,
					setIsSummary,
					setErrorText,
					sessionIdentityType
				);
			}
		},
		type: "alphanumeric",
		placeholder: "",
	});

	const { t } = useTranslation();

	useEffect(() => {
		if (counter == 0) {
			setIsResendCodeActive(true);
			setCounter(Number(sessionStorage.getItem("resendIn")));
		}
		const timer = counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		return () => clearInterval(timer as any);
	}, [counter]);

	useEffect(() => {
		setSessionIdentityType(sessionStorage.getItem("loginIdentityType") as string);
		updateTheme();
	}, []);

	const handleResendCode = async () => {
		setIsResendCodeActive(false);
		await refetchLoginOTP(state, sessionIdentityType);
	};

	if (isSummary) {
		return (
			<BankSummary
				currentAsset={currentAsset}
				withdrawFee={"0"}
				withdrawAmount={amount}
				accountName={iban}
				bankName={bankName}
			/>
		);
	}

	return (
		<div className={"component-clean" + (theme === "dark" ? "-dark" : "")}>
			<div className={"innerComponent"}>
				<h1 className={"confirmEmailText" + (theme === "dark" ? "-dark" : "")}>
					{t("authenticationText")}
				</h1>
				<h1 className={"confirmCodeText" + (theme === "dark" ? "-dark" : "")}>
					{t(
						sessionIdentityType === "Email"
							? "otpCodeEmailDescription"
							: "otpCodePhoneDescription"
					) + fakeContact}
				</h1>
				<div className={"codeInputBlock"}>
					<PinInput fields={fields} />
					{isError ? (
						<h1 className={"loginErrorText"}>{t("mfCodeErrorText")}</h1>
					) : (
						<div className={"registrationEmailError"} />
					)}
					{isResendCodeActive ? (
						<h1 className={"resendCodeText"} onClick={handleResendCode}>
							{t("registrationResend")}
						</h1>
					) : (
						<h1 className={"resendCodeText-inactive"}>
							{t("registrationResendIn")} {counter}
						</h1>
					)}
				</div>
				{isError ? (
					<h1 className={"loginErrorText"}>
						{errorText ? errorText : t("mfCodeErrorText")}
					</h1>
				) : (
					<div className={"registrationEmailError"} />
				)}
				<div className={"divider" + (theme === "dark" ? "-dark" : "")}>
					<Divider className={"divider" + (theme === "dark" ? "-dark" : "")} />
				</div>
				<CustomButton
					isLoading={isButtonLoading}
					theme={theme as TTheme}
					isBack={true}
					onClick={handleBackClick}
					className={"backButton"}
				/>
			</div>
		</div>
	);
};

export default BankOtp;
