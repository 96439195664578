import { getAnalytics, logEvent, setUserId } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import React, { createContext, useState } from "react";
import { getUserPersonalAccountUserId } from "../api/api";
import { TAnalytics } from "../types/types";
import { AnalyticsEvent } from "../types/analytics/analytics-events";

const initializeFirebase = async () => {
	console.log("");
};

//eslint-disable-next-line @typescript-eslint/no-unused-vars
const sendAnalytics = async (event: AnalyticsEvent) => {
	console.log("");
};

export const FirebaseContext = createContext({
	sendAnalytics: sendAnalytics,
	initializeFirebase: initializeFirebase,
} as {
	sendAnalytics: typeof sendAnalytics;
	initializeFirebase: typeof initializeFirebase;
});
const FirebaseProvider = ({ children }: { children: React.ReactNode }) => {
	const [analytics, setAnalytics] = useState<TAnalytics>(undefined);

	const initializeFirebase = async () => {
		const userId = await getUserPersonalAccountUserId(
			sessionStorage.getItem("userToken") as string
		);
		if (userId != "") {
			const firebaseConfig = {
				apiKey: "AIzaSyDUPpgerqlRPh58pm6-uUk7b-Qte6odTgk",
				authDomain: "f2x-telegram-bot-webapp.firebaseapp.com",
				projectId: "f2x-telegram-bot-webapp",
				storageBucket: "f2x-telegram-bot-webapp.appspot.com",
				messagingSenderId: "911863468286",
				appId: "1:911863468286:web:31c0975744c6e71ecd95af",
				measurementId: "G-YNZYF3ZHWX",
			};
			const app = initializeApp(firebaseConfig);
			const analytics = getAnalytics(app);
			setUserId(analytics, userId);
			setAnalytics(analytics);
		}
	};

	const sendAnalytics = async (event: AnalyticsEvent) => {
		if(analytics) {
			logEvent(analytics, event.name.toString(), event.params ? event.params : undefined);
		}
	}

	return (
		<FirebaseContext.Provider value={{ sendAnalytics, initializeFirebase }}>
			{children}
		</FirebaseContext.Provider>
	);
};

export default FirebaseProvider;
