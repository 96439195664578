import React, { useContext, useState } from "react";
import CustomInput from "../../common/input/custom-input";
import { useTranslation } from "react-i18next";
import { TExchangeItem, TTheme } from "../../../types/types";
import { ThemeContext } from "../../../data/theme-provider";
import ExchangeModalSelect from "../../exchange/exchange-modal-select/exchange-modal-select";

interface Props {
	value: string;
	isBankCountryRequired: boolean;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	handleItemClick: (e: any) => void;
	currencies: TExchangeItem[];
    disabled?: boolean;
}
const BankCountryInput = ({value, isBankCountryRequired, handleItemClick, currencies, disabled}: Props) => {
    const {theme} = useContext(ThemeContext);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const {t} = useTranslation();

    const handleModalOpen = () => {
        setIsModalOpen(!isModalOpen);
    }

    return (
        <>
            {disabled ? '' : <ExchangeModalSelect
                label={t("cardDepositModalHeader") as string}
                data={currencies}
                isFrom={false}
                isModalOpen={isModalOpen}
                handleIsModalOpen={handleModalOpen}
                handleItemClick={handleItemClick}
            />}
            <CustomInput
                isDisabled={disabled}
                theme={theme as TTheme}
                onChange={() => ''}
                isBankTransfer={true}
                className={"internalInputContainer"}
                value={value}
                label={t("bankWithdrawChooseCurrency") as string}
                isRequired={isBankCountryRequired}
                type={"text"}
                id={"internalInput"}
                handleModalOpen={handleModalOpen}
            />
        </>
    );
};

export default BankCountryInput;