import React from 'react';

const Chev = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.9993 12.9497L16.242 8.70698C16.6325 8.31646 17.2656 8.31645 17.6562 8.70697C18.0467 9.0975 18.0467 9.73066 17.6562 10.1212L11.9993 15.7781L6.34242 10.1212C5.9519 9.73066 5.9519 9.0975 6.34242 8.70698C6.73294 8.31646 7.3661 8.31646 7.75662 8.70698L11.9993 12.9497Z" fill="#848790"/>
        </svg>
    );
};

export default Chev;