import React from 'react';
import "./status-circle.css";
import {TFunction} from "i18next";

interface Props {
    status: string;
    locale: TFunction<"translation", undefined, "translation">;
}
const StatusCircle = (props: Props) => {
    const className = () => {
        switch (props.status) {
            case props.locale("operationSuccessStatus"): return "status-circle-success";
            case props.locale("operationPendingStatus"): return "status-circle-pending";
            default: return "status-circle-error";
        }
    }

    return (
        <div className={className()}/>
    );
};

export default StatusCircle;