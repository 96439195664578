import React, { useContext, useState } from "react";
import { ThemeContext } from "../../../data/theme-provider";
import { useTranslation } from "react-i18next";
import CustomInput from "../../common/input/custom-input";
import { TMappedAsset, TTheme } from "../../../types/types";
import AssetModal from "./modal/asset-modal";

interface Props {
	value: string;
	isAssetRequired: boolean;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	handleItemClick: (e: any) => void;
	assets: TMappedAsset[];
}
const AssetInput = ({value, isAssetRequired, assets, handleItemClick}: Props) => {
    const {theme} = useContext(ThemeContext);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const {t} = useTranslation();

    const handleModalOpen = () => {
        setIsModalOpen(!isModalOpen);
    }
    
    return (
        <>
            <AssetModal
                label={"analyticsAssetText"}
                assets={assets}
                isModalOpen={isModalOpen}
                handleIsModalOpen={handleModalOpen}
                handleItemClick={handleItemClick}
            />
            <CustomInput
                theme={theme as TTheme}
                onChange={() => ''}
                isBankTransfer={true}
                className={"internalInputContainer"}
                value={value}
                label={t("bankDepositChooseAsset") as string}
                isRequired={isAssetRequired}
                type={"text"}
                id={"internalInput"}
                handleModalOpen={handleModalOpen}
            />
        </>
    );
};

export default AssetInput;