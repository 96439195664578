import React, {useContext, useEffect, useRef, useState} from 'react';
import "../analytics.css"
import {TRest, TTheme} from "../../../types/types";
import AnalyticsItem from "../analytics-item/analytics-item";
import {useTranslation} from "react-i18next";
import CustomButton from "../../common/button/custom-button";
import {ThemeContext} from "../../../data/theme-provider";
import {Transition} from "react-transition-group";

const defaultStyle = {
    transition: `opacity ${500}ms ease-in-out`,
    opacity: 0,
}

const transitionStyles = {
    entering: { opacity: 1},
    entered:  { opacity: 1},
    exiting:  { opacity: 0},
    exited:  { opacity: 0},
    unmounted: { opacity: 0}
};

const generateKey = (item: TRest, idx: number) => {
    return item.available+"_"+item.code+"_"+idx;
}

interface Props {
    data: TRest[];
}
const AnalyticsList = (props: Props) => {
    const {theme} = useContext(ThemeContext);
    const [isExpanded, setIsExpanded] = useState<boolean>(false);
    const [isNeedToExpand, setIsNeedToExpand] = useState<boolean>(false);
    const {t} = useTranslation();
    const transitionRef = useRef(null);

    useEffect(() => {
        if (props.data.length > 4) {
            setIsNeedToExpand(true);
        }
    }, [props.data])

    const handleExpandClick = () => {
        setIsExpanded(!isExpanded);
    }

    return (
        <Transition nodeRef={transitionRef} in={isExpanded} timeout={500}>
            {state => (
                <>
                    <h1 className={"analyticsAssetText" + (theme === 'dark' ? '-dark' : '')}>{t("analyticsAssetText")}</h1>
                    <div className={"analyticsAssetList"}>{isNeedToExpand ?
                        <>
                            {props.data.slice(0, 4).map((item: TRest, idx: number) => (
                                <AnalyticsItem key={generateKey(item, idx)} item={item}/>
                            ))}
                            <div ref={transitionRef} style={{
                                ...defaultStyle,
                                ...transitionStyles[state]
                            }}>
                                {isExpanded ? (
                                    <>
                                        {props.data.slice(4, props.data.length).map((item: TRest, idx: number) => (
                                            <AnalyticsItem key={generateKey(item, idx) + "_expanded"} item={item}/>))}
                                    </>
                                ) : ""}
                            </div>
                            {isNeedToExpand ? (
                                <CustomButton theme={theme as TTheme} text={t("showMore") as string} isShowMore={true} onClick={handleExpandClick} className={""} isExpanded={!isExpanded}/>
                            ) : ""}
                        </> :
                        <>
                            {props.data.slice(0, 4).map((item: TRest, idx: number) => (
                                <AnalyticsItem key={generateKey(item, idx)} item={item}/>
                            ))}
                        </>
                    }
                    </div>
                </>
            )}
        </Transition>

    );
};

export default AnalyticsList;