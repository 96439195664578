import React, { useContext, useRef, useState } from "react";
import { Divider } from "@mui/material";
import { enableMF, loginWith2FA, loginWithout2FAAfterSetup } from "../../../api/api";
import { useNavigate } from "react-router-dom";
import PinInput from "../../common/input/pin-input";
import CustomButton from "../../common/button/custom-button";
import { PinInputFieldProps, usePinInput } from "react-pin-input-hook";
import { useTranslation } from "react-i18next";
import { useTelegram } from "../../../hooks/useTelegram";
import { ThemeContext } from "../../../data/theme-provider";
import { TTheme } from "../../../types/types";
import { FirebaseContext } from "../../../data/firebase-provider";

interface Props {
	handleBackClick(): void;
	setIsRedirectPageOpen(isOpen: boolean): void;
	isRedirectPageOpen: boolean;
	login: string;
	password: string;
	state: string;
	setError: React.Dispatch<boolean>;
	isError: boolean;
	userToken: string;
	userLocale: string;
	maxExpSec: string;
	isEnablingMfCode?: boolean;
	accessToken?: string;
	secretKey?: string;
}

const MFCode = ({
	state,
	isEnablingMfCode,
	isError,
	setError,
	password,
	login,
	secretKey,
	userToken,
	accessToken,
	userLocale,
	isRedirectPageOpen,
	setIsRedirectPageOpen,
	handleBackClick,
	maxExpSec,
}: Props) => {
	const {initializeFirebase, sendAnalytics} = useContext(FirebaseContext);
	const { theme } = useContext(ThemeContext);
	const [values, setValues] = useState<string[]>(["", "", "", "", "", ""]);
	const [errorText, setErrorText] = useState<string>("");
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const inputPropsRef = useRef<any>();
	const { fields } = usePinInput({
		values,
		onChange: (values: string[]) => {
			setValues(values);
			if (values.join("").length == 6) {
				inputPropsRef.current.focus();
			}
		},
		onComplete: () => {
			if (values.join("").toString().length == 6) {
				inputPropsRef.current.focus();
			}
		},
		placeholder: "",
	});
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const { t } = useTranslation();
	const { tg } = useTelegram();

	const navigate = useNavigate();

	const handleBackClickButton = () => {
		handleBackClick();
	};

	const handleSubmit = async () => {
		let response;
		if (isEnablingMfCode && accessToken && secretKey) {
			setIsLoading(true);
			await enableMF(accessToken, values.join("").toString(), secretKey, sendAnalytics);
			response = (await loginWithout2FAAfterSetup(login, password, maxExpSec)) as {
				state: string;
			};
		}
		await loginWith2FA(
			login,
			password,
			isEnablingMfCode && response ? (response.state as string) : state,
			setError,
			isError,
			values.join("").toString(),
			setValues,
			setIsRedirectPageOpen,
			isRedirectPageOpen,
			handleBackClickButton,
			userToken,
			navigate,
			setIsLoading,
			userLocale,
			maxExpSec,
			tg,
			initializeFirebase,
			sendAnalytics,
			setErrorText
		);
	};

	return (
		<div className={"component-clean" + (theme === "dark" ? "-dark" : "")}>
			<div className={"innerComponent"}>
				<h1 className={"confirmEmailText" + (theme === "dark" ? "-dark" : "")}>
					{t("authenticationText")}
				</h1>
				<h1 className={"confirmCodeText" + (theme === "dark" ? "-dark" : "")}>
					{t("mfCodeDescription")}
				</h1>
				<div className={"codeInputBlock"}>
					<PinInput fields={fields} />
				</div>
				{isError ? (
					<h1 className={"loginErrorText"}>{errorText === "" ? t("mfCodeErrorText") : t("loginNotCompleteRegistration")}</h1>
				) : (
					<div className={"registrationEmailError"} />
				)}
				<CustomButton
					buttonRef={inputPropsRef}
					isLoading={isLoading}
					text={t("exchangeSubmitButton") as string}
					type={"submit"}
					onClick={handleSubmit}
					className={isError ? "loginButtonError" : "loginButton"}
					isDisabled={
						fields.map((field: PinInputFieldProps) => field.value).join("")
							.length < 6
					}
					theme={theme as TTheme}
				/>
				<div className={"divider" + (theme === "dark" ? "-dark" : "")}>
					<Divider className={"divider" + (theme === "dark" ? "-dark" : "")} />
				</div>
				<CustomButton
					theme={theme as TTheme}
					isBack={true}
					onClick={handleBackClickButton}
					className={"backButton"}
				/>
			</div>
		</div>
	);
};

export default MFCode;
