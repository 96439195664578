import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useTelegram } from "../../../../hooks/useTelegram";
import { Backdrop, Fade, Modal } from "@mui/material";
import CustomButton from "../../../common/button/custom-button";
import CloseIcon from "@mui/icons-material/Close";
import { Whale } from "../../../../icons/whale";
import { ThemeContext } from "../../../../data/theme-provider";
import { TAddress, TTheme } from "../../../../types/types";
import AddressItem from "../item/address-item";

interface Props {
	isModalOpen: boolean;
	handleIsModalOpen: () => void;
	handleItemClick: (i: unknown) => void;
	assets: TAddress[] | undefined;
	label: string;
}
const AddressModal = ({
	isModalOpen,
	handleIsModalOpen,
	handleItemClick,
	assets,
	label,
}: Props) => {
	const { theme } = useContext(ThemeContext);
	const { tg } = useTelegram();
	const { t } = useTranslation();
	const [mappedData, setMappedData] = useState<TAddress[]>();

	useEffect(() => {
		setMappedData(assets);
		console.log(assets)
		tg.expand();
	}, [assets]);

	const handleCloseModal = () => {
		handleIsModalOpen();
	};

	const handleKeyPress = (e: React.KeyboardEvent) => {
		if (e.key === "Enter" || e.key == "Escape") {
			handleCloseModal();
		}
	};

	return (
		<Modal
			open={isModalOpen}
			onClose={handleCloseModal}
			closeAfterTransition
			disableAutoFocus={true}
			onKeyDown={handleKeyPress}
			slots={{ backdrop: Backdrop }}
			slotProps={{
				backdrop: {
					timeout: 500,
				},
			}}
		>
			<Fade in={isModalOpen} timeout={200}>
				<div
					className={
						"registrationModalComponent" + (theme === "dark" ? "-dark" : "")
					}
				>
					<div className={"exchange-modal-header"}>
						<div className={"registrationModalHeader"}>
							<h1
								className={
									"registrationModalHeaderText" +
									(theme === "dark" ? "-dark" : "")
								}
							>
								{t(label)}
							</h1>
							<CustomButton
								theme={theme as TTheme}
								isClose={true}
								onClick={handleCloseModal}
								className={"registrationCloseButtonContainer"}
							>
								<CloseIcon
									sx={{
										fill: theme === "dark" ? "white" : "black",
									}}
									className={"registrationCloseButton"}
								/>
							</CustomButton>
						</div>
					</div>
					{mappedData && mappedData?.length > 0 ? (
						<div className={"registrationModalContent"}>
							{mappedData?.map((item) => (
								<AddressItem
									key={item.address}
									handleModalOpen={handleIsModalOpen}
									item={item}
									handleItemClick={handleItemClick}
								/>
							))}
						</div>
					) : (
						<div className={"exchangeModalContent-empty"}>
							<div className={"exchangeModalEmptyContainer"}>
								<Whale className={"exchangeWhale"} />
								<h1
									className={
										"exchangeModalEmptyHeader" +
										(theme === "dark" ? "-dark" : "")
									}
								>
									{t("exchangeNotFound")}
								</h1>
								<h1
									className={
										"exchangeModalEmptyBottom" +
										(theme === "dark" ? "-dark" : "")
									}
								>
									{t("cryptoWithdrawEmptyIssue")}
								</h1>
							</div>
						</div>
					)}
				</div>
			</Fade>
		</Modal>
	);
};

export default AddressModal;
