import React, {useContext} from 'react';
import {ThemeContext} from "../../../data/theme-provider";

interface Props {
    item: {
        code: string,
        name: string,
        link: string,
        phoneCode: string,
        isRestricted: boolean
    };
    handleItemClick: (i: {
        code: string;
        imgUrl: string;
    }) => void;
    handleModalOpen: () => void;
    setSearchValue: React.Dispatch<React.SetStateAction<string>>;
    isVerificationSelect?: boolean;
}
const CountryModalItem = (
    {
        item,
        handleItemClick,
        handleModalOpen,
        setSearchValue,
        isVerificationSelect
    }: Props
) => {
    const {theme} = useContext(ThemeContext);
    const handleClick = () => {
        if(isVerificationSelect) {
            handleItemClick({
                code: item.name,
                imgUrl: item.link,
            });
            handleModalOpen();
            setSearchValue("")
            return;
        }
        handleItemClick({
            code: item.phoneCode,
            imgUrl: item.link,
        });
        handleModalOpen();
        setSearchValue("")
    }

    return (
        <div onClick={handleClick} className={"modalItem"}>
            <div key={item.code} className={"modalItemLogo"}>
                <img key={item.code} className={"modalItemImg"} src={item.link} alt={item.code}/>
                <h1 className={"modalItemLogoText" + (theme === 'dark' ? '-dark' : '')}>{item.name}</h1>
            </div>
            <h1 className={"modalItemCode" + (theme === 'dark' ? '-dark' : '')}>{isVerificationSelect ? item.code : item.phoneCode}</h1>
        </div>
    );
};

export default CountryModalItem;