import { useContext } from "react";
import "./redirect.css";
import { TFunction } from "i18next";
import Logo from "../../icons/logo";
import { Whale } from "../../icons/whale";
import { ThemeContext } from "../../data/theme-provider";
import { TTheme } from "../../types/types";

interface Props {
	locale: TFunction<"translation", undefined, "translation">;
}

const Redirect = (props: Props) => {
	const { theme } = useContext(ThemeContext);

	return (
		<div className={"component-clean" + (theme === "dark" ? "-dark" : "")}>
			<div className={"innerComponent"}>
				<div className={"redirectLogo"}>
					<Logo theme={theme as TTheme} />
				</div>
				<Whale className={"redirectWhale"} />
				<h1 className={"redirectMainHeader" + (theme === "dark" ? "-dark" : "")}>
					{props.locale("authorizationSuccessText")}
				</h1>
				<h1 className={"redirectMainBottom" + (theme === "dark" ? "-dark" : "")}>
					{props.locale("authorizationRedirectText")}
				</h1>
			</div>
		</div>
	);
};

export default Redirect;
