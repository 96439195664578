import { useContext } from "react";
import { ThemeContext } from "../../../data/theme-provider";
import { TExchangeItem, TLocale, TTheme } from "../../../types/types";
import { useTranslation } from "react-i18next";
import { useTelegram } from "../../../hooks/useTelegram";
import BaseIcon from "../../../icons/base-icon";
import CustomButton from "../../common/button/custom-button";

interface Props {
	paymentInfo: {
		assetFrom: TExchangeItem;
		assetTo: TExchangeItem;
		amountFrom: string;
		amountTo: string;
		date: string;
	};
	locale: TLocale;
	rate: string;
	eWallet: string;
}
const OTCSummary = (props: Props) => {
	const { theme } = useContext(ThemeContext);
	const { t } = useTranslation();
	const { tg } = useTelegram();

	const handleCloseClick = () => {
		tg.close();
	};

	return (
		<div className={"component-clean" + (theme === "dark" ? "-dark" : "")}>
			<div className={"innerComponent"}>
				<div className={"exchangePaymentLogoContainer"}>
					<img
						className={"exchangePaymentLogo"}
						src={"/success-icon.png"}
						alt={"success"}
					/>
				</div>
				<h1
					className={
						"exchangePaymentHeaderText" + (theme === "dark" ? "-dark" : "")
					}
				>
					{t("OTCSummaryHeader")}
				</h1>
				<h1
					className={
						"exchangePaymentBottomText" + (theme === "dark" ? "-dark" : "")
					}
				>
					{t("OTCSummaryHeaderBottom")}
				</h1>
				<div
					className={
						"exchangePaymentFirstInfoContainer" +
						(theme === "dark" ? "-dark" : "")
					}
				>
					<div className={"exchangeAssetContainer"}>
						<h1
							className={
								"exchangeSellText" + (theme === "dark" ? "-dark" : "")
							}
						>
							{t("exchangeSellText")}
						</h1>
						<div className={"exchangeAssetBlock"}>
							<h1
								className={
									"exchangeConfirmAmount" +
									(theme === "dark" ? "-dark" : "")
								}
							>
								{props.paymentInfo.amountFrom}
							</h1>
							<h1
								className={
									"exchangeConfirmText" +
									(theme === "dark" ? "-dark" : "")
								}
							>
								{props.paymentInfo.assetFrom.code.toUpperCase()}
							</h1>
							<BaseIcon
								theme={theme as TTheme}
								path={props.paymentInfo.assetFrom.code.toLowerCase()}
								group={props.paymentInfo.assetFrom.group.toLowerCase()}
								className={"exchangeConfirmAsset"}
							/>
						</div>
					</div>
					<div
						id={"secondExchangeAssetContainer"}
						className={"exchangeAssetContainer"}
					>
						<h1
							className={
								"exchangeSellText" + (theme === "dark" ? "-dark" : "")
							}
						>
							{t("exchangeBuyText")}
						</h1>
						<div className={"exchangeAssetBlock"}>
							<h1
								className={
									"exchangeConfirmAmount" +
									(theme === "dark" ? "-dark" : "")
								}
							>
								{props.paymentInfo.amountTo}
							</h1>
							<h1
								className={
									"exchangeConfirmText" +
									(theme === "dark" ? "-dark" : "")
								}
							>
								{props.paymentInfo.assetTo.code.toUpperCase()}
							</h1>
							<BaseIcon
								theme={theme as TTheme}
								path={props.paymentInfo.assetTo.code.toLowerCase()}
								group={props.paymentInfo.assetTo.group.toLowerCase()}
								className={"exchangeConfirmAsset"}
							/>
						</div>
					</div>
					<div
						id={"secondExchangeAssetContainer"}
						className={"exchangeAssetBlock"}
					>
						<h1
							className={
								"exchangeSellText" + (theme === "dark" ? "-dark" : "")
							}
						>
							{t("OTCRate")}
						</h1>
						<h1
							className={
								"exchangeConfirmAmount" +
								(theme === "dark" ? "-dark" : "")
							}
						>
							{props?.rate}
						</h1>
					</div>
				</div>
				<div
					className={
						"exchangePaymentSecondInfoContainer" +
						(theme === "dark" ? "-dark" : "")
					}
				>
					<div className={"exchangeAssetBlock"}>
						<h1
							className={
								"exchangeSellText" + (theme === "dark" ? "-dark" : "")
							}
						>
							{t("exchangeDate")}
						</h1>
						<h1
							className={
								"exchangeConfirmAmount" +
								(theme === "dark" ? "-dark" : "")
							}
						>
							{new Intl.DateTimeFormat(
								props.locale.toLowerCase() +
									"-" +
									props.locale.toUpperCase(),
								{
									day: "2-digit",
									month: "2-digit",
									year: "numeric",
									hour: "2-digit",
									minute: "2-digit",
								}
							)
								.format(
									new Date(props.paymentInfo.date).setHours(
										new Date(props.paymentInfo.date).getHours() - 3
									)
								)
								.replace("/", ".")
								.replace("/", ".")}
						</h1>
					</div>
					<div
						id={"secondExchangeAssetContainer"}
						className={"exchangeAssetBlock"}
					>
						<h1
							className={
								"exchangeSellText" + (theme === "dark" ? "-dark" : "")
							}
						>
							{t("eWallet")}
						</h1>
						<h1
							className={
								"exchangeConfirmAmount" +
								(theme === "dark" ? "-dark" : "")
							}
						>
							{props?.eWallet}
						</h1>
					</div>
				</div>
				<CustomButton
					theme={theme as TTheme}
					onClick={handleCloseClick}
					className={"exchangeConfirmButton"}
					text={t("exchangeCloseButton") as string}
				/>
			</div>
		</div>
	);
};

export default OTCSummary;
