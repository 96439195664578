import React from 'react';
import {TMargin} from "../../../types/types";
import AnalyticsMarginItem from "../analytics-margin-item/analytics-margin-item";

const generateKey = (item: TMargin) => {
    return JSON.stringify(item.tradingAccKey+"_"+item.tradingProductCode+"_"+item.balance);
}

interface Props {
    data: TMargin[];
}
const AnalyticsMarginList = (props: Props) => {
    return (
        <>
            {props.data.length == 0 ? "" : <div className={"analyticsMarginList"}>
                {props.data.map((item) => (
                    <AnalyticsMarginItem key={generateKey(item)} item={item}/>
                ))}
            </div>}
        </>
    );
};

export default AnalyticsMarginList;