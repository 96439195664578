export enum AnalyticsEventsName {
	screenView = "ScreenView",
	signUpStart = "SignUp_Start",
	signUpEmailEntered = "SignUp_EmailEntered",
	signUpEmailConfirmed = "SignUp_EmailConfirmed",
	signUpPhoneEntered = "SignUp_PhoneEntered",
	signUpPhoneConfirmed = "SignUp_PhoneConfirmed",
	signUpNamePasswordCreated = "SignUp_NamePasswordCreated",
	signUpSuccess = "SignUp_Success",
	login = "Login",
	twoFAStart = "TwoFA_Start",
	twoFASuccess = "TwoFA_Success",
	verificationStart = "Verification_Start",
	verificationPending = "Verification_Pending",
	verificationSuccess = "Verification_Success",
	verificationFailed = "Verification_Failed",
	depositStart = "Deposit_Start",
	depositSuccess = "Deposit_Success",
	transferFromOwnWallet = "TransferFromOwnWallet",
	transactionsStatementSent = "TransactionsStatementSent",
	exchangeStarted = "Exchange_Started",
	exchangeSuccess = "Exchange_Success",
	withdrawStart = "Withdraw_Start",
	withdrawSuccess = "Withdraw_Success",
	invoiceCreated = "InvoiceCreated",
}

export type AnalyticsEvent = {
	name: AnalyticsEventsName;
	params?:
		| DepositAnalyticsEventParameters
		| ExchangeAnalyticsEventParameters
		| InvoiceAnalyticsEventParameters
		| SignInAnalyticsParameters
		| SignUpAnalyticsParameters
		| TransferAnalyticsParameters
		| WithdrawAnalyticsEventParameters | TransactionsAnalyticsEventParameters;
};

type DepositAnalyticsEventParameters = Partial<{
	deposit_source: string;
	deposit_method: string;
	currency_type: string;
	deposit_amount: string;
	deposit_network: string;
}>;

type ExchangeAnalyticsEventParameters = Partial<{
	source: string;
	exchange_from: string;
	exchange_from_amount: string;
	exchange_to: string;
	exchange_to_amount: string;
	exchange_amount_usdt: string;
}>;

type InvoiceAnalyticsEventParameters = Partial<{
	bank: string;
	bank_country: string;
	currency: string;
	amount: string;
}>;

type SignUpAnalyticsParameters = {
	referral: string;
};

type SignInAnalyticsParameters = {
	login_type: string;
};

type TransferAnalyticsParameters = Partial<{
	transfer_amount: string;
	transfer_currency: string;
}>;

type WithdrawAnalyticsEventParameters = Partial<{
	withdraw_amount: string;
	withdraw_amount_usdt: string;
	withdraw_asset: string;
	withdraw_method: string;
	withdraw_network: string;
	withdraw_network_code: string;
}>;

type TransactionsAnalyticsEventParameters = Partial<{
	statement_format: string;
	statement_period: string;
}>