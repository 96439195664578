import React from 'react';

const BankPreview = () => {
    return (
        <div style={{
            width: '100%',
            height: '100%',
        }}>
            <svg style={{
                width: '100%',
                height: '100%',
            }} width="366" height="360" viewBox="0 0 366 360" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="366" height="360" rx="13" fill="#F6F6F8"/>
                <path d="M290.193 74.7165H240.684L290.395 25H339.909V74.8349L290.193 123.93V74.7165Z" stroke="black" strokeOpacity="0.36" strokeWidth="3"/>
                <path d="M339.638 25.2188C323.158 41.7186 306.673 58.2184 290.193 74.7182" stroke="black" strokeOpacity="0.36" strokeWidth="3"/>
                <path d="M216.043 198.525H142L216.346 124.172H290.271V198.678L216.043 273.295V198.525Z" stroke="black" strokeOpacity="0.36" strokeWidth="3"/>
                <path d="M290.024 124.172C265.378 148.848 240.69 173.85 216.043 198.526" stroke="black" strokeOpacity="0.36" strokeWidth="3"/>
                <path d="M316.432 173.972H291.898L316.533 149.336H341.069L341.092 174.007L316.432 198.803V173.972Z" stroke="black" strokeOpacity="0.36" strokeWidth="3"/>
                <path d="M340.934 149.445C332.767 157.622 324.598 165.798 316.432 173.974" stroke="black" strokeOpacity="0.36" strokeWidth="3"/>
                <path d="M125.093 317.695H121.15L126.806 309.459L121.488 301.812H125.431L128.924 307.031L132.394 301.812H136.359L131.042 309.459L136.697 317.695H132.732L128.924 311.91L125.093 317.695Z" fill="black"/>
                <path d="M56.3618 317.695H52.9844V301.812H59.4688C60.2945 301.812 61.0525 301.896 61.7429 302.062C62.4484 302.229 63.1088 302.501 63.7242 302.879C64.3546 303.257 64.8425 303.794 65.1878 304.49C65.5482 305.186 65.7282 306.018 65.7282 306.986C65.7282 309.391 64.5799 310.964 62.2833 311.705L66.9891 317.695H63.0938L58.8384 312.136H56.3618V317.695ZM56.3618 304.808V309.3H59.6264C60.3921 309.3 61.0225 309.119 61.5178 308.756C62.028 308.377 62.2833 307.81 62.2833 307.054C62.2833 306.297 62.0357 305.715 61.5404 305.352C61.045 304.989 60.4071 304.808 59.6264 304.808H56.3618Z" fill="black"/>
                <path d="M68.8418 301.812H79.9603V304.808H72.2246V308.188H79.2836V311.161H72.2246V314.7H80.0956V317.695H68.8418V301.812Z" fill="black"/>
                <path d="M81.9512 301.812H93.0697V304.808H85.334V308.188H92.393V311.161H85.334V314.7H93.2049V317.695H81.9512V301.812Z" fill="black"/>
                <path d="M108.043 301.812H119.161V304.808H111.426V308.188H118.485V311.161H111.426V314.7H119.297V317.695H108.043V301.812Z" fill="black"/>
                <path d="M40 301.812H51.1287V304.876H43.3793V308.574H50.4754V311.637H43.3793V317.695H40V301.812Z" fill="black"/>
                <path d="M106.189 314.968V317.697H95.4753V316.009L100.245 310.158L101.005 309.233L101.719 308.377C101.858 308.208 102.019 307.984 102.203 307.707C102.526 307.244 102.687 306.797 102.687 306.366C102.687 305.841 102.51 305.402 102.157 305.047C101.804 304.693 101.289 304.516 100.613 304.516C99.9376 304.516 99.4228 304.716 99.0235 305.117C98.6242 305.518 98.386 306.08 98.3093 306.805H95.0605C95.0912 305.217 95.5905 303.961 96.5581 303.036C97.526 302.095 98.847 301.625 100.521 301.625C102.195 301.625 103.547 302.087 104.53 303.013C105.529 303.922 106.028 305.04 106.028 306.366C106.028 307.106 105.813 307.807 105.383 308.47C105.06 308.979 104.262 310.019 102.987 311.592L100.222 314.968H106.189Z" fill="black"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M149.204 299C155.004 299 159.706 303.702 159.706 309.505C159.706 315.307 155.004 320.008 149.204 320.008C143.403 320.008 138.699 315.305 138.699 309.505C138.699 303.704 143.401 299 149.204 299ZM144.597 313.759V311.517L146.545 309.569H144.597V307.725H148.389L150.837 305.277H142.15V313.757H144.597V313.759ZM153.141 309.843L152.818 309.519L157.058 305.279H153.597L145.115 313.759H148.576L151.085 311.25L153.595 313.759H157.056L153.303 310.004L153.141 309.843Z" fill="black"/>
            </svg>
        </div>
    );
};

export default BankPreview;