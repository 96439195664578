import {
	ChangeEvent,
	ReactElement,
	useContext,
	useEffect,
	useRef,
	useState,
} from "react";
import "./login.css";
import MFCode from "./mf-code/mfCode";
import Redirect from "../redirect/redirect";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import ForgotPassword from "../forgot-password/forgot-password";
import CircleProgress from "../circle-progress/circle-progress";
import SetupMfCode from "./setup-mf-code/setupMfCode";
import { fetchLoginOTP, getUserEmailByTimeToken, loginWithout2FA } from "../../api/api";
import Background from "../common/background/background";
import CustomInput from "../common/input/custom-input";
import CustomButton from "../common/button/custom-button";
import { ThemeContext } from "../../data/theme-provider";
import { TTheme } from "../../types/types";
import { Helmet } from "react-helmet";
import { Titles } from "../../types/titles";
import SelectTwoFa from "./select-two-fa/selectTwoFa";
import Otp from "./otp/otp";

const emailParam = new URLSearchParams(window.location.search).get("email");
const locale = window.location.pathname.split("/")[1] != "en" ? "ru" : "en";
const maxExpSec = new URLSearchParams(window.location.search).get("maxExpSec");

const Login = (): ReactElement => {
	const { theme, updateTheme } = useContext(ThemeContext);
	const [email, setEmail] = useState<string>("");
	const [isEmailRequired, setIsEmailRequired] = useState<boolean>(false);

	const [password, setPassword] = useState<string>("");
	const [isPasswordRequired, setIsPasswordRequired] = useState<boolean>(false);

	const [isMFPageOpen, setIsMFPageOpen] = useState<boolean>(false);
	const [isSetupMFPageOpen, setIsSetupMFPageOpen] = useState<boolean>(false);
	const [isRedirectPageOpen, setIsRedirectPageOpen] = useState<boolean>(false);
	const [isError, setIsError] = useState<boolean>(false);
	const [errorText, setErrorText] = useState<string>("");
	const [isForgotPasswordOpen, setIsForgotPasswordOpen] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const [isPageLoading, setIsPageLoading] = useState<boolean>(true);
	const [state, setState] = useState<string>("");
	const [isVerification, setIsVerification] = useState<boolean>(false);

	const [isSelectTwoFa, setIsSelectTwoFa] = useState<boolean>(false);
	const [isOtp, setIsOtp] = useState<boolean>(false);

	const [fakeContact, setFakeContact] = useState<string>("");

	const buttonRef = useRef(null);
	const navigate = useNavigate();
	const { t } = useTranslation();

	useEffect(() => {
		setIsSetupMFPageOpen(false);
	}, [isRedirectPageOpen]);

	const uploadUserData = async () => {
		sessionStorage.setItem(
			"userToken",
			new URLSearchParams(window.location.search).get("token") as string
		);
		if (sessionStorage.getItem("userToken") as string) {
			if (emailParam != "" && emailParam != null) {
				setEmail(emailParam as string);
				setIsEmailRequired(true);
			} else {
				const email = await getUserEmailByTimeToken(
					sessionStorage.getItem("userToken") as string
				);
				if (email != "") {
					setIsEmailRequired(true);
				}
				setEmail(email);
			}
		}
		setIsPageLoading(false);
	};

	useEffect(() => {
		setIsPageLoading(true);
		updateTheme();
		uploadUserData();
	}, []);

	if (isPageLoading || theme === "") return <CircleProgress thickness={6} size={40} />;

	const handleEmailChange = (e: ChangeEvent<HTMLInputElement>): void => {
		setEmail(e.target.value);
		e.target.value.length > 0 ? setIsEmailRequired(true) : setIsEmailRequired(false);
	};

	const handleEmailClearClick = () => {
		setEmail("");
		setIsEmailRequired(false);
	};

	const handlePasswordChange = (e: ChangeEvent<HTMLInputElement>): void => {
		setPassword(e.target.value);
		e.target.value.length > 0
			? setIsPasswordRequired(true)
			: setIsPasswordRequired(false);
	};

	const handlePasswordClearClick = () => {
		setPassword("");
		setIsPasswordRequired(false);
	};

	const handleSubmit = async (): Promise<void> => {
		await loginWithout2FA(
			email,
			password,
			setEmail,
			setPassword,
			setIsError,
			setState,
			setIsMFPageOpen,
			isMFPageOpen,
			navigate,
			setIsLoading,
			maxExpSec as string,
			setIsSetupMFPageOpen,
			setErrorText,
			setIsVerification,
			setIsSelectTwoFa,
			setIsOtp,
			setFakeContact
		);
	};

	const handleForgotPasswordClick = () => {
		setIsForgotPasswordOpen(!isForgotPasswordOpen);
	};

	const handleIsSetupMfCodeOpen = () => {
		setIsSetupMFPageOpen(false);
	};

	if (isSetupMFPageOpen) {
		return (
			<>
				<Helmet>
					<title>{Titles.LOGIN.SETUP}</title>
				</Helmet>
				<SetupMfCode
					handleIsSetupMfCodeOpen={handleIsSetupMfCodeOpen}
					userInfo={{
						login: email,
						password: password,
						state: state,
					}}
					handleBackClick={(): void => {
						setIsMFPageOpen(!isMFPageOpen);
						setIsRedirectPageOpen(false);
						setIsSetupMFPageOpen(false);
					}}
					setIsRedirectPageOpen={setIsRedirectPageOpen}
					isRedirectPageOpen={isRedirectPageOpen}
					setError={setIsError}
					isError={isError}
					userToken={sessionStorage.getItem("userToken") as string}
					userLocale={locale}
					maxExpSec={maxExpSec as string}
					locale={locale}
				/>
			</>
		);
	}

	if (isVerification) {
		navigate(
			`/${locale}/verification?token=${
				sessionStorage.getItem("userToken") as string
			}&email=${email}&password=${password}`,
			{
				replace: true,
				state: {
					email: email,
					userToken: sessionStorage.getItem("userToken") as string,
					password: password,
				},
			}
		);
	}

	const handleOtpSelect = async (otpKey: string) => {
		await fetchLoginOTP(
			state,
			otpKey,
			setIsLoading,
			setIsError,
			setIsOtp,
			setIsSelectTwoFa
		);
	};

	if (isSelectTwoFa) {
		return (
			<>
				<Helmet>
					<title>{Titles.LOGIN.SELECT_TWOFA}</title>
				</Helmet>
				<SelectTwoFa
					handleBackClick={() => {
						setIsSelectTwoFa(false);
					}}
					handleGASelect={() => {
						setIsMFPageOpen(true);
						setIsSelectTwoFa(false);
					}}
					handleSelect={handleOtpSelect}
				/>
			</>
		);
	}

	if (isOtp) {
		return (
			<>
				<Helmet>
					<title>{Titles.LOGIN.OTP}</title>
				</Helmet>
				<Otp
					handleBackClick={() => {
						setIsOtp(false);
					}}
					fakeContact={fakeContact}
					setIsRedirectPageOpen={setIsRedirectPageOpen}
					isRedirectPageOpen={isRedirectPageOpen}
					login={email}
					password={password}
					state={state}
					setError={setIsError}
					isError={isError}
					userToken={sessionStorage.getItem("userToken") as string}
					userLocale={locale}
					maxExpSec={maxExpSec as string}
				/>
			</>
		);
	}

	if (isMFPageOpen) {
		return (
			<>
				<Helmet>
					<title>{Titles.LOGIN.TWOFA}</title>
				</Helmet>
				<MFCode
					handleBackClick={(): void => {
						setIsMFPageOpen(false);
						setIsRedirectPageOpen(false);
						setIsSetupMFPageOpen(false);
					}}
					setIsRedirectPageOpen={setIsRedirectPageOpen}
					isRedirectPageOpen={isRedirectPageOpen}
					login={email}
					password={password}
					state={state}
					setError={setIsError}
					isError={isError}
					userToken={sessionStorage.getItem("userToken") as string}
					userLocale={locale}
					maxExpSec={maxExpSec as string}
				/>
			</>
		);
	}

	if (isForgotPasswordOpen) {
		return (
			<>
				<Helmet>
					<title>{Titles.LOGIN.FORGOT_PASSWORD}</title>
				</Helmet>
				<ForgotPassword
					handleBackClick={handleForgotPasswordClick}
					locale={locale}
					email={email}
					setEmail={setEmail}
				/>
			</>
		);
	}

	if (isRedirectPageOpen) {
		return (
			<>
				{" "}
				<Helmet>
					<title>{Titles.LOGIN.REDIRECT}</title>
				</Helmet>
				<Redirect locale={t} />
			</>
		);
	}

	return (
		<>
			<Helmet>
				<title>{Titles.LOGIN.INDEX}</title>
			</Helmet>
			<div>
				<Background />
				<div className={"component" + (theme === "dark" ? "-dark" : "")}>
					<div className={"innerComponent"}>
						<h1 className={"welcomeText" + (theme === "dark" ? "-dark" : "")}>
							{t("loginText")}
						</h1>
						<CustomInput
							isRequired={isEmailRequired}
							type={"email"}
							id={"email"}
							label={"Email"}
							value={email}
							onChange={handleEmailChange}
							className={"emailInput"}
							onClearClick={handleEmailClearClick}
							inputPropsRef={buttonRef}
							theme={theme as TTheme}
						/>
						<CustomInput
							isRequired={isPasswordRequired}
							type={"password"}
							id={"password"}
							label={t("passwordText")}
							value={password}
							onChange={handlePasswordChange}
							className={"passwordInput"}
							onClearClick={handlePasswordClearClick}
							inputPropsRef={buttonRef}
							isPassword={true}
							theme={theme as TTheme}
						/>
						<a onClick={handleForgotPasswordClick}>
							<h1 className={"forgotPasswordText"}>
								{t("forgotPasswordText")}
							</h1>
						</a>
						{isError ? (
							<h1 className={"loginErrorText"}>
								{errorText === ""
									? t("loginErrorText")
									: t("loginNotCompleteRegistration")}
							</h1>
						) : (
							""
						)}
						<CustomButton
							buttonRef={buttonRef}
							isLoading={isLoading}
							text={t("loginText") as string}
							type={"submit"}
							onClick={handleSubmit}
							className={isError ? "loginButtonError" : "loginButton"}
							isDisabled={password.length == 0 || email.length == 0}
							theme={theme as TTheme}
						/>
					</div>
				</div>
			</div>
		</>
	);
};

export default Login;
