import React, {useContext} from 'react';
import "./circle-progress.css";
import {CircularProgress} from "@mui/material";
import {ThemeContext} from "../../data/theme-provider";

interface Props {
    thickness: number;
    size?: number;
}
const CircleProgress = (props: Props) => {
    const {theme} = useContext(ThemeContext);

    return (
        <div className={"circle-wrapper" + (theme === 'dark' ? '-dark' : '')}>
            <svg width="40" height="40">
                <linearGradient id="linearColors" x1="0" y1="0" x2="1" y2="1">
                    <stop offset="0%" stopColor={`${theme === "dark" ? "#141414" : "#FFFFFF"}`} />
                    <stop offset="5%" stopColor="#00BEB4" />
                    <stop offset="95%" stopColor={`${theme === "dark" ? "#141414" : "#FFFFFF"}`} />
                    <stop offset="100%" stopColor={`${theme === "dark" ? "#141414" : "#FFFFFF"}`} />
                </linearGradient>
            </svg>
            <CircularProgress className={"circle-position"} sx={{
                ".MuiCircularProgress-circle": {
                    stroke: "url(#linearColors)"
                }
            }} thickness={props.thickness} size={props.size}/>
        </div>
    );
};

export default CircleProgress;