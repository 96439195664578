export const API_ROUTES = {
	AUTH: {
		BANK: {
			INVOICE_INFO: '/api/auth/bank/getInvoiceInfo',
			INVOICE_PDF: '/api/auth/bank/getInvoicePdf',
			SEND_BANK_DEPOSIT: '/api/auth/bank/sendBankDeposit',
			WITHDRAW: {
				FIND_BANK_BY_IBAN: '/api/auth/bank/withdraw/getBankByIban',
				WITHDRAW: '/api/auth/bank/withdraw/withdraw'
			}
		},
		CARD: {
			COMMISSION: '/api/auth/card/getCommission',
			SEND_CARD_DEPOSIT: '/api/auth/card/sendCardDeposit'
		},
		CRYPTO: {
			WITHDRAW: {
				ADDRESSES: '/api/auth/withdraw/crypto/getAddresses',
				COMMISSION: '/api/auth/withdraw/crypto/getCommission',
				WITHDRAW: '/api/auth/withdraw/crypto/withdraw'
			}
		},
		COMMON: {
			DICTIONARY: '/api/auth/common/getDictionary',
			TRANSACTION_BY_ID: '/api/auth/common/getTransactionById',
			USER_MARGIN_RESTS: '/api/auth/common/getUserMarginRests',
			USER_REST_BY_ACCOUNT: '/api/auth/common/getUserRestByAccount',
			USER_REST_BY_ACCOUNT_WITHOUT_ANALYTICS:
				'/api/auth/common/getUserRestByAccountWithoutAnalytics',
			USER_RESTS: '/api/auth/common/getUserRests',
			USER_TRANSACTIONS: '/api/auth/common/getUserTransactions'
		},
		OTC: {
			SEND_OTC: '/api/auth/otc/sendOtc',
			GET_EULA: '/api/auth/otc/getEula',
			SEND_EULA: '/api/auth/otc/sendEula'
		},
		EXCHANGE: {
			EXCHANGE_CURRENCY: '/api/auth/exchange/exchangeCurrency',
			AVAILABLE_CURRENCY_PAIR_FOR_EXCHANGE:
				'/api/auth/exchange/getAvailableForExchangePairCurrency',
			RATE_FOR_CURRENT_PAIR: '/api/auth/exchange/getRateForCurrentPair'
		},
		STATEMENT: {
			GET_PDF: '/api/auth/statement/getStatement'
		},
		INTERNAL: {
			SEND_TRANSFER: '/api/auth/internal/sendTransfer'
		}
	},
	COMMON: {
		ENABLE_MF: '/api/common/enableMF',
		INIT_MF_CODE: '/api/common/getInitMFCode',
		LOGIN: '/api/common/login',
		SEND_CODE: '/api/common/sendCode',
		SEND_PASSWORD_RECOVERY: '/api/common/sendPasswordRecovery'
	},
	REGISTRATION: {
		CHECK_STEP_ID: '/api/registration/checkStepId',
		CLEAR_STATE: '/api/registration/clearState',
		REGISTER_ACCOUNT: '/api/registration/createAccount',
		CREATE_NEW_ACCOUNT: '/api/registration/createNewAccount',
		EULA_CHECKBOXES: '/api/registration/getEulaCheckboxes',
		SEND_CODE: '/api/registration/sendCode',
		SEND_EULA_CHECKBOXES: '/api/registration/sendEulaCheckboxes',
		VERIFY_CODE: '/api/registration/verifyCode'
	},
	USER: {
		USER_ID: '/api/user/getPersonalAccountUserId',
		USER_EMAIL: '/api/user/getUserEmail',
		USER_REGISTRATION_STATE: '/api/user/getUserState',
		UPDATE_USER_STATE: '/api/user/updateUserState'
	},
	VERIFICATION: {
		EULA_VERIFICATION: '/api/verification/getEulaVerification',
		SUMSUB: '/api/verification/getSumSub',
		USER_VERIFICATION_INFO: '/api/verification/getUserVerificationInfo',
		USER_VERIFICATION_PROFILE:
			'/api/verification/getUserVerificationProfile',
		SEND_EULA_VERIFICATION_CHECKBOXES:
			'/api/verification/sendEulaVerificationCheckBoxes',
		SEND_VERIFICATION_QUESTIONARIES:
			'/api/verification/sendVerificationQuestionaries',
		UPDATE_USER_PROFILE: '/api/verification/updateUserProfile'
	}
};
