import React, { useContext, useEffect, useState } from "react";
import "../internal-transfer.css";
import { Divider } from "@mui/material";
import { TWallet } from "../../../types/types";
import { ThemeContext } from "../../../data/theme-provider";

interface Props {
	item: string;
	wallets: TWallet;
	currentWallet: string;
	handleItemClick: (i: string) => void;
	handleIsModalOpen: () => void;
	isFrom: boolean;
}
const InternalModalItem = ({
	item,
	handleItemClick,
	handleIsModalOpen,
	wallets,
	currentWallet,
	isFrom,
}: Props) => {
	const { theme } = useContext(ThemeContext);
	const [isDisabled, setIsDisabled] = useState<boolean>(false);

	const handleClick = (item: string) => {
		if (!isDisabled) {
			handleItemClick(item);
			handleIsModalOpen();
		}
		if (!isFrom) {
			handleItemClick(item);
			handleIsModalOpen();
		}
	};

	useEffect(() => {
		if (wallets[item].assets.length == 0 || currentWallet === item) {
			setIsDisabled(true);
		}
	}, []);

	return (
		<div className={"internalModalItem"} onClick={() => handleClick(item)}>
			{isDisabled && isFrom ? (
				<h1
					className={
						"internalModalItemTextDisabled" +
						(theme === "dark" ? "-dark" : "")
					}
				>
					{item}
				</h1>
			) : (
				<h1
					className={
						"internalModalItemText" + (theme === "dark" ? "-dark" : "")
					}
				>
					{item}
				</h1>
			)}
			<Divider />
		</div>
	);
};

export default InternalModalItem;
