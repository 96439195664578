import React, { createContext, useState } from "react";
import {
	TAccountInfo,
	TAllAccountsResponse,
	TTransferResponse,
	TWallet,
} from "../types/types";
import {
	getTransactionInfoById,
	getUserRestByAccountWithoutAnalytics,
	getUserRests,
	sendTransfer,
} from "../api/api";

const updateUserToken = (userToken: string, userLocale: string) => {
    console.log(userToken, userLocale);
    return;
}

const transfer = (accountFrom: string, accountTo: string, amount: string, asset: string) => {
    console.log(accountFrom, accountTo, amount, asset);
    return;
}

export const InternalContext = createContext({
    data: [],
    fromData: [],
    wallets: {},
    isLoading: false,
    updateUserToken: updateUserToken,
    transfer: transfer,
    isTransferLoading: false,
    isCompleted: false,
    isSuccess: false,
    transferResponse: {
        code: "",
        date: "",
        amount: "",
    },
    isWalletsEmpty: false,
} as {
    data: string[],
    fromData: string[],
    wallets: TWallet,
    isLoading: boolean,
    updateUserToken: typeof updateUserToken,
    transfer: typeof transfer,
    isTransferLoading: boolean,
    isCompleted: boolean,
    isSuccess: boolean,
    transferResponse: TTransferResponse,
    isWalletsEmpty: boolean,
});


const InternalProvider = ({children}: { children: React.ReactNode }) => {
    const [data, setData] = useState<string[]>([]);
    const [fromData, setFromData] = useState<string[]>([]);
    const [wallets, setWallets] = useState<TWallet>({});
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [locale, setLocale] = useState<string>("");
    const [isTransferLoading, setIsTransferLoading] = useState<boolean>(false);
    const [isCompleted, setIsCompleted] = useState<boolean>(false);
    const [isSuccess, setIsSuccess] = useState<boolean>(false);
    const [isWalletsEmpty, setIsWalletsEmpty] = useState<boolean>(false);
    const [transferResponse, setTransferResponse] = useState({
        code: "",
        date: "",
        amount: "",
    });

    const updateUserToken = async (userToken: string, userLocale: string) => {
        setIsLoading(true);
        setLocale(userLocale);
        await initData();
    }

    const initData = async () => {
        const accountResponse: TAllAccountsResponse = await getUserRests(sessionStorage.getItem("userToken") as string, );
        const array: string[] = [];
        const fromArray: string[] = [];
        const modificatedArray: TWallet = {};
        // eslint-disable-next-line
        const walletsArray: any[] = [];
        if(accountResponse) {
            for (let i = 0; i < accountResponse.length; i++) {
                if(accountResponse[i].accTypeName === "Main") {
                    array.push(accountResponse[i].accNum);
                } else {
                    if(accountResponse[i].tradingProductCode === "Cash") {
                        array.push(`${String(accountResponse[i].tradingAccKey)} (Spot)`);
                    } else {
                        array.push(`${String(accountResponse[i].tradingAccKey)} (${String(accountResponse[i].tradingProductCode)})`);
                    }
                }
                await getUserRestByAccount(accountResponse[i], array, fromArray, modificatedArray, walletsArray);
            }
            setData(array);
            setFromData(fromArray);
            setWallets(modificatedArray);
        }
        if(walletsArray.length == 0) {
            setIsWalletsEmpty(true);
        }
        setIsLoading(false);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const getUserRestByAccount = async (accountResponse: TAccountInfo, array: string[], fromArray: string[], modificatedArray: TWallet, walletsArray: any[]) => {
        const rests = await getUserRestByAccountWithoutAnalytics(sessionStorage.getItem("userToken") as string, accountResponse.accNum, );
        if(rests) {
            if(accountResponse.accTypeName === "Main") {
                modificatedArray[accountResponse.accNum] = {
                    assets: rests.accounts[0].assets,
                    wallet: accountResponse.accNum,
                    isLeverage: false,
                }
            } else {
                if(accountResponse.tradingProductCode === "Cash") {
                    modificatedArray[`${String(accountResponse.tradingAccKey)} (Spot)`] = {
                        assets: rests.accounts[0].assets,
                        wallet: accountResponse.accNum,
                        isLeverage: false,
                    }
                } else {
                    modificatedArray[`${String(accountResponse.tradingAccKey)} (${String(accountResponse.tradingProductCode)})`] = {
                        assets: rests.accounts[0].assets,
                        wallet: accountResponse.accNum,
                        isLeverage: true,
                    }
                }
            }
            if(rests.accounts[0].assets.length > 0) {
                walletsArray.push(...rests.accounts[0].assets)
                if(accountResponse.accTypeName === "Main") {
                    fromArray.push(accountResponse.accNum);
                } else {
                    if(accountResponse.tradingProductCode === "Cash") {
                        fromArray.push(`${String(accountResponse.tradingAccKey)} (Spot)`);
                    } else {
                        fromArray.push(`${String(accountResponse.tradingAccKey)} (${String(accountResponse.tradingProductCode)})`);
                    }
                }
            }
        }
    }

    const transfer = async (accountFrom: string, accountTo: string, amount: string, asset: string) => {
        setIsTransferLoading(true);
        const response = await sendTransfer(accountFrom, accountTo, amount, asset, sessionStorage.getItem("userToken") as string, );
        if(response.code) {
            setIsTransferLoading(false);

            setIsSuccess(false);
            setIsCompleted(true);
            return;
        }
        const result = await getTransactionInfoById(response.chainId, locale, sessionStorage.getItem("userToken") as string, );
        setIsSuccess(true);
        setIsCompleted(true);
        setTransferResponse({
            code: result.transactions[0]?.transfer.asset.code,
            date: result.transactions[0]?.transfer.createDate,
            amount: result.transactions[0]?.transfer.amount,
        })
        setIsTransferLoading(false);
    }

    return (
        <InternalContext.Provider value={{isWalletsEmpty, data, fromData, wallets, isLoading, updateUserToken, transfer, isTransferLoading, isCompleted, isSuccess, transferResponse}}>
            {children}
        </InternalContext.Provider>
    );
};

export default InternalProvider;