import React, { useContext, useEffect, useState } from "react";
import "./exchange.css";
import { useTranslation } from "react-i18next";
import ExchangeRate from "./exchange-rate/exchange-rate";
import { ExchangeContext } from "../../data/exchange-provider";
import ExchangePayment from "./exchange-payment/exchange-payment";
import CircleProgress from "../circle-progress/circle-progress";
import { useTelegram } from "../../hooks/useTelegram";
import { TExchangeItem, TTheme } from "../../types/types";
import ExchangeInput from "../common/input/exchange-input/exchange-input";
import SwapButton from "../../icons/swap-button";
import CustomButton from "../common/button/custom-button";
import ExchangeConfirmPage from "./exchange-confirm-page/exchange-confirm-page";
import { Whale } from "../../icons/whale";
import { ThemeContext } from "../../data/theme-provider";
import { Helmet } from "react-helmet";
import { Titles } from "../../types/titles";
import { FirebaseContext } from "../../data/firebase-provider";
import { AnalyticsEventsName } from "../../types/analytics/analytics-events";

const locale = window.location.pathname.split("/")[1] != "en" ? "ru" : "en";

const Exchange = () => {
	const { initializeFirebase, sendAnalytics } = useContext(FirebaseContext);
	const { theme, updateTheme } = useContext(ThemeContext);
	const {
		getRateForUsdt,
		data,
		isWalletsEmpty,
		isExchanged,
		isLoading,
		isOperationLoading,
		pairs,
		getPairForSymbol,
		updateUserToken,
		getRateForPair,
		rate,
		rateNum,
		exchangePair,
		eWallet,
		isSuccess,
		date,
	} = useContext(ExchangeContext);
	const [isInputError, setIsInputError] = useState<boolean>(false);
	const { t } = useTranslation();
	const [fromValue, setFromValue] = useState<string>("");
	const [toValue, setToValue] = useState<string>("");
	const isConfirmButtonDisabled =
		!isInputError &&
		!(Number(fromValue) == 0 || fromValue === "") &&
		!(
			toValue === "NaN" ||
			toValue === "Infinity" ||
			toValue === "" ||
			Number(toValue) == 0
		) &&
		rate != t("defaultRateValue");
	const [selectedFromItem, setSelectedFromItem] = useState<TExchangeItem>();
	const [selectedToItem, setSelectedToItem] = useState<TExchangeItem>();
	const [isSwap, setIsSwap] = useState<boolean>(false);
	const [isSubmitModalOpen, setIsSubmitModalOpen] = useState<boolean>(false);
	const [amountUSDT, setAmountUSDT] = useState<string>("");

	const { tg } = useTelegram();

	const handleFirebaseInitialization = async () => {
		await initializeFirebase();
	};

	useEffect(() => {
		handleFirebaseInitialization();
		updateTheme();
		tg.expand();
		sessionStorage.setItem(
			"userToken",
			new URLSearchParams(window.location.search).get("token") as string
		);
		updateUserToken(sessionStorage.getItem("userToken") as string, locale);
	}, []);

	useEffect(() => {
		if (!isSwap) {
			handleDataUpload();
		}
	}, [data]);

	const handleDataUpload = async () => {
		if (!isSwap) {
			if (data.length > 0) {
				await setSelectedFromItem(data[0]);
				await getPairForSymbol(data[0]);
			}
		}
	};

	useEffect(() => {
		handlePairsUpload();
	}, [pairs]);

	const handlePairsUpload = async () => {
		if (!isSwap) {
			if (pairs.length > 0) {
				await setSelectedToItem(pairs[0]);
				await getRateForPair(
					pairs[0].symbolName as string,
					selectedFromItem as TExchangeItem,
					pairs[0]
				);
			}
		}
	};

	useEffect(() => {
		handleToItemClickRate();
	}, [rateNum]);

	const handleToItemClickRate = async () => {
		if (
			selectedFromItem?.code.concat(selectedToItem?.code as string) ===
			selectedToItem?.symbolName
		) {
			await setToValue(
				String(
					Number(Number(rateNum) * Number(fromValue)).toFixed(
						selectedToItem?.precision
					)
				)
			);
		} else {
			await setToValue(
				String(
					Number(Number(fromValue) / Number(rateNum)).toFixed(
						selectedToItem?.precision
					)
				)
			);
		}
	};

	const handleCloseClick = () => {
		tg.close();
	};

	if (isWalletsEmpty) {
		return (
			<>
				<Helmet>
					<title>{Titles.EXCHANGE.EMPTY}</title>
				</Helmet>
				<div className={"component-clean" + (theme === "dark" ? "-dark" : "")}>
					<div className={"innerComponent"}>
						<div className={"exchangePaymentLogoContainer"}>
							<Whale className={""} />
						</div>
						<h1
							className={
								"exchangePaymentHeaderText" +
								(theme === "dark" ? "-dark" : "")
							}
						>
							{t("exchangeWelcomeText")}
						</h1>
						<h1
							className={
								"exchangePaymentBottomText" +
								(theme === "dark" ? "-dark" : "")
							}
						>
							{t("exchangeWalletsEmptyText")}
						</h1>
						<CustomButton
							theme={theme as TTheme}
							onClick={handleCloseClick}
							className={"exchangeConfirmCloseButton"}
							text={t("exchangeCloseButton") as string}
						/>
					</div>
				</div>
			</>
		);
	}

	if (
		isLoading ||
		data.length === 0 ||
		selectedFromItem == undefined ||
		pairs.length === 0 ||
		selectedToItem == undefined ||
		theme === ""
	)
		return <CircleProgress thickness={6} size={40} />;

	const handleFromValueChange = async (amount: string) => {
		const regex = new RegExp(`^[0-9]*\\.?[0-9]{0,${selectedFromItem.precision}}$`);
		if (amount === "" || regex.test(amount)) {
			await setFromValue(amount);
			if (amount === "") {
				await setToValue("");
				return;
			}
			if (
				selectedFromItem.code.concat(selectedToItem.code) ===
				selectedToItem.symbolName
			) {
				await setToValue(
					String(
						Number(Number(rateNum) * Number(amount)).toFixed(
							selectedToItem.precision
						)
					)
				);
			} else {
				await setToValue(
					String(
						Number(Number(amount) / Number(rateNum)).toFixed(
							selectedToItem.precision
						)
					)
				);
			}
		}
	};

	const handleToValueChange = async (amount: string) => {
		const regex = new RegExp(`^[0-9]*\\.?[0-9]{0,${selectedToItem.precision}}$`);
		if (amount === "" || regex.test(amount)) {
			await setToValue(amount);
			if (amount === "") {
				await setFromValue("");
				return;
			}
			if (
				selectedFromItem.code.concat(selectedToItem.code) ===
				selectedToItem.symbolName
			) {
				await setFromValue(
					String(
						Number(Number(amount) / Number(rateNum)).toFixed(
							selectedFromItem.precision
						)
					)
				);
			} else {
				await setFromValue(
					String(
						Number(Number(rateNum) * Number(amount)).toFixed(
							selectedFromItem.precision
						)
					)
				);
			}
		}
	};

	const handleFromClick = async (i: TExchangeItem) => {
		await setFromValue("");
		await setToValue("");
		await setSelectedFromItem(i);
		await getPairForSymbol(i);
		//await getRateForPair(pairs[0].symbolName, i, pairs[0]);
	};

	const handleAvailableFromClick = (amount: string) => {
		handleFromValueChange(amount);
	};

	const handleAvailableToClick = (amount: string) => {
		handleToValueChange(amount);
	};

	const handleToClick = async (i: TExchangeItem) => {
		await setSelectedToItem(i);
		await getRateForPair(i.symbolName as string, selectedFromItem, i);
	};

	const handleSwapClick = async () => {
		setIsSwap(true);
		await setFromValue("");
		await setToValue("");
		const from = selectedFromItem;
		const to = selectedToItem;
		from.symbolName = to.symbolName;
		await setSelectedFromItem(to);
		await setSelectedToItem(from);
		await getPairForSymbol(to);
		await getRateForPair(to.symbolName as string, to, from);
		setIsSwap(false);
	};

	const isSwapButtonDisabled = selectedToItem.available === 0;

	const handleSubmitModalOpen = async () => {
		await sendAnalytics({
			name: AnalyticsEventsName.exchangeStarted,
		});
		await setIsSubmitModalOpen(!isSubmitModalOpen);
	};

	const handleSubmitClickOnConfirmPage = async () => {
		exchangePair(selectedFromItem, selectedToItem, fromValue);
		const response = await getRateForUsdt(toValue, selectedToItem.code);
		setAmountUSDT(response);
	};

	const handleBackClickButton = () => {
		tg.close();
	};

	if (isExchanged) {
		return (
			<>
				<Helmet>
					<title>{Titles.EXCHANGE.SUMMARY}</title>
				</Helmet>
				<ExchangePayment
					paymentInfo={{
						assetFrom: selectedFromItem,
						assetTo: selectedToItem,
						amountFrom: fromValue,
						amountTo: toValue,
						amountUSDT: amountUSDT,
						date: date,
					}}
					locale={locale}
					rate={rate}
					eWallet={eWallet}
					isSuccess={isSuccess}
				/>
			</>
		);
	}

	if (isSubmitModalOpen) {
		return (
			<>
				<Helmet>
					<title>{Titles.EXCHANGE.CONFIRMATION}</title>
				</Helmet>
				<ExchangeConfirmPage
					selectedFromItem={selectedFromItem}
					selectedToItem={selectedToItem}
					fromValue={fromValue}
					toValue={toValue}
					rate={rate}
					setIsSubmitModalOpen={setIsSubmitModalOpen}
					handleSubmit={handleSubmitClickOnConfirmPage}
					isLoading={isOperationLoading}
				/>
			</>
		);
	}

	return (
		<>
			<Helmet>
				<title>{Titles.EXCHANGE.INDEX}</title>
			</Helmet>
			<div className={"component-clean" + (theme === "dark" ? "-dark" : "")}>
				<div className={"innerComponent"}>
					<h1
						className={
							"exchangeWelcomeText" + (theme === "dark" ? "-dark" : "")
						}
					>
						{t("exchangeWelcomeText")}
					</h1>
					<ExchangeInput
						isFrom={true}
						isInputError={isInputError}
						setIsInputError={setIsInputError}
						label={t("exchangeFromText")}
						amount={fromValue}
						setAmount={handleFromValueChange}
						available={selectedFromItem.available}
						asset={selectedFromItem}
						handleItemClick={handleFromClick}
						handleAvailableClick={handleAvailableFromClick}
						assets={data}
						fontSize={fromValue.length > 8 ? "18px" : "24px"}
					/>
					<CustomButton
						theme={theme as TTheme}
						onClick={handleSwapClick}
						isDisabled={isSwapButtonDisabled}
						isSwapButton={true}
						className={"exchangeSwapContainer"}
					>
						<SwapButton className={"exchangeSwapIcon"} />
					</CustomButton>
					<ExchangeInput
						isFrom={false}
						isInputError={isInputError}
						setIsInputError={setIsInputError}
						label={t("exchangeToText")}
						amount={toValue}
						setAmount={handleToValueChange}
						available={selectedToItem.available}
						asset={selectedToItem}
						handleItemClick={handleToClick}
						handleAvailableClick={handleAvailableToClick}
						assets={pairs}
						fontSize={toValue.length > 8 ? "18px" : "24px"}
					/>
					<ExchangeRate text={rate} />
					<CustomButton
						theme={theme as TTheme}
						onClick={handleSubmitModalOpen}
						className={"exchangeConfirmButton"}
						isDisabled={!isConfirmButtonDisabled}
						text={t("exchangeButtonText") as string}
					/>
					<CustomButton
						theme={theme as TTheme}
						isBack={true}
						onClick={handleBackClickButton}
						className={"backButton"}
					/>
				</div>
			</div>
		</>
	);
};

export default Exchange;
