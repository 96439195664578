import React, {useContext, useState} from 'react';
import CustomButton from "../../common/button/custom-button";
import {useTranslation} from "react-i18next";
import {Checkbox} from "@mui/material";
import {Whale} from "../../../icons/whale";
import {ThemeContext} from "../../../data/theme-provider";
import {TTheme} from "../../../types/types";

interface Props {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    questionaries: any,
    setIsButtonLoading: React.Dispatch<React.SetStateAction<boolean>>,
    isButtonLoading: boolean,
    answers: {
        questionId: string,
        answerId: string,
    }[],
    setAnswers: React.Dispatch<React.SetStateAction<{
        questionId: string,
        answerId: string,
    }[] | undefined>>,
    handleSubmitTest: () => void,
    isError: boolean,
    isTestCompleted: boolean,
    setIsError: React.Dispatch<React.SetStateAction<boolean>>,
    handleTestPassedSuccessfully: () => void;
}
const Questionary = (
    {
        questionaries,
        isButtonLoading,
        setIsButtonLoading,
        answers,
        setAnswers,
        handleSubmitTest,
        isError,
        setIsError,
        isTestCompleted,
        handleTestPassedSuccessfully
    }: Props) => {
    const {theme} = useContext(ThemeContext);
    const [currentQuestion, setCurrentQuestion] = useState<number>(0);
    const [isTestStarted, setIsTestStarted] = useState<boolean>(false);
    const [isChecked, setIsChecked] = useState<boolean[]>([false, false, false]);

    const {t} = useTranslation();

    const isButtonDisabled = isChecked.filter((check) => check).length === 0;

    const handleCurrentQuestionChange = () => {
        setIsChecked([false, false, false]);
        setCurrentQuestion(currentQuestion + 1);
    }

    const handleLastQuestionClick = () => {
        setIsButtonLoading(true);
        handleSubmitTest();
    }

    const handleStartTestButtonClick = () => {
        setIsChecked([false, false, false]);
        setIsButtonLoading(false);
        setIsError(false);
        setCurrentQuestion(0);
        setIsTestStarted(true);
    }

    const handleFirstCheckBoxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        answers[currentQuestion].answerId = questionaries[currentQuestion].answers[0].answerKey;
        setAnswers(answers);
        setIsChecked([e.target.checked, false, false]);
    }

    const handleSecondCheckBoxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        answers[currentQuestion].answerId = questionaries[currentQuestion].answers[1].answerKey;
        setAnswers(answers)
        setIsChecked([false, e.target.checked, false]);
    }

    const handleThirdCheckBoxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        answers[currentQuestion].answerId = questionaries[currentQuestion].answers[2].answerKey;
        setAnswers(answers)
        setIsChecked([false, false, e.target.checked]);
    }

    if(!isError && isTestCompleted) {
        return (
            <div className={"componentBorderless" + (theme === 'dark' ? '-dark' : '')}>
                <div className={"innerComponent"}>
                    <Whale className={"verificationWhale"}/>
                    <h1 className={"verificationQuestion" + (theme === 'dark' ? '-dark' : '')}>{t("verificationTestSuccess")}</h1>
                    <CustomButton
                        theme={theme as TTheme}
                        text={t("registrationContinue") as string}
                        type={"submit"}
                        onClick={handleTestPassedSuccessfully}
                        className={"verificationStartTestButton"}
                    />
                </div>
            </div>
        )
    }

    if(isError && !isTestCompleted) {
        return (
            <div className={"componentBorderless" + (theme === 'dark' ? '-dark' : '')}>
                <div className={"innerComponent"}>
                    <Whale className={"verificationWhale"}/>
                    <h1 className={"verificationQuestion" + (theme === 'dark' ? '-dark' : '')}>{t("verificationTestFailed")}</h1>
                    <h1 className={"verificationTestDescription" + (theme === 'dark' ? '-dark' : '')}>{t("verificationTestFailedDescription")}</h1>
                    <CustomButton
                        theme={theme as TTheme}
                        text={t("verificationTestFailedButton") as string}
                        type={"submit"}
                        onClick={handleStartTestButtonClick}
                        className={"verificationStartTestButton"}
                    />
                </div>
            </div>
        )
    }

    if(isTestStarted) {
        return (
            <div className={"componentBorderless" + (theme === 'dark' ? '-dark' : '')}>
                <div className={"innerComponent"}>
                    <h1 className={"verificationQuestionNumber" + (theme === 'dark' ? '-dark' : '')}>{t("verificationQuestionNumber").replace("{number}", String(currentQuestion+1))}</h1>
                    <h1 className={"questionText" + (theme === 'dark' ? '-dark' : '')}>{questionaries[currentQuestion].questionValue.split(". ")[1].replace(":", "?")}</h1>
                    <div className={"answersDiv"}>
                        <div key={questionaries[currentQuestion].answers[0].answerKey} className={`answerDiv`}>
                            <Checkbox
                                className={"answerCheckBox"}
                                sx={{
                                    '&.Mui-checked': {
                                        color: '#00BEB4 !important',
                                    },
                                    color: theme === 'dark' ? "#303030 !important" : '#EBECEE !important',
                                    borderRadius: '50% !important'
                                }}
                                checked={isChecked[0]}
                                onChange={handleFirstCheckBoxChange}
                            />
                            <h1 className={"answerText" + (theme === 'dark' ? '-dark' : '')}>{questionaries[currentQuestion].answers[0].answerValue}</h1>
                        </div>
                        <div key={questionaries[currentQuestion].answers[1].answerKey} className={`answerDiv`}>
                            <Checkbox
                                className={"answerCheckBox"}
                                sx={{
                                    '&.Mui-checked': {
                                        color: '#00BEB4 !important',
                                    },
                                    color: theme === 'dark' ? "#303030 !important" : '#EBECEE !important',
                                    borderRadius: '50% !important'
                                }}
                                checked={isChecked[1]}
                                onChange={handleSecondCheckBoxChange}
                            />
                            <h1 className={"answerText" + (theme === 'dark' ? '-dark' : '')}>{questionaries[currentQuestion].answers[1].answerValue}</h1>
                        </div>
                        <div key={questionaries[currentQuestion].answers[2].answerKey} className={`answerDiv`}>
                            <Checkbox
                                className={"answerCheckBox"}
                                sx={{
                                    '&.Mui-checked': {
                                        color: '#00BEB4 !important',
                                    },
                                    color: theme === 'dark' ? "#303030 !important" : '#EBECEE !important',
                                    borderRadius: '50% !important'
                                }}
                                checked={isChecked[2]}
                                onChange={handleThirdCheckBoxChange}
                            />
                            <h1 className={"answerText" + (theme === 'dark' ? '-dark' : '')}>{questionaries[currentQuestion].answers[2].answerValue}</h1>
                        </div>
                    </div>
                    {currentQuestion+1 === answers.length ?
                        <CustomButton
                            theme={theme as TTheme}
                            isLoading={isButtonLoading}
                            isDisabled={isButtonDisabled}
                            text={t("registrationContinue") as string}
                            type={"submit"}
                            onClick={handleLastQuestionClick}
                            className={"verificationStartTestButton"}
                        />
                        : <CustomButton
                            theme={theme as TTheme}
                            isLoading={isButtonLoading}
                            isDisabled={isButtonDisabled}
                            text={t("registrationContinue") as string}
                            type={"submit"}
                            onClick={handleCurrentQuestionChange}
                            className={"verificationStartTestButton"}
                        />
                    }
                </div>
            </div>
        )
    }

    return (
        <div className={"componentBorderless" + (theme === 'dark' ? '-dark' : '')}>
            <div className={"innerComponent"}>
                <h1 className={"verificationTestStartLogo" + (theme === 'dark' ? '-dark' : '')}>{t("verificationTestStartLogo")}</h1>
                <h1 className={"verificationTestDescription" + (theme === 'dark' ? '-dark' : '')}>{t("verificationTestDescription")}</h1>
                <CustomButton
                    theme={theme as TTheme}
                    text={t("verificationStartTestButton") as string}
                    type={"submit"}
                    onClick={handleStartTestButtonClick}
                    className={"verificationStartTestButton"}
                />
            </div>
        </div>
    )
};

export default Questionary;