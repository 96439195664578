import React, { useContext } from "react";
import { TAddress, TTheme } from "../../../../types/types";
import { ThemeContext } from "../../../../data/theme-provider";
import BaseIcon from "../../../../icons/base-icon";
import { useTranslation } from "react-i18next";

interface Props {
	item: TAddress;
	handleItemClick: (i: unknown) => void;
	handleModalOpen: () => void;
}
const AddressItem = ({ item, handleItemClick, handleModalOpen }: Props) => {
	const { theme } = useContext(ThemeContext);
	const { t } = useTranslation();

	const handleClick = () => {
		handleItemClick(item);
		handleModalOpen();
	};

	return (
		<div onClick={handleClick} className={"modalItem"}>
			<div key={item.address} className={"modalItemLogo"}>
				<BaseIcon
					theme={theme as TTheme}
					path={String(item?.asset).toLowerCase()}
					group={"crypto"}
					className={"analyticsImg"}
				/>
				<div className={"exchangeModalItemNameContainer"}>
					<h1
						className={
							"analyticsCurrencyRowAssetText" +
							(theme === "dark" ? "-dark" : "")
						}
					>
						{item.maskedAddress}
					</h1>
					<h1
						className={
							"analyticsCurrencyRowUsdtText" +
							(theme === "dark" ? "-dark" : "")
						}
					>
						{item.lastPaymentDate
							.replace(/T.*/, "")
							.split("-")
							.reverse()
							.join("-")}{" "}
						{t("cryptoWithdrawTotal")}
						{" "}
						{item.count}
					</h1>
				</div>
			</div>
		</div>
	);
};

export default AddressItem;
