import React, {useContext, useEffect} from 'react';
import {useTranslation} from "react-i18next";
import BaseIcon from "../../../icons/base-icon";
import {useTelegram} from "../../../hooks/useTelegram";
import "../exchange.css";
import {TExchangeItem, TTheme} from "../../../types/types";
import CustomButton from "../../common/button/custom-button";
import {ThemeContext} from "../../../data/theme-provider";
import { FirebaseContext } from '../../../data/firebase-provider';
import { AnalyticsEventsName } from '../../../types/analytics/analytics-events';

interface Props {
    paymentInfo: {
        assetFrom: TExchangeItem;
        assetTo: TExchangeItem;
        amountFrom: string;
        amountTo: string;
        amountUSDT: string;
        date: string;
    }
    locale: string;
    rate: string;
    eWallet: string;
    isSuccess: boolean;
}
const ExchangePayment = (props: Props) => {
    const {sendAnalytics} = useContext(FirebaseContext);
    const {theme} = useContext(ThemeContext);
    const {t} = useTranslation();
    const {tg} = useTelegram();
    const handleCloseClick = () => {
        tg.close();
    }

    const handleSendAnalytics = async () => {
        await sendAnalytics({
            name: AnalyticsEventsName.exchangeSuccess,
            params: {
                exchange_from: props.paymentInfo.assetFrom.code,
                exchange_from_amount: props.paymentInfo.amountFrom,
                exchange_to: props.paymentInfo.assetTo.code,
                exchange_to_amount: props.paymentInfo.amountTo,          
                exchange_amount_usdt: props.paymentInfo.amountUSDT
            }
        })
    }

    useEffect(() => {
        handleSendAnalytics();
    }, [])

    if(props.isSuccess) {
        return (
            <div className={"component-clean" + (theme === 'dark' ? '-dark' : '')}>
                <div className={"innerComponent"}>
                    <div className={"exchangePaymentLogoContainer"}>
                        <img className={"exchangePaymentLogo"} src={"/success-icon.png"} alt={"success"} />
                    </div>
                    <h1 className={"exchangePaymentHeaderText" + (theme === 'dark' ? '-dark' : '')}>{t("exchangeOperationStatusPayment")}</h1>
                    <h1 className={"exchangePaymentBottomText" + (theme === 'dark' ? '-dark' : '')}>{t("exchangeOperationStatusQueue")}</h1>
                    <div className={"exchangePaymentFirstInfoContainer" + (theme === 'dark' ? '-dark' : '')}>
                        <div className={"exchangeAssetContainer"}>
                            <h1 className={"exchangeSellText" + (theme === 'dark' ? '-dark' : '')}>{t("exchangeSellText")}</h1>
                            <div className={"exchangeAssetBlock"}>
                                <h1 className={"exchangeConfirmAmount" + (theme === 'dark' ? '-dark' : '')}>{props.paymentInfo.amountFrom}</h1>
                                <h1 className={"exchangeConfirmText" + (theme === 'dark' ? '-dark' : '')}>{props.paymentInfo.assetFrom.code.toUpperCase()}</h1>
                                <BaseIcon
                                    theme={theme as TTheme}
                                    path={props.paymentInfo.assetFrom.code.toLowerCase()}
                                    group={props.paymentInfo.assetFrom.group.toLowerCase()}
                                    className={"exchangeConfirmAsset"}
                                />
                            </div>
                        </div>
                        <div id={"secondExchangeAssetContainer"} className={"exchangeAssetContainer"}>
                            <h1 className={"exchangeSellText" + (theme === 'dark' ? '-dark' : '')}>{t("exchangeBuyText")}</h1>
                            <div className={"exchangeAssetBlock"}>
                                <h1 className={"exchangeConfirmAmount" + (theme === 'dark' ? '-dark' : '')}>{props.paymentInfo.amountTo}</h1>
                                <h1 className={"exchangeConfirmText" + (theme === 'dark' ? '-dark' : '')}>{props.paymentInfo.assetTo.code.toUpperCase()}</h1>
                                <BaseIcon
                                    theme={theme as TTheme}
                                    path={props.paymentInfo.assetTo.code.toLowerCase()}
                                    group={props.paymentInfo.assetTo.group.toLowerCase()}
                                    className={"exchangeConfirmAsset"}
                                />
                            </div>
                        </div>
                    </div>
                    <div className={"exchangePaymentSecondInfoContainer" + (theme === 'dark' ? '-dark' : '')}>
                        <div className={"exchangeAssetBlock"}>
                            <h1 className={"exchangeSellText" + (theme === 'dark' ? '-dark' : '')}>{t("exchangeDate")}</h1>
                            <h1 className={"exchangeConfirmAmount" + (theme === 'dark' ? '-dark' : '')}>{new Intl.DateTimeFormat(props.locale.toLowerCase()+"-"+props.locale.toUpperCase(), {
                                day: '2-digit',
                                month: '2-digit',
                                year: 'numeric',
                                hour: '2-digit',
                                minute: '2-digit'
                            }).format(new Date(props.paymentInfo.date).setHours(new Date(props.paymentInfo.date).getHours()-3)).replace("/", ".").replace("/", ".")}</h1>
                        </div>
                        <div id={"secondExchangeAssetContainer"} className={"exchangeAssetBlock"}>
                            <h1 className={"exchangeSellText" + (theme === 'dark' ? '-dark' : '')}>{t("eWallet")}</h1>
                            <h1 className={"exchangeConfirmAmount" + (theme === 'dark' ? '-dark' : '')}>{props?.eWallet}</h1>
                        </div>
                        <div id={"secondExchangeAssetContainer"} className={"exchangeAssetBlock"}>
                            <h1 className={"exchangeSellText" + (theme === 'dark' ? '-dark' : '')}>{t("exchangeOptionRate")}</h1>
                            <h1 className={"exchangeConfirmAmount" + (theme === 'dark' ? '-dark' : '')}>{props?.rate}</h1>
                        </div>
                    </div>
                    <CustomButton
                        theme={theme as TTheme}
                        onClick={handleCloseClick}
                        className={"exchangeConfirmButton"}
                        text={t("exchangeCloseButton") as string}
                    />
                </div>
            </div>
        )
    }

    return (
        <div className={"component-clean" + (theme === 'dark' ? '-dark' : '')}>
            <div className={"innerComponent"}>
                <div className={"exchangePaymentLogoContainer"}>
                    <img className={"exchangePaymentLogo"} src={"/error-icon.png"} alt={"error"} />
                </div>
                <h1 className={"exchangePaymentHeaderText" + (theme === 'dark' ? '-dark' : '')}>{t("exchangeErrorOperationHeader")}</h1>
                <h1 className={"exchangePaymentBottomText + (theme === 'dark' ? '-dark' : '')"}>{t("exchangeErrorOperationBottom")}</h1>
                <div className={"exchangePaymentFirstInfoContainer" + (theme === 'dark' ? '-dark' : '')}>
                    <div className={"exchangeAssetBlock"}>
                        <h1 className={"exchangeSellText" + (theme === 'dark' ? '-dark' : '')}>eWallet</h1>
                        <h1 className={"exchangeConfirmAmount" + (theme === 'dark' ? '-dark' : '')}>{props?.eWallet}</h1>
                    </div>
                </div>
                <CustomButton
                    theme={theme as TTheme}
                    onClick={handleCloseClick}
                    className={"exchangeConfirmButton"}
                    text={t("exchangeCloseButton") as string}
                />
            </div>
        </div>
    )
};

export default ExchangePayment;