import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CustomInput from "../common/input/custom-input";
import { initBankDepositPage, sendBankDeposit } from "../../api/api";
import CircleProgress from "../circle-progress/circle-progress";
import {
	TBank,
	TBankCredentials,
	TExchangeItem,
	TMappedBank,
	TTheme,
} from "../../types/types";
import ExchangeModalSelect from "../exchange/exchange-modal-select/exchange-modal-select";
import BankCountryInput from "./bank-country-modal/bank-country-input";
import PreferredBankInput from "./preferred-bank-modal/preferred-bank-input";
import CustomButton from "../common/button/custom-button";
import { Divider } from "@mui/material";
import { useTelegram } from "../../hooks/useTelegram";
import BankCredentialsModal from "./bank-credentials-modal/bank-credentials-modal";
import ShareIcon from "@mui/icons-material/Share";
import BankPreview from "../../icons/bank-preview";
import { ThemeContext } from "../../data/theme-provider";
import { Helmet } from "react-helmet";
import { Titles } from "../../types/titles";
import { FirebaseContext } from "../../data/firebase-provider";
import { AnalyticsEventsName } from "../../types/analytics/analytics-events";

const locale = window.location.pathname.split("/")[1] != "en" ? "ru" : "en";

const BankDeposit = () => {
	const { initializeFirebase, sendAnalytics } = useContext(FirebaseContext);
	const { theme, updateTheme } = useContext(ThemeContext);
	const [bankCountry, setBankCountry] = useState<string>("");
	const [isBankCountryRequired, setIsBankCountryRequired] = useState<boolean>(false);
	const [preferredBank, setPreferredBank] = useState<string>("");
	const [isPreferredBankRequired, setIsPreferredBankRequired] =
		useState<boolean>(false);
	const [amount, setAmount] = useState<string>("");
	const [isAmountRequired, setIsAmountRequired] = useState<boolean>(false);
	const [currentAsset, setCurrentAsset] = useState<TExchangeItem>();
	const [banks, setBanks] = useState<TMappedBank[]>();
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
	const { tg, openLink } = useTelegram();
	const [isButtonLoading, setIsButtonLoading] = useState<boolean>(false);
	const [mappedBanks, setMappedBanks] = useState<TBank[]>([]);
	const [currencies, setCurrencies] = useState<TExchangeItem[]>([]);
	const [eWallet, setEWallet] = useState<string>("");
	const [invoicePage, setInvoicePage] = useState<boolean>(false);
	const [bankCredentials, setBankCredentials] = useState<
		TBankCredentials | undefined
	>();
	const [isBankCredentialsModalOpen, setIsBankCredentialsModalOpen] =
		useState<boolean>(false);
	const [isError, setIsError] = useState<boolean>(false);
	const [isErrorText, setErrorText] = useState<string>("");
	const [fileName, setFileName] = useState<string>("");
	const [isMobile, setIsMobile] = useState<boolean>(false);
	const [invoiceId, setInvoiceId] = useState<string>("");
	const [pdfUrl, setPdfUrl] = useState<string>("");
	const { t } = useTranslation();

	const initPage = async () => {
		await initializeFirebase();
		updateTheme();
		console.log(invoiceId);
		sessionStorage.setItem(
			"userToken",
			new URLSearchParams(window.location.search).get("token") as string
		);
		setIsButtonLoading(false);
		if (
			navigator.userAgent.match(/Android/i) ||
			navigator.userAgent.match(/iPhone/i) ||
			navigator.userAgent.match(/Mac/i)
		) {
			setIsMobile(true);
		}
		await initBankDepositPage(
			sessionStorage.getItem("userToken") as string,
			setBanks,
			setIsLoading,
			setEWallet
		);
	};

	useEffect(() => {
		initPage();
	}, []);

	useEffect(() => {
		setFileName(`Deposit_${preferredBank.replaceAll(" ", "_")}_${Date.now()}.pdf`);
	}, [bankCredentials]);

	if (isLoading || banks === undefined || theme === "")
		return <CircleProgress thickness={6} size={40} />;

	const handleModalOpen = () => {
		if (bankCountry != "") setIsModalOpen(!isModalOpen);
	};

	const handleBankCountryItemClick = (e: string) => {
		setBankCountry(e);
		setPreferredBank("");
		setAmount("");
		setIsAmountRequired(false);
		setIsPreferredBankRequired(false);
		setCurrentAsset(undefined);
		setCurrencies(
			banks.find((bank: TMappedBank) => bank.countryName === e)
				?.currencies as TExchangeItem[]
		);
		setMappedBanks(
			banks.find((bank: TMappedBank) => bank.countryName === e)?.banks as TBank[]
		);
		e.length > 0 ? setIsBankCountryRequired(true) : setIsBankCountryRequired(false);
	};

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const handleItemClick = (e: any) => {
		setCurrentAsset(e);
	};

	const handlePreferredBankItemClick = (e: string) => {
		setPreferredBank(e);
		const bankKey = mappedBanks.find((bank) => bank.bankName === e)?.bankKey;
		const localCurrencies = banks
			.find((bank: TMappedBank) => bank.countryName === bankCountry)
			//eslint-disable-next-line
			?.currencies.filter((currency: any) => currency.banks.includes(bankKey));
		if (localCurrencies) {
			setCurrencies(localCurrencies as TExchangeItem[]);
			setCurrentAsset(localCurrencies[0] as TExchangeItem);
		}

		e.length > 0
			? setIsPreferredBankRequired(true)
			: setIsPreferredBankRequired(false);
	};

	const handleInputChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
		const regex = new RegExp(`^[0-9]*\\.?[0-9]{0,2}$`);
		if (e.target.value === "" || regex.test(e.target.value)) {
			setAmount(e.target.value);
			e.target.value.length > 0
				? setIsAmountRequired(true)
				: setIsAmountRequired(false);
		}
	};

	const handleSubmit = async () => {
		await sendAnalytics({
			name: AnalyticsEventsName.depositStart,
			params: {
				deposit_source: "Bot",
			},
		});
		await sendBankDeposit(
			mappedBanks?.find((bank: TBank) => bank.bankName === preferredBank)
				?.bankKey as string,
			banks?.find((bank: TMappedBank) => bank.countryName === bankCountry)
				?.countryCode as string,
			currentAsset?.code as string,
			amount,
			eWallet,
			sessionStorage.getItem("userToken") as string,
			setIsButtonLoading,
			setIsError,
			setErrorText,
			locale,
			setInvoicePage,
			setBankCredentials,
			setPdfUrl,
			setInvoiceId,
			sendAnalytics,
			preferredBank
		);
	};

	const handleBackClickButton = () => {
		tg.close();
	};

	const handleBankCredentialsModalOpen = () => {
		setIsBankCredentialsModalOpen(!isBankCredentialsModalOpen);
	};

	const editHtmlPdf = async () => {
		if (isMobile) {
			openLink(pdfUrl);
			return;
		}
		window.location.replace(pdfUrl);
	};

	if (invoicePage) {
		return (
			<>
				<Helmet>
					<title>{Titles.BANK_DEPOSIT.INVOICE}</title>
				</Helmet>
				<div className={"component-clean" + (theme === "dark" ? "-dark" : "")}>
					<div className={"innerComponent"}>
						<BankCredentialsModal
							bankCredentials={bankCredentials as TBankCredentials}
							isModalOpen={isBankCredentialsModalOpen}
							handleIsModalOpen={handleBankCredentialsModalOpen}
						/>
						<h1
							className={
								"exchangeWelcomeText" + (theme === "dark" ? "-dark" : "")
							}
						>
							{t("bankDepositInvoiceHeaderText")}
						</h1>
						<div
							id={"bankDepositPDFPreviewContainer"}
							className={
								"bankDepositPDFUpperContainer" +
								(theme === "dark" ? "-dark" : "")
							}
						>
							<BankPreview />
						</div>
						<div
							className={
								"bankDepositPDFBottomContainer" +
								(theme === "dark" ? "-dark" : "")
							}
						>
							<h1
								className={
									"bankDepositPDFBottomText" +
									(theme === "dark" ? "-dark" : "")
								}
							>
								{fileName.split(".pdf")[0].length > 13
									? fileName.substring(0, 13) + "...pdf"
									: fileName}
							</h1>
							<ShareIcon
								sx={{
									fill: theme === "dark" ? "white" : "black",
								}}
								className={"bankDepositShareButton"}
								onClick={editHtmlPdf}
							/>
						</div>
						<CustomButton
							theme={theme as TTheme}
							onClick={handleBankCredentialsModalOpen}
							className={"exchangeConfirmButton"}
							text={t("bankDepositBankCredentials") as string}
						/>
						<div className={"divider" + (theme === "dark" ? "-dark" : "")}>
							<Divider
								className={"divider" + (theme === "dark" ? "-dark" : "")}
							/>
						</div>
						<CustomButton
							theme={theme as TTheme}
							isBack={true}
							onClick={handleBackClickButton}
							className={"backButton"}
						/>
					</div>
				</div>
			</>
		);
	}

	return (
		<>
			<Helmet>
				<title>{Titles.BANK_DEPOSIT.INDEX}</title>
			</Helmet>
			<div className={"component-clean" + (theme === "dark" ? "-dark" : "")}>
				<div className={"innerComponent"}>
					<h1
						className={
							"exchangeWelcomeText" + (theme === "dark" ? "-dark" : "")
						}
					>
						{t("bankDepositHeaderText")}
					</h1>
					<ExchangeModalSelect
						isFrom={false}
						label={t("cardDepositModalHeader") as string}
						data={currencies}
						isModalOpen={isModalOpen}
						handleIsModalOpen={handleModalOpen}
						handleItemClick={handleItemClick}
					/>
					<BankCountryInput
						value={bankCountry}
						isBankCountryRequired={isBankCountryRequired}
						handleItemClick={handleBankCountryItemClick}
						countries={banks}
					/>
					<PreferredBankInput
						value={preferredBank}
						isPreferredBankRequired={isPreferredBankRequired}
						bankCountry={bankCountry}
						handleItemClick={handlePreferredBankItemClick}
						countries={mappedBanks}
					/>
					<CustomInput
						theme={theme as TTheme}
						isDisabled={preferredBank === "" || bankCountry === ""}
						isInternal={true}
						isRequired={isAmountRequired}
						type={"number"}
						id={"internalAmountInput"}
						label={t("internalAmountText") as string}
						value={amount}
						onChange={handleInputChange}
						className={"internalInputContainer"}
						asset={currentAsset}
						handleModalOpen={handleModalOpen}
					/>
					{isError ? (
						<h1 className={"registrationEmailError"}>{t(isErrorText)}</h1>
					) : (
						<div className={"registrationEmailError"} />
					)}
					<CustomButton
						theme={theme as TTheme}
						isLoading={isButtonLoading}
						onClick={handleSubmit}
						className={"exchangeConfirmButton"}
						isDisabled={
							Number(amount) <= 0 ||
							bankCountry === "" ||
							preferredBank === "" ||
							!currentAsset
						}
						text={t("bankDepositCreateInvoice") as string}
					/>
					<div className={"divider" + (theme === "dark" ? "-dark" : "")}>
						<Divider
							className={"divider" + (theme === "dark" ? "-dark" : "")}
						/>
					</div>
					<CustomButton
						theme={theme as TTheme}
						isBack={true}
						onClick={handleBackClickButton}
						className={"backButton"}
					/>
				</div>
			</div>
		</>
	);
};

export default BankDeposit;
