import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useTelegram } from "../../../hooks/useTelegram";
import BaseIcon from "../../../icons/base-icon";
import "../../exchange/exchange.css";
import { TItem, TTheme } from "../../../types/types";
import CustomButton from "../../common/button/custom-button";
import { ThemeContext } from "../../../data/theme-provider";
import { FirebaseContext } from "../../../data/firebase-provider";
import { AnalyticsEventsName } from "../../../types/analytics/analytics-events";

interface Props {
	isSuccess: boolean;
	assetFrom: TItem;
	date: string;
	eWallet: string;
	amountFrom: string;
	toWallet: string;
	locale: string;
}

const InternalPayment = (props: Props) => {
	const { sendAnalytics } = useContext(FirebaseContext);
	const { theme } = useContext(ThemeContext);
	const { t } = useTranslation();
	const { tg } = useTelegram();

	const handleCloseClick = () => {
		tg.close();
	};

	const handleSendAnalytics = async () => {
		await sendAnalytics({
			name: AnalyticsEventsName.transferFromOwnWallet,
			params: {
				transfer_amount: props.amountFrom,
				transfer_currency: props.assetFrom.code,
			},
		});
	};

	useEffect(() => {
		handleSendAnalytics();
	}, []);

	if (props.isSuccess) {
		return (
			<div className={"component-clean" + (theme === "dark" ? "-dark" : "")}>
				<div className={"innerComponent"}>
					<div className={"exchangePaymentLogoContainer"}>
						<img
							className={"exchangePaymentLogo"}
							src={"/success-icon.png"}
							alt={"success"}
						/>
					</div>
					<h1
						className={
							"exchangePaymentHeaderText" +
							(theme === "dark" ? "-dark" : "")
						}
					>
						{t("exchangeOperationStatusPayment")}
					</h1>
					<h1
						className={
							"exchangePaymentBottomText" +
							(theme === "dark" ? "-dark" : "")
						}
					>
						{t("exchangeOperationStatusQueue")}
					</h1>
					<div
						className={
							"exchangePaymentFirstInfoContainer" +
							(theme === "dark" ? "-dark" : "")
						}
					>
						<div className={"exchangeAssetContainer"}>
							<h1
								className={
									"exchangeSellText" + (theme === "dark" ? "-dark" : "")
								}
							>
								{t("internalAmountText")}
							</h1>
							<div className={"exchangeAssetBlock"}>
								<h1
									className={
										"exchangeConfirmAmount" +
										(theme === "dark" ? "-dark" : "")
									}
								>
									{Number(props.amountFrom).toFixed(
										props.assetFrom.precision
									)}
								</h1>
								<h1
									className={
										"exchangeConfirmText" +
										(theme === "dark" ? "-dark" : "")
									}
								>
									{props.assetFrom.code.toUpperCase()}
								</h1>
								<BaseIcon
									theme={theme as TTheme}
									path={props.assetFrom.code.toLowerCase()}
									group={props.assetFrom.group.toLowerCase()}
									className={"exchangeConfirmAsset"}
								/>
							</div>
						</div>
					</div>
					<div
						className={
							"exchangePaymentSecondInfoContainer" +
							(theme === "dark" ? "-dark" : "")
						}
					>
						<div className={"exchangeAssetBlock"}>
							<h1
								className={
									"exchangeSellText" + (theme === "dark" ? "-dark" : "")
								}
							>
								{t("exchangeDate")}
							</h1>
							<h1
								className={
									"exchangeConfirmAmount" +
									(theme === "dark" ? "-dark" : "")
								}
							>
								{new Intl.DateTimeFormat(
									props.locale.toLowerCase() +
										"-" +
										props.locale.toUpperCase(),
									{
										day: "2-digit",
										month: "2-digit",
										year: "numeric",
										hour: "2-digit",
										minute: "2-digit",
									}
								)
									.format(
										new Date(props.date).setHours(
											new Date(props.date).getHours() - 3
										)
									)
									.replace("/", ".")
									.replace("/", ".")}
							</h1>
						</div>
						<div
							id={"secondExchangeAssetContainer"}
							className={"exchangeAssetBlock"}
						>
							<h1
								className={
									"exchangeSellText" + (theme === "dark" ? "-dark" : "")
								}
							>
								{t("internalFromText")}
							</h1>
							<h1
								className={
									"exchangeConfirmAmount" +
									(theme === "dark" ? "-dark" : "")
								}
							>
								{props?.eWallet}
							</h1>
						</div>
						<div
							id={"secondExchangeAssetContainer"}
							className={"exchangeAssetBlock"}
						>
							<h1
								className={
									"exchangeSellText" + (theme === "dark" ? "-dark" : "")
								}
							>
								{t("internalToText")}
							</h1>
							<h1
								className={
									"exchangeConfirmAmount" +
									(theme === "dark" ? "-dark" : "")
								}
							>
								{props?.toWallet}
							</h1>
						</div>
					</div>
					<CustomButton
						theme={theme as TTheme}
						onClick={handleCloseClick}
						className={"exchangeConfirmButton"}
						text={t("exchangeCloseButton") as string}
					/>
				</div>
			</div>
		);
	}

	return (
		<div className={"component-clean" + (theme === "dark" ? "-dark" : "")}>
			<div className={"innerComponent"}>
				<div className={"exchangePaymentLogoContainer"}>
					<img
						className={"exchangePaymentLogo"}
						src={"/error-icon.png"}
						alt={"error"}
					/>
				</div>
				<h1
					className={
						"exchangePaymentHeaderText" + (theme === "dark" ? "-dark" : "")
					}
				>
					{t("exchangeErrorOperationHeader")}
				</h1>
				<h1
					className={
						"exchangePaymentBottomText" + (theme === "dark" ? "-dark" : "")
					}
				>
					{t("exchangeErrorOperationBottom")}
				</h1>
				<div
					className={
						"exchangePaymentFirstInfoContainer" +
						(theme === "dark" ? "-dark" : "")
					}
				>
					<div className={"exchangeAssetBlock"}>
						<h1
							className={
								"exchangeSellText" + (theme === "dark" ? "-dark" : "")
							}
						>
							eWallet
						</h1>
						<h1
							className={
								"exchangeConfirmAmount" +
								(theme === "dark" ? "-dark" : "")
							}
						>
							{props?.eWallet}
						</h1>
					</div>
				</div>
				<CustomButton
					theme={theme as TTheme}
					onClick={handleCloseClick}
					className={"exchangeConfirmButton"}
					text={t("exchangeCloseButton") as string}
				/>
			</div>
		</div>
	);
};

export default InternalPayment;
