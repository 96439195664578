import React, {useContext} from 'react';
import "../analytics.css";
import {TFunction} from "i18next";
import {TTheme, TTransaction} from "../../../types/types";
import AnalyticsItemTransactions from "../analytics-item-transactions/analytics-item-transactions";
import {useTranslation} from "react-i18next";
import CopyIcon from "../../../icons/copy-icon";
import {ThemeContext} from "../../../data/theme-provider";

interface Props {
    accountId: string;
    locale: TFunction<"translation", undefined, "translation">;
    data: TTransaction[];
    isLoading: boolean;
    userLocale: string;
    handleCopyClick: () => void;
}
const AnalyticsTransactions = (props: Props) => {
    const {theme} = useContext(ThemeContext);
    const {t} = useTranslation();

    const handleCopyClick = async () => {
        await navigator.clipboard.writeText(props.accountId);
        props.handleCopyClick();
    }

    return (
        <div className={"analyticsTransactionsContainer"}>
            <h1 className={"analyticsTransactionsText" + (theme === 'dark' ? '-dark' : '')}>{t("operationsText")}</h1>
            <div className={"analyticsEWalletLayout" + (theme === 'dark' ? '-dark' : '')} onClick={handleCopyClick}>
                <h1 className={"analyticsEWalletLayoutText" + (theme === 'dark' ? '-dark' : '')}>{props.accountId}</h1>
                <CopyIcon theme={theme as TTheme} className={"bankDepositCopyIcon"}/>
            </div>
            <div className={"analyticsTransactionsList"}>
                {props.data.length > 0 ? (
                        <>
                            {props.data.map((item, idx: number) => <AnalyticsItemTransactions key={item.amount+`_${idx}`} item={item} userLocale={props.userLocale}/>)}
                        </>
                    ) : ''}
            </div>
        </div>
    );
};

export default AnalyticsTransactions;