import React, { createContext, useState } from "react";
import {
	getUserMarginRests,
	getUserRestByAccount,
	getUserRests,
	getUserTransactions,
} from "../api/api";
import { TAllAccountsResponse, TMargin, TRest, TTransaction } from "../types/types";

const updateUserToken = (userToken: string, userLocale: string) => {
    console.log(userToken, userLocale);
    return;
}

export const AnalyticsContext = createContext({
    data: [],
    isTransactionsLoading: false,
    transactions: [],
    marginData: [],
    mainAccountID: "",
    isLoading: false,
    updateUserToken: updateUserToken,
} as {
    data: TRest[],
    isTransactionsLoading: boolean,
    transactions: TTransaction[],
    marginData: TMargin[],
    mainAccountID: string,
    isLoading: boolean,
    updateUserToken: typeof updateUserToken
});


const AnalyticsProvider = ({children}: { children: React.ReactNode }) => {
    const [data, setData] = useState<TRest[]>([]);
    const [transactions, setTransactions] = useState<TTransaction[]>([]);
    const [isTransactionsLoading, setIsTransactionsLoading] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [mainAccountID, setMainAccountID] = useState<string>("");
    const [marginData, setMarginData] = useState<TMargin[]>([]);

    const updateUserToken = async (userToken: string, userLocale: string) => {
        setIsLoading(!isLoading);
        setIsTransactionsLoading(true);
        await initData(userLocale);
    }

    const initData = async (userLocale: string) => {
        const response: TAllAccountsResponse = await getUserRests(sessionStorage.getItem("userToken") as string);
        // eslint-disable-next-line
        const mappedAccountRest: any = {}
        const mappedTransactions: TTransaction[] = [];
        const mappedMarginData: TMargin[] = [];
        for (let i = 0; i < response.length; i++) {
            if(response[i].accTypeName === "Main" || (response[i].accTypeName === "Trading" && response[i].tradingProductCode === "Cash")) {
                const accountRest = await getUserRestByAccount(sessionStorage.getItem("userToken") as string, response[i].accNum);
                for (let j = 0; j < accountRest.accounts.length; j++) {
                    if(accountRest.accounts[j].accTypeName === "Main") {
                        setMainAccountID(accountRest.accounts[j].accNum)
                    }
                    accountRest.accounts[j]?.assets.forEach((currency: TRest) => {
                        if (!mappedAccountRest[currency.code]) {
                            mappedAccountRest[currency.code] = currency
                            return;
                        }
                        mappedAccountRest[currency.code] = {
                            code: currency.code,
                            name: currency.name,
                            group: currency.group,
                            precision: currency.precision,
                            basePrecision: accountRest.basePrecision,
                            available: mappedAccountRest[currency.code].available + currency.available,
                            reserved: mappedAccountRest[currency.code].reserved + currency.reserved,
                            baseAvailable: mappedAccountRest[currency.code].baseAvailable + currency.baseAvailable,
                            baseReserved: mappedAccountRest[currency.code].baseReserved + currency.baseReserved,
                        }
                    })
                }
            } else {
                const marginAccountRest = await getUserMarginRests(sessionStorage.getItem("userToken") as string, response[i].accNum);
                Object.keys(marginAccountRest).forEach((object) => {
                    if(marginAccountRest[object].balance != 0) {
                        mappedMarginData.push({
                            tradingAccKey: response[i].tradingAccKey as number,
                            tradingProductCode: response[i].tradingProductCode as string,
                            accMainAsset: response[i].accMainAsset,
                            leverageName: response[i].leverage?.name,
                            balance: marginAccountRest[object].balance,
                            balanceAsset: marginAccountRest[object].balanceAsset,
                            equity: marginAccountRest[object].equity,
                            margin: marginAccountRest[object].margin,
                            marginLevel: marginAccountRest[object].marginLevel,
                            swap: marginAccountRest[object].swap,
                            pnl: String(Number(marginAccountRest[object].equity) - Number(marginAccountRest[object].balance) - Number(marginAccountRest[object].swap))
                        } as TMargin)
                    }
                })
            }

            setIsLoading(false);
            const objectToArray: TRest[] = [];
            Object.keys(mappedAccountRest).forEach((key) => {
                objectToArray.push(mappedAccountRest[key]);
            })
            setData(objectToArray.sort((firstItem, secondItem) => firstItem.baseAvailable > secondItem.baseAvailable ? -1 : 1));
            setMarginData(mappedMarginData);
        }

        const transactionsResponse = await getUserTransactions(sessionStorage.getItem("userToken") as string, userLocale);
        if(transactionsResponse?.transactions?.length > 0) {
            for (let j = 0; j < transactionsResponse.transactions?.length; j++) {
                const mappedTransaction: TTransaction = {
                    code: transactionsResponse.transactions[j].payInfo.asset.code,
                    date: transactionsResponse.transactions[j].createDate,
                    type: transactionsResponse.transactions[j].payInfo.transactionTypeName,
                    amount: transactionsResponse.transactions[j].amount,
                    status: transactionsResponse.transactions[j].payInfo.statusName,
                    precision: transactionsResponse.transactions[j].payInfo.asset.precision,
                }

                for (let k = 0; k < transactionsResponse.transactions?.length; k++) {
                    if(transactionsResponse.transactions[j].chain === transactionsResponse.transactions[k].chain &&
                        mappedTransaction.to != transactionsResponse.transactions[k].asset
                    ) {
                        mappedTransaction.to = transactionsResponse.transactions[j].asset;
                        mappedTransaction.from = transactionsResponse.transactions[k].asset;
                    }
                }
                mappedTransactions.push(mappedTransaction);
            }
        }
        setTransactions(mappedTransactions);
        setIsTransactionsLoading(false);
        const objToArray: TRest[] = [];
        Object.keys(mappedAccountRest).forEach((key) => {
            objToArray.push(mappedAccountRest[key]);
        })
        setMarginData(mappedMarginData);
        setData(objToArray.sort((firstItem, secondItem) => firstItem.baseAvailable > secondItem.baseAvailable ? -1 : 1));
    }

    return (
        <AnalyticsContext.Provider value={{isTransactionsLoading, marginData, data, transactions, mainAccountID, isLoading,updateUserToken}}>
            {children}
        </AnalyticsContext.Provider>
    );
};

export default AnalyticsProvider;