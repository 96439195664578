import { Backdrop, Fade, Modal } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import OTPModalItem from './item/otp-item';
import { useTelegram } from '../../../hooks/useTelegram';
import { ThemeContext } from '../../../data/theme-provider';
import CustomButton from '../button/custom-button';
import { TTheme } from '../../../types/types';
import CloseIcon from '@mui/icons-material/Close';
import { useTranslation } from 'react-i18next';

interface OTPModalProps {
	isModalOpen: boolean;
	handleIsModalOpen: () => void;
	handleItemClick: (i: { key: string; value: string }) => void;
}

const OTPModal = ({
	isModalOpen,
	handleIsModalOpen,
	handleItemClick
}: OTPModalProps) => {
	const { theme } = useContext(ThemeContext);
	const { tg } = useTelegram();
	const [mappedData, setMappedData] = useState<
		{
			key: string;
			value: string;
		}[]
	>();

	const { t } = useTranslation();

	useEffect(() => {
		setMappedData(
			JSON.parse(sessionStorage.getItem('identityTypes') as string)
		);
		tg.expand();
	}, []);

	const handleModalClose = () => {
		handleIsModalOpen();
	};

	const handleKeyPress = (e: React.KeyboardEvent) => {
		if (e.key === 'Enter' || e.key == 'Escape') {
			handleModalClose();
		}
	};

	return (
		<Modal
			sx={{
				position: 'absolute',
				zIndex: 1300,
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				inset: 0
			}}
			open={isModalOpen}
			onClose={handleModalClose}
			closeAfterTransition
			disableAutoFocus={true}
			onKeyDown={handleKeyPress}
			slots={{ backdrop: Backdrop }}
			slotProps={{
				backdrop: {
					timeout: 500
				}
			}}
		>
			<Fade in={isModalOpen} timeout={200}>
				<div
					className={
						'registrationModalComponent' +
						(theme === 'dark' ? '-dark' : '')
					}
				>
					<div className={'exchange-modal-header'}>
						<div className={'registrationModalHeader'}>
							<h1
								className={
									'registrationModalHeaderText' +
									(theme === 'dark' ? '-dark' : '')
								}
							>
								{t('loginSelectTwoFaHeader')}
							</h1>
							<CustomButton
								theme={theme as TTheme}
								isClose={true}
								onClick={handleModalClose}
								className={'registrationCloseButtonContainer'}
							>
								<CloseIcon
									sx={{
										fill:
											theme === 'dark' ? 'white' : 'black'
									}}
									className={'registrationCloseButton'}
								/>
							</CustomButton>
						</div>
					</div>
					<div className={'modalContent'}>
						{mappedData?.map((item) => (
							<OTPModalItem
								key={item.key}
								handleModalOpen={handleIsModalOpen}
								item={item}
								handleItemClick={handleItemClick}
							/>
						))}
					</div>
				</div>
			</Fade>
		</Modal>
	);
};

export default OTPModal;
