import React, {useContext} from 'react';
import {TMappedBank} from "../../../types/types";
import {ThemeContext} from "../../../data/theme-provider";

interface Props {
    item: TMappedBank
    handleItemClick: (i: string) => void;
    handleModalOpen: () => void;
    setSearchValue: React.Dispatch<React.SetStateAction<string>>;
}
const BankCountryItem = (
    {
        item,
        handleItemClick,
        handleModalOpen,
        setSearchValue
    }: Props
) => {
    const {theme} = useContext(ThemeContext);
    const handleClick = () => {
        handleItemClick(item.countryName);
        handleModalOpen();
        setSearchValue("")
    }

    return (
        <div onClick={handleClick} className={"modalItem"}>
            <div key={item.countryCode} className={"modalItemLogo"}>
                <img key={item.countryCode} className={"modalItemImg"} src={item.countryImg} alt={item.countryCode}/>
                <h1 className={"modalItemLogoText" + (theme === 'dark' ? '-dark' : '')}>{item.countryName}</h1>
            </div>
        </div>
    );
};

export default BankCountryItem;