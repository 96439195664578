import React from 'react';

interface Props {
    className: string;
}
const SwapButton = ({className}: Props) => {
    return (
        <div className={className}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M5.00098 18.1718L5.00098 7.00024C5.00098 6.44796 5.44869 6.00024 6.00098 6.00024C6.55326 6.00024 7.00097 6.44796 7.00097 7.00025L7.00097 18.1718L9.65778 15.515C10.0483 15.1244 10.6815 15.1244 11.072 15.515C11.4625 15.9055 11.4625 16.5386 11.072 16.9291L6.00097 22.0002L0.929864 16.9292C0.53934 16.5386 0.53934 15.9055 0.929865 15.5149C1.32039 15.1244 1.95354 15.1244 2.34407 15.5149L5.00098 18.1718Z" fill="white"/>
                <path d="M18.999 5.82843L18.999 17C18.999 17.5523 18.5513 18 17.999 18C17.4467 18 16.999 17.5523 16.999 17L16.999 5.82843L14.3422 8.48529C13.9517 8.87581 13.3185 8.87582 12.928 8.48529C12.5375 8.09478 12.5375 7.46162 12.928 7.0711L17.999 2L23.0701 7.07109C23.4607 7.46162 23.4607 8.09478 23.0701 8.4853C22.6796 8.87582 22.0465 8.87582 21.6559 8.48531L18.999 5.82843Z" fill="white"/>
            </svg>
        </div>
    );
};

export default SwapButton;