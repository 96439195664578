import React, {useContext, useRef, useState} from 'react';
import {TMargin, TTheme} from "../../../types/types";
import {Divider} from "@mui/material";
import "../analytics.css";
import BaseIcon from "../../../icons/base-icon";
import {useTranslation} from "react-i18next";
import CustomButton from "../../common/button/custom-button";
import {ThemeContext} from "../../../data/theme-provider";
import {Transition} from "react-transition-group";

const defaultStyle = {
    transition: `opacity ${500}ms ease-in-out`,
    opacity: 0,
}

const transitionStyles = {
    entering: { opacity: 1},
    entered:  { opacity: 1},
    exiting:  { opacity: 0},
    exited:  { opacity: 0},
    unmounted: { opacity: 0}
};

interface Props {
    item: TMargin;
}
const AnalyticsMarginItem = (props: Props) => {
    const {theme} = useContext(ThemeContext);
    const {t} = useTranslation()
    const [isExpanded, setIsExpanded] = useState<boolean>(false);
    const transitionRef = useRef(null);

    const handleExpandClick = () => {
        setIsExpanded(!isExpanded);
    }

    const displayMarginLevel = () => {
        if(Number(props.item.marginLevel) >= 100 || Number(props.item.marginLevel) == 0) {
            return "exchangeConfirmAmount" + (theme === 'dark' ? '-dark' : '');
        }
        if(Number(props.item.marginLevel) > 0 && Number(props.item.marginLevel) < 50) {
            return "analyticsMarginLess";
        }
        return "analyticsMarginMedium";
    }

    return (
        <Transition nodeRef={transitionRef} in={isExpanded} timeout={500}>
            {state => (
                <div id={"notFirstAnalyticsMarginItem"} className={"analyticsMarginItem" + (theme === 'dark' ? '-dark' : '')} onClick={handleExpandClick}>
                    <div className={"analyticsMarginHeader"}>
                        <div>
                            <h1 className={"analyticsMarginHeaderText" + (theme === 'dark' ? '-dark' : '')}>Leverage - {props.item.tradingProductCode}</h1>
                            <div className={"analyticsMarginHeaderBottomContainer"}>
                                <BaseIcon theme={theme as TTheme} path={props.item.accMainAsset.toLowerCase()} group={"Crypto"} className={'analyticsMarginImg'}/>
                                <h1 id={"marginLeftAnalyticsText"} className={"analyticsMarginHeaderText" + (theme === 'dark' ? '-dark' : '')}>{props.item.accMainAsset.toUpperCase()}</h1>
                                <h1 className={"analyticsMarginAccNum" + (theme === 'dark' ? '-dark' : '')}>{props.item.tradingAccKey}</h1>
                            </div>
                        </div>
                        <div>
                            <CustomButton theme={theme as TTheme} text={t("showMore") as string} isShowMore={true} onClick={handleExpandClick} className={""} isExpanded={!isExpanded}/>
                        </div>
                    </div>
                    <div className={"analyticsDivider" + (theme === 'dark' ? '-dark' : '')}>
                        <Divider/>
                    </div>
                    <div className={"analyticsNotExpandedMain"}>
                        <div className={"analyticsMainContainer"}>
                            <h1 className={"exchangeSellText" + (theme === 'dark' ? '-dark' : '')}>Equity</h1>
                            <h1 className={"exchangeConfirmAmount" + (theme === 'dark' ? '-dark' : '')}>{Number(props.item.equity).toFixed(props.item.balanceAsset.precision)}</h1>
                        </div>
                        <div className={"analyticsMainContainer"}>
                            <h1 className={"exchangeSellText" + (theme === 'dark' ? '-dark' : '')}>MarginLevel</h1>
                            <h1 className={displayMarginLevel()}>{Number(props.item.marginLevel).toFixed(props.item.balanceAsset.precision)} %</h1>
                        </div>
                    </div>
                    <div style={{
                        ...defaultStyle,
                        ...transitionStyles[state]
                    }} ref={transitionRef}>
                        {isExpanded ? <>
                            <div className={"analyticsMainContainer"}>
                                <h1 className={"exchangeSellText" + (theme === 'dark' ? '-dark' : '')}>Balance</h1>
                                <h1 className={"exchangeConfirmAmount" + (theme === 'dark' ? '-dark' : '')}>{Number(props.item.balance).toFixed(props.item.balanceAsset.precision)}</h1>
                            </div>
                            <div className={"analyticsMainContainer"}>
                                <h1 className={"exchangeSellText" + (theme === 'dark' ? '-dark' : '')}>Margin</h1>
                                <h1 className={"exchangeConfirmAmount" + (theme === 'dark' ? '-dark' : '')}>{Number(props.item.margin).toFixed(props.item.balanceAsset.precision)}</h1>
                            </div>
                            <div className={"analyticsMainContainer"}>
                                <h1 className={"exchangeSellText" + (theme === 'dark' ? '-dark' : '')}>Swap</h1>
                                <h1 className={"exchangeConfirmAmount" + (theme === 'dark' ? '-dark' : '')}>{Number(props.item.swap).toFixed(props.item.balanceAsset.precision)}</h1>
                            </div>
                            <div className={"analyticsMainContainer"}>
                                <h1 className={"exchangeSellText" + (theme === 'dark' ? '-dark' : '')}>PNL</h1>
                                <h1 className={"exchangeConfirmAmount" + (theme === 'dark' ? '-dark' : '')}>{Number(props.item.pnl).toFixed(props.item.balanceAsset.precision)}</h1>
                            </div>
                        </> : ''}
                    </div>

                </div>
            )}
        </Transition>
    )
};

export default AnalyticsMarginItem;