import React from 'react';

const Tails = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="79" height="99" viewBox="0 0 79 99" fill="none">
            <g opacity="0.5">
                <path d="M58.5712 20.2218H39.6719L58.6484 1H77.5496V20.2676L58.5712 39.249V20.2218Z" stroke="white"/>
                <path d="M77.4471 1.08594C71.1561 7.46522 64.8632 13.8445 58.5723 20.2238" stroke="white"/>
                <path d="M30.2647 68.0907H2L30.3802 39.3438H58.6V68.1497L30.2647 96.999V68.0907Z" stroke="white"/>
                <path d="M58.5048 39.3438C49.0964 48.8842 39.6721 58.5506 30.2637 68.0911" stroke="white"/>
                <path d="M68.5861 58.5955H59.2207L68.6243 49.0703H77.9906L77.9993 58.6087L68.5861 68.1958V58.5955Z" stroke="white"/>
                <path d="M77.9392 49.1172C74.8217 52.2784 71.7034 55.4396 68.5859 58.6008" stroke="white"/>
            </g>
        </svg>
    );
};

export default Tails;