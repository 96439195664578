import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "./error-page.css";
import { ThemeContext } from "../../data/theme-provider";
import Background from "../common/background/background";
import { Helmet } from "react-helmet";
import { Titles } from "../../types/titles";

const ErrorPage = () => {
	const { theme } = useContext(ThemeContext);
	const [errorText, setErrorText] = useState("");
	const error = new URLSearchParams(window.location.search).get("code");
	const [isLinkShow, setIsLinkShow] = useState<boolean>(false);

	const { t } = useTranslation();

	useEffect(() => {
		switch (error) {
			case "2fa": {
				const text = t("2faErrorText");
				setErrorText(text);
				setIsLinkShow(true);
				break;
			}
			case "grade": {
				const text = t("gradeErrorText");
				setErrorText(text);
				setIsLinkShow(true);
				break;
			}
			default: {
				const text = t("defaultErrorText");
				setErrorText(text);
				break;
			}
		}
	}, []);

	return (
		<>
			<Helmet>
				<title>{Titles.ERROR.INDEX}</title>
			</Helmet>
			<div>
				<Background />
				<div className={"component" + (theme === "dark" ? "-dark" : "")}>
					<div className={"innerComponent"}>
						<h1 className={"welcomeText" + (theme === "dark" ? "-dark" : "")}>
							{errorText}
						</h1>
						{isLinkShow ? <a href={"my.free2ex.com"}>my.free2ex.com</a> : ""}
					</div>
				</div>
			</div>
		</>
	);
};

export default ErrorPage;
