import React, {ChangeEvent, useContext, useEffect, useState} from 'react';
import {Backdrop, Fade, Modal} from "@mui/material";
import {useTranslation} from "react-i18next";
import CustomInput from "../../common/input/custom-input";
import CountryModalItem from "./country-modal-item";
import CloseIcon from '@mui/icons-material/Close';
import {useTelegram} from "../../../hooks/useTelegram";
import {TCountry, TTheme} from "../../../types/types";
import CustomButton from "../../common/button/custom-button";
import { ThemeContext } from '../../../data/theme-provider';
import {Whale} from "../../../icons/whale";

interface Props {
    isModalOpen: boolean;
    handleIsModalOpen: () => void;
    handleItemClick: (i: {
        code: string;
        imgUrl: string;
    }) => void;
    isVerificationSelect?: boolean;
    countries: TCountry[];
}
const CountryModal = (
    {
        isModalOpen,
        handleIsModalOpen,
        handleItemClick,
        isVerificationSelect,
        countries
    }: Props
) => {
    const {theme} = useContext(ThemeContext);
    const {tg} = useTelegram();
    const {t} = useTranslation();
    const [searchValue, setSearchValue] = useState<string>("");
    const [isRequired, setIsRequired] = useState<boolean>(false);
    const [mappedData, setMappedData] = useState<{
        code: string,
        name: string,
        link: string,
        phoneCode: string,
        isRestricted: boolean
    }[]>();

    useEffect(() => {
        setMappedData(countries);
        tg.expand();
    }, []);

    const handleCloseModal = () => {
        handleIsModalOpen();
        setSearchValue("");
    }

    useEffect(() => {
        setMappedData(countries.filter((item: {
                code: string,
                name: string,
                link: string,
                phoneCode: string,
                isRestricted: boolean
            }) => item.code.toLowerCase().includes(searchValue.toLowerCase()) ||
            item.name.toLowerCase().includes(searchValue.toLowerCase()) ||
            item.phoneCode.toLowerCase().includes(searchValue.toLowerCase()))
        );
    },[searchValue]);

    const handleSearchValueChange = (e: ChangeEvent<HTMLInputElement>) => {
        setSearchValue(e.target.value);
        e.target.value.length >= 1 ? setIsRequired(true) : setIsRequired(false);
    }

    const handleKeyPress = (e: React.KeyboardEvent) => {
        if(e.key === "Enter" || e.key == "Escape") {
            handleCloseModal();
        }
    }

    return (
        <Modal
            open={isModalOpen}
            onClose={handleCloseModal}
            closeAfterTransition
            disableAutoFocus={true}
            onKeyDown={handleKeyPress}
            slots={{ backdrop: Backdrop }}
            slotProps={{
                backdrop: {
                    timeout: 500,
                },
            }}
        >
            <Fade in={isModalOpen} timeout={200}>
                <div className={"registrationModalComponent" + (theme === 'dark' ? '-dark' : '')}>
                    <div className={"exchange-modal-header"}>
                        <div className={"registrationModalHeader"}>
                            <h1 className={"registrationModalHeaderText" + (theme === 'dark' ? '-dark' : '')}>{t("registrationChooseCountry")}</h1>
                            <CustomButton theme={theme as TTheme} isClose={true} onClick={handleCloseModal} className={'registrationCloseButtonContainer'}>
                                <CloseIcon sx={{
                                    fill: theme === 'dark' ? 'white' : 'black'
                                }} className={"registrationCloseButton"}/>
                            </CustomButton>
                        </div>
                        <CustomInput
                            theme={theme as TTheme}
                            className={"registrationModalInput"}
                            id={"registrationModalInput"}
                            value={searchValue}
                            label={t("registrationSearch")}
                            isRequired={isRequired}
                            type={"text"}
                            onChange={handleSearchValueChange}
                            searchInput={true}
                        />
                    </div>
                    {mappedData && mappedData?.length > 0 ? <div className={"registrationModalContent"}>
                        {mappedData?.map((item) => (
                            <CountryModalItem isVerificationSelect={isVerificationSelect} key={item.code} handleModalOpen={handleIsModalOpen} item={item} handleItemClick={handleItemClick} setSearchValue={setSearchValue}/>
                        ))}
                    </div> : <div className={"exchangeModalContent-empty"}>
                        <div className={"exchangeModalEmptyContainer"}>
                            <Whale className={'exchangeWhale'}/>
                            <h1 className={'exchangeModalEmptyHeader' + (theme === 'dark' ? '-dark' : '')}>{t("exchangeNotFound")}</h1>
                            <h1 className={"exchangeModalEmptyBottom" + (theme === 'dark' ? '-dark' : '')}>{t("exchangeChangeSettingsText")}</h1>
                        </div>
                    </div>}
                </div>
            </Fade>
        </Modal>
    );
};

export default CountryModal;