import { Divider } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { usePinInput } from 'react-pin-input-hook';
import { useNavigate } from 'react-router-dom';
import { loginWith2FA, refetchLoginOTP } from '../../../api/api';
import { FirebaseContext } from '../../../data/firebase-provider';
import { ThemeContext } from '../../../data/theme-provider';
import { useTelegram } from '../../../hooks/useTelegram';
import { TTheme } from '../../../types/types';
import CustomButton from '../../common/button/custom-button';
import PinInput from '../../common/input/pin-input';

interface Props {
	handleBackClick(): void;
	fakeContact: string;
	setIsRedirectPageOpen(isOpen: boolean): void;
	isRedirectPageOpen: boolean;
	login: string;
	password: string;
	state: string;
	setError: React.Dispatch<boolean>;
	isError: boolean;
	userToken: string;
	userLocale: string;
	maxExpSec: string;
	isEnablingMfCode?: boolean;
	accessToken?: string;
	secretKey?: string;
}
const Otp = ({
	handleBackClick,
	fakeContact,
	state,
	isError,
	setError,
	password,
	login,
	userToken,
	userLocale,
	isRedirectPageOpen,
	setIsRedirectPageOpen,
	maxExpSec
}: Props) => {
	const { theme, updateTheme } = useContext(ThemeContext);

	const [sessionIdentityType, setSessionIdentityType] = useState<string>('');

	const { tg } = useTelegram();
	const { initializeFirebase, sendAnalytics } = useContext(FirebaseContext);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const navigate = useNavigate();
	const [errorText, setErrorText] = useState<string>('');

	const [isResendCodeActive, setIsResendCodeActive] =
		useState<boolean>(false);
	const [counter, setCounter] = useState<number>(
		Number(sessionStorage.getItem('resendIn'))
	);

	const [values, setValues] = useState<string[]>(['', '', '', '', '', '']);
	const { fields } = usePinInput({
		values,
		onChange: (values: string[]) => {
			setValues(values);
		},
		onComplete: async (values: string) => {
			if (values.length == 6) {
				await loginWith2FA(
					login,
					password,
					state,
					setError,
					isError,
					values,
					setValues,
					setIsRedirectPageOpen,
					isRedirectPageOpen,
					handleBackClick,
					userToken,
					navigate,
					setIsLoading,
					userLocale,
					maxExpSec,
					tg,
					initializeFirebase,
					sendAnalytics,
					setErrorText,
					sessionStorage.getItem('loginIdentityType') as string
				);
			}
		},
		type: 'alphanumeric',
		placeholder: ''
	});

	const { t } = useTranslation();

	useEffect(() => {
		if (counter == 0) {
			setIsResendCodeActive(true);
			setCounter(Number(sessionStorage.getItem('resendIn')));
		}
		const timer =
			counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		return () => clearInterval(timer as any);
	}, [counter]);

	useEffect(() => {
		setSessionIdentityType(
			sessionStorage.getItem('loginIdentityType') as string
		);
		updateTheme();
	}, []);

	const handleResendCode = async () => {
		setIsResendCodeActive(false);
		await refetchLoginOTP(state, sessionIdentityType);
	};

	return (
		<div className={'component-clean' + (theme === 'dark' ? '-dark' : '')}>
			<div className={'innerComponent'}>
				<h1
					className={
						'confirmEmailText' + (theme === 'dark' ? '-dark' : '')
					}
				>
					{t('authenticationText')}
				</h1>
				<h1
					className={
						'confirmCodeText' + (theme === 'dark' ? '-dark' : '')
					}
				>
					{t(
						sessionIdentityType === 'Email'
							? 'otpCodeEmailDescription'
							: 'otpCodePhoneDescription'
					) + fakeContact}
				</h1>
				<div className={'codeInputBlock'}>
					<PinInput fields={fields} />
					{isError ? (
						<h1 className={'loginErrorText'}>
							{errorText === ''
								? t('mfCodeErrorText')
								: t('loginNotCompleteRegistration')}
						</h1>
					) : (
						<div className={'registrationEmailError'} />
					)}
					{isResendCodeActive ? (
						<h1
							className={'resendCodeText'}
							onClick={handleResendCode}
						>
							{t('registrationResend')}
						</h1>
					) : (
						<h1 className={'resendCodeText-inactive'}>
							{t('registrationResendIn')} {counter}
						</h1>
					)}
				</div>
				<div className={'divider' + (theme === 'dark' ? '-dark' : '')}>
					<Divider
						className={
							'divider' + (theme === 'dark' ? '-dark' : '')
						}
					/>
				</div>
				<CustomButton
					isLoading={isLoading}
					theme={theme as TTheme}
					isBack={true}
					onClick={handleBackClick}
					className={'backButton'}
				/>
			</div>
		</div>
	);
};

export default Otp;
