import React, {useContext, useEffect, useState} from 'react';
import "../analytics.css";
import {AnalyticsContext} from "../../../data/analytics-provider";
import {TRest} from "../../../types/types";
import {useTranslation} from "react-i18next";
import {ThemeContext} from "../../../data/theme-provider";

interface Props {
    data: TRest[];
}
const AnalyticsTotalCard = (props: Props) => {
    const {theme} = useContext(ThemeContext);
    const {data} = useContext(AnalyticsContext);
    const [totalAmount, setTotalAmount] = useState<number>(0);
    const [totalAvailableAmount, setTotalAvailableAmount] = useState<number>(0);
    const [isEqual, setIsEqual] = useState<boolean>(false);
    const {t} = useTranslation();

    useEffect(() => {
        if(props.data.length == 0) {
            return;
        }
        let totalAmount = 0;
        let totalAvailableAmount = 0;
        for (let i = 0; i < props.data.length; i++) {
            totalAmount += props.data[i].baseAvailable + props.data[i].baseReserved;
            totalAvailableAmount += props.data[i].baseAvailable;
        }
        if(totalAmount == totalAvailableAmount) {
            setIsEqual(true);
        }
        setTotalAmount(Number(totalAmount?.toFixed(4)));
        setTotalAvailableAmount(Number(totalAvailableAmount?.toFixed(4)))
    },[data])

    return (
        <>
            {isEqual ? <>
                <h1 className={"analyticsLogoText" + (theme === 'dark' ? '-dark' : '')}>{t("accountBalance")}</h1>
                <div className={"analyticsTotalContainer"}>
                    <h1 className={"analyticsTotalAmount" + (theme === 'dark' ? '-dark' : '')}>~ {totalAmount
                        .toLocaleString('ru-RU', {minimumFractionDigits: 2, maximumFractionDigits: 4})
                        .replace(",", ".")}
                    </h1>
                    <h1 className={"analyticsTotalAmountCurrency" + (theme === 'dark' ? '-dark' : '')}>
                        USDT
                    </h1>
                </div>
                <div className={"analyticsEqualContainer"}>
                    <h1 className={"analyticsEqualText" + (theme === 'dark' ? '-dark' : '')}>{t("accountBlocked")}</h1>
                    <h1 className={"analyticsEqualAmount" + (theme === 'dark' ? '-dark' : '')}>~ {totalAvailableAmount
                        .toLocaleString('ru-RU', {minimumFractionDigits: 2, maximumFractionDigits: 4})
                        .replace(",", ".")} USDT</h1>
                </div>
            </>  : <>
                <h1 className={"analyticsLogoText" + (theme === 'dark' ? '-dark' : '')}>{t("accountBalance")}</h1>
                <div className={"analyticsTotalContainer"}>
                    <h1 className={"analyticsTotalAmount" + (theme === 'dark' ? '-dark' : '')}>~ {totalAmount
                        .toLocaleString('ru-RU', {minimumFractionDigits: 2, maximumFractionDigits: 4})
                        .replace(",", ".")}
                    </h1>
                    <h1 className={"analyticsTotalAmountCurrency" + (theme === 'dark' ? '-dark' : '')}>
                        USDT
                    </h1>
                </div>
                <div className={"analyticsEqualContainer"}>
                    <h1 className={"analyticsEqualText" + (theme === 'dark' ? '-dark' : '')}>{t("accountBlocked")}</h1>
                    <h1 className={"analyticsEqualAmount" + (theme === 'dark' ? '-dark' : '')}>~ {totalAvailableAmount
                        .toLocaleString('ru-RU', {minimumFractionDigits: 2, maximumFractionDigits: 4})
                        .replace(",", ".")} USDT</h1>
                </div>
            </>}
        </>
    );
};

export default AnalyticsTotalCard;