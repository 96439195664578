import React, { MutableRefObject } from "react";
import { Button, CircularProgress, styled } from "@mui/material";
import { West } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { TTheme } from "../../../types/types";
import { TStyledButtonProps } from "../../../types/styled/types";

const mapButtonSxByType = ({
	isDarkMode,
	isExchangeBackButton,
	isClose,
	isSelectAsset,
	isText,
	isBack,
	isShowMore,
	isSwapButton,
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
TStyledButtonProps): any => {
	const defaultSx = {
		textTransform: "unset !important",
		fontFamily: "Noto Sans !important",
		fontWeight: "400 !important",
		fontSize: "16px !important",
		fontStyle: "normal !important",
		lineHeight: "24px !important",
		height: "56px !important",
		borderRadius: "12px !important",
	};
	if (isExchangeBackButton) {
		return Object.assign(defaultSx, {
			"&.Mui-disabled": {
				background: "#EBECEE !important",
			},
			background:
				isDarkMode === "dark" ? "#303030 !important" : "#EBECEE !important",
			color: isDarkMode === "dark" ? "#FEFEFE !important" : "#181A21 !important",
		});
	}
	if (isClose) {
		return Object.assign(defaultSx, {
			"&.Mui-disabled": {
				background: "#F6F6F8 !important",
			},
			background:
				isDarkMode === "dark" ? "#1F1F1F !important" : "#F6F6F8 !important",
			borderRadius: "50% !important",
			minWidth: "42px !important",
			width: "42px !important",
			height: "42px !important",
			padding: "1px 1px 1px 1px !important",
		});
	}
	if (isSwapButton) {
		return Object.assign(defaultSx, {
			"&.Mui-disabled": {
				background:
					isDarkMode === "dark" ? "#303030 !important" : "#EBECEE !important",
			},
			"&:hover": {
				background: "#00BEB4",
			},
			"&:active": {
				background: "#00BEB4",
			},
			"&:focus": {
				background: "#00BEB4",
			},
			background: "#00BEB4",
			borderRadius: "50% !important",
			minWidth: "48px !important",
			width: "48px !important",
			height: "48px !important",
			padding: "1px 1px 1px 1px !important",
		});
	}
	if (isSelectAsset) {
		return Object.assign(defaultSx, {
			"&.Mui-disabled": {
				background: "#EBECEE !important",
			},
			background:
				isDarkMode === "dark" ? "#1F1F1F !important" : "#F6F6F8 !important",
			color: isDarkMode === "dark" ? "#FEFEFE !important" : "#848790 !important",
			fontSize: "14px !important",
			lineHeight: "20px !important",
			height: "36px !important",
			padding: "1px 3px 1px 3px !important",
		});
	}
	if (isShowMore) {
		return Object.assign(defaultSx, {
			"&.Mui-disabled": {
				background:
					isDarkMode === "dark" ? "#9C9C9D !important" : "#EBECEE !important",
			},
			background:
				isDarkMode === "dark" ? "#1F1F1F !important" : "#F6F6F8 !important",
			color: isDarkMode === "dark" ? "#9C9C9D !important" : "#848790 !important",
			fontSize: "14px !important",
			lineHeight: "20px !important",
			height: "46px !important",
		});
	}
	if (isText) {
		return Object.assign(defaultSx, {
			fontWeight: "600 !important",
			height: "60px !important",
			textAlign: "center",
			color: "#00BEB4",
		});
	}
	if (isBack) {
		return Object.assign(defaultSx, {
			textAlign: "center",
			color: "#00BEB4",
		});
	}
	return Object.assign(defaultSx, {
		"&.Mui-disabled": {
			background:
				isDarkMode === "dark" ? "#303030 !important" : "#EBECEE !important",
			color: isDarkMode === "dark" ? "#5F5F5F !important" : "#BFC1C5 !important",
		},
		"&:hover": {
			background: "#00BEB4",
		},
		"&:active": {
			background: "#00BEB4",
		},
		"&:focus": {
			background: "#00BEB4",
		},
		background: "#00BEB4",
		color: isDarkMode === "dark" ? "#FFF !important" : "#FFF !important",
	});
};

const StyledButton = styled(Button, {
	shouldForwardProp: (prop) => prop !== "isDarkMode",
})<TStyledButtonProps>(
	({
		isDarkMode,
		isExchangeBackButton,
		isClose,
		isSelectAsset,
		isBack,
		isText,
		isShowMore,
		isSwapButton,
	}) =>
		mapButtonSxByType({
			isDarkMode,
			isExchangeBackButton,
			isClose,
			isSelectAsset,
			isText,
			isBack,
			isShowMore,
			isSwapButton,
		})
);

interface Props {
	isLoading?: boolean;
	isDisabled?: boolean;
	text?: string;
	type?: "button" | "submit" | "reset";
	onClick: () => void;
	className: string;
	isBack?: boolean;
	isText?: boolean;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	buttonRef?: MutableRefObject<any>;
	isShowMore?: boolean;
	isExpanded?: boolean;
	isSelectAsset?: boolean;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	children?: any;
	isSwapButton?: boolean;
	isClose?: boolean;
	isExchangeBackButton?: boolean;
	theme?: TTheme;
	themeNumber?: boolean;
}
const CustomButton = ({
	isLoading,
	isDisabled,
	text,
	type,
	onClick,
	className,
	isBack,
	isText,
	buttonRef,
	isShowMore,
	isExpanded,
	isSelectAsset,
	children,
	isSwapButton,
	isClose,
	isExchangeBackButton,
	theme,
}: Props) => {
	const { t } = useTranslation();

	if (isExchangeBackButton) {
		return (
			<div className={className}>
				<StyledButton
					isExchangeBackButton
					fullWidth
					isDarkMode={theme as TTheme}
					ref={buttonRef}
					disableElevation
					type="submit"
					variant={"contained"}
					disabled={isDisabled}
					onClick={onClick}
				>
					{text}
				</StyledButton>
			</div>
		);
	}

	if (isClose) {
		return (
			<div className={className}>
				<StyledButton
					isClose
					fullWidth
					isDarkMode={theme as TTheme}
					TouchRippleProps={{
						classes: {
							child: "analyticsShowMoreButtonLayout",
						},
					}}
					className={""}
					disabled={isDisabled}
					ref={buttonRef}
					disableElevation
					type="submit"
					variant={"contained"}
					onClick={onClick}
				>
					{children}
				</StyledButton>
			</div>
		);
	}

	if (isSwapButton) {
		return (
			<div className={className}>
				<StyledButton
					isSwapButton
					fullWidth
					isDarkMode={theme as TTheme}
					TouchRippleProps={{
						classes: {
							child: "analyticsShowMoreButtonLayout",
						},
					}}
					className={""}
					disabled={isDisabled}
					ref={buttonRef}
					disableElevation
					type="submit"
					variant={"contained"}
					onClick={onClick}
				>
					{children}
				</StyledButton>
			</div>
		);
	}

	if (isSelectAsset) {
		return (
			<div className={className}>
				<StyledButton
					isSelectAsset
					fullWidth
					isDarkMode={theme as TTheme}
					TouchRippleProps={{
						classes: {
							child: "analyticsShowMoreButtonLayout",
						},
					}}
					className={className}
					ref={buttonRef}
					disableElevation
					type="submit"
					variant={"contained"}
					onClick={onClick}
				>
					{children}
				</StyledButton>
			</div>
		);
	}

	if (isShowMore) {
		return (
			<div className={className}>
				<StyledButton
					isShowMore
					fullWidth
					isDarkMode={theme as TTheme}
					TouchRippleProps={{
						classes: {
							child: "analyticsShowMoreButtonLayout",
						},
					}}
					ref={buttonRef}
					disableElevation
					type="submit"
					variant={"contained"}
					onClick={onClick}
					endIcon={
						isExpanded ? (
							<KeyboardArrowDownIcon
								sx={{
									color: theme === "dark" ? "#9C9C9D" : "#848790",
								}}
							/>
						) : (
							<KeyboardArrowUpIcon
								sx={{
									color: theme === "dark" ? "#9C9C9D" : "#848790",
								}}
							/>
						)
					}
				>
					{text}
				</StyledButton>
			</div>
		);
	}

	if (isText) {
		return (
			<div className={className}>
				<StyledButton
					isText
					isDarkMode={theme as TTheme}
					disableElevation
					variant={"text"}
					fullWidth
					onClick={onClick}
				>
					{text}
				</StyledButton>
			</div>
		);
	}

	if (isBack) {
		return (
			<div className={className}>
				<StyledButton
					isBack
					isDarkMode={theme as TTheme}
					disableElevation
					variant={"text"}
					fullWidth
					onClick={onClick}
					startIcon={<West />}
				>
					{t("returnText")}
				</StyledButton>
			</div>
		);
	}

	return (
		<div className={className}>
			<StyledButton
				isDarkMode={theme as TTheme}
				ref={buttonRef}
				fullWidth
				endIcon={
					isLoading ? (
						<CircularProgress
							sx={{
								".MuiCircularProgress-circle": {
									stroke: "#FFFFFF",
								},
							}}
							thickness={5}
							size={15}
						/>
					) : (
						""
					)
				}
				disableElevation
				type={type}
				variant={"contained"}
				disabled={isDisabled}
				onClick={onClick}
			>
				{text}
			</StyledButton>
		</div>
	);
};

export default CustomButton;
