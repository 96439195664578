import { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import CustomButton from "../../common/button/custom-button";
import CustomInput from "../../common/input/custom-input";
import OTPModal from "../../common/modal/otp-modal";
import { ThemeContext } from "../../../data/theme-provider";
import { TTheme } from "../../../types/types";
import { Divider } from "@mui/material";

interface Props {
	handleBackClick(): void;
	handleGASelect(): void;
	handleSelect(identityType: string): void;
	isError?: boolean;
}
const SelectBankTwoFa = ({ handleBackClick, handleGASelect, handleSelect }: Props) => {
	const { t } = useTranslation();

	const { theme, updateTheme } = useContext(ThemeContext);

	const buttonRef = useRef(null);

	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

	const [otp, setOTP] = useState({ key: "", value: "" });

	const handleOTPSelect = () => {
		setIsModalOpen(!isModalOpen);
	};

	const handleSelectClick = () => {
		setIsModalOpen(!isModalOpen);
	};

	const handleOTPChange = (value: { key: string; value: string }) => {
		setOTP({ key: value.key, value: value.value });
	};

	const handleLogin = () => {
		if (otp.key === "GoogleAuthenticator") {
			handleGASelect();
		} else {
			sessionStorage.setItem("loginIdentityType", otp.key);
			handleSelect(otp.key);
		}
	};

	useEffect(() => {
		updateTheme();
	}, []);

	return (
		<>
			<div>
				<div className={"component-clean" + (theme === "dark" ? "-dark" : "")}>
					<div className={"innerComponent"}>
						<OTPModal
							isModalOpen={isModalOpen}
							handleIsModalOpen={handleOTPSelect}
							handleItemClick={handleOTPChange}
						/>
						<h1 className={"welcomeText" + (theme === "dark" ? "-dark" : "")}>
							{t("loginSelectTwoFaHeader")}
						</h1>
						<h1
							className={
								"confirmCodeText" + (theme === "dark" ? "-dark" : "")
							}
						>
							{t("loginSelectTwoFaMain")}
						</h1>
						<CustomInput
							theme={theme as TTheme}
							isDisabled={false}
							isRequired={otp.key === "" ? false : true}
							type={"text"}
							id={"otpSelect"}
							label={
								otp.key === ""
									? t("loginSelectTwoFaHeader")
									: t("otpHeader")
							}
							value={otp.key}
							onClearClick={handleSelectClick}
							className={"otpSelect"}
							isSelectOtpCode={true}
							imgUrl={""}
						/>
						{otp.key === "FIDO2" ? (
							<h1 className={"twofaWrongOption"}>
								{t("TWOFASelectWrongOption")}
							</h1>
						) : (
							""
						)}
						<CustomButton
							theme={theme as TTheme}
							buttonRef={buttonRef}
							isLoading={false}
							text={t("registrationStart") as string}
							type={"submit"}
							onClick={handleLogin}
							className={"loginButton"}
							isDisabled={otp.key === "" || otp.key === "FIDO2"}
						/>
						<div className={"divider" + (theme === "dark" ? "-dark" : "")}>
							<Divider
								className={"divider" + (theme === "dark" ? "-dark" : "")}
							/>
						</div>
						<CustomButton
							theme={theme as TTheme}
							isBack={true}
							onClick={handleBackClick}
							className={"backButton"}
						/>
					</div>
				</div>
			</div>
		</>
	);
};

export default SelectBankTwoFa;
