import React, { useContext, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { ThemeContext } from "../../../data/theme-provider";
import { PinInputFieldProps, usePinInput } from "react-pin-input-hook";
import { confirmBankWithdrawTwoFactor } from "../../../api/api";
import PinInput from "../../common/input/pin-input";
import CustomButton from "../../common/button/custom-button";
import { TTheme } from "../../../types/types";
import { Divider } from "@mui/material";
import BankSummary from "../bank-summary/bank-summary";

interface Props {
	currencyCode: string;
	iban: string;
	handleBackClick: () => void;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	currentAsset: any;
	eWallet: string;
	amount: string;
	bankName: string;
	bankCode: string;
	bankCountry: string;
	taxIdentificationNumber: string;
	paymentSystem: string;
	intermediaryAccount: string;
	intermediaryBankName: string;
	intermediaryBankCode: string;
	state: string;
}
const BankTwoFactor = ({
	currencyCode,
	bankCountry,
	bankName,
	bankCode,
	intermediaryBankCode,
	intermediaryBankName,
	intermediaryAccount,
	taxIdentificationNumber,
	paymentSystem,
	state,
	currentAsset,
	iban,
	amount,
	eWallet,
	handleBackClick,
}: Props) => {
	const { t } = useTranslation();
	const { theme } = useContext(ThemeContext);

	const [values, setValues] = useState<string[]>(["", "", "", "", "", ""]);
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const inputPropsRef = useRef<any>();
	const { fields } = usePinInput({
		values,
		onChange: (values: string[]) => {
			setValues(values);
			if (values.join("").length == 6) {
				inputPropsRef.current.focus();
			}
		},
		onComplete: () => {
			if (values.join("").toString().length == 6) {
				inputPropsRef.current.focus();
			}
		},
		placeholder: "",
	});
	const [isError, setIsError] = useState<boolean>(false);
	const [errorText, setErrorText] = useState<string>("");
	const [isButtonLoading, setIsButtonLoading] = useState<boolean>(false);

	const [isSummary, setIsSummary] = useState<boolean>(false);

	const handleSubmitClick = async () => {
		setIsError(false);
		setErrorText("");
		await confirmBankWithdrawTwoFactor(
			sessionStorage.getItem("userToken") as string,
			currencyCode,
			iban,
			amount,
			bankName,
			bankCode,
			bankCountry,
			eWallet,
			taxIdentificationNumber,
			intermediaryAccount,
			intermediaryBankName,
			intermediaryBankCode,
			values.join("").toString(),
			state,
			paymentSystem,
			setIsError,
			setIsButtonLoading,
			setIsSummary,
			setErrorText,
			'GoogleAuthenticator'
		);
	};

	if (isSummary) {
		return (
			<BankSummary
				currentAsset={currentAsset}
				withdrawFee={"0"}
				withdrawAmount={amount}
				accountName={iban}
				bankName={bankName}
			/>
		);
	}

	return (
		<div className={"component-clean" + (theme === "dark" ? "-dark" : "")}>
			<div className={"innerComponent"}>
				<h1 className={"confirmEmailText" + (theme === "dark" ? "-dark" : "")}>
					{t("authenticationText")}
				</h1>
				<h1 className={"confirmCodeText" + (theme === "dark" ? "-dark" : "")}>
					{t("mfCodeDescription")}
				</h1>
				<div className={"codeInputBlock"}>
					<PinInput fields={fields} />
				</div>
				{isError ? (
					<h1 className={"loginErrorText"}>
						{errorText ? errorText : t("mfCodeErrorText")}
					</h1>
				) : (
					<div className={"registrationEmailError"} />
				)}
				<CustomButton
					buttonRef={inputPropsRef}
					isLoading={isButtonLoading}
					text={t("exchangeSubmitButton") as string}
					type={"submit"}
					onClick={handleSubmitClick}
					className={isError ? "loginButtonError" : "loginButton"}
					isDisabled={
						fields.map((field: PinInputFieldProps) => field.value).join("")
							.length < 6
					}
					theme={theme as TTheme}
				/>
				<div className={"divider" + (theme === "dark" ? "-dark" : "")}>
					<Divider className={"divider" + (theme === "dark" ? "-dark" : "")} />
				</div>
				<CustomButton
					theme={theme as TTheme}
					isBack={true}
					onClick={handleBackClick}
					className={"backButton"}
				/>
			</div>
		</div>
	);
};

export default BankTwoFactor;
