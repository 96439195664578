import React, { useContext } from "react";
import BaseIcon from "../../../icons/base-icon";
import { Divider } from "@mui/material";
import CustomButton from "../../common/button/custom-button";
import { useTranslation } from "react-i18next";
import { TExchangeItem, TTheme } from "../../../types/types";
import { ThemeContext } from "../../../data/theme-provider";

interface Props {
	selectedFromItem: TExchangeItem;
	selectedToItem: TExchangeItem;
	fromValue: string;
	toValue: string;
	rate: string;
	setIsSubmitModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
	handleSubmit: () => void;
	isLoading: boolean;
}
const ExchangeConfirmPage = ({
	selectedToItem,
	selectedFromItem,
	fromValue,
	toValue,
	rate,
	setIsSubmitModalOpen,
	handleSubmit,
	isLoading,
}: Props) => {
	const { theme } = useContext(ThemeContext);
	const { t } = useTranslation();

	return (
		<div className={"component-clean" + (theme === "dark" ? "-dark" : "")}>
			<div className={"innerComponent"}>
				<h1 className={"exchangeWelcomeText" + (theme === "dark" ? "-dark" : "")}>
					{t("exchangeConfirmationText")}
				</h1>
				<div
					className={
						"exchangeConfirmContainer" + (theme === "dark" ? "-dark" : "")
					}
				>
					<div className={"exchangeConfirmFirstBlock"}>
						<h1
							className={
								"exchangeSellText" + (theme === "dark" ? "-dark" : "")
							}
						>
							{t("exchangeSellText")}
						</h1>
						<div className={"exchangeAssetBlock"}>
							<h1
								className={
									"exchangeConfirmAmount" +
									(theme === "dark" ? "-dark" : "")
								}
							>
								{fromValue}
							</h1>
							<h1
								className={
									"exchangeConfirmText" +
									(theme === "dark" ? "-dark" : "")
								}
							>
								{selectedFromItem.code.toUpperCase()}
							</h1>
							<BaseIcon
								path={selectedFromItem.code.toLowerCase()}
								group={selectedFromItem.group.toLowerCase()}
								className={"exchangeConfirmAsset"}
							/>
						</div>
					</div>
					<div className={"exchangeConfirmSecondBlock"}>
						<h1
							className={
								"exchangeSellText" + (theme === "dark" ? "-dark" : "")
							}
						>
							{t("exchangeBuyText")}
						</h1>
						<div className={"exchangeAssetBlock"}>
							<span
								className={
									"spanAsymp" + (theme === "dark" ? "-dark" : "")
								}
							>
								&asymp;
							</span>
							<h1
								className={
									"exchangeConfirmAmount" +
									(theme === "dark" ? "-dark" : "")
								}
							>
								{" "}
								{toValue}
							</h1>
							<h1
								className={
									"exchangeConfirmText" +
									(theme === "dark" ? "-dark" : "")
								}
							>
								{selectedToItem.code.toUpperCase()}
							</h1>
							<BaseIcon
								path={selectedToItem.code.toLowerCase()}
								group={selectedToItem.group.toLowerCase()}
								className={"exchangeConfirmAsset"}
							/>
						</div>
					</div>
					<div
						className={"exchangeDivider" + (theme === "dark" ? "-dark" : "")}
					>
						<Divider />
					</div>
					<div className={"exchangeConfirmThirdBlock"}>
						<h1
							className={
								"exchangeSellText" + (theme === "dark" ? "-dark" : "")
							}
						>
							{t("exchangePriceText")}
						</h1>
						<h1
							className={
								"exchangeConfirmTextRate" + (theme === "dark" ? "-dark" : "")
							}
						>
							{rate}
						</h1>
					</div>
					<h1
						className={
							"exchangeConfirmFourthBlock" +
							(theme === "dark" ? "-dark" : "")
						}
					>
						{t("exchangeWarningRateText")}
					</h1>
				</div>
				<CustomButton
					theme={theme as TTheme}
					onClick={handleSubmit}
					isLoading={isLoading}
					text={t("exchangeSubmitButton") as string}
					className={"exchangeConfirmButton"}
				/>
				<CustomButton
					theme={theme as TTheme}
					isExchangeBackButton={true}
					onClick={() => setIsSubmitModalOpen(false)}
					className={"exchangeConfirmButton"}
					text={t("exchangeDiscardButton") as string}
				/>
			</div>
		</div>
	);
};

export default ExchangeConfirmPage;
