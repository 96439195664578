import { useContext, useEffect, useRef, useState } from "react";
import { ThemeContext } from "../../../data/theme-provider";
import { usePinInput } from "react-pin-input-hook";
import { useTranslation } from "react-i18next";
import { TMappedAsset, TTheme } from "../../../types/types";
import CustomButton from "../../common/button/custom-button";
import PinInput from "../../common/input/pin-input";
import { Divider } from "@mui/material";
import { confirmTwoFactorCryptoWithdraw, refetchLoginOTP } from "../../../api/api";
import { FirebaseContext } from "../../../data/firebase-provider";

interface Props {
	handleSubmit: () => void;
	handleBackClick(): void;
	fakeContact: string;
	selectedAsset: TMappedAsset;
	address: string;
	amount: string;
	eWallet: string;
	state: string;
	setChainId: React.Dispatch<React.SetStateAction<string>>;
}
const CryptoOtp = ({
	handleSubmit,
	handleBackClick,
	fakeContact,
	selectedAsset,
	amount,
	address,
	eWallet,
	state,
	setChainId,
}: Props) => {
	const { theme, updateTheme } = useContext(ThemeContext);

	const [sessionIdentityType, setSessionIdentityType] = useState<string>("");

	//eslint-disable-next-line
	const inputPropsRef = useRef<any>();

	const { sendAnalytics } = useContext(FirebaseContext);

	const [isError, setIsError] = useState<boolean>(false);

	const [isResendCodeActive, setIsResendCodeActive] = useState<boolean>(false);
	const [counter, setCounter] = useState<number>(
		Number(sessionStorage.getItem("resendIn"))
	);

	const [values, setValues] = useState<string[]>(["", "", "", "", "", ""]);
	const { fields } = usePinInput({
		values,
		onChange: (values: string[]) => {
			setValues(values);
		},
		onComplete: async (values: string) => {
			if (values.length == 6) {
				await confirmTwoFactorCryptoWithdraw(
					sessionStorage.getItem("userToken") as string,
					eWallet,
					selectedAsset.code,
					amount,
					selectedAsset.networkCode,
					selectedAsset.networkName,
					address,
					state,
					values,
					setIsError,
					setValues,
					setChainId,
					handleSubmit,
					sendAnalytics,
					sessionIdentityType
				);
			}
		},
		type: "alphanumeric",
		placeholder: "",
	});

	const { t } = useTranslation();

	useEffect(() => {
		if (counter == 0) {
			setIsResendCodeActive(true);
			setCounter(Number(sessionStorage.getItem("resendIn")));
		}
		const timer = counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		return () => clearInterval(timer as any);
	}, [counter]);

	useEffect(() => {
		setSessionIdentityType(sessionStorage.getItem("loginIdentityType") as string);
		updateTheme();
	}, []);

	const handleResendCode = async () => {
		setIsResendCodeActive(false);
		await refetchLoginOTP(state, sessionIdentityType);
	};

	return (
		<div className={"component-clean" + (theme === "dark" ? "-dark" : "")}>
			<div className={"innerComponent"}>
				<h1 className={"confirmEmailText" + (theme === "dark" ? "-dark" : "")}>
					{t("authenticationText")}
				</h1>
				<h1 className={"confirmCodeText" + (theme === "dark" ? "-dark" : "")}>
					{t(
						sessionIdentityType === "Email"
							? "otpCodeEmailDescription"
							: "otpCodePhoneDescription"
					) + fakeContact}
				</h1>
				<div className={"codeInputBlock"}>
					<PinInput fields={fields} />
					{isError ? (
						<h1 className={"loginErrorText"}>{t("mfCodeErrorText")}</h1>
					) : (
						<div className={"registrationEmailError"} />
					)}
					{isResendCodeActive ? (
						<h1 className={"resendCodeText"} onClick={handleResendCode}>
							{t("registrationResend")}
						</h1>
					) : (
						<h1 className={"resendCodeText-inactive"}>
							{t("registrationResendIn")} {counter}
						</h1>
					)}
				</div>
				<div className={"divider" + (theme === "dark" ? "-dark" : "")}>
					<Divider className={"divider" + (theme === "dark" ? "-dark" : "")} />
				</div>
				<CustomButton
					theme={theme as TTheme}
					isBack={true}
					onClick={handleBackClick}
					className={"backButton"}
				/>
			</div>
		</div>
	);
};

export default CryptoOtp;
