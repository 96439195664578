import React, { ChangeEvent, useContext, useEffect, useState } from 'react';
import './verification.css';
import { useTranslation } from 'react-i18next';
import CustomInput from '../common/input/custom-input';
import CountryModal from '../registration/country-modal/country-modal';
import { Divider } from '@mui/material';
import CustomButton from '../common/button/custom-button';
import Questionary from './questionary/questionary';
import {
	uploadTest,
	uploadUserProfile,
	uploadVerificationPage
} from '../../api/api';
import {
	TCountry,
	TModifiedCountry,
	TQuestionare,
	TTheme,
	TVerificationCheckBox,
	TVerificationEula
} from '../../types/types';
import CircleProgress from '../circle-progress/circle-progress';
import SumsubWebSdk from '@sumsub/websdk-react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ThemeContext } from '../../data/theme-provider';
import { Helmet } from 'react-helmet';
import { Titles } from '../../types/titles';

const locale = window.location.pathname.split('/')[1] != 'en' ? 'ru' : 'en';
const paramEmail = new URLSearchParams(window.location.search).get('email');
const paramPassword = new URLSearchParams(window.location.search).get(
	'password'
);

const Verification = () => {
	const { theme, updateTheme } = useContext(ThemeContext);
	const [firstName, setFirstName] = useState<string>('');
	const [isFirstNameRequired, setIsFirstNameRequired] =
		useState<boolean>(false);
	const [lastName, setLastName] = useState<string>('');
	const [isLastNameRequired, setLastNameRequired] = useState<boolean>(false);
	const [middleName, setMiddleName] = useState<string>('');
	const [isMiddleNameRequired, setMiddleNameRequired] =
		useState<boolean>(false);
	const [dateOfBirth, setDateOfBirth] = useState<string>('');
	const [isDateOfBirthRequired, setDateOfBirthRequired] =
		useState<boolean>(false);
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
	const [eulaCheckBoxes, setEulaCheckBoxes] = useState<
		TVerificationEula | undefined
	>();
	const [questionaries, setQuestionaries] = useState<
		TQuestionare[] | undefined
	>();
	const [country, setCountry] = useState<TModifiedCountry | undefined>(
		undefined
	);
	const [isQuestionary, setIsQuestionary] = useState<boolean>(false);
	const [isSumSub, setIsSumSub] = useState<boolean>(false);
	const [isTestCompleted, setIsTestCompleted] = useState<boolean>(false);
	const [sumSubAccessToken, setSumSubAccessToken] = useState<string>('');
	const [answers, setAnswers] = useState<
		{
			questionId: string;
			answerId: string;
		}[]
	>();
	const [countries, setCountries] = useState<TCountry[]>();

	const [isError, setIsError] = useState<boolean>(false);
	const [errorText, setErrorText] = useState<string>('');
	const [isButtonLoading, setIsButtonLoading] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const navigate = useNavigate();
	const location = useLocation();
	const { t } = useTranslation();

	const initData = async () => {
		sessionStorage.setItem(
			'userToken',
			new URLSearchParams(window.location.search).get('token') as string
		);
		updateTheme();
		setTimeout(async () => {
			const historyState = location.state;
			setIsError(false);
			setErrorText('');
			if (historyState.name) {
				setFirstName(historyState.name);
				setIsFirstNameRequired(true);
			}
			await uploadVerificationPage(
				sessionStorage.getItem('userToken') as string,
				historyState.email
					? historyState.email
					: (paramEmail as string),
				historyState.password
					? historyState.password
					: (paramPassword as string),
				locale,
				setIsError,
				setErrorText,
				setIsLoading,
				setEulaCheckBoxes,
				setQuestionaries,
				setSumSubAccessToken,
				setIsSumSub,
				setIsQuestionary,
				setCountries,
				setFirstName,
				setIsFirstNameRequired
			);
		}, 1000);
	};

	useEffect(() => {
		if (countries) {
			setCountry({
				code: t('verificationChooseCountry'),
				imgUrl: ''
			});
		}
	}, [countries]);

	useEffect(() => {
		setAnswers(
			questionaries?.map(
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				(question: any) =>
					(question = {
						questionId: question.questionKey,
						answerId: ''
					})
			)
		);
	}, [questionaries]);

	useEffect(() => {
		initData();
	}, []);

	if (
		isLoading ||
		questionaries === undefined ||
		answers === undefined ||
		countries === undefined
	) {
		return <CircleProgress thickness={6} size={40} />;
	}

	const handleBackClick = () => {
		navigate(
			`/${locale}/login?token=${
				sessionStorage.getItem('userToken') as string
			}`
		);
	};

	const handleContinueClick = async () => {
		setIsError(false);
		setErrorText('');
		await uploadUserProfile(
			firstName,
			middleName,
			lastName,
			String(
				String(dateOfBirth.split('.')[0]) +
					String(dateOfBirth.split('.')[1]) +
					String(dateOfBirth.split('.')[2])
			),
			countries?.find(
				(countryFromList: TCountry) =>
					countryFromList.name === country?.code
			)?.code as string,
			setIsButtonLoading,
			setIsError,
			setErrorText,
			setIsQuestionary,
			eulaCheckBoxes?.checkBoxes.map(
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				(checkBox: any, idx: number) =>
					(checkBox = {
						checkBoxId: checkBox.checkBoxId,
						isSelected: idx == 0
					})
			) as TVerificationCheckBox[],
			sessionStorage.getItem('accessToken') as string,
			setSumSubAccessToken,
			setIsSumSub
		);
	};

	//checkBox.isRequired ? isChecked : false

	const handleLastNameChange = (e: ChangeEvent<HTMLInputElement>) => {
		setLastName(e.target.value);
		e.target.value.length >= 1
			? setLastNameRequired(true)
			: setLastNameRequired(false);
	};

	const handleMiddleNameChange = (e: ChangeEvent<HTMLInputElement>) => {
		setMiddleName(e.target.value);
		e.target.value.length >= 1
			? setMiddleNameRequired(true)
			: setMiddleNameRequired(false);
	};

	const handleFirstNameChange = (e: ChangeEvent<HTMLInputElement>) => {
		setFirstName(e.target.value);
		e.target.value.length >= 1
			? setIsFirstNameRequired(true)
			: setIsFirstNameRequired(false);
	};

	const handleDateOfBirthChange = (
		e: string | ChangeEvent<HTMLInputElement>
	) => {
		if (typeof e === 'string') {
			setIsError(false);
			const regex = new RegExp(
				/^\s*(3[01]|[12][0-9]|0?[1-9])\.(1[012]|0?[1-9])\.((?:19|20)\d{2})\s*$/
			);
			if (e.length <= dateOfBirth.length) {
				setDateOfBirth(e);
				e.length >= 1
					? setDateOfBirthRequired(true)
					: setDateOfBirthRequired(false);
			}
			if (regex.test(e)) {
				const parsedDate = e.split('.');
				if (
					new Date(
						String(
							parsedDate[2] +
								'/' +
								Number(+parsedDate[1] - 1) +
								'/' +
								parsedDate[0]
						)
					).toString() === 'Invalid Date'
				) {
					setIsError(true);
					setErrorText('verificationDateOfBirthInvalid');
				}
				if (parsedDate[0] > '29' && parsedDate[1] === '02') {
					setDateOfBirth('29.02.' + parsedDate[2]);
					e.length >= 1
						? setDateOfBirthRequired(true)
						: setDateOfBirthRequired(false);
					return;
				}
				setDateOfBirth(e);
				e.length >= 1
					? setDateOfBirthRequired(true)
					: setDateOfBirthRequired(false);
			}
		}
	};

	const handleSelectClick = () => {
		setIsModalOpen(!isModalOpen);
	};

	const handleCountryChange = (value: { code: string; imgUrl: string }) => {
		setCountry({
			code: value.code,
			imgUrl: value.imgUrl
		});
	};

	const handleChangeWIP = (e: ChangeEvent<HTMLInputElement>) => {
		console.log(e);
	};

	const handleLastNameClearClick = () => {
		setLastName('');
		setLastNameRequired(false);
	};

	const handleFirstNameClearClick = () => {
		setFirstName('');
		setIsFirstNameRequired(false);
	};

	const handleMiddleNameClearClick = () => {
		setMiddleName('');
		setMiddleNameRequired(false);
	};

	const handleDateOfBirthClearClick = () => {
		setDateOfBirth('');
		setDateOfBirthRequired(false);
	};

	const handleSubmitTest = async () => {
		setIsError(false);
		setErrorText('');
		await uploadTest(
			sessionStorage.getItem('accessToken') as string,
			answers,
			setIsButtonLoading,
			setIsError,
			setErrorText,
			setIsTestCompleted,
			setIsSumSub,
			setSumSubAccessToken
		);
	};

	const expirationHandler = async () => {
		setIsError(false);
		setErrorText('');
		//await getNewSumSubToken(accessToken, setIsError, setErrorText, setSumSubAccessToken);
	};

	const handleTestPassedSuccessfully = () => {
		setIsQuestionary(false);
		setIsSumSub(true);
	};

	if (isSumSub) {
		return (
			<>
				<Helmet>
					<title>{Titles.VERIFICATION.SUMSUB}</title>
				</Helmet>
				<div
					className={
						'componentBorderless' + (theme === 'dark' ? '' : '')
					}
				>
					<SumsubWebSdk
						accessToken={sumSubAccessToken}
						expirationHandler={expirationHandler}
					/>
				</div>
			</>
		);
	}

	if (isQuestionary) {
		return (
			<>
				<Helmet>
					<title>{Titles.VERIFICATION.QUESTIONARY}</title>
				</Helmet>
				<Questionary
					questionaries={questionaries}
					setIsButtonLoading={setIsButtonLoading}
					isButtonLoading={isButtonLoading}
					answers={answers}
					setAnswers={setAnswers}
					handleSubmitTest={handleSubmitTest}
					isError={isError}
					isTestCompleted={isTestCompleted}
					setIsError={setIsError}
					handleTestPassedSuccessfully={handleTestPassedSuccessfully}
				/>
			</>
		);
	}

	return (
		<>
			<Helmet>
				<title>{Titles.VERIFICATION.INDEX}</title>
			</Helmet>
			<div
				className={
					'componentBorderless' + (theme === 'dark' ? '-dark' : '')
				}
			>
				<div className={'innerComponent'}>
					<CountryModal
						countries={countries}
						isVerificationSelect={true}
						isModalOpen={isModalOpen}
						handleIsModalOpen={handleSelectClick}
						handleItemClick={handleCountryChange}
					/>
					<h1
						className={
							'verificationLogoText' +
							(theme === 'dark' ? '-dark' : '')
						}
					>
						{t('verificationLogoText')}
					</h1>
					<CustomInput
						theme={theme as TTheme}
						isRequired={isFirstNameRequired}
						type={'text'}
						id={'firstName'}
						label={t('registrationName')}
						value={firstName}
						onChange={handleFirstNameChange}
						className={'verificationLastName'}
						onClearClick={handleFirstNameClearClick}
					/>
					<CustomInput
						theme={theme as TTheme}
						isRequired={isMiddleNameRequired}
						type={'text'}
						id={'middleName'}
						label={t('verificationMiddleName')}
						value={middleName}
						onChange={handleMiddleNameChange}
						className={'verificationLastName'}
						onClearClick={handleMiddleNameClearClick}
					/>
					<CustomInput
						theme={theme as TTheme}
						isRequired={isLastNameRequired}
						type={'text'}
						id={'lastName'}
						label={t('verificationLastName')}
						value={lastName}
						onChange={handleLastNameChange}
						className={'verificationLastName'}
						onClearClick={handleLastNameClearClick}
					/>
					<CustomInput
						theme={theme as TTheme}
						isRequired={isDateOfBirthRequired}
						type={'text'}
						id={'dateOfBirth'}
						label={t('verificationDateOfBirth')}
						value={dateOfBirth}
						onChange={handleDateOfBirthChange}
						className={'verificationLastName'}
						onClearClick={handleDateOfBirthClearClick}
						placeHolder={
							locale === 'en' ? 'dd.mm.yyyy' : 'дд.мм.гггг'
						}
						isDateOfBirth={true}
					/>
					<CustomInput
						theme={theme as TTheme}
						isRequired={true}
						type={'text'}
						id={'countryNameSelect'}
						label={t('verificationCitizenship')}
						value={country?.code as string}
						onChange={handleChangeWIP}
						onClearClick={handleSelectClick}
						className={'verificationLastName'}
						isSelectCountry={true}
						imgUrl={country?.imgUrl}
					/>
					{isError ? (
						<h1 className={'registrationEmailError'}>
							{t(errorText as string)}
						</h1>
					) : (
						''
					)}
					<CustomButton
						theme={theme as TTheme}
						isLoading={isButtonLoading}
						isDisabled={
							lastName.length == 0 ||
							dateOfBirth.length < 10 ||
							country?.code ===
								(t('verificationChooseCountry') as string)
						}
						type={'submit'}
						text={t('registrationContinue') as string}
						onClick={handleContinueClick}
						className={'verificationLastName'}
					/>
					<div
						className={
							'divider' + (theme === 'dark' ? '-dark' : '')
						}
					>
						<Divider
							className={
								'divider' + (theme === 'dark' ? '-dark' : '')
							}
						/>
					</div>
					<CustomButton
						theme={theme as TTheme}
						isBack={true}
						onClick={handleBackClick as () => void}
						className={'backButton'}
					/>
				</div>
			</div>
		</>
	);
};

export default Verification;
