import React, {ChangeEvent, useContext, useEffect, useState} from 'react';
import {Backdrop, Fade, Modal} from "@mui/material";
import ExchangeModalItem from "../exchange-modal-item/exchange-modal-item";
import "../exchange.css";
import {useTelegram} from "../../../hooks/useTelegram";
import {TExchangeItem, TTheme} from "../../../types/types";
import CloseIcon from "@mui/icons-material/Close";
import CustomInput from "../../common/input/custom-input";
import {useTranslation} from "react-i18next";
import CustomButton from "../../common/button/custom-button";
import {Whale} from "../../../icons/whale";
import {ThemeContext} from "../../../data/theme-provider";

interface Props {
    label: string;
    data: TExchangeItem[] | undefined;
    isModalOpen: boolean;
    handleIsModalOpen: () => void;
    handleItemClick: (i: TExchangeItem) => void;
    isFrom: boolean;
}
const ExchangeModalSelect = ({data, isModalOpen, handleIsModalOpen, handleItemClick, label, isFrom}: Props) => {
    const {theme} = useContext(ThemeContext);
    const {tg} = useTelegram();
    const [searchValue, setSearchValue] = useState<string>("");
    const [mappedData, setMappedData] = useState<TExchangeItem[]>([]);
    const [isRequired, setIsRequired] = useState<boolean>(false);
    const {t} = useTranslation();

    useEffect(() => {
        setMappedData(data as TExchangeItem[]);
        tg.expand();
    }, []);

    useEffect(() => {
        setMappedData(data as TExchangeItem[]);
        tg.expand();
    },[data]);

    const handleCloseModal = () => {
        handleIsModalOpen();
        setIsRequired(false)
        setSearchValue("");
    }

    useEffect(() => {
        setMappedData(data?.filter((item) => item.code.toLowerCase().includes(searchValue.toLowerCase()) || item.name.toLowerCase().includes(searchValue.toLowerCase())) as TExchangeItem[])
    },[searchValue]);

    const handleSearchValueChange = (e: ChangeEvent<HTMLInputElement>) => {
        setSearchValue(e.target.value);
        e.target.value.length >= 1 ? setIsRequired(true) : setIsRequired(false);
    }

    const handleKeyPress = (e: React.KeyboardEvent) => {
        if(e.key === "Enter" || e.key == "Escape") {
            handleCloseModal();
        }
    }

    return (
        <Modal
            open={isModalOpen}
            onClose={handleCloseModal}
            closeAfterTransition
            disableAutoFocus={true}
            onKeyDown={handleKeyPress}
            slots={{ backdrop: Backdrop }}
            slotProps={{
                backdrop: {
                    timeout: 500,
                },
            }}
        >
            <Fade in={isModalOpen} timeout={200}>
                {mappedData != undefined ?
                    <div className={"registrationModalComponent" + (theme === 'dark' ? '-dark' : '')}>
                        <div className={"exchange-modal-header"}>
                            <div className={"registrationModalHeader"}>
                                <h1 className={"registrationModalHeaderText" + (theme === 'dark' ? '-dark' : '')}>{label}</h1>
                                <CustomButton theme={theme as TTheme} isClose={true} onClick={handleCloseModal} className={'registrationCloseButtonContainer'}>
                                    <CloseIcon sx={{
                                        fill: theme === 'dark' ? 'white' : 'black'
                                    }} className={"registrationCloseButton"}/>
                                </CustomButton>
                            </div>
                            <CustomInput
                                theme={theme as TTheme}
                                className={"registrationModalInput"}
                                id={"registrationModalInput"}
                                value={searchValue}
                                label={t("registrationSearch")}
                                isRequired={isRequired}
                                type={"text"}
                                onChange={handleSearchValueChange}
                                searchInput={true}
                            />
                        </div>
                        {mappedData?.length > 0 ? <div className={"exchangeModalContent"}>
                            {mappedData?.map((item: TExchangeItem) => (
                                <ExchangeModalItem key={item.code+"_"+item.available} isFrom={isFrom} item={item} handleItemClick={handleItemClick} handleIsModalOpen={handleIsModalOpen} setSearchValue={setSearchValue}/>
                            ))}
                        </div> : <div className={"exchangeModalContent-empty"}>
                            <div className={"exchangeModalEmptyContainer"}>
                                <Whale className={'exchangeWhale'}/>
                                <h1 className={'exchangeModalEmptyHeader' + (theme === 'dark' ? '-dark' : '')}>{t("exchangeNotFound")}</h1>
                                <h1 className={"exchangeModalEmptyBottom" + (theme === 'dark' ? '-dark' : '')}>{t("exchangeChangeSettingsText")}</h1>
                            </div>
                        </div>}
                    </div>
                    : <></>}
            </Fade>
        </Modal>
    );
};

export default ExchangeModalSelect;