import React, {useContext} from 'react';
import {TBank} from "../../../types/types";
import {ThemeContext} from "../../../data/theme-provider";

interface Props {
    item: TBank
    handleItemClick: (i: string) => void;
    handleModalOpen: () => void;
    setSearchValue: React.Dispatch<React.SetStateAction<string>>;
}
const PreferredBankItem = (
    {
        item,
        handleItemClick,
        handleModalOpen,
        setSearchValue
    }: Props
) => {
    const {theme} = useContext(ThemeContext);
    const handleClick = () => {
        handleItemClick(item.bankName);
        handleModalOpen();
        setSearchValue("")
    }

    return (
        <div onClick={handleClick} className={"modalItem"}>
            <div key={item.bankKey} className={"modalItemLogo"}>
                <h1 className={"modalItemLogoTextWithoutMargin" + (theme === 'dark' ? '-dark' : '')}>{item.bankName}</h1>
            </div>
        </div>
    );
};

export default PreferredBankItem;