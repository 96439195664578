export const Titles = {
	ANALYTICS: {
		INDEX: 'Analytics'
	},
	BANK_DEPOSIT: {
		INDEX: 'Bank Deposit',
		INVOICE: 'Invoice | Bank Deposit'
	},
	BANK_WITHDRAW: {
		INDEX: 'Bank Withdraw',
		DATA: 'Data | Bank Withdraw',
		SUMMARY: 'Summary | Bank Withdraw',
		EMPTY: 'Empty wallets | Bank Withdraw'
	},
	CARD_DEPOSIT: {
		INDEX: 'Card Deposit'
	},
	CRYPTO_WITHDRAW: {
		INDEX: 'Crypto Withdraw',
		SUMMARY: 'Summary | Crypto Withdraw',
		TWOFA: 'TwoFA | Crypto Withdraw',
		OTP: 'OTP | Crypto Withdraw',
		SELECT_TWOFA: 'Select TwoFa | Crypto Withdraw',
		CONFIRMATION: 'Confirmation | Crypto Withdraw'
	},
	ERROR: {
		INDEX: 'Error'
	},
	EXCHANGE: {
		INDEX: 'Exchange',
		CONFIRMATION: 'Confirmation | Exchange',
		SUMMARY: 'Summary | Exchange',
		EMPTY: 'Empty Wallets | Exchange'
	},
	INTERNAL_TRANSFER: {
		INDEX: 'Internal Transfer',
		SUMMARY: 'Summary | Internal Transfer',
		EMPTY: 'Empty Wallets | Internal Transfer'
	},
	LOGIN: {
		INDEX: 'Login',
		FORGOT_PASSWORD: 'Forgot Password | Login',
		TWOFA: 'TwoFA | Login',
		SETUP: 'Setup TwoFA | Login',
		SECRET_KEY: 'Secret Key | Login',
		SENT: 'Sent | Login',
		REDIRECT: 'Redirect | Login',
		SELECT_TWOFA: 'Select TwoFa | Login',
		OTP: 'OTP | Login',
	},
	VERIFICATION: {
		INDEX: 'Verification',
		QUESTIONARY: 'Questionary | Verification',
		SUMSUB: 'SumSub | Verification'
	},
	REGISTRATION: {
		INDEX: 'Registration',
		EMAIL: 'Email Code | Registration',
		PHONE: 'Pnone | Registration',
		PHONE_CODE: 'Phone Code | Registration',
		SUMMARY: 'Summary | Registration',
		DATA: 'Data | Registration'
	},
	STATEMENT: {
		INDEX: 'Statement',
		SUMMARY: 'Summary | Statement'
	},
	OTC: {
		INDEX: 'OTC',
		EULA: 'Eula | OTC',
		CONFIRMATION: 'Confirmation | OTC',
		SUMMARY: 'Summary | OTC'
	}
};
