import React from 'react';
import UpdatedLogo from "../../../icons/updated-logo";
import "../background/background.css";
import Tails from "../../../icons/tails";

const Background = () => {
    return (
        <div className={"background"}>
            <div className={"logo"}>
                <UpdatedLogo/>
            </div>
            <div className={"tails"}>
                <Tails/>
            </div>
        </div>
    );
};

export default Background;