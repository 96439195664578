import React, {createContext, useState} from 'react';
import {useTelegram} from "../hooks/useTelegram";
import {TTheme} from "../types/types";

const updateTheme = () => {
    console.log('');
}

export const ThemeContext = createContext({
    theme: '',
    updateTheme: updateTheme,
} as {
    theme: string
    updateTheme: typeof updateTheme,
});
const ThemeProvider = ({children}: { children: React.ReactNode }) => {
    const {tg} = useTelegram();
    const [theme, setTheme] = useState('');

    const updateTheme = () => {
        setTheme(tg.colorScheme as TTheme);
    }

    return (
        <ThemeContext.Provider value={{theme, updateTheme}}>
            {children}
        </ThemeContext.Provider>
    );
};

export default ThemeProvider;