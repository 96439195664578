import React, { useContext, useEffect, useState } from "react";
import "../internal-transfer.css";
import { useTelegram } from "../../../hooks/useTelegram";
import { Backdrop, Fade, Modal } from "@mui/material";
import InternalModalItem from "../internal-modal-item/internal-modal-item";
import { TTheme, TWallet } from "../../../types/types";
import CustomButton from "../../common/button/custom-button";
import CloseIcon from "@mui/icons-material/Close";
import { ThemeContext } from "../../../data/theme-provider";

interface Props {
	data: string[];
	wallets: TWallet;
	isModalOpen: boolean;
	handleIsModalOpen: () => void;
	handleItemClick: (i: string) => void;
	selectedItem: string;
	isFrom: boolean;
	label: string;
}
const InternalModalSelect = ({
	selectedItem,
	data,
	isModalOpen,
	handleIsModalOpen,
	handleItemClick,
	wallets,
	isFrom,
	label,
}: Props) => {
	const { theme } = useContext(ThemeContext);
	const { tg } = useTelegram();
	const [mappedData, setMappedData] = useState<string[]>([]);

	useEffect(() => {
		if (isFrom) {
			setMappedData(data.filter((item) => item != selectedItem));
			tg.expand();
			return;
		}
		setMappedData(data);
		tg.expand();
	}, [isModalOpen]);

	const handleCloseModal = () => {
		handleIsModalOpen();
	};

	const handleKeyPress = (e: React.KeyboardEvent) => {
		if (e.key === "Enter" || e.key == "Escape") {
			handleCloseModal();
		}
	};

	return (
		<Modal
			open={isModalOpen}
			onClose={handleCloseModal}
			closeAfterTransition
			disableAutoFocus={true}
			onKeyDown={handleKeyPress}
			slots={{ backdrop: Backdrop }}
			slotProps={{
				backdrop: {
					timeout: 500,
				},
			}}
		>
			<Fade in={isModalOpen} timeout={200}>
				<div
					className={
						"internalModalComponent" + (theme === "dark" ? "-dark" : "")
					}
				>
					<div className={"exchange-modal-header"}>
						<div className={"registrationModalHeader"}>
							<h1
								className={
									"registrationModalHeaderText" +
									(theme === "dark" ? "-dark" : "")
								}
							>
								{label}
							</h1>
							<CustomButton
								theme={theme as TTheme}
								isClose={true}
								onClick={handleCloseModal}
								className={"registrationCloseButtonContainer"}
							>
								<CloseIcon
									sx={{
										fill: theme === "dark" ? "white" : "black",
									}}
									className={"registrationCloseButton"}
								/>
							</CustomButton>
						</div>
					</div>
					<div className={"internal-modal-content"}>
						{mappedData?.map((item) => (
							<InternalModalItem
								key={item}
								currentWallet={selectedItem}
								handleIsModalOpen={handleIsModalOpen}
								item={item}
								handleItemClick={handleItemClick}
								wallets={wallets}
								isFrom={isFrom}
							/>
						))}
					</div>
				</div>
			</Fade>
		</Modal>
	);
};

export default InternalModalSelect;
