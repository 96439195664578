import { useContext, useEffect } from "react";
import { ThemeContext } from "../../../data/theme-provider";
import { useTranslation } from "react-i18next";
import { useTelegram } from "../../../hooks/useTelegram";
import BaseIcon from "../../../icons/base-icon";
import { Divider } from "@mui/material";
import CustomButton from "../../common/button/custom-button";
import { TTheme } from "../../../types/types";
import { Helmet } from "react-helmet";
import { Titles } from "../../../types/titles";
import { FirebaseContext } from "../../../data/firebase-provider";
import { AnalyticsEventsName } from "../../../types/analytics/analytics-events";

interface Props {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	currentAsset: any;
	withdrawFee: string;
	withdrawAmount: string;
	accountName: string;
	bankName: string;
}
const BankSummary = ({
	currentAsset,
	bankName,
	accountName,
	withdrawAmount,
	withdrawFee,
}: Props) => {
	const { sendAnalytics } = useContext(FirebaseContext);
	const { theme } = useContext(ThemeContext);
	const { t } = useTranslation();
	const { tg } = useTelegram();

	const handleCloseClick = () => {
		tg.close();
	};

	const handleSendAnalytics = async () => {
		await sendAnalytics({
			name: AnalyticsEventsName.withdrawSuccess,
			params: {
				withdraw_amount: withdrawAmount,
				withdraw_asset: currentAsset.code.toUpperCase(),
				withdraw_method: "bank",
			},
		});
	};

	useEffect(() => {
		handleSendAnalytics();
	}, []);

	return (
		<>
			<Helmet>
				<title>{Titles.BANK_WITHDRAW.SUMMARY}</title>
			</Helmet>
			<div className={"component-clean" + (theme === "dark" ? "-dark" : "")}>
				<div className={"innerComponent"}>
					<div className={"exchangePaymentLogoContainer"}>
						<img
							className={"exchangePaymentLogo"}
							src={"/success-icon.png"}
							alt={"success"}
						/>
					</div>
					<h1
						className={
							"exchangePaymentHeaderText" +
							(theme === "dark" ? "-dark" : "")
						}
					>
						{t("exchangeOperationStatusPayment")}
					</h1>
					<h1
						className={
							"exchangePaymentBottomText" +
							(theme === "dark" ? "-dark" : "")
						}
					>
						{t("exchangeOperationStatusQueue")}
					</h1>
					<div
						className={
							"exchangeConfirmContainer" + (theme === "dark" ? "-dark" : "")
						}
					>
						<div className={"exchangeConfirmFirstBlock"}>
							<h1
								className={
									"exchangeSellText" + (theme === "dark" ? "-dark" : "")
								}
							>
								{t("cryptoWithdrawTotalWithdrawAmount")}
							</h1>
							<div className={"exchangeAssetBlock"}>
								<span
									className={
										"spanAsymp" + (theme === "dark" ? "-dark" : "")
									}
								>
									&asymp;
								</span>
								<h1
									className={
										"bankWithdrawAmountText" +
										(theme === "dark" ? "-dark" : "")
									}
								>
									{"  "}
									{withdrawAmount}
								</h1>
								<h1
									className={
										"exchangeConfirmText" +
										(theme === "dark" ? "-dark" : "")
									}
								>
									{currentAsset.code.toUpperCase()}
								</h1>
								<BaseIcon
									path={currentAsset.code.toLowerCase()}
									group={"fiat"}
									className={"exchangeConfirmAsset"}
								/>
							</div>
						</div>

						<div className={"exchangeConfirmSecondBlock"}>
							<h1
								className={
									"exchangeSellText" + (theme === "dark" ? "-dark" : "")
								}
							>
								{t("cryptoWithdrawWithdrawFee")}
							</h1>
							<div className={"exchangeAssetBlock"}>
								<span
									className={
										"spanAsymp" + (theme === "dark" ? "-dark" : "")
									}
								>
									&asymp;
								</span>
								<h1
									className={
										"bankWithdrawAmountText" +
										(theme === "dark" ? "-dark" : "")
									}
								>
									{" "}
									{withdrawFee}
								</h1>
								<h1
									className={
										"exchangeConfirmText" +
										(theme === "dark" ? "-dark" : "")
									}
								>
									{currentAsset.code.toUpperCase()}
								</h1>
								<BaseIcon
									path={currentAsset.code.toLowerCase()}
									group={"fiat"}
									className={"exchangeConfirmAsset"}
								/>
							</div>
						</div>

						<div className={"exchangeConfirmSecondBlock"}>
							<h1
								className={
									"exchangeSellText" + (theme === "dark" ? "-dark" : "")
								}
							>
								{t("bankWithdrawAccount")}
							</h1>
							<div className={"exchangeAssetBlock"}>
								<h1
									className={
										"bankWithdrawAmountText" +
										(theme === "dark" ? "-dark" : "")
									}
								>
									{accountName}
								</h1>
							</div>
						</div>

						<div className={"exchangeConfirmSecondBlock"}>
							<h1
								className={
									"exchangeSellText" + (theme === "dark" ? "-dark" : "")
								}
							>
								{t("bankWithdrawBank")}
							</h1>
							<div className={"exchangeAssetBlock"}>
								<h1
									className={
										"bankWithdrawAmountText" +
										(theme === "dark" ? "-dark" : "")
									}
								>
									{bankName}
								</h1>
							</div>
						</div>

						<div className={"divider" + (theme === "dark" ? "-dark" : "")}>
							<Divider
								className={"divider" + (theme === "dark" ? "-dark" : "")}
							/>
						</div>

						<div className={"exchangeConfirmSecondBlock"}>
							<h1
								className={
									"exchangeSellText" + (theme === "dark" ? "-dark" : "")
								}
							>
								{t("cryptoWithdrawCreated")}
							</h1>
							<div className={"exchangeAssetBlock"}>
								<h1
									className={
										"bankWithdrawAmountText" +
										(theme === "dark" ? "-dark" : "")
									}
								>
									{new Date(Date.now()).toUTCString()}
								</h1>
							</div>
						</div>
					</div>
					<CustomButton
						theme={theme as TTheme}
						onClick={handleCloseClick}
						className={"exchangeConfirmButton"}
						text={t("exchangeCloseButton") as string}
					/>
				</div>
			</div>
		</>
	);
};

export default BankSummary;
