import React, {useContext} from 'react';
import {useTranslation} from 'react-i18next';
import {TBankCredentials, TTheme} from "../../../types/types";
import {Backdrop, Fade, Modal} from '@mui/material';
import CustomButton from "../../common/button/custom-button";
import CloseIcon from "@mui/icons-material/Close";
import "../bank-deposit.css";
import CopyIcon from "../../../icons/copy-icon";
import {ThemeContext} from "../../../data/theme-provider";

interface Props {
    bankCredentials: TBankCredentials;
    isModalOpen: boolean;
    handleIsModalOpen: () => void;
}
const PreferredBankModal = ({isModalOpen, handleIsModalOpen, bankCredentials}: Props) => {
    const {theme} = useContext(ThemeContext);
    const {t} = useTranslation();

    const handleCloseModal = () => {
        handleIsModalOpen();
    }

    const handleKeyPress = (e: React.KeyboardEvent) => {
        if(e.key === "Enter" || e.key == "Escape") {
            handleCloseModal();
        }
    }

    return (
        <Modal
            open={isModalOpen}
            onClose={handleCloseModal}
            closeAfterTransition
            disableAutoFocus={true}
            onKeyDown={handleKeyPress}
            slots={{ backdrop: Backdrop }}
            slotProps={{
                backdrop: {
                    timeout: 500,
                },
            }}
        >
            <Fade in={isModalOpen} timeout={200}>
                <div className={"bankCredentialsModalComponent" + (theme === 'dark' ? '-dark' : '')}>
                    <div className={"exchange-modal-header"}>
                        <div className={"registrationModalHeader"}>
                            <h1 className={"registrationModalHeaderText" + (theme === 'dark' ? '-dark' : '')}>{t("bankDepositBankCredentials")}</h1>
                            <CustomButton theme={theme as TTheme} isClose={true} onClick={handleCloseModal} className={'registrationCloseButtonContainer'}>
                                <CloseIcon sx={{
                                    fill: theme === 'dark' ? 'white' : 'black'
                                }} className={"registrationCloseButton"}/>
                            </CustomButton>
                        </div>
                    </div>
                    <div className={"bankDepositCredentialList"}>
                        {Object.keys(bankCredentials).map((key: string) => (
                            <div className={"bankDepositCredentialContainer" + (theme === 'dark' ? '-dark' : '')} key={key}>
                                <h1 className={"bankDepositCredentialsHeader" + (theme === 'dark' ? '-dark' : '')}>{t(key)}</h1>
                                <div className={"bankDepositCredentialsInnerContainer"}>
                                    <h1 className={"bankDepositCredentialsInnerText" + (theme === 'dark' ? '-dark' : '')}>{bankCredentials[key as keyof TBankCredentials]}</h1>
                                    <CopyIcon theme={theme as TTheme} className={"bankDepositCopyIcon"} onClick={() => navigator.clipboard.writeText(bankCredentials[key as keyof TBankCredentials])}/>
                                </div>
                            </div>
                        ))}
                        <div className={"bankDepositCredentialContainerFake" + (theme === 'dark' ? '-dark' : '')} key={'und'}>
                            <h1 className={"bankDepositCredentialsHeaderFake" + (theme === 'dark' ? '-dark' : '')}></h1>
                            <div className={"bankDepositCredentialsInnerContainer"}>
                                <h1 className={"bankDepositCredentialsInnerTextFake" + (theme === 'dark' ? '-dark' : '')}></h1>
                            </div>
                        </div>
                    </div>
                </div>
            </Fade>
        </Modal>
    );
};

export default PreferredBankModal;