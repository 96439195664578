import React, {useContext} from 'react';
import {TTransaction} from "../../../types/types";
import "../analytics.css";
import StatusCircle from "../../status-circle/status-circle";
import {useTranslation} from "react-i18next";
import {ThemeContext} from "../../../data/theme-provider";

interface Props {
    item: TTransaction;
    userLocale: string;
}
const AnalyticsItemTransactions = (props: Props) => {
    const {theme} = useContext(ThemeContext);
    const {t} = useTranslation();
    
    return (
        <div className={"analyticsTransactionsItem"}>
            <div className={"analyticsTransactionsItemFirstContainer"}>
                <h1 className={"analyticsTransactionsItemDate" + (theme === 'dark' ? '-dark' : '')}>{new Intl.DateTimeFormat(`${props.userLocale}-${props.userLocale.toUpperCase()}`, {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric',
                    hour: '2-digit',
                    minute: '2-digit',
                }).format(new Date(props.item.date)).replace(",","")}</h1>
                <h1 className={"analyticsTransactionsItemNaming" + (theme === 'dark' ? '-dark' : '')}>{props.item.type}</h1>
                <div className={"analyticsTransactionsItemStatusContainer"}>
                    <StatusCircle status={props.item.status} locale={t}/>
                    <h1 className={"analyticsTransactionsItemStatus" + (theme === 'dark' ? '-dark' : '')}>{props.item.status}</h1>
                </div>
            </div>
            <div className={"analyticsTransactionsItemSecondContainer"}>
                <h1 className={"analyticsTransactionsItemAmount" + (theme === 'dark' ? '-dark' : '')}>{Number(props.item.amount).toLocaleString('ru-RU', {
                    minimumFractionDigits: props.item.precision,
                }).replace(",", ".")} {props.item.code}</h1>
                {t("operationType") === props.item.type ? (
                    <div className={"analyticsTransactionsExchangeContainer" + (theme === 'dark' ? '-dark' : '')}>
                        <h1 className={"analyticsTransactionsExchangeInfo" + (theme === 'dark' ? '-dark' : '')}>{props.item.from} &gt; {props.item.to}</h1>
                    </div>
                ) : ""}
            </div>
        </div>
    );
};

export default AnalyticsItemTransactions;