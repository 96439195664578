import { useContext } from 'react';
import { ThemeContext } from '../../../../data/theme-provider';

interface OTPModalItemProps {
	item: {
		key: string;
		value: string;
	};
	handleItemClick: (i: { key: string; value: string }) => void;
	handleModalOpen: () => void;
}

const OTPModalItem = ({
	item,
	handleItemClick,
	handleModalOpen
}: OTPModalItemProps) => {
	const { theme } = useContext(ThemeContext);

	const handleClick = () => {
		handleItemClick({
			key: item.key,
			value: item.value
		});
		handleModalOpen();
	};
	return (
		<div onClick={handleClick} className={'modalItem'}>
			<h1
				className={
					'modalOtpItemCode' + (theme === 'dark' ? '-dark' : '')
				}
			>
				{item.key}
			</h1>
		</div>
	);
};

export default OTPModalItem;
